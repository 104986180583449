;(function () {
  'use strict';

  const angular = window.angular;

  angular
    .module('app')
    .directive('assetIcon', function () {
      return {
        restrict: 'E',
        scope: {
          asset: '=',
          color: '='
        },
        controller: ['$scope', 'iconService', AssetIconController],
        template: `<span><img ng-src="{{iconPath}}" alt={{asset.icon}}"></span>`
      };
    });

  function AssetIconController (scope, iconService) {
    scope.iconPath = iconService.getAssetIcon(scope.asset, scope.color);

    scope.$watch('asset', function () {
      scope.iconPath = iconService.getAssetIcon(scope.asset, scope.color);
    });
  }
})();
