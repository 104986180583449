(function () {
  'use strict';

  let angular = window.angular;

  ProfileEditController.$inject = [];

  angular.module('app').component('disableTwoFactorForm', {
    template:'<div ng-class="{transparent: $ctrl.loading}"><div class="modal-header" translate>buttons.disable2fa</div><div class="modal-body text-center"><div class="marginTB20" style="margin-left: 25px; margin-right: 25px; text-align: justify"><p translate>form.2fa.disableMessage1</p><p translate>form.2fa.disableMessage2</p><p><strong translate>form.2fa.disableMessage3</strong></p></div></div><div class="modal-footer"><button class="btn btn-default pull-left" type="button" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" type="button" ng-click="$ctrl.disable2fa()" translate>buttons.disable2fa</button></div></div><div class="alert alert-warning no-margin text-center" ng-show="$ctrl.disableError">{{$ctrl.disableError}}</div><spinner css-class="\'small\'" show-logo="true" show="$ctrl.loading"></spinner>',
    controller: ProfileEditController,
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function ProfileEditController() {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.user = angular.copy(vm.resolve.user);
      vm.Model = vm.resolve.model;
      vm.disable2fa = disable2fa;
    };
    function disable2fa() {
      vm.loading = true;
      vm.Model.prototype$otpDisable({
        id: vm.user.id,
      })
        .$promise.then((result) => {
          vm.loading = false;
          vm.modalInstance.close({
            disabled: true,
          });
        })
        .catch((err) => {
          vm.loading = false;
          vm.disableError = utils.getHTTPError(err);
        });
    }
  }
})();
