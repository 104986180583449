;(function () {
  'use strict';

  const angular = window.angular;

  Controller.$inject = ['$element', '$uibModal', '$state', '$translate'];

  angular
    .module('app')
    .component('varList', {
      template:'<div class="container"><h2><span translate>entities.organization.var.modelNamePl</span><div class="btn-group btn-group-sm pull-right"><button class="btn btn-primary" ng-click="$ctrl.showVarForm()"><i class="fas fa-plus"></i> <span translate>buttons.new</span></button></div></h2><div class="well well-light well-sm no-padding"><div class="margin5"><table id="var-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th><th style="width: 20px"></th><th style="width: 20px"></th><th style="width: 20px"></th></tr></thead></table></div></div></div>',
      controller: Controller,
      bindings: {
        user: '<',
        vars: '<',
        timeZones: '<',
        countries: '<',
      },
    });

  function Controller($element, $uibModal, $state, $translate) {

    let vm = this;

    vm.$onInit = function () {
      vm.showVarForm = showVarForm;
      initVarTable([].concat(vm.vars));
    };

    function showVarForm(_var) {
      let instance = $uibModal
        .open({
          animation: true,
          bindToController: true,
          component: 'varForm',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            var: () => _var,
            timeZones: () => vm.timeZones,
            countries: () => vm.countries,
          },
        });

      instance
        .result
        .then(function (result) {
          if (!result) {
            return;
          }

          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function showVarRemove(_var) {
      let instance = $uibModal
        .open({
          animation: true,
          bindToController: true,
          component: 'varRemove',
          backdrop: 'static',
          keyboard: false,
          resolve: {
            var: function () {
              return _var;
            },
            user: function () {
              return vm.user;
            },
          },
        });

      instance
        .result
        .then(function (result) {
          if (!result) {
            return;
          }

          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function initVarTable(data) {
      let dtOptions = {
        processing: true,
        info: false,
        pageLength: 15,
        lengthMenu: [15, 25, 50, 75, 100],
        data: data,
        columns: [
          {
            data: 'name',
            title: $translate.instant('entities._all.name'),
          }, {
            data: 'id',
            orderable: false,
            render: function (data) {
              return `<a class="btn btn-xs btn-info" href="/member/var/${data}"><i class="fas fa-fw fa-arrow-right"></i></a>`;
            },
          }, {
            data: 'id',
            orderable: false,
            render: function (data) {
              return `<a class="btn btn-xs btn-warning edit" data-var="${data}"><i class="fas fa-fw fa-edit"></i></a>`;
            },
          }, {
            data: 'id',
            orderable: false,
            render: function (data) {
              return `<a class="btn btn-xs btn-danger remove" data-var="${data}"><i class="fas fa-fw fa-times"></i></a>`;
            },
          },
        ],
      };

      let table = $element
        .find('#var-table');

      table.DataTable(dtOptions);
      table
        .on('click', '.edit', function () {
          let varId = $(this).data('var');
          let _var = vm.vars.find(_var => _var.id === varId);

          showVarForm(_var);
        })
        .on('click', '.remove', function () {
          let varId = $(this).data('var');
          let _var = vm.vars.find(_var => _var.id === varId);

          showVarRemove(_var);
        });

    }
  }
})();
