(function () {
  'use strict';

  GaugeRealTimeController.$inject = ['$element', '$timeout', 'AppUtils', '$rootScope'];

  angular.module('app').component('numberGauge', {
    template:'<div class="panel panel-default gauge-panel rt-panel rt-panel-sm"><header class="panel-heading"><h2 class="nowrap"><strong>{{$ctrl.sensor.name}}</strong> <small>{{$ctrl.assetName}}</small></h2></header><div class="panel-body"><div class="chart-header"><div class="btn-group" data-toggle="buttons"><label ng-repeat="type in $ctrl.chartTypes" ng-click="$ctrl.changeType(type)" ng-class="{active: type.id === $ctrl.activeType}" class="btn btn-default btn-xs" title="{{type.name}}"><input type="radio"> <i class="fas fa-{{type.icon}}"></i></label></div></div><div class="chart highcharts-{{$root.darkMode? \'dark\':\'light\'}}"></div><div class="rt-footer chart-footer" ng-if="$ctrl.data.content !== undefined"><div class="value">{{$ctrl.data.content | number: $ctrl.sensor.accuracy}} <span ng-if="$ctrl.sensor.unit">[{{$ctrl.sensor.unit}}]</span></div><div class="date">{{$ctrl.data.from | date: "yyyy-MM-dd HH:mm:ss"}}</div></div><div class="rt-footer chart-footer" ng-if="$ctrl.data && $ctrl.data.content === undefined"><div class="small" translate>errors.NOT_DATA_FOUND</div></div><div class="rt-footer chart-footer" ng-if="!$ctrl.data"><div class="small"><i translate>general.loading</i><i>...</i></div></div></div></div>',
    controller: GaugeRealTimeController,
    bindings: {
      sensor: '<',
      assetName: '<',
      state: '<',
      data: '<',
    },
  });

  function GaugeRealTimeController($element, $timeout, utils, $root) {
    const vm = this;

    vm.$onInit = function () {
      vm.activeType = 'gauge';
      vm.chartTypes = [
        {
          id: 'gauge',
          name: 'Gauge',
          icon: 'tachometer-alt',
        },
        {
          id: 'horizontal',
          name: 'Horizontal Gauge',
          icon: 'ellipsis-h',
        },
        {
          id: 'vertical',
          name: 'Vertical Gauge',
          icon: 'ellipsis-v',
        },
      ];
    };

    vm.$postLink = function () {
      if (!vm.sensor) {
        return;
      }

      vm.chartContainer = $element.find('.chart');

      vm.timeout = $timeout(() => {
        createChart(vm.sensor, vm.chartContainer, vm.state, 'gauge', vm.data);
      }, 100);

      vm.changeType = function (type) {
        if (vm.activeType === type.id) return;

        let chart = vm.chartContainer.highcharts();
        if (chart) {
          chart.destroy();
          chart = null;
        }
        createChart(vm.sensor, vm.chartContainer, vm.state, type.id, vm.data);
        vm.activeType = type;
      };
    };

    vm.$onChanges = function (changes) {
      if (!changes.data || !vm.chartContainer) {
        return;
      }

      const yAxis = getYAxis(vm.sensor, vm.state);
      let newValue = changes.data.currentValue;
      if (!newValue) {
        newValue = vm.data = {};
      }

      let value = isNaN(newValue.content) ? undefined : parseFloat(newValue.content);
      let number = yAxis.min > value ? yAxis.min : value;
      number = yAxis.max < number ? yAxis.max : number;
      try {
        let chart = vm.chartContainer.highcharts();
        chart.series[0].setData([number]);
      } catch (e) {
        console.log('error');
      }
    };

    vm.$onDestroy = function () {
      if (vm.timeout) {
        $timeout.cancel(vm.timeout);
      }

      if (vm.chartContainer && typeof vm.chartContainer.highcharts === 'function') {
        vm.chartContainer.highcharts().destroy();
      }
    };

    function createChart(sensor, target, state, type, data) {
      const value = !data || isNaN(data.content) ? undefined : parseFloat(data.content);

      let opts = JSON.parse(JSON.stringify(chartOptions[type](target)));
      opts.yAxis = getYAxis(sensor, state, type);

      let number = opts.yAxis.min > value ? opts.yAxis.min : value;
      number = opts.yAxis.max < number ? opts.yAxis.max : number;
      opts.series = [
        {
          name: sensor.name,
          data: [number],
        },
      ];

      if (type !== 'gauge') {
        opts.series[0].pointWidth = 10;
        opts.series[0].type = 'scatter';
      }

      target.highcharts(opts);
    }

    function getYAxis(sensor, state, type) {
      let bands = utils.getSensorStates(sensor, state);

      if (!bands) {
        return;
      }

      let min = bands[0].from;
      let max = bands[bands.length - 1].to;

      let ticks = [];

      for (let i = 0; i < bands.length; i++) {
        if (i === 0) {
          ticks.push(bands[i].from);
        }

        ticks.push(bands[i].to);
      }

      let yAxis = {
        title: null,
        min: min,
        max: max,
        minorTickWidth: 0,
        tickWidth: 4,
        tickLength: 6,
        tickColor: $root.darkMode ? '#636363' : '#ffffff',
        lineWidth: 0,
        gridLineWidth: 0,
        tickPositions: ticks,
        plotBands: bands,
        labels: {
          style: {
            fontSize: '9px',
            whiteSpace: 'nowrap',
            textOverflow: 'none',
          },
          formatter: function () {
            if (this.isFirst || this.isLast) {
              return '';
            } else {
              let result = this.value;
              if (this.value > 1000000) {
                result = Math.floor((this.value * 100) / 1000000) / 100 + 'M';
              } else if (this.value > 1000) {
                result = Math.floor((this.value * 100) / 1000) / 100 + 'k';
              }
              return result;
            }
          },
        },
      };

      if (type === 'horizontal') {
        yAxis.labels.y = 15;
      }

      return yAxis;
    }

    const chartOptions = {
      gauge: function (container) {
        let height = container.height() || 157;
        return {
          title: null,
          chart: {
            type: 'gauge',
            animation: false,
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            marginBottom: -height / 2,
            spacingLeft: 0,
            spacingRight: 0,
          },
          tooltip: { enabled: false },
          credits: { enabled: false },
          exporting: { enabled: false },
          legend: { enabled: false },
          plotOptions: {
            gauge: {
              animation: false,
              dataLabels: false,
              dial: {
                radius: '80%',
                backgroundColor: $root.darkMode ? '#636363' : '#c2c2c2',
                baseLength: '100%',
                baseWidth: 2,
                rearLength: '0%',
              },
              pivot: {
                backgroundColor: $root.darkMode ? '#000' : '#fff',
                borderWidth: 2,
              },
            },
          },
          pane: {
            startAngle: -90,
            endAngle: 90,
            background: {
              backgroundColor: 'transparent',
              borderWidth: 0,
            },
          },
        };
      },
      horizontal: function (container) {
        let height = container.height() || 157;
        let width = container.width();

        let top = (height - 35) / 2;

        return {
          title: null,
          chart: {
            type: 'bar',
            animation: false,
            height: top + 25,
            margin: [top, 10, 15, 15],
          },
          credits: { enabled: false },
          exporting: { enabled: false },
          legend: { enabled: false },
          tooltip: { enabled: false },
          xAxis: {
            tickLength: 0,
            lineColor: '#999',
            lineWidth: 1,
          },
          plotOptions: {
            scatter: {
              animation: false,
              marker: {
                lineWidth: 1,
                radius: 8,
              },
            },
          },
          noData: {
            position: {
              verticalAlign: 'bottom',
            },
            style: {
              color: '#fff',
            },
          },
        };
      },
      vertical: function (container) {
        let height = container.height();
        let width = container.width() || 200;

        let left = (width - 35 + 15) / 2;
        return {
          title: null,
          chart: {
            type: 'column',
            animation: false,
            width: left + 25,
            margin: [10, 15, 10, left],
          },
          credits: { enabled: false },
          exporting: { enabled: false },
          legend: { enabled: false },
          tooltip: { enabled: false },
          xAxis: {
            tickLength: 0,
            lineWidth: 0,
          },
          plotOptions: {
            scatter: {
              animation: false,
              marker: {
                lineWidth: 1,
                radius: 8,
              },
            },
          },
          noData: {
            position: {
              verticalAlign: 'top',
            },
            style: {
              color: '#fff',
            },
          },
        };
      },
    };
  }
})();
