;(function () {
  'use strict';

  const angular = window.angular;

  HomeController.$inject = ['$uibModal', '$state'];

  angular
    .module('app')
    .component('memberHome', {
      template:'<div class="page-header"><h1 class="no-margin" style="border-bottom: 0"><i class="fas fa-home fa-fw"></i> <span class="header-text"><span translate>general.welcome</span> {{$ctrl.user.name}}</span></h1></div><div class="container"><h2 class="text-info marginT0" translate>entities.organization.var.modelNamePl</h2><hr class="marginT0"><div class="form-group" ng-if="$ctrl.vars.length > 9"><div class="input-group"><span class="input-group-addon"><i class="fas fa-search fa-fw"></i></span> <input ng-model="$ctrl.searchText" class="form-control" translate-attr="::{placeholder: \'buttons.search\'}"></div></div><div class="home-shortcuts home-shortcuts"><a class="shortcut" ng-repeat="_var in $ctrl.vars | filter: {name: $ctrl.searchText}" ui-sref="member.var.show.home({varId: _var.id})"><div class="shortcut-content"><div class="link-icon"><i class="fas fa-id-card"></i></div><div class="link-text-wrapper"><div class="link-text">{{_var.name}}</div></div></div></a></div></div>',
      controller: HomeController,
      bindings: {
        user: '<',
        vars: '<',
      },
    });

  function HomeController($uibModal, $state) {
    let vm = this;

    vm.$onInit = function () {
    };
  }

})();
