(() => {
  const angular = window.angular;

  DataDisplayController.$inject = ['AppUtils', 'AssetService', 'Sensor'];

  angular.module('app').component('monitoringDataDisplay', {
    template:'<div ng-if="!ctrl.loading && !ctrl.error" class="data-display-container"><div class="data-display-sidebar"><div class="data-display-sidebar-title"><span translate>entities.sensor.detector.detections</span></div><div class="data-display-sidebar-body"><detections-cropper sensor="ctrl.sensor" image="ctrl.image" ng-if="ctrl.image" data="ctrl.data"></detections-cropper></div></div><div class="data-display-detection-object-container"><type-value-display sensor="ctrl.sensor" filter-by-zone="ctrl.filterByZone" asset-name="ctrl.asset.name" copy-objects="false" disable-click="true" on-load-content="ctrl.onLoadContent" data="ctrl.data"></type-value-display></div></div><div ng-if="ctrl.error" style="max-width: 500px; margin: 100px auto 0" class="text-center"><h3 class="marginB20"><span class="fas fa-fw fa-exclamation-triangle text-danger marginB10 fa-2x"></span><br><span translate>errors.DATA_LOAD</span></h3><pre>{{ctrl.error}}</pre></div><spinner show-logo="true" css-class="\'body-bg\'" show="ctrl.loading || ctrl.loadingImage"></spinner>',
    controllerAs: 'ctrl',
    controller: DataDisplayController,
    bindings: {
      $transition$: '<',
      customer: '<',
    },
  });

  function DataDisplayController(utils, AssetService, Sensor) {
    const vm = this;

    vm.$onInit = function () {
      const params = vm.$transition$.params();

      vm.loadingImage = true;
      vm.onLoadContent = onLoadImage;

      vm.filterByZone = params.zoneFilter === 'true';
      const dataId = parseInt(params.dataId);
      let from = new Date(dataId);

      let localDate = new Date(from);
      let detailedDate = {
        year: localDate.getUTCFullYear(),
        month: localDate.getUTCMonth(),
        day: localDate.getUTCDate(),
        hour: localDate.getUTCHours(),
        minute: localDate.getUTCMinutes(),
      };

      let datasetDate = new Date(
        Date.UTC(
          detailedDate['year'],
          detailedDate['month'],
          detailedDate['day'],
          detailedDate['hour'],
          detailedDate['minute'] - (detailedDate['minute'] % 5)
        )
      );

      vm.loading = true;

      const requests = [
        {
          function: AssetService.findById,
          args: [
            params.assetId,
            {
              include: {
                relation: 'sensors',
                scope: {
                  fields: ['id', 'name', 'type', 'parameters'],
                  where: { id: params.sensorId },
                },
              },
            },
          ],
        },
        {
          function: Sensor.prototype$__get__datasets,
          args: [
            {
              id: params.sensorId,
              filter: {
                fields: ['id'],
                where: {
                  from: datasetDate.getTime(),
                },
              },
            },
          ],
        },
      ];

      utils
        .parallelPromises(requests)
        .then((results) => {
          const { success, errors } = results;
          const error = errors.some((err) => !!err);
          if (error) {
            throw error;
          }

          vm.asset = success[0];
          vm.sensor = vm.asset.sensors[0];
          const dataset = success[1][0];

          return Sensor.prototype$__findById__datasets__data({
            id: vm.sensor.id,
            nk: dataset.id,
            fk: dataId,
          }).$promise;
        })
        .then((data) => {
          vm.data = data;
          vm.loading = false;
        })
        .catch((err) => {
          vm.loading = false;
          vm.loadingImage = false;
          vm.error = utils.getHTTPError(err);
          console.log(vm.error);
        });
    };

    function onLoadImage(err, image) {
      vm.loadingImage = false;
      if (!image) {
        vm.error = new Error('Image not found');
        return;
      }
      vm.image = image;
    }
  }
})();
