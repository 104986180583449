;(function () {
  const angular = window.angular;

  ManagersHandlerHomeController.$inject = ['Customer', 'AppUtils', 'Manager', 'PeopleCounterReport', 'TrafficFlowAnalysis', '$element', '$state', '$uibModal'];

  angular
    .module('app')
    .component('managersHandlerHome', {
      template:'<div class="managers-handler"><div ng-style="{opacity: ctrl.loading? 0: 1}"><div class="dataTable-toolbar paddingT0"><div class="btn-group btn-group-sm"><button class="btn btn-default btn-label disabled" translate>general.actions</button> <button class="btn btn-primary" ng-click="ctrl.addUser()" style="min-width: 90px">Add / Remove</button></div></div><table id="managers-handler-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th>Name</th><th>Username</th><th>Email</th></tr></thead></table></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner></div>',
      controller: ManagersHandlerHomeController,
      controllerAs: 'ctrl',
      bindings: {
        $transition$: '<',
        customer: '<',
        modelName: '<',
        instance: '<',
      },
    });

  function ManagersHandlerHomeController (Customer, utils, Manager, PeopleCounterReport, TrafficFlowAnalysis, $element, $state, $uibModal) {
    let vm = this;
    vm.$onInit = function () {
      vm.models = {
        PeopleCounterReport: PeopleCounterReport,
        TrafficFlowAnalysis: TrafficFlowAnalysis,
      };
      vm.methods = {
        PeopleCounterReport: 'peopleCounterReports',
        TrafficFlowAnalysis: 'trafficFlowAnalysis',
      };

      vm.managers = [];
      vm.Model = vm.models[vm.modelName];
      vm.methodName = vm.methods[vm.modelName];
      vm.loading = true;
      loadManagers();

      vm.addUser = addUser;
    };

    function loadManagers () {
      let Model = vm.Model;
      vm.loading = true;

      Model
        .prototype$__get__managers({
          id: vm.instance.id,
        })
        .$promise
        .then((managers) => {
          vm.managers = managers;
          initUserTable(vm.managers);
          vm.loading = false;
        })
        .catch((err) => {
          console.error(utils.getHTTPError(err));
        });
    }

    function addUser () {
      let instance = $uibModal
        .open({
          animation: false,
          keyboard: false,
          size: 'lg',
          backdrop: 'static',
          bindToController: true,
          component: 'managersHandlerSelector',
          resolve: {
            customer: vm.customer,
            instance: vm.instance,
            methodName: function () {
              return vm.methodName;
            },
            userType: function () {
              return vm.userType || 'manager';
            },
            selected: function () {
              return vm.managers.map(user => user.id);
            },
          },
        });

      instance
        .result
        .then(function (result) {
          loadManagers();
          return null;
        })
        .catch(function (err) {
          console.error(err);
          loadManagers();
          return null;
        });
    }

    function initUserTable (data) {
      let dtOptions = {
        dom: '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
          '<div tr>' +
          '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        data: data,
        columns: [
          {
            data: 'name',
            render: function (data, type, current) {
              return current.name + ' ' + (current.surname || '');
            },
          },
          {
            data: 'username',
            defaultContent: '',
          },
          {
            data: 'email',
            defaultContent: '',
          },
        ],
      };

      if (vm.dtInstance) {
        vm.dtInstance.clear();
        vm.dtInstance.rows.add(data);
        vm.dtInstance.draw();
      } else {
        vm.dtInstance = $element.find('#managers-handler-table').DataTable(dtOptions);
      }
    }

  }
})();
