;(function () {
  'use strict';
  angular
    .module('app')
    .constant('THRESHOLDS', {
      NONE: [],
      ND: ['#609450', '#dc2127'],
      DN: ['#dc2127', '#609450'],
      NWD: ['#609450', '#fbd75b', '#dc2127'],
      DWN: ['#dc2127', '#fbd75b', '#609450'],
      DND: ['#dc2127', '#609450', '#dc2127'],
      NDN: ['#609450', '#dc2127', '#609450'],
      DWNWD: ['#dc2127', '#fbd75b', '#609450', '#fbd75b', '#dc2127'],
      NWDWN: ['#609450', '#fbd75b', '#dc2127', '#fbd75b', '#609450']
    })
    .constant('PALETTE', [
      ['#000000', '#434343', '#666666', '#cccccc', '#d9d9d9', '#ffffff'],
      ['#ff0000', '#ff9900', '#ffff00', '#00ff00', '#00ffff', '#4a86e8', '#0000ff', '#9900ff'],
      ['#f4cccc', '#fce5cd', '#fff2cc', '#d9ead3', '#d0e0e3', '#c9daf8', '#cfe2f3', '#d9d2e9'],
      ['#ea9999', '#f9cb9c', '#ffe599', '#b6d7a8', '#a2c4c9', '#a4c2f4', '#9fc5e8', '#b4a7d6'],
      ['#e06666', '#f6b26b', '#ffd966', '#93c47d', '#76a5af', '#6d9eeb', '#6fa8dc', '#8e7cc3'],
      ['#cc0000', '#e69138', '#f1c232', '#6aa84f', '#45818e', '#3c78d8', '#3d85c6', '#674ea7'],
      ['#660000', '#783f04', '#7f6000', '#274e13', '#0c343d', '#1c4587', '#073763', '#20124d']
    ])
    .constant('LANGS', [
      'es', 'en'
    ]);
})();
