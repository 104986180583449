;(function () {
  'use strict';

  const angular = window.angular;

  angular
    .module('app')
    .directive('viewSidebar', () => {
      return {
        restrict: 'E',
        scope: {
          title: '=',
          size: '=',
          position: '=',
        },
        replace: true,
        transclude: true,
        controller: ['$rootScope', '$scope', ViewSidebarController],
        controllerAs: 'ctrl',
        template: `<div class="view-sidebar {{size? 'view-sidebar-' + size : ''}} {{position? 'view-sidebar-' + position : ''}}" ng-class="{'collapse': !ctrl.show}">
          <div class="view-sidebar-toggle-bar text-right">
            <view-sidebar-toggle hide-container="true"></view-sidebar-toggle>
          </div>
          <div ng-transclude></div>
        </div>`,
      };
    })
    .directive('viewSidebarTitle', () => {
      return {
        restrict: 'E',
        replace: true,
        template: '<div class="view-sidebar-title" ng-transclude></div>',
        transclude: true,
        require: '^viewSidebar',
      };
    })
    .directive('viewSidebarContent', () => {
      return {
        restrict: 'E',
        replace: true,
        template: '<div class="view-sidebar-content" ng-transclude></div>',
        transclude: true,
        require: '^viewSidebar',
      };
    });

  function ViewSidebarController ($root, $scope) {
    let vm = this;
    vm.show = true;


    const stopWatch = $root.$on('view-sidebar.toggle', function () {
      vm.show = !vm.show;
    });

    $scope.$on('$destroy', () => {
      stopWatch();
    });
  }

})();
