;(function () {
  'use strict';

  const angular = window.angular;

  ToolRemoveController.$inject = ['AppUtils', 'Var', 'Customer', '$translate', 'LocalStorageService'];

  angular
    .module('app')
    .component('removeTool', {
      template:'<div style="position: relative"><div class="modal-header"><span translate>form.actions.remove</span> <span translate>entities.tool.modelName</span></div><div ng-style="{opacity: $ctrl.loading? 0: 1}"><div class="modal-body text-center"><h4 translate class="text-danger">general.attention</h4><div ng-bind-html="$ctrl.warningMessage"></div></div><div class="alert alert-warning no-margin text-center" ng-if="$ctrl.unlinkError">{{$ctrl.unlinkError}}</div><div class="modal-footer"><button class="btn btn-default btn-sm pull-left" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger btn-sm" ng-click="$ctrl.unlinkTool()" translate>buttons.imSure</button></div></div><spinner show="$ctrl.loading" show-logo="true" css-class="\'small\'"></spinner></div>',
      controller: ToolRemoveController,
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    });

  function ToolRemoveController(utils, Var, Customer, $translate, LocalStorageService) {
    let vm = this;

    vm.$onInit = function () {
      vm.entityType = vm.resolve.entityType;
      vm.entityId = vm.resolve.entityId;
      vm.tool = vm.resolve.tool;

      const target = vm.resolve.target || 'tools';

      vm.organizationId = vm.resolve.organizationId;
      switch (vm.entityType) {
        case 'toolkit':
          vm.unlinkMethod = Var[`prototype$__unlink__toolkits__${target}`];
          vm.warningMessage = $translate.instant('entities.toolkit.messages.removeTool', {tool: vm.tool.name});
          break;
        case 'project':
          vm.unlinkMethod = Customer[`prototype$__unlink__projects__${target}`];
          vm.warningMessage = $translate.instant('entities.project.messages.removeTool', {tool: vm.tool.name});
          break;
      }

      if (!vm.unlinkMethod || !vm.entityId || !vm.tool) {
        return;
      }

      vm.unlinkTool = unlinkTool;
    };

    function unlinkTool() {
      vm.loading = true;
      vm.unlinkMethod({
        id: vm.organizationId,
        nk: vm.entityId,
        fk: vm.tool.id,
      })
        .$promise
        .then(result => {
          vm.loading = false;
          LocalStorageService.clear();
          vm.modalInstance.close(result);
        })
        .catch(err => {
          vm.unlinkError = utils.getHTTPError(err).message;
          vm.loading = false;
          console.log(vm.unlinkError);
        });
    }
  }

})();
