;(function () {
  'use strict';

  let angular = window.angular;

  EmbeddedRemoveController.$inject = ['Customer', 'AppUtils'];

  angular
    .module('app')
    .component('embeddedRemove', {
      template:'<div ng-style="{opacity: ctrl.loading? 0: 1}"><div class="modal-header"><span>Remove Report</span></div><div class="modal-body text-center"><p>You are about to remove the report <strong>{{ctrl.report.name}}</strong>, this operation can\'t be undone</p><h5 class="marginB5"><strong>Are you Sure?</strong></h5></div><div ng-if="ctrl.removeReportError" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.removeReportError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" ng-click="ctrl.removeReport()" translate>buttons.delete</button></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
      controller: EmbeddedRemoveController,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function EmbeddedRemoveController (Customer, utils) {
    let vm = this;

    vm.$onInit = function onInit () {
      vm.customer = vm.resolve.customer;
      vm.project = vm.resolve.project;
      vm.report = vm.resolve.report;
      vm.removeReport = removeReport;
    };

    function removeReport () {
      if (!vm.report) {
        return;
      }

      vm.loading = true;
      Customer
        .prototype$__destroyById__projects__embeddedReports({
          id: vm.customer.id,
          nk: vm.project.id,
          fk: vm.report.id
        })
        .$promise
        .then(function () {
          vm.loading = false;
          vm.modalInstance.close();
        })
        .catch(function (err) {
          vm.loading = false;
          let error = utils.getHTTPError(err);
          vm.removeReportError = error.code || error.message;
        });
    }
  }
})();
