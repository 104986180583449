(function () {
  'use strict';

  let angular = window.angular;

  storeHeatmapRouter.$inject = ['$stateProvider'];

  angular.module('app').config(storeHeatmapRouter);

  function storeHeatmapRouter($stateProvider) {
    $stateProvider.state('member.var.show.customer.show.project.storeHeatmap', {
      url: '/store-heatmap/:assetId',
      component: 'storeHeatmapComponent',
      authenticated: true,
      params: {
        assetId: {
          type: 'string',
          dynamic: true,
          squash: true,
        },
      },
      ncyBreadcrumb: {
        label: 'Store Heatmap',
      },
      resolve: {
        dependencies: [
          '$ocLazyLoad',
          '$rootScope',
          function ($ocLazyLoad, $root) {
            const style = $root.darkMode ? 'dark' : 'light';
            return $ocLazyLoad.load([
              window.assetsPath + `/css/store-heatmap.style-${style}.css`,
              window.assetsPath + '/js/app/store-heatmap.module.min.js',
            ]);
          },
        ],
      },
    });
  }
})();
