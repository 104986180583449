;(function () {
  'use strict';

  const angular = window.angular;
  angular
    .module('app')
    .directive('jsonDiff', function () {
      return {
        restrict: 'E',
        scope: {
          json1: '<',
          json2: '<'
        },
        link: renderDiff,
        template: `<div class="json-diff-container"></div>`
      };
    });

  function renderDiff (scope, element) {
    const jsonContainer = element.find('.json-diff-container')[0];
    if (!jsonContainer) {
      return;
    }

    rerender(jsonContainer, scope.json1, scope.json2);

    scope.$watch('json1', function (v) {
      rerender(jsonContainer, scope.json1, scope.json2);
    });
    scope.$watch('json2', function (v) {
      rerender(jsonContainer, scope.json1, scope.json2);
    });
  }

  function rerender (container, json1, json2) {
    const delta = window.jsondiffpatch.diff(json1, json2);
    container.innerHTML = window.jsondiffpatch.formatters.html.format(delta);
  }
})();
