;(function () {
  angular
    .module('app')
    .directive('simplemde', ['$parse', function ($parse) {
      return {
        restrict: 'A',
        require: 'ngModel',
        controller: ['$scope', function ($scope) {
          return {
            get: function () {
              return $scope.simplemde.instance;
            },
            rerenderPreview: function (val) {
              return $scope.simplemde.rerenderPreview(val);
            }
          };
        }],
        link: function (scope, element, attrs, ngModel) {
          let options, rerenderPreview;
          options = $parse(attrs.simplemde)(scope) || {};
          options.element = element[0];
          const mde = new SimpleMDE(options);
          mde.codemirror.on('change', function () {
            scope.$applyAsync(function () {
              ngModel.$setViewValue(mde.value());
            });
          });

          if (attrs.maxlength) {
            let maxlength = parseInt(attrs.maxlength);
            mde.codemirror.on('beforeChange', function (instance, change) {
              let current = mde.value();

              if (change.origin === '+delete' || change.origin === 'cut') {
                return;
              }

              if (current.length >= maxlength) {
                change.cancel();
              }

              const newText = [];
              let text = '';
              for (let i = 0; i < change.text.length; i++) {
                const line = change.text[i];
                const lineLen = line ? line.length : 1;
                if (current.length + lineLen < maxlength) {
                  text += line;
                  newText.push(line);
                } else {
                  newText.push(line.substring(0, maxlength - current.length - text.length));
                  break;
                }
              }

              change.text = newText;
            });
          }

          ngModel.$render = function () {
            const val = ngModel.$modelValue || options['default'];
            mde.value(val);
            if (mde.isPreviewActive()) {
              rerenderPreview(val);
            }
          };
          rerenderPreview = function (val) {};
          scope.simplemde = {
            instance: mde,
            rerenderPreview: rerenderPreview
          };
        }
      };
    }
    ]);

})();
