(() => {
  Controller.$inject = ['$element', 'AppUtils', 'DetectionCanvasUtilsService'];

  window.angular.module('app').component('imageSequence', {
    template: `<div class="bg-image-container">
            <div ng-if="originalSize" class="strong" style="position: absolute; top: 0; right: 0; background: #ffffff; opacity: .6; padding: 0 2px" 
            title="{{ctrl.originalSize.width}}x{{ctrl.originalSize.height}}">
              {{ctrl.originalSize.width}}x{{ctrl.originalSize.height}}
            </div>
            <div class="img-target" style="margin: auto; overflow-y: hidden"></div>
            <spinner show="ctrl.loading && !ctrl.disableSpinner" css-class="ctrl.spinnerClass"></spinner>
        </div>`,
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      imagesSrc: '<',
      interval: '<',
      onLoadContent: '=',
      dimensions: '<',
      spinnerClass: '<',
      disableSpinner: '<?',
      maxHeight: '<',
      data: '<',
      sensor: '<',
      filterByZone: '<',
    },
  });

  function Controller($element, utils, DetectionCanvasUtilsService) {
    const vm = this;
    let defaultRatio = 1.77777777778;

    vm.$onInit = () => {
      vm.interval = vm.interval || 1;
      vm.spinnerClass = vm.spinnerClass || 'xs light';
    };

    vm.$onChanges = () => {
      vm.imagesSrc = vm.imagesSrc || [];
      if (!vm.linked) {
        return;
      }

      renderSequence();
    };

    vm.$postLink = () => {
      vm.container = $element.find('.bg-image-container');
      vm.imageTarget = $element.find('.img-target');
      vm.linked = true;
      renderSequence();
    };

    function renderSequence() {
      calcContainerDimensions();

      const requests = vm.imagesSrc.map((src) => {
        return { function: downloadImage, args: [src] };
      });

      vm.loading = true;
      return utils
        .parallelPromises(requests)
        .then((result) => {
          const images = result.success.filter((image) => !!image);
          if (vm.onLoadContent) {
            vm.onLoadContent(null, images);
          }
          return images;
        })
        .then((images) => {
          return generateGif(images);
        })
        .then(() => {
          vm.loading = false;
        })
        .catch((err) => {
          vm.loading = false;
        });
    }

    function downloadImage(src) {
      return new utils.Promise((resolve, reject) => {
        let image = new window.Image();
        image.src = src;
        image.crossOrigin = 'anonymous';
        image.onload = function () {
          resolve(image);
        };
        image.onerror = function (err) {
          reject(image);
        };
      });
    }

    function generateGif(images) {
      const size = {};
      const firstImage = images[0];
      vm.dimensions.originalSize = vm.originalSize = {
        height: firstImage.naturalHeight,
        width: firstImage.naturalWidth,
      };

      let imageRatio = firstImage.naturalWidth / firstImage.naturalHeight;
      const containerRatio = vm.containerWidth / vm.containerHeight;
      if (imageRatio > containerRatio) {
        size.width =
          firstImage.naturalWidth > vm.containerWidth ? vm.containerWidth : firstImage.naturalWidth;
        size.height = Math.floor((firstImage.naturalHeight * size.width) / firstImage.naturalWidth);
      } else {
        size.height =
          firstImage.naturalHeight > vm.containerHeight
            ? vm.containerHeight
            : firstImage.naturalHeight;
        size.width = Math.floor((firstImage.naturalWidth * size.height) / firstImage.naturalHeight);
      }

      vm.dimensions.ratio = Math.round((size.width * 1000) / firstImage.naturalWidth) / 1000;

      const left = (vm.containerWidth - size.width) / 2;
      const top = (vm.containerHeight - size.height) / 2;

      const canvasList = renderDetections(images, vm.filterByZone);

      if (canvasList?.length) {
        images = canvasList.map((canvas) => canvas.toDataURL());
      }

      return new utils.Promise((resolve, reject) => {
        const gifWidth = firstImage.naturalWidth > 720 ? 720 : firstImage.naturalWidth;
        const gifHeight = (gifWidth * size.height) / size.width;

        gifshot.createGIF(
          {
            interval: vm.interval,
            gifWidth: gifWidth,
            gifHeight: gifHeight,
            images,
          },
          (obj) => {
            if (obj.error) {
              return reject(obj.error);
            }

            const imgContainer = $('<div/>');
            const gif = document.createElement('img');
            gif.src = obj.image;

            imgContainer.width(size.width);
            imgContainer.height(size.height);
            imgContainer.css({ paddingTop: top, marginLeft: left });
            imgContainer.append(gif);

            vm.imageTarget.empty();
            vm.imageTarget.prepend(imgContainer);
            vm.dimensions.width = size.width;
            vm.dimensions.height = size.height;
            vm.dimensions.offset = { top: top, left: left };
            if (vm.onLoadContent) {
              vm.onLoadContent(null, gif);
            }

            resolve();
          }
        );
      });
    }

    function calcContainerDimensions() {
      vm.imageTarget.hide();
      const width = vm.container.width();
      const height = vm.maxHeight || vm.container.height();
      vm.containerWidth = width > 150 ? Math.floor(width) : 150;
      vm.imageTarget.show();

      if (!height) {
        vm.containerHeight = vm.containerWidth / defaultRatio;
      } else {
        const ratio = width / height;
        if (ratio > defaultRatio) {
          vm.containerHeight = height;
          vm.containerWidth = height * defaultRatio;
        } else {
          vm.containerHeight = vm.containerWidth / defaultRatio;
        }
      }

      vm.imageTarget.width(vm.containerWidth);
      vm.imageTarget.height(vm.containerHeight);
    }

    function renderDetections(images, filterByZone = true) {
      if (!vm.data || !vm.sensor) {
        return;
      }

      const detectionsFields = ['detections', 'objects'];

      return images
        .filter((c, i) => {
          const fileMeta = vm.data.content.files.sequence[i];
          return !!fileMeta;
        })
        .map((image, i) => {
          const fileMeta = vm.data.content.files.sequence[i];
          const data = structuredClone(vm.data);

          for (const key of detectionsFields) {
            if (data.content[key]) {
              data.content[key] = data.content[key].filter(
                (c) => c.frameId === fileMeta.originalFilename
              );
            }
          }

          return DetectionCanvasUtilsService.drawDetections({
            image,
            data: data,
            sensor: vm.sensor,
            drawLabels: false,
            drawZone: true,
            drawOverImage: true,
            filterByZone: filterByZone,
          });
        });
    }
  }
})();
