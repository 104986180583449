/**
 * https://gist.github.com/haorui/ca3137a013d86eb9387f2998c9925596
 */
(function () {
  UITreeSelect.$inject = ['groupFactory', '$compile'];

  window.angular
    .module('app')
    .directive('uiTreeSelect', UITreeSelect)
    // Couldn't get on-focus to work, so wrote my own
    .directive('uiSelectFocuser', [
      '$timeout',
      function ($timeout) {
        return {
          restrict: 'A',
          require: '^uiSelect',
          link: function (scope, elem, attrs, uiSelect) {
            scope.$on('uiSelectFocus', function () {
              $timeout(uiSelect.activate);
            });
          },
        };
      },
    ])

    .factory('groupFactory', [
      function () {
        return {
          prepare: function (data, parentField, labelField) {
            parentField = parentField || 'parentId';
            const result = { 0: [] };
            data.forEach((current) => {
              const parentId = current[parentField];
              if (!parentId) {
                result[0].push(current);
              } else {
                result[parentId] = result[parentId] || [];
                result[parentId].push(current);
              }
            });

            const collator = new Intl.Collator(undefined, {
              numeric: true,
              sensitivity: 'base',
            });

            for (let id in result) {
              result[id].sort((a, b) => collator.compare(a[labelField], b[labelField]));
            }

            return result;
          },
          load: function (tree, id) {
            return tree[id];
          },
        };
      },
    ])

    .run([
      '$templateCache',
      function ($templateCache) {
        // Overrides select2 template for group select tree.
        $templateCache.put(
          'select2/choices.tpl.html',
          `
      <ul tabindex="-1" class="ui-select-choices ui-select-choices-content select2-results">
      <li ng-show="breadcrumbs.length > 1" class="ui-select-breadcrumbs">
      <span class="ui-breadcrumb" title="{{crumb[labelField]}}" ng-repeat="crumb in breadcrumbs" ng-click="navigateBackTo(crumb, $select)">
      {{crumb[labelField]}}
</span>
</li>
  <li class="ui-select-choices-group" ng-class="{'select2-result-with-children': $select.choiceGrouped($group) }">
    <div ng-show="$select.choiceGrouped($group)" class="ui-select-choices-group-label select2-result-label" ng-bind="$group.name"></div>
    <ul
      id="ui-select-choices-{{ $select.generatedId }}" ng-class="{'select2-result-sub': $select.choiceGrouped($group), 'select2-result-single': !$select.choiceGrouped($group) }">
      <li role="option" ng-attr-id="ui-select-choices-row-{{ $select.generatedId }}-{{$index}}" class="ui-select-choices-row" ng-class="{'select2-highlighted': $select.isActive(this), 'select2-disabled': $select.isDisabled(this)}">
        <div class="select2-result-label ui-select-choices-row-inner"></div>
      </li>
    </ul>
  </li>
</ul>`
        );
      },
    ]);

  function UITreeSelect(groupFactory, $compile) {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        ngModel: '=',
        ngChange: '&',
        data: '=',
        ngDisabled: '=?',
        placeholder: '@?',
        theme: '=?',
        labelField: '=?',
        parentField: '=?',
      },
      link: function (scope, el, attrs, ctrl) {
        scope.data = scope.data || [];
        scope.theme = scope.theme || 'select2';
        scope.placeholder = scope.placeholder || 'Select a group';
        scope.labelField = scope.labelField || 'title';

        scope.model = {};

        let hash = {};

        function initTree() {
          const first = { id: 0 };
          first[scope.labelField] = 'All';
          scope.breadcrumbs = [first];

          hash = {};
          scope.data.forEach((current) => {
            hash[current.id] = current;
          });
          const data = (scope.tree = groupFactory.prepare(
            scope.data,
            scope.parentField,
            scope.labelField
          ));
          scope.groups = groupFactory.load(data, 0) || [];
        }

        initTree();
        scope.loadChildGroupsOf = function (group, $select, noFocus) {
          $select.search = '';

          if (!group) {
            return;
          }

          scope.breadcrumbs.push(group);
          scope.groups = groupFactory.load(scope.tree, group.id);
          if (!noFocus) {
            scope.$broadcast('uiSelectFocus');
          }
        };

        scope.navigateBackTo = function (crumb, $select) {
          $select.search = '';
          const index = scope.breadcrumbs.findIndex((current) => current.id === crumb.id);

          scope.breadcrumbs.splice(index + 1, scope.breadcrumbs.length);
          const length = scope.breadcrumbs.length;
          const last = length ? scope.breadcrumbs[length - 1] : undefined;
          scope.groups = groupFactory.load(scope.tree, last ? last.id : undefined);
          $select.open = false;
          scope.$broadcast('uiSelectFocus');
        };

        scope.onChange = function (item) {
          ctrl.$setViewValue(item);
        };

        scope.$watch('ngModel', function (newValue) {
          if (newValue !== scope.model.value) {
            scope.model.value = newValue;
            const element = scope.data.find((current) => current.id === newValue);
            if (element) {
              scope.loadChildGroupsOf(hash[element[scope.parentField]], {}, true);
            }
          }
        });

        scope.$watch('data', function (newValue) {
          initTree();
        });

        scope.getSelectedLabel = function (val) {
          if (val == null) {
            return '';
          }

          if (typeof val === 'object') {
            return val[scope.labelField] || '';
          }

          const element = scope.data.find((current) => current.id === val);
          return element ? element[scope.labelField] : '';
        };

        const template = getTemplate(scope.theme, scope.placeholder);
        el.append($compile(template)(scope));
      },
    };
  }

  function getTemplate(theme, placeholder) {
    return `<ui-select
  ng-model="model.value"
  ng-change="onChange($select.selected.id)"
  ui-select-focuser
  ng-disabled="ngDisabled"
  theme="${theme}">
  <ui-select-match placeholder="${placeholder}">
    {{ getSelectedLabel($select.selected) }}
  </ui-select-match>

  <ui-select-choices repeat="group.id as group in groups | filter: $select.search">
    <div>
      <span ng-bind-html="group[labelField] | highlight: $select.search"></span>
      <a href
         ng-show="tree[group.id].length"
         class="goto-child-group"
         ng-click="loadChildGroupsOf(group, $select)">
        <i class="fa fa-fw fa-angle-right"></i>
      </a>
    </div>
  </ui-select-choices>
</ui-select>`;
  }
})();
