;(function () {
  'use strict';

  let angular = window.angular;

  Controller.$inject = ['Var', 'AppUtils'];

  angular
    .module('app')
    .component('varRemove', {
      template:'<div class="modal-header"><span translate>buttons.delete</span> <span translate>entities.organization.var.modelName</span></div><div class="modal-body text-center"><p>You are about to remove the var <strong>{{$ctrl.var.name}}</strong></p><h5 class="marginB5"><strong>Are you Sure?</strong></h5></div><div ng-if="$ctrl.removeVarError" class="alert alert-warning text-center marginB0 marginT10">{{$ctrl.removeVarError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" type="button" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" type="button" ng-click="$ctrl.removeVar()" translate>buttons.delete</button></div>',
      controller: Controller,
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    });

  function Controller(Var, utils) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.var = vm.resolve.var;
      vm.removeVar = removeVar;
    };

    function removeVar() {
      if (!vm.var) {
        return;
      }

      Var.destroyById({id: vm.var.id})
        .$promise
        .then(function () {
          vm.modalInstance.close();
        })
        .catch(function (response) {
          vm.removeVarError = utils.getHTTPError(response);
        });
    }
  }
})();
