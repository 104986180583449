(function () {
  'use strict';

  let angular = window.angular;

  PublicRouter.$inject = ['$stateProvider'];

  angular.module('app').config(PublicRouter);

  function PublicRouter($stateProvider, utils) {
    $stateProvider
      .state('member.var', {
        url: '/var',
        redirectTo: 'member.var.list',
        authenticated: true,
        abstract: true,
        ncyBreadcrumb: {
          skip: true,
        },
      })
      .state('member.var.list', {
        url: '/',
        authenticated: true,
        component: 'varList',
        resolve: {
          countries: [
            'StaticService',
            function (StaticService) {
              return StaticService.Country.find();
            },
          ],
          timeZones: [
            'StaticService',
            function (StaticService) {
              return StaticService.TimeZone.find();
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'vars',
        },
      })

      .state('member.var.show', {
        url: '/:varId',
        authenticated: true,
        template: '<ui-view></ui-view>',
        redirectTo: 'member.var.show.home',
        resolve: {
          var: [
            'Var',
            '$transition$',
            'AppUtils',
            '$rootScope',
            function (Var, $transition$, utils, $root) {
              let params = $transition$.params();
              return Var.findById({
                id: params.varId,
                filter: { include: ['container'] },
              })
                .$promise.then((_var) => {
                  $root.varId = params.varId;
                  return _var;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, { redirectTo: 'member.home' });
                });
            },
          ],
          customers: [
            'var',
            'Var',
            '$transition$',
            'AppUtils',
            'navbarService',
            '$rootScope',
            function (_var, Var, $transition$, utils, navbarService, $rootScope) {
              let params = $transition$.params();
              return Var.customers({
                id: params.varId,
                filter: { order: 'name ASC' },
              })
                .$promise.then((customers) => {
                  navbarService.setCustomers(customers);
                  return customers;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, { redirectTo: 'member.home' });
                });
            },
          ],
          adminCount: [
            'Var',
            '$transition$',
            (Var, $transition$) => {
              let params = $transition$.params();
              return Var.prototype$__count__admins({ id: params.varId }).$promise.then(
                (response) => response.count
              );
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.var.name}}',
        },
      })
      .state('member.var.show.home', {
        url: '/',
        redirectTo: 'member.var.show.home.customerList',
        controller: [
          'var',
          function (_var) {
            this.var = _var;
          },
        ],
        controllerAs: 'ctrl',
        template:'<div class="page-header"><h1 class="no-margin"><i class="fas fa-id-card fa-fw"></i> <span class="header-text">{{ctrl.var.name}}</span></h1><div class="container"><ul class="nav nav-tabs nav-justified"><li ui-sref-active="active"><a ui-sref="member.var.show.home.customerList" translate>entities.organization.customer.modelNamePl</a></li><li ui-sref-active="active"><a ui-sref="member.var.show.home.profile" translate>entities.organization.var.modelName</a></li></ul></div></div><div class="container"><ui-view></ui-view></div>',
        ncyBreadcrumb: { skip: true },
      })
      .state('member.var.show.home.customerList', {
        url: 'customer-list',
        authenticated: true,
        component: 'varCustomers',
        ncyBreadcrumb: {
          label: '<span>{{"entities.organization.customer.modelNamePl"|translate}}</span>',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
        },
      });
    /*.state('member.var.show.applications', {
      url: '/applications',
      authenticated: true,
      template: '<ui-view/>',
      redirectTo: 'member.applications.list',
      ncyBreadcrumb: {
        label: '<span>{{"entities.toolkit.modelNamePl" | translate}}</span>',
      },
    })
    .state('member.var.show.applications.list', {
      url: '/',
      authenticated: true,
      component: 'toolkitList',
      resolve: {
        toolKits: ['Var', '$transition$', function (Var, $transition$) {
          let params = $transition$.params();
          return Var
            .prototype$__get__toolkits({id: params.varId, filter: {include: 'tools'}})
            .$promise;
        }],
      },
      ncyBreadcrumb: {
        skip: true,
      },
    })
    .state('member.var.show.applications.show', {
      url: '/:toolkitId',
      authenticated: true,
      template:'<div class="page-header"><h1 class="no-margin"><i class="fas fa-window-restore fa-fw"></i> <span class="header-text">{{ctrl.toolKit.name}}</span></h1><div class="container"><ul class="nav nav-tabs nav-justified"><li ui-sref-active="active"><a ui-sref=".overview">Información</a></li><li ui-sref-active="active"><a ui-sref=".configuration">Configuración</a></li></ul></div></div><ui-view></ui-view>',
      redirectTo: 'member.applications.show.overview',
      controller: ['toolKit', function (toolKit) {
        this.toolKit = toolKit;
      }],
      controllerAs: 'ctrl',
      resolve: {
        toolKit: ['Var', '$transition$', function (Var, $transition$) {
          let params = $transition$.params();
          return Var
            .prototype$__get__toolkits({
              id: params.varId,
              filter: {include: 'tools', where: {id: params.toolkitId}},
            })
            .$promise
            .then(result => {
              return result[0];
            });
        }],
      },
      ncyBreadcrumb: {
        label: '{{$resolve.toolKit.name}}',
      },
    })
    .state('member.var.show.applications.show.overview', {
      url: '/overview',
      authenticated: true,
      component: 'toolkitOverview',
      ncyBreadcrumb: {
        label: 'Información',
      },
    })
    .state('member.var.show.applications.show.configuration', {
      url: '/configuration',
      authenticated: true,
      component: 'toolkitConfiguration',
      ncyBreadcrumb: {
        label: 'Configuración',
      },
      resolve: {
        dependencies: ['$ocLazyLoad', function ($ocLazyLoad) {
          return $ocLazyLoad.load([
            window.assetsPath + '/js/vendors/editors/simplemde.min.js',
            window.assetsPath + '/css/editors.min.css',
          ]);
        }],
      },
    })

    .state('member.var.show.tools', {
      url: '/tools',
      authenticated: true,
      template: '<ui-view/>',
      ncyBreadcrumb: {
        label: 'Herramientas',
      },
    })
    .state('member.var.show.tools.show', {
      url: '/:toolId',
      authenticated: true,
      component: 'toolShow',
      resolve: {
        tool: ['Var', '$transition$', function (Var, $transition$) {
          let params = $transition$.params();
          return Var
            .prototype$__get__tools({id: params.varId, filter: {where: {id: params.toolId}}})
            .$promise
            .then(result => {
              return result[0];
            })
            .catch(err => {
              console.log(err);
            });
        }],
      },
      ncyBreadcrumb: {
        label: '{{$resolve.tool.name}}',
      },
    });*/
  }
})();
