(() => {
  Controller.$inject = ['$translate'];
  angular.module('app').component('detectionColorsDisplay', {
    template:'<div class="detection-colors"><div class="detection-color" ng-repeat="color in ctrl.colors" title="{{color.title}} | Click to copy" clipboard ng-click="$event.stopPropagation();$event.preventDefault();" data-text="color.label" ng-style="{background: color.label, width: color.percentage + \'%\'}"></div></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      detection: '<',
      size: '<',
    },
  });

  function Controller($translate) {
    const vm = this;

    vm.$onInit = function () {};

    vm.$onChanges = function (changes) {
      if (changes.detection && vm.detection) {
        if (vm.detection.colors) {
          vm.colors = angular.copy(vm.detection.colors);
          vm.colors.sort((a, b) => b.percentage - a.percentage);
          vm.colors.forEach((current) => {
            current.percentage = Math.round(current.percentage * 1000) / 10;
            current.title =
              $translate.instant('entities.sensor.__colors.' + current.label) +
              `: ${current.percentage}%`;
          });
        } else if (vm.detection.colorExperimental) {
          vm.colors = angular.copy(vm.detection.colorExperimental);
          vm.colors.sort((a, b) => b.clusterSizeRatio - a.clusterSizeRatio);
          vm.colors.forEach((current) => {
            current.percentage = Math.round(current.clusterSizeRatio * 1000) / 10;
            current.label = `rgb(${current.rgb.r},${current.rgb.g},${current.rgb.b})`;
            current.title =
              `rgb(${current.rgb.r},${current.rgb.g},${current.rgb.b})` +
              `: ${current.percentage}%`;
          });
        }
      }
    };
  }
})();
