;(function () {
  'use strict';

  const angular = window.angular;

  Controller.$inject = ['$uibModal', '$state'];

  angular
    .module('app')
    .component('varCustomers', {
      template:'<h2 class="text-info marginT0" translate>entities.organization.customer.modelNamePl</h2><div class="form-group" ng-if="$ctrl.customers.length > 9"><div class="input-group"><span class="input-group-addon"><i class="fas fa-search fa-fw"></i></span> <input ng-model="$ctrl.searchText" class="form-control" translate-attr="::{placeholder: \'buttons.search\'}"></div></div><hr class="marginT0"><div class="home-shortcuts"><a class="shortcut" ng-click="$ctrl.showCustomerForm()"><div class="shortcut-content primary"><div class="link-icon"><i class="fas fa-plus"></i></div><div class="link-text-wrapper"><div class="link-text" translate>buttons.create</div></div></div></a> <a class="shortcut" ng-repeat="customer in $ctrl.customers | search: {name: $ctrl.searchText}" ui-sref="member.var.show.customer.show.home({customerId: customer.id})"><div class="shortcut-content"><div class="link-icon"><i class="fas fa-folder"></i></div><div class="link-text-wrapper" title="{{customer.name}}"><div class="link-text nowrap">{{customer.name}}</div></div></div></a></div>',
      controller: Controller,
      bindings: {
        customers: '<',
      },
    });

  function Controller($uibModal, $state) {
    let vm = this;

    vm.$onInit = function () {
      vm.showCustomerForm = showCustomerForm;
    };

    function showCustomerForm() {
      let instance = $uibModal
        .open({
          animation: true,
          bindToController: true,
          component: 'customerForm',
          backdrop: 'static',
          keyboard: false,
          resolve: {},
        });

      instance
        .result
        .then(function (result) {
          if (!result) {
            return;
          }
          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

})();
