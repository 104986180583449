;(function () {
  'use strict';

  let angular = window.angular;

  AssetService.$inject = ['Customer', '$rootScope', 'assetUtilsService', 'AppUtils'];

  angular
    .module('appServices')
    .service('AssetService', AssetService);

  function AssetService (Customer, $rootScope, assetUtilsService, utils) {
    return {
      findById: findById,
      find: find,
      loadSummaries: loadSummaries,
      loadAlerts: loadAlerts,
      loadEvents: loadEvents,
      getAssetTree: assetUtilsService.getAssetTree,
      getAssetTreeNode: assetUtilsService.getAssetTreeNode,
      reloadImageUrl: reloadImageUrl,
    };

    function loadSummaries (assetId, sensorIds, from, to) {
      return assetUtilsService.loadSummaries($rootScope.customerId, assetId, sensorIds, from, to);
    }

    function loadAlerts (assetId, filters, options) {
      return assetUtilsService.loadAlerts($rootScope.customerId, assetId, filters, options);
    }

    function loadEvents (assetId, filters, options) {
      return assetUtilsService.loadEvents($rootScope.customerId, assetId, filters, options);
    }

    function findById (assetId, filter) {
      filter = filter || {};
      filter.where = {id: assetId};

      return Customer
        .assets({
          id: $rootScope.customerId,
          filter: filter,
        })
        .$promise
        .then(assets => {
          const asset = assets[0];
          if (!asset) {
            const err = new Error('Not Found');
            err.code = 'NOT_FOUND';
            err.statusCode = 404;
            throw err;
          }

          if (asset.assets) {
            asset.assets.sort((a, b) => utils.sortComparator(a.name, b.name));
          }

          return asset;
        });
    }

    function find (filter) {
      filter = filter || {};

      if (filter.fields) {
        filter.fields.id = true;
      }

      return Customer
        .prototype$__get__assets({
          id: $rootScope.customerId,
          filter: filter,
        })
        .$promise;
    }

    function reloadImageUrl (asset, url) {
      const urlParams = new URLSearchParams(url);
      const property = urlParams.get('property');

      return findById(asset.id, {include: 'container', fields: ['id']})
        .then(result => {
          asset.container = result.container;
          return assetUtilsService.getImageUrl(asset, property);
        });
    }
  }
})();
