;(function () {
  'use strict';

  let angular = window.angular;

  OrganizationService.$inject = ['$rootScope', 'Admin', 'Var', 'LoopBackAuth', 'AppUtils'];

  angular
    .module('appServices')
    .service('OrganizationService', OrganizationService);

  function OrganizationService($rootScope, LoopBackUser, LoopBackOrganization, LoopBackAuth, utils) {
    return {
      getCurrent: getCurrent,
      getUsers: getUsers,
      saveProfile: saveProfile,
      getOrganizationImageUrl: getOrganizationImageUrl,
      getModel: getModel,
    };

    function getCurrent(filter) {
      filter = filter || {};
      return LoopBackOrganization
        .findById({
          id: $rootScope.varId,
          filter: filter,
        })
        .$promise;
    }

    function getUsers(organization) {
      if (organization.admins) {
        return utils.Promise.resolve(organization.admins);
      }

      return LoopBackOrganization
        .admins({id: $rootScope.varId, include: ['container']})
        .$promise;
    }

    function getOrganizationImageUrl(Organization, property) {
      let model = getModel();
      property = property || '_logo';
      if (Organization.container && Organization.container[property]) {
        let file = Organization.container[property];
        property = property.replace('_', '');
        return utils.getEntityFileUrl(model, Organization.id, property, file, LoopBackAuth.accessTokenId);
      }
    }

    function getModel() {
      return LoopBackOrganization.modelName;
    }

    function saveProfile(Organization) {
      return LoopBackOrganization
        .prototype$patchAttributes({
          id: Organization.id,
        }, {
          name: Organization.name,
          surname: Organization.surname,
          //email: vm.Organization.email,
          phone: Organization.phone,
          birthday: Organization.birthday,
          address: Organization.address,
          countryId: Organization.countryId,
          timeZoneId: Organization.timeZoneId,
          passwordConfirmation: Organization.passwordConfirmation,
        })
        .$promise;
    }
  }
})();
