;(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  Controller.$inject = [
    '$element',
    '$state',
    '$uibModal',
    'Customer',
    'Var',
    'AppUtils',
    '$translate'
  ];

  angular
    .module('app')
    .component('tagsTable', {
      template:'<div style="position: relative; min-height: 200px"><div class="panel panel-default"><header class="panel-heading"><h2 class="nowrap"><strong translate>entities.project._tag.modelNamePl</strong></h2><div class="panel-toolbars pull-right"><div class="panel-toolbar"><button class="btn btn-primary" ng-click="ctrl.showLinkModal()" translate>buttons.add_remove</button></div></div></header><div class="panel-body no-padding"><table id="tools-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th></tr></thead></table></div></div><spinner show="ctrl.loading" show-logo="true"></spinner></div>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        project: '<'
      }
    });

  function Controller ($element, $state, $uibModal, Customer, Var, utils, $translate) {
    let vm = this;

    vm.$onInit = function onInit () {
      initTable(vm.project.tags);

      vm.showLinkModal = showLinkModal;
    };

    function initTable (tags) {
      tags = tags || [];
      let dtOptions = {
        dom: '<tr> <\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        searching: false,
        info: false,
        data: tags,
        columns: [{
          data: 'name',
          title: $translate.instant('entities._all.name'),
          render: function (data) {
            return $translate.instant('plugins.' + data);
          }
        }/*, {
          orderable: false,
          data: null,
          defaultContent: '<div class="table-buttons"><button class="btn btn-danger btn-xs"><i class="fas fa-times fa-fw"></i></button></div>'
        }*/]
      };

      const table = $element.find('#tools-table');
      vm.dtInstance = table.DataTable(dtOptions);

      table
        .find('tbody')
        .on('click', 'button', function () {
          const data = vm.dtInstance.row(angular.element(this).parents('tr')).data();
          $uibModal
            .open({
              // animation: true,
              backdrop: 'static',
              windowClass: 'modal-xs modal-warning',
              bindToController: true,
              component: 'removeTag',
              resolve: {
                project: () => vm.project,
                tag: () => data
              }
            })
            .result
            .then(function () {
              $state.go('.', { toolkitId: vm.entityId }, { reload: true });
            })
            .catch(function (err) {
              console.log(err);
            });
        });
    }

    function showLinkModal () {
      $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          component: 'linkTag',
          resolve: {
            project: () => vm.project
          }
        })
        .result
        .then(function (result) {
          $state.go('.', { toolkitId: vm.entityId }, { reload: true });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
})();
