; (function () {
  const angular = window.angular;

  Controller.$inject = ['$element', 'AppUtils', 'Customer', '$translate', 'LocalStorageService'];

  angular
    .module('app')
    .component('restrictionSensorTypeLinkModal', {
      template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><span translate>buttons.add_remove</span> <span translate>entities.sensortype.modelNamePl</span></div><div ng-style="{opacity: ctrl.loading? 0: 1}" style="min-height: 200px"><div class="paddingT5"><table class="table table-bordered table-hover no-margin" id="tag-table"></table></div><div class="alert alert-warning no-margin text-center" ng-if="ctrl.loadError">{{ctrl.loadError}}</div><div class="alert alert-warning no-margin text-center" ng-if="ctrl.linkError">{{ctrl.linkError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="ctrl.setSensorTypes()" translate>buttons.save</button></div></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function Controller($element, utils, Customer, $translate, LocalStorageService) {
    let vm = this;

    vm.$onInit = function () {
      vm.project = vm.resolve.project;

      vm.loading = true;
      Customer
        .prototype$__get__projects__assetTypes({
          id: vm.project.customerId,
          fk: vm.project.id,
          filter: {
            include: {
              relation: 'sensorTypes',
              scope: {
                fields: ['id', 'type']
              }
            },
          },
        })
        .$promise
        .then(assetTypes => {
          const sensorTypes = assetTypes.reduce((array, current) => {
            for (let sensorType of current.sensorTypes) {
              if (array.findIndex(c => c.id === sensorType.id) === -1) {
                array.push(sensorType);
              }
            }
            return array;
          }, []);
          vm.loading = false;
          let currentSensorTypes = utils.arrayToObject(vm.project.allowedSensorTypes || [], 'id');
          vm.sensorTypes = sensorTypes;
          sensorTypes.forEach(current => {
            current.checked = !!currentSensorTypes[current.id];
          });

          const selected = [];
          vm.project.allowedSensorTypes.forEach(current => {
            const index = vm.sensorTypes.findIndex(tag => tag.id === current.id);
            if (index !== -1) {
              selected.push(index);
            }
          });

          initTable(sensorTypes, selected);
        })
        .catch(err => {
          vm.loadError = utils.getHTTPError(err).message;
          console.log(vm.loadError);
          vm.loading = false;
        });

      vm.setSensorTypes = setSensorTypes;
    };

    function initTable(data, selected) {
      let dtOptions = {
        dom: '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
          '<div tr>' +
          '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        order: [1, 'asc'],
        paging: false,
        info: false,
        data: data,
        scrollY: '400px',
        select: {
          selector: 'td:not(.control)',
          style: 'multi'
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-checkbox',
            defaultContent: '',
            width: '10px'
          },
          {
            data: 'type',
            title: $translate.instant('entities._all.name'),
            render: function (data) {
              return $translate.instant('entities.sensortype.__types.' + data);
            }
          }
        ]
      };

      vm.dtInstance = $element.find('#tag-table').DataTable(dtOptions);

      if (Array.isArray(selected) && selected.length) {
        vm.dtInstance.rows(selected).select();
      }
    }

    function setSensorTypes() {
      let selected = vm.dtInstance.rows({ selected: true }).data().toArray();
      let toLink = [];
      let toUnlink = [];

      let map = utils.arrayToObject(selected, 'id');

      vm.sensorTypes.forEach(sensorType => {
        if (sensorType.checked) {
          if (!map[sensorType.id]) {
            toUnlink.push(sensorType.id);
          }
        } else if (map[sensorType.id]) {
          toLink.push(sensorType.id);
        }
      });

      vm.loading = true;
      executeRequest(toLink, 'link')
        .then(() => {
          return executeRequest(toUnlink, 'unlink');
        })
        .then(errors => {
          LocalStorageService.removeItem('Customer$CurrentProject');
          vm.loading = false;
          if (errors && errors.length) {
            vm.errors = errors;
            return;
          }
          vm.modalInstance.close();
        })
        .catch(err => {
          console.log(err);
          vm.loading = false;
        });
    }

    function executeRequest(array, operation) {
      return utils.Promise(resolve => {
        let count = 0;
        const method = `prototype$__${operation}__projects__allowedSensorTypes`;

        const errors = [];
        if (array.length) {
          array.forEach(sensorTypeId => {
            let data = undefined;
            Customer[method]({
              id: vm.project.customerId,
              nk: vm.project.id,
              fk: sensorTypeId
            }, data)
              .$promise
              .then(result => {
                count++;
                if (count === array.length) {
                  resolve(errors);
                }
              })
              .catch(err => {
                count++;
                errors.push(utils.getHTTPError(err));
                if (count === array.length) {
                  resolve(errors);
                }
              });
          });
        } else {
          resolve();
        }
      });
    }
  }

})();
