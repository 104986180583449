(() => {
  Controller.$inject = [
    '$scope',
    '$element',
    '$timeout',
    'assetUtilsService',
    '$state',
    '$translate',
    'AgGridService',
  ];
  angular.module('app').component('edgeAgentAssetTable', {
    template: `<div class="ag-theme-custom" style="height: 250px" id="assets-table"></div>`,
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      agentAssets: '<',
      assets: '<',
    },
  });

  function Controller(
    $scope,
    $element,
    $timeout,
    assetUtilsService,
    $state,
    $translate,
    AgGridService
  ) {
    const vm = this;

    vm.$onInit = function () {
      if (vm.agentAssets) {
        for (let asset of vm.agentAssets) {
          asset.path = assetUtilsService.getHierarchyPath(asset, vm.assets);
        }
      }

      $timeout(() => {
        initAssetTable(vm.agentAssets);

        $scope.$watchCollection('ctrl.agentAssets', () => {
          if (vm.agentAssets) {
            for (let asset of vm.agentAssets) {
              asset.path = assetUtilsService.getHierarchyPath(asset, vm.assets);
            }
          }
          resetTable();
        });
      }, 100);
    };

    vm.$onChanges = function (changes) {
      if (changes.agentAssets) {
        resetTable();
      }
    };

    function resetTable() {
      if (vm.dtInstance) {
        vm.dtInstance.api.setRowData(vm.agentAssets || []);
        vm.dtInstance.api.sizeColumnsToFit();
      }
    }

    function initAssetTable(data) {
      data = data || [];
      let gridOptions = {
        getRowId: (params) => params.data.id,
        suppressCellFocus: true,
        rowData: data,
        columnDefs: [
          {
            sortable: false,
            headerName: $translate.instant('entities._all.name'),
            field: 'name',
          },
          {
            sortable: false,
            headerName: $translate.instant('entities._all.parents'),
            field: 'path',
            valueGetter: (params) => {
              const path = params.data.path;
              const last = path.lastIndexOf('/');
              return last !== -1 ? path.substring(0, last) : '';
            },
          },
          {
            field: 'id',
            headerName: '',
            sortable: false,
            width: 55,
            suppressSizeToFit: true,
            cellClass: 'text-center',
            cellRenderer: (params) => {
              const asset = params.data;
              const location = $state.current.url;
              let ref;
              if (location == '/devices/:deviceId') {
                ref = '^.^.project.contextualization.assets';
              } else {
                ref = '^.assets';
              }

              const url = $state.href(
                ref,
                {
                  projectId: asset.projectId,
                  assetId: asset.id,
                  tab: null,
                },
                { absolute: true }
              );
              return `<a href="${url}" target="_blank">${$translate.instant('buttons.view')}</a>`;
            },
          },
          {
            field: 'id',
            headerName: '',
            sortable: false,
            width: 55,
            suppressSizeToFit: true,
            cellClass: 'text-center',
            cellRenderer: (params) => {
              const asset = params.data;
              const title = $translate.instant('buttons.unlink');
              const btn = document.createElement('button');
              btn.classList.add('btn', 'btn-link', 'btn-xs');
              btn.style.verticalAlign = 'unset';
              btn.title = title;
              btn.innerHTML = `<span class="fas fa-fw fa-trash"></span>`;

              btn.addEventListener('click', (e) => {
                e.stopPropagation();
                $timeout(() => {
                  const index = vm.agentAssets.findIndex((current) => current.id === asset.id);
                  if (index !== -1) {
                    vm.agentAssets.splice(index, 1);
                  }
                });
              });

              return btn;
            },
          },
        ],
      };

      const target = $element.find('#assets-table')[0];
      vm.dtInstance = AgGridService.newInstance(target, gridOptions).gridOptions;
      vm.dtInstance.api.sizeColumnsToFit();
    }
  }
})();
