; (function () {
  'use strict';

  let angular = window.angular;

  Controller.$inject = [
    'Customer',
    'AppUtils'
  ];

  angular
    .module('app')
    .component('connectionEmergencyContactRemove', {
      template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><span translate>buttons.delete</span> <span translate>entities.emergencycontact.modelName</span></div><div class="modal-body text-center"><div translate data-translate-values="{model: \'entities.emergencycontact.modelName\', name: ctrl.emergencyContact.name}">general._messages.remove</div></div><div ng-if="ctrl.removeEmergencyContactError" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.removeEmergencyContactError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" type="button" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" type="button" ng-click="ctrl.removeEmergencyContact()" translate>buttons.delete</button></div></div><spinner css-class="\'small\'" show-logo="true" show="ctrl.loading"></spinner>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function Controller(Customer, utils) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.emergencyContact = vm.resolve.emergencyContact;
      vm.customer = vm.resolve.customer;
      vm.removeEmergencyContact = removeEmergencyContact;
    };

    function removeEmergencyContact() {
      if (!vm.emergencyContact) {
        return;
      }

      vm.loading = true;
      Customer.emergencyContacts
        .destroyById({
          id: vm.customer.id,
          fk: vm.emergencyContact.id
        })
        .$promise
        .then(() => {
          vm.loading = false;
          vm.modalInstance.close();
        })
        .catch(err => {
          vm.loading = false;
          vm.removeEmergencyContactError = utils.getHTTPError(err);
        });
    }
  }
})();
