;(function () {
  const angular = window.angular;
  const $ = window.$;

  angular
    .module('app')
    .directive('viewSidebarToggle', function () {
      return {
        restrict: 'E',
        scope: {
          hideContainer: '='
        },
        controller: ['$scope', '$rootScope', ViewSidebarToggleController],
        template: `<div>
          <button class="btn btn-default btn-sm" ng-click="emitToggle()">
              <i class="fas fa-bars"></i>
          </button>
        </div>`
      };
    });

  function ViewSidebarToggleController (scope, $root) {
    scope.emitToggle = function () {
      $root.$broadcast('view-sidebar.toggle');
      setTimeout(() => {
        $(window).resize();
      }, 100);
    };
  }
})();
