;(function () {
  'use strict'

  let angular = window.angular

  ProjectRemoveController.$inject = ['ProjectService', 'AppUtils']

  angular
    .module('app')
    .component('projectRemove', {
      template:'<div ng-style="{opacity: ctrl.loading? 0: 1}"><div class="modal-header"><span translate>buttons.delete</span> <span translate>entities.project.modelName</span></div><div class="modal-body text-center"><p>You are about to remove the project <strong>{{$ctrl.project.name}}</strong> from the customer <strong>{{$ctrl.customer.name}}</strong></p><h5 class="marginB5"><strong>Are you Sure?</strong></h5></div><div ng-if="$ctrl.removeProjectError" class="alert alert-warning text-center marginB0 marginT10">{{$ctrl.removeProjectError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" ng-click="$ctrl.removeProject()" translate>buttons.delete</button></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
      controller: ProjectRemoveController,
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    })

  function ProjectRemoveController (ProjectService, utils) {
    let vm = this

    vm.$onInit = function onInit () {
      vm.project = vm.resolve.project
      vm.customer = vm.resolve.customer
      vm.removeProject = removeProject
    }

    function removeProject () {
      if (!vm.project) {
        return
      }

      vm.loading = true
      ProjectService
        .deleteProject(vm.customer.id, vm.project.id)
        .then(function () {
          vm.loading = false
          vm.modalInstance.close()
        })
        .catch(function (response) {
          vm.loading = false
          let error = utils.getHTTPError(response)
          vm.removeProjectError = error.code || error.message
        })
    }
  }
})()
