(function () {
  'use strict';

  const angular = window.angular;

  ProjectProfileController.$inject = ['$uibModal', '$state', 'LoopBackAuth'];

  angular.module('app').component('projectProfile', {
    template:'<div class="container"><div class="well well-light"><div class="profile-content"><div><div class="table-responsive"><table class="table table-borderless no-margin"><thead><tr><th colspan="2"><div class="pull-left"><h2 class="no-margin text-info"><span>{{ctrl.project.name}}</span></h2></div><button class="btn txt-color-white btn-warning btn-sm pull-right" ng-click="ctrl.showEditModal()"><i class="fas fa-edit"></i></button></th></tr></thead><tbody><tr class="separator"><td colspan="2"></td></tr><tr class="bordered"><td class="field" translate>entities._all.description</td><td><p class="markdown text-justify" markdown-to-html="ctrl.project.description || \'\'"></p></td></tr></tbody></table></div></div></div></div><tools-table customer-id="ctrl.project.customerId" entity-type="\'project\'" entity-id="ctrl.project.id"></tools-table><tools-table ng-if="ctrl.isAdmin" customer-id="ctrl.project.customerId" entity-type="\'project\'" entity-id="ctrl.project.id" target="\'adminTools\'" title="\'entities.tool.adminTools.modelNamePl\'"></tools-table><restriction-sensor-type-table project="ctrl.project" ng-if="ctrl.isAdmin"></restriction-sensor-type-table><restriction-yolo-classes-table project="ctrl.project" ng-if="ctrl.isAdmin"></restriction-yolo-classes-table></div>',
    controller: ProjectProfileController,
    controllerAs: 'ctrl',
    bindings: {
      industries: '<',
      countries: '<',
      customer: '<',
      project: '<',
      organization: '<',
      isAdmin: '<',
      users: '<',
    },
  });

  function ProjectProfileController($uibModal, $state, LoopBackAuth) {
    let vm = this;

    vm.$onInit = function () {
      vm.showEditModal = showEditModal;
      console.log(vm.users);
    };

    function showEditModal() {
      let instance = $uibModal.open({
        animation: true,
        backdrop: 'static',
        bindToController: true,
        component: 'projectForm',
        resolve: {
          project: function () {
            return vm.project;
          },
          customer: function () {
            return vm.customer || vm.organization;
          },
        },
      });

      instance.result
        .then(function (result) {
          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
})();
