;(function () {
  'use strict';

  let angular = window.angular;

  ApiKeyFormController.$inject = [
    'Customer',
    'AppUtils'
  ];

  angular
    .module('app')
    .component('connectionApiKeyRemove', {
      template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><span translate>buttons.delete</span> <span translate>entities.apikey.modelName</span></div><div class="modal-body text-center"><div translate data-translate-values="{model: \'entities.apikey.modelName\', name: ctrl.apiKey.name, article: \'general.articles.the_f\'}">general._messages.remove</div></div><div ng-if="ctrl.removeApiKeyError" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.removeApiKeyError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" type="button" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" type="button" ng-click="ctrl.removeApiKey()" translate>buttons.delete</button></div></div><spinner css-class="\'small\'" show-logo="true" show="ctrl.loading"></spinner>',
      controller: ApiKeyFormController,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function ApiKeyFormController (Customer, utils) {
    let vm = this;

    vm.$onInit = function onInit () {
      vm.apiKey = vm.resolve.apiKey;
      vm.customer = vm.resolve.customer;
      vm.removeApiKey = removeApiKey;
    };

    function removeApiKey () {
      if (!vm.apiKey) {
        return;
      }

      vm.loading = true;
      Customer.apiKeys
        .destroyById({
          id: vm.customer.id,
          fk: vm.apiKey.id
        })
        .$promise
        .then(function () {
          vm.loading = false;
          vm.modalInstance.close();
        })
        .catch(function (err) {
          vm.loading = false;
          vm.removeApiKeyError = utils.getHTTPError(err);
        });
    }
  }
})();
