;(function () {
  'use strict';

  const angular = window.angular;
  const $ = window.$;

  Runner.$inject = [
    'LANGS',
    '$transitions',
    '$state',
    '$translate',
    'SuperAdmin',
    'LoopBackAuth',
    '$q',
    '$rootScope',
    '$interpolate',
    'socketIOService',
    '$uibModalStack',
  ];

  angular
    .module('app')
    .run(Runner);

  function Runner (LANGS, $transitions, $state, $translate, SuperAdmin, LoopBackAuth, $q, $rootScope, $interpolate, socketIOService, $uibModalStack) {
    // window.Promise = $q;

    $rootScope.assetsPath = window.assetsPath;
    $rootScope.langs = LANGS;
    $rootScope.currentLang = $translate.proposedLanguage();
    $rootScope.currentLang = $rootScope.currentLang ? $rootScope.currentLang.trim() : null;

    $rootScope.systemMessage = {
      level: 'warning',
      show: false,
      text: `A partir de hoy a las 22:00 horas realizaremos un mantenimiento a nuestros servidores, esto hará que la aplicación no esté disponible por a lo más 90 minutos.<br/>
      Lamentamos cualquier inconveniente que esto pueda provocar.`,
    };

    let operations = 2;
    let opCount = 0;
    $translate
      .onReady()
      .then(function () {
        opCount++;
        dataTablesDefault($translate);

        if (opCount === operations) {
          $rootScope.appReady = true;
        }
      });

    $transitions.onBefore({}, transition => {
      let next = transition.$to().self;
      let defer = $q.defer();

      const params = transition.params();
      $rootScope.customerId = params.customerId;
      $rootScope.projectId = params.projectId;

      let loginState = $state.target('public.login');
      let member = $state.target('member.home');

      if (next.login) {
        if (SuperAdmin.isAuthenticated()) {
          defer.resolve(member);
        } else {
          defer.resolve(true);
        }
      } else if (next.authenticated) {
        if (SuperAdmin.isAuthenticated()) {
          defer.resolve(true);
        } else {
          defer.resolve(loginState);
        }
      } else {
        defer.resolve();
      }

      return defer.promise;
    });

    $transitions.onError({}, transition => {
      const params = transition.params();
      $rootScope.customerId = params.customerId;
      $rootScope.projectId = params.projectId;

      let error = transition.error();
      if (error) {
        if (error.type === 5) { // The transition was ignored
          $rootScope.viewLoading = false;
        }

        if (error.detail) {
          if (error.detail.redirectTo) {
            $state.go(error.detail.redirectTo, error.detail.params);
          } else if (error.detail.status === 401) {
            $state.go('public.login');
          } else if (error.type !== 2) {
            console.log(error);
            $state.go('member.home');
          }
        }
      }
    });

    $transitions.onStart({}, transition => {
      $uibModalStack.dismissAll();
      $rootScope.viewLoading = true;
    });

    $transitions.onFinish({}, transition => {
      $uibModalStack.dismissAll();
      const params = transition.params();
      $rootScope.customerId = params.customerId;
      $rootScope.projectId = params.projectId;

      $rootScope.viewLoading = false;
      opCount++;
      if (opCount === operations) {
        $rootScope.appReady = true;
      }

      let current = transition.targetState()._definition.self;

      if (current.authenticated && !socketIOService.isConnected()) {
        socketIOService.init(window.rtPath, LoopBackAuth.accessTokenId);
      }

      $('#page-wrapper > .absolute').scrollTop(0);
    });
  }

  function dataTablesDefault (translate) {
    translate = translate.instant;
    $.extend(true, $.fn.dataTable.defaults, {
      language: {
        emptyTable: `<div class="alert alert-info no-margin">${translate('datatables.emptyTable')}</div>`,
        zeroRecords: `<div class="alert alert-info no-margin">${translate('datatables.zeroRecords')}</div>`,
        info: translate('datatables.info'),
        infoEmpty: translate('datatables.infoEmpty'),
        infoFiltered: translate('datatables.infoFiltered'),
        lengthMenu: translate('datatables.lengthMenu'),
        loadingRecords: `${translate('datatables.loadingRecords')}`,
        processing: `${translate('datatables.processing')}`,
        search: '',
        searchPlaceholder: translate('datatables.search'),
        paginate: {
          first: translate('datatables.paginate.first'),
          last: translate('datatables.paginate.last'),
          next: translate('datatables.paginate.next'),
          previous: translate('datatables.paginate.previous'),
        },
        aria: {
          sortAscending: translate('datatables.aria.sortAscending'),
          sortDescending: translate('datatables.aria.sortDescending'),
        },
      },
    });
  }
})();
