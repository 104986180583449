(function () {
  'use strict';

  let angular = window.angular;

  forensicRetailRouter.$inject = ['$stateProvider'];

  angular.module('app').config(forensicRetailRouter);

  function forensicRetailRouter($stateProvider) {
    $stateProvider.state('member.var.show.customer.show.project.storeForensicSales', {
      url: '/store-forensic-sales',
      component: 'storeForensicSales',
      authenticated: true,
      ncyBreadcrumb: {
        label: '{{"modules.store-forensic-sales.breadcrumb" | translate}}',
      },
      resolve: {
        stores: [
          'AssetService',
          (AssetService) => {
            return AssetService.find({
              where: { type: 'Store' },
              fields: ['id', 'name', 'uri'],
              include: [
                {
                  relation: 'sensors',
                  scope: {
                    fields: ['id', 'name', 'relativeId', 'type'],
                    where: {
                      type: 'Checkout',
                      relativeId: 'store_sales',
                    },
                  },
                },
                {
                  relation: 'staffs',
                  scope: {
                    fields: ['id', 'name', 'referenceId', 'type'],
                  },
                },
              ],
            });
          },
        ],
        dependencies: [
          '$ocLazyLoad',
          '$rootScope',
          ($ocLazyLoad, $root) => {
            const style = $root.darkMode ? 'dark' : 'light';
            return $ocLazyLoad.load([
              window.assetsPath + `/css/store-forensic-sales.style-${style}.css`,
              window.assetsPath + '/js/app/store-forensic-sales.module.min.js',
              window.assetsPath + '/js/vendors/xml2json.min.js',
            ]);
          },
        ],
      },
    });
  }
})();
