(function () {
  Controller.$inject = ['$timeout', '$rootScope', 'SensorService', 'AppUtils', '$state'];

  window.angular.module('app').component('detectionIsoContainerImage', {
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      sensor: '<',
      data: '<',
      disableClick: '<',
      onLoadContent: '<',
      maxHeight: '<',
      disableSpinner: '<',
      imageMode: '<',
      showFigures: '<',
      hideButtons: '<',
    },
    template:'<a style="display: block" ng-class="{\'cursor-pointer\': !ctrl.disableClick}" ng-click="ctrl.expand()" target="_blank"><background-image ng-if="ctrl.imageSrc" image-src="ctrl.imageSrc" on-load-content="ctrl.onLoadImage" image-mode="ctrl.imageMode" dimensions="ctrl.dimensions" max-height="ctrl.maxHeight" disable-spinner="ctrl.disableSpinner"></background-image><div style="position: absolute; width: 100%" ng-style="{top: ctrl.dimensions.offset.top}" ng-if="ctrl.showFigures && ctrl.dimensions.width && ctrl.dimensions.height" class="detection-objects-container"><detection-iso-container-canvas sensor="ctrl.sensor" data="ctrl.data" image="ctrl.image" width="ctrl.dimensions.width" height="ctrl.dimensions.height" ratio="ctrl.dimensions.ratio"></detection-iso-container-canvas></div><div class="floating-btn" style="left: 5px" ng-show="ctrl.dimensions.loading === false" ng-if="!ctrl.imageError && !ctrl.hideButtons" data-html2canvas-ignore><div class="btn-group btn-group-xs" ng-click="$event.stopPropagation();"><a class="btn btn-default" style="padding: 1px; font-size: 0.9rem" ng-click="$event.preventDefault(); ctrl.showFigures = !ctrl.showFigures"><span class="fa-stack fa-fw" style="width: 2.5em"><i class="far fa-fw fa-circle fa-stack-2x"></i> <i class="fas fa-fw fa-draw-polygon fa-stack-1x"></i></span></a> <a class="btn btn-default" ng-href="{{ctrl.imageSrc}}" target="_blank"><i class="fas fa-download fa-fw"></i></a></div></div></a>',
  });

  function Controller($timeout, $root, SensorService, utils, $state) {
    let vm = this;
    let firstChange = true;

    vm.$onInit = function () {
      vm.onLoadImage = onLoadImage;
      vm.dimensions = {};

      vm.state = $state.get().reduce((filtered, current) => {
        const name = current.name;
        const component = current.component;
        if (name.includes('dataDisplay') && component === 'monitoringDataDisplay') {
          filtered.push(current);
        }
        return filtered;
      }, [])[0];

      vm.expand = expand;

      vm.showFigures = vm.showFigures == null ? true : vm.showFigures;
      vm._classes = typeof vm.showFigures === 'boolean' ? null : vm.showFigures.split();
      vm.showFigures = !!vm.showFigures;
      init();
    };

    vm.$onChanges = function (changes) {
      if (firstChange) {
        firstChange = false;
        return;
      }

      if (changes.showFigures) {
        vm.showFigures = vm.showFigures == null ? true : vm.showFigures;
        vm._classes = typeof vm.showFigures === 'boolean' ? null : vm.showFigures.split();
        vm.showFigures = !!vm.showFigures;
      }

      init();
    };

    function init() {
      $timeout(() => {
        vm.showFigures = typeof vm.showFigures === 'boolean' ? vm.showFigures : true;
        vm.disableSpinner = typeof vm.disableSpinner === 'boolean' ? vm.disableSpinner : false;
        if (!vm.sensor || !vm.data) {
          return;
        }

        vm.content = vm.data.content;

        if (vm.data && vm.content) {
          vm.imageSrc = getImageUrl(vm.data);
        }
      });
    }

    function onLoadImage(err, image) {
      if (vm.onLoadContent) {
        vm.onLoadContent(err, image);
      }

      vm.image = err ? null : image;
      vm.imageError = err;
    }

    function getImageUrl(data) {
      if (!data || !data.content) {
        return;
      }

      const content = data.content;
      let image;
      if (vm.content?.files) {
        image = content.files.gif ? content.files.gif : content.files.image;
        image = Array.isArray(image) ? image[0] : image;
      }

      if (!image || !image.container || !image.name) {
        return;
      }

      vm.container = image.container;
      vm.imageName = image.name;

      return SensorService.getImageUrl(vm.sensor.id, image, data.from);
    }

    function expand() {
      if (vm.disableClick) {
        return;
      }

      const dataId = vm.data.referenceId || vm.data.id;
      window.open(
        $state.href(
          vm.state,
          {
            projectId: $root.projectId,
            assetId: vm.sensor.assetId,
            sensorId: vm.sensor.id,
            dataId: utils.isDate(dataId) ? dataId.getTime() : dataId,
            zoneFilter: vm.filterByZone,
          },
          '_blank'
        )
      );
    }
  }
})();
