;(function () {
  'use strict';

  let angular = window.angular;

  DeviceFormController.$inject = [
    '$scope',
    '$timeout',
    'Customer',
    'AppUtils',
  ];

  angular
    .module('app')
    .component('connectionDeviceForm', {
      template:'<form class="validate" name="ctrl.deviceForm" novalidate ng-class="{transparent: ctrl.saving}"><div class="modal-header"><span>{{:: ctrl.device.id ? \'form.actions.update\' : \'form.actions.create\' | translate}}</span> <span translate>entities.device.modelName</span></div><div class="modal-body"><div><div class="form-group" show-errors><label for="createDeviceName"><span translate>entities._all.name</span> <span class="text-danger">*</span></label> <input id="createDeviceName" ng-model="ctrl.device.name" class="form-control" translate-attr="{placeholder:\'entities._all.name\'}" name="deviceName" required><div class="help-block" ng-messages="ctrl.deviceForm.deviceName.$error"><div ng-messages-include="messages.html"></div></div></div><div class="checkbox marginT0 marginB20"><label><input type="checkbox" ng-model="ctrl.device.enabled"> <span translate>entities._all.enabled</span></label></div><div class="form-group no-margin"><label class="control-label" for="descriptionInput" translate>entities._all.description</label> <textarea id="descriptionInput" name="descriptionInput" maxlength="10000" simplemde="ctrl.mdeOptions" ng-model="ctrl.device.description" class="form-control input-sm"></textarea></div></div></div><div ng-if="ctrl.createDeviceError" class="alert alert-warning text-center marginB0">{{ctrl.createDeviceError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" ng-show="!ctrl.saving" translate>buttons.cancel</button> <button ng-disabled="ctrl.saving" class="btn btn-primary" ng-click="ctrl.save()" translate>buttons.save</button></div></form><spinner css-class="\'small\'" show-logo="true" show="ctrl.saving"></spinner>',
      controller: DeviceFormController,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    });

  function DeviceFormController ($scope, $timeout, Customer, utils) {
    let vm = this;

    vm.$onInit = function onInit () {
      vm.device = vm.resolve.device ? angular.copy(vm.resolve.device) : {enabled: true};
      vm.operation = vm.device.id ? 'Update' : 'Create';
      vm.customer = vm.resolve.customer;

      vm.mdeOptions = {
        spellChecker: false,
        toolbar: [
          'bold', 'italic', 'heading', '|',
          'unordered-list', 'ordered-list', '|',
          'quote', 'link', 'table', 'code', '|',
          'preview',
        ],
      };

      vm.save = createDevice;
    };

    function createDevice () {
      $scope.$broadcast('show-errors-check-validity');
      if (!vm.device || !vm.deviceForm.$valid) {
        return;
      }

      let device = {
        name: vm.device.name,
        description: vm.device.description,
        enabled: vm.device.enabled,
      };

      let promise;
      if (vm.device.id) {
        promise = Customer.devices
          .updateById({
            id: vm.customer.id,
            fk: vm.device.id,
          }, device)
          .$promise;
      } else {
        promise = Customer.devices
          .create({
            id: vm.customer.id,
          }, device)
          .$promise;
      }

      vm.saving = true;
      promise
        .then(function (result) {
          vm.modalInstance.close({
            operation: vm.device.id ? 'update' : 'create',
            device: result,
            node: {
              id: result.id,
              text: result.name,
              parent: '#',
            },
          });
        })
        .catch(function (err) {
          vm.saving = false;
          vm.createDeviceError = utils.getHTTPError(err);
        });
    }
  }
})();
