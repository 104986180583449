(function () {
  'use strict';

  const angular = window.angular;

  HomeController.$inject = [];

  angular.module('app').component('projectHome', {
    template:'<div class="page-header"><h1 class="no-margin"><i class="fas fa-folder fa-fw"></i> <span class="header-text">{{ctrl.project.name}}</span></h1><div class="container"><ul class="nav nav-tabs nav-justified"><li ui-sref-active="active"><a ui-sref=".directAccess" translate>entities.tool.modelNamePl</a></li><li ui-sref-active="active"><a ui-sref=".profile" translate>general.configuration</a></li></ul></div></div><div><ui-view></ui-view></div>',
    controller: HomeController,
    controllerAs: 'ctrl',
    bindings: {
      project: '<',
      assets: '<',
    },
  });

  function HomeController() {
    let vm = this;

    vm.$onInit = function () {};
  }
})();
