(function () {
  'use strict';
  const angular = window.angular;

  ToolShowController.$inject = [];
  angular.module('app').component('toolShow', {
    template:'<div class="page-header"><h1 class="no-margin"><i class="fas fa-window-maximize fa-fw"></i> <span class="header-text">{{ctrl.tool.name}}</span></h1></div><div class="tool"><div class="container"><div class="tool-header"><div class="tool-icon"><div class="thumbnail no-margin"><i class="{{ctrl.tool.icon}}"></i></div></div><div class="tool-name"><h2 class="no-margin">{{ctrl.tool.name}}</h2></div></div></div><div class="tool-body"><div class="container"><div class="row"><div class="col-lg-7"><p class="markdown text-justify" markdown-to-html="ctrl.tool.description || \'\'"></p></div><div class="col-lg-5"><div style="padding-left: 30px"><div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="//www.youtube.com/embed/J3FceN2lYdA"></iframe></div></div></div></div></div></div></div>',
    controller: ToolShowController,
    controllerAs: 'ctrl',
    bindings: {
      tool: '<',
    },
  });

  function ToolShowController() {
    const vm = this;

    vm.$onInit = function () {};
  }
})();
