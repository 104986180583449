(() => {
  Controller.$inject = ['$scope', '$timeout'];

  angular.module('app').component('detectionDataCarousel', {
    template:'<div class="detection-carousel"><div class="slideshow-container"><div class="detection-slide slide-fade"><div class="carousel-caption"><span>{{ctrl.sensors[ctrl.currentElement.sensorId].name}}</span> <small>{{ctrl.currentElement.from | date: "HH:mm:ss"}}</small></div><div class="carousel-controls"><div class="paddingR5"><a class="cursor-pointer" style="font-size: 120%; color: white" ng-click="ctrl.toggleAutoPlay()" translate-attr="{title: ctrl.autoPlay? \'entities.event.view.carousel.manual\': \'entities.event.view.carousel.autoplay\' }" ng-disabled="ctrl.loadingImage"><i class="fas fa-fw" ng-class="ctrl.autoPlay? \'fa-stop-circle\': \'fa-play-circle\'"></i></a></div><div class="paddingL5">{{ctrl.slideIndex + 1 }} / <span ng-class="{\'text-red-white\': ctrl.blink}">{{ctrl.data.length}}</span></div></div><detection-image show-zone="ctrl.showZone" hide-buttons="ctrl.hideButtons" filter-by-zone="ctrl.filterByZone" show-figures="ctrl.showFigures" sensor="ctrl.sensors[ctrl.currentElement.sensorId]" data="ctrl.currentElement" only-content="true" on-load-content="ctrl.onLoadImage"></detection-image></div><div ng-show="ctrl.slides > 1"><a class="prev" ng-class="{disabled: ctrl.slideIndex === 0 || ctrl.loadingImage}" ng-click="ctrl.plusSlides(-1)">&#10094;</a> <a class="next" ng-class="{disabled: ctrl.slideIndex === ctrl.data.length - 1 || ctrl.loadingImage, \'text-red-white\':ctrl.blink}" ng-click="ctrl.plusSlides(1)">&#10095;</a></div></div></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      data: '<',
      sensors: '<',
      asset: '<',
      filterByZone: '<',
      hideButtons: '<',
      showZone: '<',
      autoPlay: '<',
      startSlide: '<',
      showFigures: '<',
    },
  });

  function Controller($scope, $timeout) {
    const vm = this;
    let firstChange = true;
    let slideTime = 3000;

    vm.$onInit = function () {
      vm.plusSlides = plusSlides;
      vm.currentSlide = currentSlide;
      vm.toggleAutoPlay = toggleAutoPlay;

      vm.onLoadImage = onLoadImage;
      vm.autoPlay =
        typeof vm.autoPlay === 'boolean'
          ? vm.autoPlay
          : localStorage.getItem('data_carousel_autoplay') !== 'false';
    };

    vm.$onChanges = function (changes) {
      vm.currentElement = null;
      cancelTimeout();
      if (changes.data) {
        vm.stopWatch = $scope.$watchCollection('ctrl.data.length', () => {
          cancelTimeout();
          if (!vm.data) {
            vm.slides = 0;
            return;
          }

          vm.slides = vm.data.length;
        });

        vm.slideIndex = getStartSlide();
        showSlides(vm.slideIndex);
      }

      if (changes.startSlide) {
        vm.slideIndex = getStartSlide();
        showSlides(vm.slideIndex);
      }
      firstChange = false;
    };

    vm.$onDestroy = () => {
      cancelTimeout();
      if (vm.stopWatch) {
        vm.stopWatch();
      }
    };

    function getStartSlide() {
      const startSlide = vm.startSlide || 0;
      return vm.slides > startSlide ? startSlide : 0;
    }

    function cancelTimeout() {
      if (vm.timeout) {
        $timeout.cancel(vm.timeout);
      }
    }

    function plusSlides(n) {
      if (vm.loadingImage) {
        return;
      }
      showSlides(vm.slideIndex + n);
    }

    function currentSlide(n) {
      showSlides((vm.slideIndex = n));
    }

    function showSlides(selected) {
      let slides = (vm.slides = vm.data.length);
      if (selected >= slides) {
        selected = 0;
      }
      if (selected < 0) {
        vm.slideIndex = 0;
        return;
      }

      vm.loadingImage = true;
      vm.slideIndex = selected;
      const data = vm.data[vm.slideIndex];
      if (vm.currentElement && vm.currentElement.referenceId === data.referenceId) {
        onLoadImage();
      }
      vm.currentElement = data;
    }

    function onLoadImage() {
      vm.loadingImage = false;
      initAutoPlay();
    }

    function toggleAutoPlay() {
      vm.autoPlay = !vm.autoPlay;
      localStorage.setItem('data_carousel_autoplay', vm.autoPlay + '');
      initAutoPlay();
    }

    function initAutoPlay() {
      if (vm.playTimeout) {
        $timeout.cancel(vm.playTimeout);
      }

      if (vm.autoPlay) {
        vm.playTimeout = $timeout(() => {
          plusSlides(1);
        }, slideTime);
      }
    }
  }
})();
