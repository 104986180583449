(() => {
  const angular = window.angular;

  angular.module('app').config(Router);

  Router.$inject = ['$stateProvider'];

  function Router($stateProvider) {
    $stateProvider.state('member.var.show.customer.show.project.granularity-dashboard', {
      url: '/granularity-dashboard',
      component: 'granularityDashboard',
      authenticated: true,
      resolve: {
        module: [
          '$ocLazyLoad',
          function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/granularity-dashboard.module.min.js',
            ]);
          },
        ],
      },
      ncyBreadcrumb: {
        label: '{{"entities.tool.__tools.granularity-dashboard" | translate}}',
      },
    });
  }
})();
