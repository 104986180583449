(function () {
  'use strict';

  let angular = window.angular;

  ProjectRouter.$inject = ['$stateProvider'];

  angular.module('app').config(ProjectRouter);

  function ProjectRouter($stateProvider) {
    $stateProvider
      .state('member.var.show.customer.show.project', {
        url: '/project/:projectId',
        authenticated: true,
        template: '<ui-view></ui-view>',
        redirectTo: 'member.var.show.customer.show.project.home',
        projectRoot: true,
        resolve: {
          project: [
            'ProjectService',
            '$transition$',
            'navbarService',
            'ToolService',
            'AppUtils',
            '$translate',
            function (ProjectService, $transition$, navbarService, ToolService, utils, $translate) {
              let params = $transition$.params();
              return ProjectService.findById(params.customerId, params.projectId, {
                include: ['tools', 'adminTools', 'tags', 'yoloClasses', 'allowedSensorTypes'],
                order: 'name ASC',
              })
                .then((project) => {
                  const modules = ToolService.getToolsModules();
                  project.links = getLinks($translate, project.tools, modules).sort(
                    (prev, current) => (prev.label > current.label ? 1 : -1)
                  );

                  project.adminLinks = getLinks($translate, project.adminTools, modules).sort(
                    (prev, current) => (prev.label > current.label ? 1 : -1)
                  );

                  for (let yoloClass of project.yoloClasses) {
                    yoloClass.value = yoloClass.name.replace(/\s/g, '');
                    yoloClass.label = $translate.instant(
                      'entities.sensor.__classes.' + yoloClass.value
                    );
                  }

                  navbarService.setProject(project);
                  return project;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.var.show.customer.show.home',
                    params: { customerId: params.customerId },
                  });
                });
            },
          ],
          assets: [
            'assetUtilsService',
            '$rootScope',
            function (assetUtilsService, $rootScope) {
              return assetUtilsService.loadTree().then((tree) => {
                subscribeToHealthStatus($rootScope, tree);
                return tree;
              });
            },
          ],
          tools: [
            'project',
            (project) => {
              const tools = [];
              Array.prototype.push.apply(tools, project.tools);
              Array.prototype.push.apply(tools, project.adminTools);
              return tools;
            },
          ],
          assetTypes: [
            'Customer',
            '$transition$',
            '$rootScope',
            'project',
            function (Customer, $transition$, $root, project) {
              let params = $transition$.params();
              return Customer.prototype$__get__projects__assetTypes({
                id: params.customerId,
                fk: params.projectId,
                filter: {
                  include: ['sensorTypes', 'assetWizardTypes'],
                },
              }).$promise.then((assetTypes) => {
                $root.assetTypes = assetTypes;
                $root.sensorTypes = assetTypes.reduce((array, at) => {
                  for (let st of at.sensorTypes) {
                    if (array.findIndex((c) => c.type === st.type) === -1) {
                      array.push(st);
                    }
                  }
                  return array;
                }, []);
                if (project.allowedSensorTypes && project.allowedSensorTypes.length) {
                  for (let assetType of assetTypes) {
                    assetType.sensorTypes = assetType.sensorTypes.filter((sensorType) => {
                      return project.allowedSensorTypes.find((c) => c.id === sensorType.id);
                    });
                  }
                }
                return assetTypes;
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.project.name}}',
        },
      })
      .state('member.var.show.customer.show.project.home', {
        url: '/',
        redirectTo: 'member.var.show.customer.show.project.home.directAccess',
        component: 'projectHome',
        ncyBreadcrumb: { skip: true },
      })

      .state('member.var.show.customer.show.project.home.directAccess', {
        url: 'direct-access',
        authenticated: true,
        component: 'projectLinks',
        ncyBreadcrumb: { label: '{{"entities.tool.modelNamePl" | translate}}' },
      })

      .state('member.var.show.customer.show.project.home.profile', {
        url: 'profile',
        authenticated: true,
        component: 'projectProfile',
        ncyBreadcrumb: { label: '{{"general.configuration" | translate}}' },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
        },
      });

    function getLinks($translate, tools, modules) {
      return tools.reduce((links, tool) => {
        const module = modules[tool.module];
        if (module) {
          module.name = module.label || tool.name;
          module.langCode = tool.module.replace(/xompass|vsaas/, '');
          const label = module.langCode
            ? $translate.instant('entities.tool.__tools.' + module.langCode)
            : module.name;
          module.label = label;
          module.inlineLabel = label.replace(/<[^>]+>/gm, '');
          links.push(module);
        } else {
          console.log(tool.module);
        }

        return links;
      }, []);
    }

    function subscribeToHealthStatus($rootScope, assets) {
      const leafs = assets.tree.reduce((map, leaf) => {
        map[leaf.id] = leaf;
        return map;
      }, {});

      $rootScope.$on('Asset.healthStatus', (event, data) => {
        const instanceId = data.through?.id;
        const status = data.body?.currentHealthStatus;
        if (!assets.assets[instanceId]) {
          return;
        }

        assets.assets[instanceId].currentHealthStatus = status;
        leafs[instanceId].li_attr['data-status'] = status;
      });
    }
  }
})();
