;(function () {
  Controller.$inject = ['$scope', '$compile', '$element'];

  const angular = window.angular;
  angular
    .module('app')
    .component('svgPluginDisplay', {
      template: '',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        plugin: '<',
        fillColor: '<',
        showLabel: '<'
      }
    });

  function Controller ($scope, $compile, $element) {
    let vm = this;

    vm.$postLink = function () {
      $element
        .append($compile(`<${vm.plugin}
        fill-color="ctrl.fillColor"
        show-label="ctrl.showLabel"></${vm.plugin}>`)($scope));
    };
  }
})();
