(function () {
  const angular = window.angular;

  angular.module('app').directive('eyeDropperInput', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'E',
        require: '^ngModel',
        scope: {
          size: '=',
        },
        template: `<div class="input-group input-group-{{size}}">
          <input placeholder="#ffffff"
            ng-change="setColor()" 
            class="form-control"
            pattern="^#[0-9A-Fa-z]{6}$"
            ng-model="colorValue"
            required
          />
          <span class="input-group-addon" ng-if="eyeDropperEnabled" ng-click="openEyeDropper()">
            <i class="fas fa-eye-dropper fa-fw"></i>
          </span>
        </div>`,
        link: (scope, el, attrs, ngModel) => {
          ngModel.$viewChangeListeners.push(function () {
            scope.$eval(attrs.ngChange);
          });

          ngModel.$render = function () {
            scope.colorValue = ngModel.$modelValue;
          };

          scope.setColor = function () {
            ngModel.$setViewValue(scope.colorValue);
          };

          if (window.EyeDropper) {
            scope.eyeDropper = new EyeDropper();
            scope.eyeDropperEnabled = true;
          }

          scope.openEyeDropper = function () {
            if (!scope.eyeDropper) {
              return;
            }
            scope.eyeDropper
              .open()
              .then((result) => {
                $timeout(() => {
                  scope.colorValue = result.sRGBHex;
                  ngModel.$setViewValue(scope.colorValue);
                });
              })
              .catch((e) => {
                console.log(e);
              });
          };
        },
      };
    },
  ]);
})();
