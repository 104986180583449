(function () {
  'use strict';

  let angular = window.angular;

  EdgeAgentModalController.$inject = [
    '$scope',
    '$rootScope',
    'AppUtils',
    'Customer',
    'EdgeDeviceService',
    'EdgeAgentService',
  ];

  angular.module('app').component('deviceEdgeAgentFormModal', {
    template:'<div><div class="modal-header"><button type="button" class="close" ng-click="ctrl.modalInstance.dismiss()"><i class="fas fa-times"></i></button> <span>{{:: ctrl.edgeAgent.id ? \'form.actions.update\' : \'form.actions.create\' | translate}}</span> <span translate>entities.edgeagent.modelName</span></div><div class="modal-body"><device-edge-agent-form edge-agent="ctrl.edgeAgent" names="ctrl.names" disable-template-selection="true"></device-edge-agent-form><div style="position: relative; height: 535px; overflow-y: auto; overflow-x: hidden"><edge-agent-json edge-agent="ctrl.edgeAgent" height="475"></edge-agent-json></div></div><div ng-if="ctrl.saveError" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.saveError}}</div><div class="modal-footer"><button class="btn btn-default js-btn-step pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-disabled="ctrl.disableSave" ng-click="ctrl.validateAndSave()" translate>buttons.save</button></div></div><spinner css-class="\'small\'" show-logo="true" show="ctrl.saving"></spinner>',
    controller: EdgeAgentModalController,
    controllerAs: 'ctrl',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function EdgeAgentModalController(
    $scope,
    $root,
    utils,
    Customer,
    EdgeDeviceService,
    EdgeAgentService
  ) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.device = vm.resolve.device;

      vm.edgeAgent = angular.copy(vm.resolve.edgeAgent || { enabled: true });
      vm.names = vm.resolve.names;
      vm.validateAndSave = applyChanges;

      $root.$on('ds:agent-generated-error', (e, error) => {
        vm.disableSave = !!error;
      });
    };

    function validateGeneral() {
      return utils.Promise((resolve, reject) => {
        const offResponse = $root.$on('ds:agent-validate-after', (e, response) => {
          offResponse();
          resolve(response.valid);
        });
        $scope.$broadcast('ds:agent-validate');
      });
    }

    function validateAgentContent() {
      return utils.Promise((resolve, reject) => {
        const offResponse = $root.$on('ds:agent-validate-content-after', (e, response) => {
          offResponse();
          resolve(response);
        });
        $scope.$broadcast('ds:agent-validate-content');
      });
    }

    function applyChanges() {
      vm.saving = true;
      validateGeneral()
        .then((valid) => {
          if (!valid) {
            throw new Error('INVALID AGENT');
          }
          return validateAgentContent();
        })
        .then((result) => {
          if (!result.valid) {
            throw new Error('INVALID AGENT CONTENT');
          }

          const agent = EdgeAgentService.getAgentBody(result.edgeAgent);
          agent.name = vm.edgeAgent.name;
          agent.sharedPath = vm.edgeAgent.sharedPath;
          agent.image = vm.edgeAgent.image.name + ':' + vm.edgeAgent.image.version;
          agent.enabled = vm.edgeAgent.enabled;

          if (!vm.edgeAgent.id) {
            return Customer.prototype$__create__devices__edgeAgents(
              {
                id: vm.device.customerId,
                nk: vm.device.id,
              },
              agent
            ).$promise;
          } else {
            return Customer.prototype$__updateById__devices__edgeAgents(
              {
                id: vm.device.customerId,
                nk: vm.device.id,
                fk: vm.edgeAgent.id,
              },
              agent
            ).$promise;
          }
        })
        .then((result) => {
          return EdgeDeviceService.loadEdgeAgents(vm.device.customerId, vm.device.id);
        })
        .then(function (agents) {
          vm.saving = false;
          vm.modalInstance.close(agents);
        })
        .catch(function (err) {
          vm.saving = false;
          vm.saveError = utils.getHTTPError(err);
        });
    }
  }
})();
