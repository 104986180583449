;(() => {
  window.angular
    .module('app')
    .component('svgPluginFire', {
      template:'<div class="x-plugin-icon"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve"><g><g><path ng-style="{fill: ctrl.fillColor}" d="M320.39,224.66l-0.82-1.8c-0.07-0.17-0.17-0.33-0.27-0.49c-1.17-1.65-2.42-7.38-3.53-12.42 c-2.46-11.25-4.24-18.25-8.16-20.57c-1.31-0.79-2.78-0.98-4.23-0.55c-0.3,0.08-0.58,0.21-0.84,0.39 c-6.06,4.08-6.4,14.19-6.73,23.97c-0.09,2.72-0.18,5.49-0.41,8.02c-5.5-20.49-14.46-39.79-23.14-58.49l-2.86-6.16 c-4.79-10.29-9.32-20.02-10.46-31.03c-0.4-3.86-2.47-5.27-3.66-5.78c-1.17-0.48-3.53-0.95-6.44,1.27 c-15.81,9.24-16.63,30.07-17.23,45.29c-0.22,5.37-0.54,13.48-1.95,14.45c-0.01,0-1.07,0.16-4.03-2.03 c-15.92-23.05-21.25-26.44-25.48-24.6c-5.51,2.41-3.86,12.28,2.26,39.86c4.24,19.13,9.52,42.93,6.22,50.93 c-0.07,0.16-0.13,0.33-0.16,0.51c-2.79,12.93-8.25,20.73-17.69,25.28c-0.78,0.38-1.35,0.62-1.76,0.76 c-0.02-0.41-0.02-0.98,0.02-1.76c0.01-0.22,0-0.43-0.04-0.65c-0.22-1.41,0.28-5.94,0.72-9.93c1.55-14,2.15-22.68-2.6-25.13 c-2.36-1.21-5.16-0.4-8.31,2.42c-0.17,0.15-0.33,0.32-0.46,0.52c-22.07,31.67-25.09,65.31-8.51,94.71 c16.59,29.43,50.85,49,84.8,49c1.88,0,3.76-0.06,5.63-0.18c33.94-2.2,60.61-23.14,75.09-58.99 C348.81,287.29,334.36,255.45,320.39,224.66z M213.22,322.69c-5.75-9.63-7.37-21.28-4.87-31.32c0.69,1.78,1.7,3.16,3.05,4.13 c2.7,1.95,6.31,1.93,10.17-0.05c6.74-3.47,14.01-13.06,13.98-22.42c0.47-15.46,5.02-30.98,13.89-47.32 c0.01,0.03,0.01,0.07,0.02,0.1c0.32,2.69,0.56,4.56,1.15,5.92c1.35,11.28,12.24,42.77,23.84,43.23 c5.59,0.22,8.55-5.84,10.16-11.31c18.5,30.63,10.39,59.7-8.3,73.46C259.72,349.34,233.79,349.6,213.22,322.69z"></path></g></g></svg><div class="caption" ng-if="ctrl.showLabel" translate translate-attr="{title: \'plugins.fire\'}">plugins.fire</div></div>',
      controllerAs: 'ctrl',
      bindings: {
        fillColor: '<',
        showLabel: '<'
      }
    });
})();
