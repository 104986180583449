(function () {
  angular.module('app').directive('resizer', [
    '$document',
    '$window',
    '$timeout',
    ($document, $window, $timeout) => {
      resizeHandler.$inject = ['$scope', '$element', '$attrs'];

      return {
        restrict: 'A',
        scope: {
          position: '=?',
        },
        link: resizeHandler,
      };

      function resizeHandler($scope, $element, $attrs) {
        const height = $element.height();
        const width = $element.width();
        const parent = $element.parent();
        const parentOffset = parent.offset();
        const resizeMin =
          $attrs.resizerMin && parseInt($attrs.resizerMin) ? parseInt($attrs.resizerMin) : 0;

        $element.on('mousedown', function (event) {
          event.preventDefault();
          $document.on('mousemove', mousemove);
          $document.on('mouseup', mouseup);
        });

        $scope.$on('$destroy', function () {
          $element.off('mousedown');
          $document.off('mousemove', mousemove);
          $document.off('mouseup', mouseup);
        });

        function mouseup() {
          $document.off('mousemove', mousemove);
          $document.off('mouseup', mouseup);
          window.dispatchEvent(new Event('resize'));
        }

        function mousemove(event) {
          if ($attrs.resizer === 'vertical') {
            let resizeMax;
            if ($attrs.resizerMax && parseInt($attrs.resizerMax)) {
              resizeMax = parseInt($attrs.resizerMax);
            } else {
              resizeMax = !isNaN(resizeMin) ? parent.width() - resizeMin : parent.width();
            }

            // Handle vertical resizer
            let x = event.pageX - parentOffset.left;

            if (x > resizeMax) {
              x = parseInt(resizeMax);
            }

            if (x < resizeMin) {
              x = parseInt(resizeMin);
            }

            $element.css({
              left: x + 'px',
            });

            const left = document.getElementsByClassName('left')[0];
            const right = document.getElementsByClassName('right')[0];

            if (left) {
              left.style.width = x + 'px';
            }
            if (right) {
              right.style.left = x + parseInt(width) + 'px';
            }
          } else {
            let resizeMax;
            if ($attrs.resizerMax && parseInt($attrs.resizerMax)) {
              resizeMax = parseInt($attrs.resizerMax);
            } else {
              resizeMax = !isNaN(resizeMin) ? parent.height() - resizeMin : parent.width();
            }

            // Handle horizontal resizer
            let y = window.innerHeight - event.pageY;

            if (y > resizeMax) {
              y = parseInt(resizeMax);
            }

            if (y < resizeMin) {
              y = parseInt(resizeMin);
            }

            $element.css({
              bottom: y + 'px',
            });

            const top = document.getElementsByClassName('top')[0];
            const bottom = document.getElementsByClassName('bottom')[0];
            if (top) {
              top.style.bottom = y + parseInt(height) + 'px';
            }
            if (bottom) {
              bottom.style.height = y + 'px';
            }
          }
        }
      }
    },
  ]);
})();
