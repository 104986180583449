(function () {
  'use strict';

  const angular = window.angular;

  angular.module('app').directive('imageUpload', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'E',
        scope: {
          image: '=',
          label: '=?',
          crop: '<?',
          imageSrc: '=?',
          imageCrop: '=?',
        },
        controller: ['$scope', '$element', ImageUploadController],
        controllerAs: 'iuCtrl',
        template: `<div class="form-group marginB10">
          <label for="image-input" ng-if="label">{{label}}</label>
          <div class="form-group input-file">
            <span class="button">
              <input file-change="previewImage()" file-model="image.file" accept=".jpg, .png, .jpeg" type="file">
              <span translate>buttons.browse</span>
            </span>
            <input class="form-control" translate-attr="{'placeholder': 'general.file'}" ng-model="image.file.name" readonly>
          </div>
        </div>
        <div class="thumbnail no-margin">
          <h3 ng-if="!iuCtrl.showImagePreview" style="line-height: 200px" class="text-center no-margin" translate>form.actions.selectImage</h3>
          <div ng-show="iuCtrl.showImagePreview" class="img-preview text-center"></div>
        </div>`,
      };

      function ImageUploadController($scope, $element) {
        let vm = this;
        let imagePreview = $element.find('.img-preview');
        let croppedImage = null;

        $scope.image = $scope.image || {};
        if ($scope.imageSrc) {
          vm.showImagePreview = true;
          $timeout(function () {
            onLoadImage($scope.imageSrc, $scope.imageSrc.replace(/^.*[\\\/]/, ''));
          });
        }

        $scope.previewImage = function () {
          let file = $scope.image ? $scope.image.file : null;

          if (!file) {
            return;
          }

          let reader = new FileReader();
          vm.showImagePreview = true;

          reader.onload = function (e) {
            $timeout(function () {
              onLoadImage(e.target.result, file.name);
            });
          };
          reader.readAsDataURL(file);
        };

        function onLoadImage(url, filename, cropData) {
          let container = imagePreview;
          let image = $('<img >');
          vm.imageLoaded = false;

          let height = (container.width() * 4) / 7;
          container.height(height > 400 ? 400 : height);

          if (croppedImage) {
            croppedImage.cropper('destroy');
          }

          image
            .on('load', function () {
              container.empty();
              container.append(image);
              image.hide();

              let contWidth = container.width();
              let contHeight = container.height();

              image.show();
              image.width(contWidth);

              if (image.height() > contHeight) {
                image.height(contHeight);
                image.css({ width: 'auto' });
              }

              if ($scope.crop) {
                if (typeof $scope.crop === 'boolean') {
                  $scope.crop = {};
                }

                $scope.crop.aspectRatio =
                  $scope.crop.aspectRatio !== undefined ? $scope.crop.aspectRatio : 1;
                $scope.crop.viewMode =
                  $scope.crop.viewMode !== undefined ? $scope.crop.viewMode : 1;
                $scope.crop.center = $scope.crop.center !== undefined ? $scope.crop.center : false;
                let cropConfig = {
                  aspectRatio: $scope.crop.aspectRatio,
                  viewMode: $scope.crop.viewMode,
                  center: $scope.crop.center,
                  autoCropArea: $scope.crop.autoCropArea,
                  crop: function (e) {
                    const config = e.detail || {};
                    $timeout(function () {
                      $scope.image.crop = {
                        x: config.x,
                        y: config.y,
                        width: config.width,
                        height: config.height,
                      };
                    });
                  },
                };

                if (cropData) {
                  cropConfig.data = cropData;
                }

                $scope.image.cropInstance = new window.Cropper(image[0], cropConfig);
              }

              vm.imageLoaded = true;
            })
            .attr('src', url);
        }
      }
    },
  ]);
})();
