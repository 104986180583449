;(function () {
  'use strict';

  let angular = window.angular;

  TimeSeriesModal.$inject = ['Customer'];

  angular
    .module('app')
    .component('timeSeriesModal', {
      template:'<div class="modal-header"><span translate>general.timeSeries._</span></div><div class="modal-body padding5"><div class="panel panel-default no-margin"><header class="panel-heading"><h2 translate>entities.sensor.modelNamePl</h2><div class="panel-toolbars pull-right"><div class="panel-toolbar"><div class="compact-select2 angular-select2" style="min-width: 300px"><ui-select append-to-body="true" ng-model="ctrl.selectedSensor" theme="select2" title="Select a Sensor"><ui-select-match placeholder="{{:: \'general._select.a\' | translate}} {{:: \'entities.sensor.modelName\' | translate}}">{{$select.selected.name}}</ui-select-match><ui-select-choices repeat="sensor in ctrl.asset.sensors | filter: $select.search"><span ng-bind-html="sensor.name | highlight: $select.search"></span></ui-select-choices></ui-select></div></div></div></header><div class="panel-body no-padding"><div class="no-padding no-margin" style="height: 190px" ng-hide="ctrl.selectedSensor"><h2 class="text-center no-margin" style="line-height: 190px;"><span translate>general._select.a</span> <span translate>entities.sensor.modelName</span></h2></div><time-series-chart chart-only="true" ng-if="ctrl.selectedSensor" sensor="ctrl.selectedSensor" asset="ctrl.asset.id"></time-series-chart></div></div></div><div class="modal-footer"><button class="btn btn-default" type="button" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.close</button></div>',
      controller: TimeSeriesModal,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function TimeSeriesModal (Customer) {
    let vm = this;

    vm.$onInit = function onInit () {
      vm.customer = vm.resolve.customer;
      vm.project = vm.resolve.project;
      vm.asset = vm.resolve.asset;

      vm.selectedSensor = null;
    };
  }
})();
