;(() => {
  const angular = window.angular;

  Controller.$inject = ['Customer', '$rootScope', 'AppUtils'];

  angular
    .module('app')
    .component('telegramChatLink', {
      template:'<form class="validate" name="ctrl.telegramChatLinkForm" novalidate ng-class="{transparent: ctrl.loading}"><div class="modal-header">{{ctrl.instance.name}} | <span translate>{{\'buttons.link\'}}</span> <span translate>entities.telegramchat.modelName</span></div><div class="modal-body" style="min-height: 300px"><div class="form-group" ng-show="ctrl.telegramChats.length"><label><span translate>entities.telegramchat.modelName</span> <span class="text-danger">*</span></label><ui-select ng-model="ctrl.telegramChat" theme="select2"><ui-select-match placeholder="{{::\'general._select.a\' | translate}} {{::\'entities.telegramchat.modelName\' | translate}}">{{$select.selected.name}}</ui-select-match><ui-select-choices repeat="telegramChat in ctrl.telegramChats | filter: {name: $select.search}"><div ng-bind-html="telegramChat.name | highlight: $select.search"></div></ui-select-choices><ui-select-no-choice><span translate>datatables.emptyTable</span></ui-select-no-choice></ui-select></div><div ng-show="!ctrl.telegramChats.length"><div class="alert alert-info no-margin text-center"><span translate>datatables.emptyTable</span></div></div></div><div ng-if="ctrl.error" class="alert alert-warning text-center marginB0">{{ctrl.error}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" ng-show="!ctrl.loading" translate>buttons.cancel</button> <button ng-disabled="ctrl.loading || !ctrl.telegramChat" class="btn btn-primary" ng-click="ctrl.linkEntity()" translate>buttons.save</button></div></form><spinner css-class="\'small\'" show-logo="true" show="ctrl.loading"></spinner>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        resolve: '<',
        modalInstance: '<'
      }
    });

  function Controller (Customer, $root, utils) {
    const vm = this;

    vm.$onInit = function () {
      vm.Entity = vm.resolve.Entity;
      vm.instance = vm.resolve.instance;
      vm.instance.telegramChats = vm.instance.telegramChats || [];
      vm.linkEntity = linkEntity;
      vm.telegramChat = null;
      loadTelegramChats();
    };

    function loadTelegramChats () {
      vm.loading = true;
      Customer
        .telegramChats({ id: $root.customerId })
        .$promise
        .then(function (telegramChats) {
          telegramChats = telegramChats.filter(current => !vm.instance.telegramChats.find(chat => chat.id === current.id));
          telegramChats.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
          vm.loading = false;

          vm.telegramChats = telegramChats;
        })
        .catch(err => {
          vm.loading = false;
          vm.error = utils.getHTTPError(err);
        });
    }

    function linkEntity () {
      if (!vm.telegramChat) {
        return;
      }
      vm.loading = true;
      vm.Entity
        .prototype$__link__telegramChats({
          id: vm.instance.id,
          fk: vm.telegramChat.id
        }, {})
        .$promise
        .then(() => {
          vm.loading = false;
          vm.modalInstance.close(vm.telegramChat);
        })
        .catch(err => {
          vm.loading = false;
          vm.error = utils.getHTTPError(err);
        });
    }
  }
})();
