(function () {
  'use strict';

  let angular = window.angular;

  Controller.$inject = ['$scope', '$rootScope', '$timeout', 'Customer'];

  angular.module('app').component('permissionCodeModal', {
    template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><span translate>buttons.show</span> <span translate>entities.telegram.titles.code</span></div><div class="modal-body text-center"><div style="margin-top: 15px; margin-bottom: 15px"><span translate>entities.telegram.alerts.showToken</span></div><div style="padding-bottom: 20px"><div ng-if="!ctrl.codeShowed"><button class="btn btn-warning" ng-click="ctrl.getCode()" style="padding: 10px"><span class="alert" translate>entities.telegram.actions.showCode</span></button></div><div ng-if="ctrl.codeShowed" style="display: flex; flex-direction: column; align-items: center; gap: 15px; margin-top: 20px;"><label><span translate>entities.telegram.titles.activationCode</span>:</label><div style="background-color: white; padding: 15px; width: 70%; box-sizing: border-box; display: flex; justify-content: space-between; align-items: center;"><span style="color: black">{{ctrl.hashedCode}}</span> <a href="#" class="clipboard-btn" clipboard data-text="ctrl.hashedCode"><i style="color: gray" class="fas fa-clipboard"></i></a></div></div></div></div><div ng-if="ctrl.showPermissionError" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.showPermissionError}}</div><div class="modal-footer text-center"><button class="btn btn-primary" ng-click="ctrl.modalInstance.close({\'showed\':ctrl.codeShowed})" translate>buttons.close</button></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function Controller($scope, $root, $timeout, Customer) {
    let vm = this;
    vm.getCode = getCode;
    vm.$onInit = function onInit() {
      vm.codeShowed = false;
      vm.loading = false;
    };

    function getCode() {
      vm.loading = true;
      Customer.prototype$__get__projects__telegramPermissionsToken({
        id: vm.resolve.customerId,
        nk: vm.resolve.projectId,
        permissionId: vm.resolve.permissionId,
      }).$promise.then((res) => {
        console.log(res);

        vm.codeShowed = true;
        vm.hashedCode = res.hashedCode;
        vm.loading = false;
      });
    }
  }
})();
