(() => {
  const angular = window.angular;

  ZonePaper.$inject = ['BasePaper', '$translate', '$timeout', '$rootScope'];

  angular.module('app').service('ZonePaper', ZonePaper);

  function ZonePaper(BasePaper, $translate, $timeout, $root) {
    class ZonePaperCanvas extends BasePaper.Canvas {
      constructor(container, targetElement, size, options = {}) {
        super(container, targetElement, size, options);
        this.PortClass = ZonePaperPort;
      }

      onDblClick() {
        const _this = this;
        return function () {
          if (_this.tempPath && _this.tempPath.length) {
            const fixedPath = _this.fixPath(_this.tempPath);
            if (fixedPath.length > 3) {
              _this.endFigure(_this.tempFigure);
              _this.tempPath = null;
            }
          }
        };
      }
    }

    class ZonePaperPort extends BasePaper.Port {
      constructor(target, index, style = {}) {
        super(target, index, style);

        this.port.dblclick((e) => {
          const path = JSON.parse(
            JSON.stringify(target.attrs.path.slice(0, target.attrs.path.length - 1))
          );
          const nextIndex = (this.index + 1) % path.length;
          const nextPoint = {
            x: path[nextIndex][1],
            y: path[nextIndex][2],
          };

          const thisPoint = {
            x: this.port.attrs.cx,
            y: this.port.attrs.cy,
          };

          const middlePoint = {
            x: (nextPoint.x + thisPoint.x) / 2,
            y: (nextPoint.y + thisPoint.y) / 2,
          };

          if (isNaN(middlePoint.x) || isNaN(middlePoint.y)) {
            return;
          }

          target.attrs.path.splice(this.index + 1, 0, ['L', middlePoint.x, middlePoint.y]);
          this.target.attr('path', target.attrs.path);

          this.updatePath();
          const canvas = this.target.canvas;

          canvas.hidePorts();
          canvas.showPorts(this.target);
        });

        const _this = this;
        this.port.node.addEventListener('contextmenu', (e) => {
          const canvas = _this.target.canvas;
          while (canvas.menu.firstChild) {
            canvas.menu.removeChild(canvas.menu.firstChild);
          }

          const li = document.createElement('LI');
          li.innerText = $translate.instant('buttons.delete');

          li.addEventListener('click', (e) => {
            if (this.index === 0) {
              this.target.attrs.path[1][0] = 'M';
            }
            this.target.attrs.path.splice(this.index, 1);

            this.target.attr('path', target.attrs.path);
            this.updatePath();
            const canvas = this.target.canvas;
            canvas.hidePorts();
            canvas.showPorts(this.target);
            canvas.hideMenu();
          });
          canvas.menu.classList.add('opened');
          canvas.menu.appendChild(li);

          const container = canvas.container[0];
          const height = container.offsetHeight;
          const width = container.offsetWidth;
          const menuWidth = canvas.menu.offsetWidth;
          const menuHeight = canvas.menu.offsetHeight;

          let top = this.port.attrs.cy + this.style.radius;
          let left = this.port.attrs.cx + this.style.radius;

          if (top >= height) {
            top = this.port.attrs.cy - this.style.radius - menuHeight;
          }

          if (left >= width) {
            left = this.port.attrs.cx - this.style.radius - menuWidth;
          }

          canvas.menu.style.top = top + 'px';
          canvas.menu.style.left = left + 'px';
          e.preventDefault();
          e.stopPropagation();
        });
      }

      updatePath() {
        const path = this.target.getPath();
        const canvas = this.target.canvas;
        let points = [];
        for (let i = 0; i < path.length - 1; i++) {
          points.push({ x: canvas.unApplyRatio(path[i][1]), y: canvas.unApplyRatio(path[i][2]) });
        }

        $timeout(() => {
          this.target._sensor_config.points = points;
        });
      }
    }

    return {
      Canvas: ZonePaperCanvas,
      Port: ZonePaperPort,
    };
  }
})();
