;(function () {
  'use strict';

  const angular = window.angular;

  angular
    .module('app')
    .directive('geocoder', function () {
      return {
        restrict: 'E',
        scope: {
          hideContainer: '='
        },
        controller: ['$scope', '$rootScope', ViewSidebarToggleController],
        template: `<div class="input-group">
      <input type="text" class="form-control" placeholder="Ingrese una dirección">
      <span class="input-group-btn">
        <button class="btn btn-default" type="button" ng-click="">Buscar</button>
      </span>
    </div>`
      };
    });

  function ViewSidebarToggleController (scope, $root) {
  }
})();
