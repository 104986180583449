(function () {
  'use strict';

  let angular = window.angular;

  PeopleCounterReportRouter.$inject = ['$stateProvider'];

  angular.module('app').config(PeopleCounterReportRouter);

  function PeopleCounterReportRouter($stateProvider) {
    $stateProvider
      .state('member.var.show.customer.show.project.pcr', {
        url: '/pcr',
        redirectTo: 'member.var.show.customer.show.project.pcr.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Store Analysis Reports',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassPeopleCounterReports');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.var.show.customer.invalidTool',
                  params: { tool: 'xompassPropleCounterReports' },
                });
              }
            },
          ],
          module: [
            '$ocLazyLoad',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([window.assetsPath + '/js/app/pcr.module.min.js']);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.pcr.home', {
        url: '/',
        component: 'pcrHome',
        authenticated: true,
        resolve: {
          reports: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = Object.assign({ order: 'name ASC' }, $transition$.params());
              return EntityService.find('PeopleCounterReport', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.pcr.dashboard', {
        url: '/:reportId',
        redirectTo: 'member.var.show.customer.show.project.pcr.dashboard.daily',
        component: 'pcrDashboard',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
              ]);
            },
          ],
          virtualGroups: [
            '$rootScope',
            'Customer',
            ($root, Customer) => {
              return Customer.prototype$__get__projects__virtualGroups({
                id: $root.customerId,
                nk: $root.projectId,
              }).$promise;
            },
          ],
          virtualExpressions: [
            '$rootScope',
            'VirtualExpressionService',
            function ($root, VirtualExpressionService) {
              return VirtualExpressionService.find($root.customerId, $root.projectId, {
                fields: ['id', 'name', 'description', 'expression', 'virtualGroupId'],
              });
            },
          ],
          report: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadPCR(EntityService, $transition$.params(), utils);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.report.name}}',
        },
      })
      .state('member.var.show.customer.show.project.pcr.dashboard.editor', {
        url: '/editor',
        component: 'pcrEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Editor',
        },
      })
      .state('member.var.show.customer.show.project.pcr.dashboard.daily', {
        url: '/daily',
        component: 'pcrDaily',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Daily Report',
        },
      })
      .state('member.var.show.customer.show.project.pcr.dashboard.weekly', {
        url: '/weekly',
        component: 'pcrWeekly',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Weekly Report',
        },
      })
      .state('member.var.show.customer.show.project.pcr.dashboard.monthly', {
        url: '/monthly',
        component: 'pcrMonthly',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Monthly Report',
        },
      })
      .state('member.var.show.customer.show.project.pcr.dashboard.managers', {
        url: '/managers',
        component: 'pcrManagers',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Managers',
        },
      });

    function loadPCR(EntityService, params, utils) {
      const options = angular.copy(params);
      options.entityId = options.reportId;
      options.filter = { include: ['series'] };

      return EntityService.findById('PeopleCounterReport', options).catch((err) => {
        console.log(err);
        throw utils.getHTTPError(err, {
          redirectTo: 'member.var.show.customer.show.project.pcr.home',
        });
      });
    }
  }
})();
