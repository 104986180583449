(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  toolsTable.$inject = [
    '$element',
    '$state',
    '$uibModal',
    'Customer',
    'Var',
    'AppUtils',
    '$translate',
  ];

  angular.module('app').component('toolsTable', {
    template:'<div style="position: relative; min-height: 200px"><div class="panel panel-default"><header class="panel-heading"><h2 class="nowrap"><strong translate>{{ctrl.title || \'entities.tool.modelNamePl\'}}</strong></h2><div class="panel-toolbars pull-right"><div class="panel-toolbar"><button class="btn btn-primary" ng-click="ctrl.showToolLinkModal()"><i class="fas fa-plus"></i></button></div></div></header><div class="panel-body no-padding"><table id="tools-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th><th></th><th style="width: 30px"></th></tr></thead></table></div></div><spinner show="ctrl.loading" show-logo="true"></spinner></div>',
    controller: toolsTable,
    controllerAs: 'ctrl',
    bindings: {
      customerId: '<',
      varId: '<',
      entityId: '<',
      entityType: '<',
      target: '<?',
      title: '<?',
    },
  });

  function toolsTable($element, $state, $uibModal, Customer, Var, utils, $translate) {
    let vm = this;

    vm.$onInit = function onInit() {
      let promise;
      const target = (vm.target = vm.target || 'tools');
      switch (vm.entityType) {
        case 'project':
          promise = Customer[`prototype$__get__projects`]({
            id: vm.customerId,
            filter: {
              where: { id: vm.entityId },
              include: { relation: target, scope: { include: 'assetTypes' } },
            },
          }).$promise.then((projects) => {
            const project = projects[0];
            if (!project) {
              throw new Error('Project not found');
            }

            console.log(project[target]);
            return project[target];
          });
          break;
        case 'toolkit':
          const entity = vm.customerId ? Customer : vm.varId ? Var : null;
          if (entity) {
            promise = entity
              .prototype$__get__toolkits({
                id: vm.customerId || vm.varId,
                filter: {
                  where: { id: vm.entityId },
                  include: { relation: target, scope: { include: 'assetTypes' } },
                },
              })
              .$promise.then((tools) => {
                return tools.length ? tools[0][target] : [];
              });
          }
      }

      if (!promise) {
        console.log('No fue posible cargar las herramientas');
        return;
      }

      vm.loading = true;
      promise
        .then((tools) => {
          console.log(tools);
          vm.loading = false;
          vm.tools = tools;
          vm.toolsMap = utils.arrayToObject(vm.tools, 'id');
          initToolsTable(vm.tools);
        })
        .catch((err) => {
          vm.loading = false;
          console.log(err);
        });

      vm.showToolLinkModal = showToolLinkModal;
    };

    function initToolsTable(data) {
      let dtOptions = {
        dom: "<tr> <'row'<'col-sm-5'i><'col-sm-7'p>>",
        processing: true,
        searching: false,
        info: false,
        data: data,
        columns: [
          {
            data: 'module',
            title: $translate.instant('entities._all.name'),
            render: function (data) {
              return $translate.instant(
                'entities.tool.__tools.' + data.replace(/xompass|vsaas/, '')
              );
            },
          },
          {
            data: 'assetTypes',
            title: $translate.instant('entities.assettype.modelNamePl'),
            render: function (data) {
              if (!data || !Array.isArray(data)) {
                return '';
              }
              return data
                .map((current) => $translate.instant('entities.assettype.__types.' + current.type))
                .join(', ');
            },
          },
          {
            orderable: false,
            data: null,
            defaultContent:
              '<div class="table-buttons"><button class="btn btn-danger btn-xs"><i class="fas fa-times fa-fw"></i></button></div>',
          },
        ],
      };

      const table = $element.find('#tools-table');
      vm.dtInstance = table.DataTable(dtOptions);

      table.find('tbody').on('click', 'button', function () {
        const data = vm.dtInstance.row(angular.element(this).parents('tr')).data();
        $uibModal
          .open({
            // animation: true,
            backdrop: 'static',
            windowClass: 'modal-xs modal-warning',
            bindToController: true,
            component: 'removeTool',
            resolve: {
              organizationId: () => vm.customerId || vm.varId,
              entityType: () => vm.entityType,
              entityId: () => vm.entityId,
              tool: () => data,
              target: () => vm.target,
            },
          })
          .result.then(function () {
            $state.go('.', { toolkitId: vm.entityId }, { reload: true });
          })
          .catch(function (err) {
            console.log(err);
          });
      });
    }

    function showToolLinkModal() {
      $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          component: 'linkTool',
          resolve: {
            organizationId: () => vm.customerId || vm.varId,
            entityType: () => vm.entityType,
            entityId: () => vm.entityId,
            target: () => vm.target,
          },
        })
        .result.then(function (result) {
          $state.go('.', { toolkitId: vm.entityId }, { reload: true });
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
})();
