(() => {
  const angular = window.angular;

  angular.module('app').config(ForensicReconstructionRouter);

  ForensicReconstructionRouter.$inject = ['$stateProvider'];

  function ForensicReconstructionRouter($stateProvider) {
    $stateProvider.state('member.var.show.customer.show.project.forensicReconstruction', {
      url: '/forensic-reconstruction',
      component: 'forensicReconstruction',
      authenticated: true,
      resolve: {
        module: [
          '$ocLazyLoad',
          function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/forensic-reconstruction.module.min.js',
            ]);
          },
        ],
      },
      ncyBreadcrumb: {
        label: '{{"entities.tool.__tools.ForensicReconstruction" | translate}}',
      },
    });
  }
})();
