(() => {
  let angular = window.angular;

  Router.$inject = ['$stateProvider'];

  angular.module('app').config(Router);

  function Router($stateProvider) {
    $stateProvider.state('member.var.show.customer.show.project.forensicAnalysis', {
      url: '/forensic',
      component: 'forensicAnalysis',
      authenticated: true,
      ncyBreadcrumb: {
        label: 'Forensic Analysis',
      },
      resolve: {
        dependencies: [
          '$ocLazyLoad',
          '$rootScope',
          function ($ocLazyLoad, $root) {
            const style = $root.darkMode ? 'dark' : 'light';
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/forensic-analysis.module.min.js',
              window.assetsPath + `/css/forensic-analysis.style-${style}.css`,
            ]);
          },
        ],
        hasTool: [
          'tools',
          'AppUtils',
          function (tools, utils) {
            const hasTool = tools.find((tool) => tool.module === 'xompassForensicAnalysis');
            if (!hasTool) {
              throw utils.getHTTPError(new Error('Not allowed'), {
                redirectTo: 'member.var.show.customer.invalidTool',
                params: { tool: 'xompassForensicAnalysis' },
              });
            }
          },
        ],
        eventStates: [
          'EventStateService',
          (EventStateService) => EventStateService.getMergedEventStates(),
        ],
      },
    });
  }
})();
