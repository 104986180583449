(function () {
  'use strict';

  const angular = window.angular;
  let selectedNode = null;

  angular.module('app').directive('jsTree', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'E',
        scope: {
          treeInstance: '=?',
          data: '=',
          selected: '=',
          onSelect: '&',
          onReady: '&',
          onDnd: '&?',
          multiple: '=?',
          node: '=?',
          selectChildren: '=?',
          hideIcons: '=?',
          beforeSelect: '&?',
          hideExpandButton: '=?',
          addCameraCount: '=?',
        },
        controller: ['$rootScope', '$element', treeController],
        controllerAs: 'ctrl',
        link: function (scope, element) {
          return renderTree(scope, element, $timeout);
        },
        template: `
            <div class="tree-options">
                <div class="expand-all" ng-if="ctrl.hideExpandButton()">
                    <button class="btn btn-default btn-xs" ng-click="ctrl.toggleExpanded()">
                        <i class="fas fa-fw" ng-class="ctrl.expanded? 'fa-minus-square': 'fa-plus-square'"></i>
                    </button>
                </div>
                <div class="search">
                    <input translate-attr="{placeholder: 'datatables.search'}" class="form-control input-xs" ng-model="ctrl.searchValue" ng-keyup="ctrl.filterTree()">
                </div>
            </div>
        <div class="tree"></div>`,
      };
    },
  ]);

  let onAddNode;
  let onRenameNode;
  let onIconNode;
  let onRemoveNode;
  let onHealthStatus;

  function treeController(rootScope, element) {
    let vm = this;
    let target = element.find('.tree');
    vm.expanded = false;
    vm.hideExpandButtonFunc = hideExpandButtonFunc;
    selectedNode = null;

    vm.clearSelection = function () {
      target.jstree('deselect_all');
    };

    onAddNode = rootScope.$on('jstree.add-node', function (event, data) {
      target.jstree('create_node', data.parent, data);
    });

    onRenameNode = rootScope.$on('jstree.rename-node', function (event, data) {
      target.jstree('rename_node', data.id, data.text);
    });

    onIconNode = rootScope.$on('jstree.icon-node', function (event, data) {
      target.jstree('set_icon', data.id, data.icon);
    });

    onRemoveNode = rootScope.$on('jstree.remove-node', function (event, data) {
      target.jstree('delete_node', data);
    });

    onHealthStatus = rootScope.$on('Asset.healthStatus', (event, data) => {
      const instanceId = data.through?.id;
      const model = data.through?.model;
      const status = data.body?.currentHealthStatus;
      if (!instanceId || !model || !status) {
        return;
      }

      const treeInstance = target.jstree(true);
      const node = target.jstree('get_node', instanceId, true);
      if (!node || !node.length || node.attr('role') === 'tree') {
        return;
      }

      treeInstance._model.data[instanceId].li_attr['data-status'] = status;
      node[0].dataset.status = status;
    });

    let timeOut;
    vm.filterTree = function () {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(function () {
        target.jstree().search(vm.searchValue);
      }, 250);
    };

    vm.toggleExpanded = function () {
      target.jstree(vm.expanded ? 'close_all' : 'open_all');
      vm.expanded = !vm.expanded;
    };
  }

  function renderTree(scope, element, $timeout) {
    let target = element.find('.tree');

    let firstWatch = true;
    scope.$watch('selected', function (newNode) {
      if (firstWatch) {
        initTree(
          scope.data,
          target,
          newNode,
          {
            onSelect: scope.onSelect,
            beforeSelect: scope.beforeSelect,
            onDnd: scope.onDnd,
            onReady: scope.onReady,
          },
          scope,
          $timeout,
          element
        );
        firstWatch = false;
      } else {
        if (!scope.multiple) {
          let activated = target.jstree('get_selected');
          if (newNode) {
            if (Array.isArray(activated) && activated[0] === newNode) {
              return;
            }
            target.jstree('activate_node', newNode);
          } else {
            target.jstree('deselect_all');
          }
        } else if (newNode) {
          target.jstree('check_node', newNode);
        }
      }
    });

    element.on('$destroy', function () {
      target.jstree('destroy');

      if (onAddNode) {
        onAddNode();
      }

      if (onRenameNode) {
        onRenameNode();
      }

      if (onIconNode) {
        onIconNode();
      }

      if (onRemoveNode) {
        onRemoveNode();
      }

      if (onHealthStatus) {
        onHealthStatus();
      }
    });
  }

  function initTree(data, target, nodeId, options, scope, $timeout, element) {
    if (scope.hideExpandButton) {
      element.find('#expand-button').remove();
    }
    const plugins = ['search'];
    if (options.beforeSelect) {
      plugins.push('conditionalselect');
    }
    let config = {
      core: {
        data: data,
        multiple: scope.multiple || false,
        check_callback: true,
        animation: false,
      },
      dnd: {
        copy: false,
      },
      plugins: plugins,
      search: {
        show_only_matches: true,
        show_only_matches_children: true,
        search_callback: (str, obj) => {
          const node = obj.original;
          const text = node.name || node.text;
          const tags = node.tags;
          if (!tags?.length) {
            return strContains(text, str);
          }
          return strContains(text, str) || strContains(tags.join(), str);
        },
      },
    };

    if (scope.multiple) {
      config.plugins.push('checkbox');
      config.checkbox = {
        three_state: scope.selectChildren || false,
        keep_selected_style: false,
        //cascade: scope.selectChildren ? 'down' : ''
      };
    }

    if (scope.hideIcons) {
      config.core.themes = { icons: false };
    }

    if (options.onDnd) {
      config.core.check_callback = function (op, node, parent, pos, more) {
        if (op === 'move_node') {
          if (node.parent === parent.id) {
            return false;
          } else if (more && more.core) {
            console.log(more);
          }
        }

        return true;
      };
      config.plugins.push('dnd');
    }

    if (options.beforeSelect) {
      config.conditionalselect = function (node, event) {
        return options.beforeSelect({ node, event });
      };
    }

    target.jstree(config);

    target.on('ready.jstree', function () {
      $timeout(() => {
        if (nodeId) {
          if (Array.isArray(nodeId)) {
            target.jstree('check_node', nodeId);
          } else {
            target.jstree('activate_node', [nodeId]);
          }

          document.getElementById(nodeId)?.scrollIntoView({
            behavior: 'instant',
            block: 'nearest',
          });
        }

        if (scope.treeInstance) {
          scope.treeInstance.target = target;
          scope.treeInstance.instance = target.jstree();
        }

        if (options.onReady) {
          options.onReady();
        }
      });
    });

    if (options.onSelect) {
      target.on('changed.jstree', function (e, data) {
        $timeout(() => {
          if (scope.multiple) {
            options.onSelect({
              selected: data.selected,
            });
          } else if (scope.node) {
            options.onSelect({
              selected: data.node,
            });
          } else {
            let newNode = Array.isArray(data.selected) ? data.selected[0] : null;
            if (newNode !== selectedNode) {
              options.onSelect({
                selected: newNode,
              });
            }
            selectedNode = newNode;
          }
        });
      });
    }

    if (options.onDnd) {
    }
  }

  function strRemoveAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Function that return true if a search string is contained in a string
   */
  function strContains(str, search) {
    return strRemoveAccents(str.toLowerCase()).includes(strRemoveAccents(search.toLowerCase()));
  }

  function hideExpandButtonFunc() {
    if (vm.hideExpandButton) {
      return false;
    } else {
      return true;
    }
  }
})();
