(() => {
  Controller.$inject = ['$rootScope', 'Customer', 'Device', 'AppUtils'];
  angular
    .module('app')
    .component('connectionDeviceCommands', {
      template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><button type="button" class="close" ng-click="ctrl.modalInstance.dismiss()"><i class="fas fa-times"></i></button> <span>{{ctrl.device.name}} |</span> <span translate>entities.device._commands.restart</span></div><div class="modal-body text-center"><div translate data-translate-values="{device: ctrl.device.name}" class="marginTB10">entities.device.messages.restart</div></div><div class="alert alert-warning no-margin text-center" ng-if="ctrl.error">{{ctrl.error}}</div><div class="modal-footer"><button class="btn btn-sm btn-default js-btn-step pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-sm btn-danger" ng-click="ctrl.execCmd()" translate>buttons.exec</button></div></div><spinner css-class="\'small\'" show-logo="true" show="ctrl.loading"></spinner>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    });

  function Controller($root, Customer, Device, utils) {
    const vm = this;

    vm.$onInit = function () {
      vm.device = vm.resolve.device;
      vm.command = vm.resolve.command;

      vm.execCmd = execCmd;

      loadDevice()
        .catch(() => {
        });
    };

    function execCmd() {
      vm.loading = true;
      const command = utils.capitalize(vm.command);
      Device[`prototype$cmdc${command}`]({
        id: vm.device.id,
      }).$promise
        .then(() => {
          vm.loading = false;
          vm.modalInstance.close();
        })
        .catch(err => {
          vm.error = utils.getHTTPError(err);
          vm.error = vm.error.message.replace('Docker error: ', '');
          vm.loading = false;
        });
    }

    function loadDevice() {
      vm.loading = true;
      return Customer
        .devices({
          id: $root.customerId,
          filter: {
            where: {id: vm.device.id},
          },
        })
        .$promise
        .then(function (result) {
          vm.device = result[0];
          vm.loading = false;
        })
        .catch(function (err) {
          vm.error = utils.getHTTPError(err);
          vm.loading = false;
        });
    }
  }
})();