;(function () {
  'use strict'
  const angular = window.angular

  ToolkitConfigController.$inject = ['Var', '$state', 'AppUtils']
  angular
    .module('app')
    .component('toolkitConfiguration', {
      template:'<div class="container-fluid paddingLR10"><div class="row"><div class="col-lg-6"><div class="panel panel-default"><div class="panel-heading"><h2 class="nowrap"><strong>Editar campos</strong></h2></div><div class="panel-body"><div class="form-group"><label for="toolkitName">Nombre</label> <input id="toolkitName" class="form-control" ng-model="ctrl.toolKit.name"></div><div class="form-group marginB0"><textarea ng-model="ctrl.toolKit.description" maxlength="15000" simplemde="ctrl.mdeOptions"></textarea></div></div><div class="panel-footer text-right"><button class="btn btn-warning marginTB5" ng-click="ctrl.saveToolkit()"><i class="fas fa-save"></i> Guardar Cambios</button></div></div></div><div class="col-lg-6"><tools-table entity-type="\'toolkit\'" entity-id="ctrl.toolKit.id" var-id="ctrl.toolKit.varId"></tools-table><tools-table entity-type="\'toolkit\'" entity-id="ctrl.toolKit.id" var-id="ctrl.toolKit.varId" target="\'adminTools\'" title="\'entities.tool.adminTools.modelNamePl\'"></tools-table></div></div></div><spinner show="ctrl.saving" show-logo="true" css-class="\'small\'"></spinner>',
      controller: ToolkitConfigController,
      controllerAs: 'ctrl',
      bindings: {
        toolKit: '<'
      }
    })

  function ToolkitConfigController (Var, $state, utils) {
    const vm = this

    vm.$onInit = function () {
      vm.toolKit = angular.copy(vm.toolKit)
      vm.saveToolkit = saveToolkit
      vm.mdeOptions = {
        spellChecker: false,
        toolbar: [
          'bold', 'italic', 'heading', '|',
          'unordered-list', 'ordered-list', '|',
          'quote', 'link', 'table', 'code', '|',
          'preview'
        ]
      }
    }

    function saveToolkit () {
      vm.saving = true
      Var
        .prototype$__updateById__toolkits({
          id: vm.toolKit.varId,
          fk: vm.toolKit.id
        }, {
          name: vm.toolKit.name,
          description: vm.toolKit.description
        })
        .$promise
        .then(result => {
          vm.saving = false
          $state.go('.', {toolkitId: vm.toolKit.id}, {reload: true})
        })
        .catch(err => {
          vm.saveError = utils.getHTTPError(err).message
          vm.saving = false
        })
    }
  }
})()
