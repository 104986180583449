(function () {
  const $ = window.$;

  directive.$inject = ['$timeout'];

  angular.module('app').directive('videoRenderer', directive);

  function directive($timeout) {
    let defaultRatio = 1.77777777778;
    return {
      restrict: 'E',
      template: `<div class="bg-video-container">
            <div class="video-target" style="margin: auto; overflow-y: hidden"></div>
            <spinner show="loading" css-class="spinnerClass"></spinner>
        </div>`,
      scope: {
        videoSrc: '<?',
        dimensions: '=?',
        onLoad: '=',
        maxHeight: '=?',
        spinnerClass: '=?',
        disableSpinner: '<?',
      },
      link: function (scope, element, attrs) {
        scope.container = element.find('.bg-video-container');
        scope.videoTarget = element.find('.video-target');
        scope.spinnerClass = scope.spinnerClass || 'xs light';

        scope.$watch('videoSrc', (newVal) => {
          if (!scope.videoSrc) {
            return;
          }

          scope.dimensions = scope.dimensions || {};
          scope.loading = !scope.disableSpinner;
          scope.dimensions.loading = true;
          init(scope);
        });
      },
      controller: [
        '$scope',
        '$timeout',
        function ($scope, $timeout) {
          let isFullscreen = false;
          $(document).on('fullscreenchange', onFullscreenChange);
          $(window).on('resize', onResize);

          $scope.$on('$destroy', () => {
            $scope.destroyed = true;
            $(window).off('resize', onResize);
            $(document).off('fullscreenchange', onFullscreenChange);

            if ($scope.timeout) {
              $timeout.cancel($scope.timeout);
            }
          });

          function onFullscreenChange() {
            isFullscreen = !isFullscreen;
          }

          function onResize() {
            if ($scope.timeout) {
              $timeout.cancel($scope.timeout);
            }
            $scope.timeout = $timeout(() => {
              if ($scope.destroyed || isFullscreen) {
                return;
              }

              $scope.timeout = null;
              calcContainerDimensions($scope);
              onLoadVideo($scope, $scope.video);
            }, 100);
          }
        },
      ],
    };

    function calcContainerDimensions(scope) {
      scope.videoTarget.hide();
      const width = scope.container.width();
      const height = scope.maxHeight || scope.container.height();
      scope.containerWidth = width > 150 ? Math.floor(width) : 150;
      scope.videoTarget.show();

      if (!height) {
        scope.containerHeight = scope.containerWidth / defaultRatio;
      } else {
        const ratio = width / height;
        if (ratio > defaultRatio) {
          scope.containerHeight = height;
          scope.containerWidth = height * defaultRatio;
        } else {
          scope.containerHeight = scope.containerWidth / defaultRatio;
        }
      }

      scope.videoTarget.width(scope.containerWidth);
      scope.videoTarget.height(scope.containerHeight);
    }

    function init(scope) {
      scope.dimensions = scope.dimensions || {};

      calcContainerDimensions(scope);
      renderVideo(scope, scope.videoSrc);
    }

    function renderVideo($scope, src) {
      let video = document.createElement('video');
      video.src = src;
      video.crossOrigin = 'anonymous';
      video.autoplay = true;
      video.controls = true;
      video.controlsList = 'nofullscreen';
      video.playbackRate = 1.75;
      video.addEventListener(
        'loadedmetadata',
        (e) => {
          if ($scope.destroyed) {
            return;
          }

          $timeout(() => {
            $scope.dimensions.hasError = false;
            $scope.video = video;
            onLoadVideo($scope, video);
            $scope.dimensions.loading = false;
            $scope.loading = false;
          }, 50);
        },
        false
      );
      video.addEventListener('error', (e) => {
        console.log(video.error);
        $timeout(() => {
          $scope.dimensions.hasError = true;
          $scope.dimensions.loading = false;
          onLoadVideo($scope, video);
          $scope.loading = false;
        });
      });
    }

    function onLoadVideo($scope, video) {
      $timeout(() => {
        if (!video) {
          if ($scope.onLoad) {
            $scope.onLoad(null, video);
          }
          return;
        }

        const size = {};

        $scope.dimensions.originalSize = $scope.originalSize = {
          height: video.videoHeight,
          width: video.videoWidth,
        };

        let videoRatio = video.videoWidth / video.videoHeight;
        const containerRatio = $scope.containerWidth / $scope.containerHeight;

        if (videoRatio > containerRatio) {
          size.width = $scope.containerWidth;
          size.height = Math.floor((video.videoHeight * size.width) / video.videoWidth);
        } else {
          size.height = $scope.containerHeight;
          size.width = Math.floor((video.videoWidth * size.height) / video.videoHeight);
        }

        $scope.dimensions.ratio = Math.round((size.width * 1000) / video.videoWidth) / 1000;
        const left = ($scope.containerWidth - size.width) / 2;
        const top = ($scope.containerHeight - size.height) / 2;

        const videoContainer = $('<div style="overflow: hidden"/>');

        videoContainer.width(size.width);
        videoContainer.height(size.height);
        videoContainer.css({ marginTop: top, marginLeft: left });

        videoContainer.append(video);

        $scope.videoTarget.empty();
        $scope.videoTarget.prepend(videoContainer);

        $scope.dimensions.width = size.width;
        $scope.dimensions.height = size.height;
        $scope.dimensions.offset = { top: top, left: left };

        if ($scope.onLoad) {
          $scope.onLoad($scope.dimensions.hasError, video);
        }
      });
    }
  }
})();
