(() => {
  const angular = window.angular;

  RectanglePaper.$inject = ['BasePaper', '$timeout', '$rootScope', 'AppUtils'];

  angular.module('app').service('RectanglePaper', RectanglePaper);

  function RectanglePaper(BasePaper, $timeout, $root, utils) {
    class RectanglePaperCanvas extends BasePaper.Canvas {
      constructor(container, targetElement, size, options = {}) {
        options.style = Object.assign({ strokeWidth: 3 }, options.style);
        super(container, targetElement, size, options);
        this.PortClass = RectanglePaperPort;
      }

      afterClick() {
        if (this.tempPath.length === 4) {
          this.endFigure(this.tempFigure, true);
        } else {
          this.tempPath.push(['L', this.drawArea.ox, this.drawArea.oy]);
          this.tempFigure.attr({ path: this.tempPath });
        }
      }

      onClick() {
        const _this = this;
        return function () {
          const paper = _this.paper;
          const drawArea = _this.drawArea;
          _this.container.focus();
          if (_this.isDragging) {
            _this.isDragging = false;
          } else if (_this.selectedFigure) {
            _this.unSelect(_this.selectedFigure);
            _this.selectedFigure = null;
          } else {
            _this.figures.forEach((current) => {
              current.attr({ fill: null });
            });
            if (!_this.tempPath) {
              _this.tempPath = [];
              _this.unSelect(_this.selectedFigure);
            }
            if (_this.tempPath.length === 0) {
              _this.tempPath = [
                ['M', drawArea.ox, drawArea.oy],
                ['L', drawArea.ox, drawArea.oy],
                ['L', drawArea.ox, drawArea.oy],
                ['L', drawArea.ox, drawArea.oy],
                ['Z'],
              ];
              _this.tempFigure = paper.path(_this.tempPath);
              _this.tempFigure.attr({
                stroke: _this.style.stroke,
                'stroke-width': _this.style.strokeWidth,
                'stroke-dasharray': '-',
                'fill-opacity': _this.style.fillOpacity,
              });
            } else if (_this.tempFigure) {
              _this.endFigure(_this.tempFigure);
            }
          }
        };
      }

      updateTempFigure(x, y) {
        if (isNaN(x) || isNaN(y)) {
          return;
        }
        const tempPath = this.tempPath;
        const _this = this;
        $timeout(() => {
          _this.mode = 'drawing';
          tempPath[1][2] = tempPath[2][2] = y;
          tempPath[2][1] = tempPath[3][1] = x;

          _this.tempFigure.attr({ path: tempPath });
        });
      }
    }

    class RectanglePaperPort extends BasePaper.Port {
      constructor(target, index, style = {}, context) {
        super(target, index, style, context);
      }

      onMove() {
        const _this = this;
        return function onMove() {
          if (_this.canvas.readOnly) {
            return;
          }
          const pointIndex = _this.index;
          const target = _this.target;
          const drawArea = _this.canvas.drawArea;
          const rect = _this.svg.getBoundingClientRect();

          let x = _this.checkLimits(drawArea.ox, rect.width);
          let y = _this.checkLimits(drawArea.oy, rect.height);

          const ports = _this.canvas.ports;
          let path = target.getPath();

          switch (pointIndex) {
            case 0:
              path[1][1] = x;
              path[3][2] = y;
              ports[1].attr({ cx: x });
              ports[3].attr({ cy: y });
              break;
            case 1:
              path[0][1] = x;
              path[2][2] = y;
              ports[0].attr({ cx: x });
              ports[2].attr({ cy: y });
              break;
            case 2:
              path[1][2] = y;
              path[3][1] = x;
              ports[1].attr({ cy: y });
              ports[3].attr({ cx: x });
              break;
            case 3:
              path[0][2] = y;
              path[2][1] = x;
              ports[0].attr({ cy: y });
              ports[2].attr({ cx: x });
              break;
          }
          target.attr({ path: path });
          _this.attr({ cx: x, cy: y });

          $timeout(() => {
            if (target._sensor_config) {
              target._sensor_config.points[pointIndex] = {
                x: _this.canvas.unApplyRatio(x),
                y: _this.canvas.unApplyRatio(y),
              };
            }
          });
        };
      }
    }

    return {
      Canvas: RectanglePaperCanvas,
      Port: RectanglePaperPort,
    };
  }
})();
