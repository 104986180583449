;(function () {
  'use strict'

  let $ = window.$
  let angular = window.angular

  let ctrlScope = null

  TimeSeriesChart.$inject = ['SensorService', '$filter']

  angular
    .module('app')
    .directive('retailCountChart', TimeSeriesChart)

  function TimeSeriesChart (SensorService, $filter) {
    return {
      restrict: 'E',
      link: link,
      controller: ['$scope', '$element', controller],
      scope: {
        sensor: '=',
        asset: '=',
        chartOnly: '=',
        chartHeight: '=?',
        chartTitle: '=?',
        from: '=?',
        to: '=?'
      },
      template: `<div class="well well-light well-sm no-margin" ng-style="{height: chartHeight + 58}">
        <div class="well-title text-normal">Showing summed up count values for scene element <strong>{{sensor.name}}</strong></div>
        <div class="chart"></div>
        <spinner show="!dataLoaded" css-class="'body-dark'"></spinner>
      </div>`
    }

    function link (scope, element, attrs) {
      let target = scope.target = element.find('.chart')
      element.on('$destroy', function () {
        let chart = target.highcharts ? target.highcharts() : null
        if (chart) {
          chart.destroy()
        }
        $(window).off('resize', reFlowChart)
      })

      scope.$watch('sensor', function () {
        refreshChart(scope, element, true)
      })
      scope.$watch('from', function (newValue, oldValue) {
        if (newValue == oldValue) {
          return
        }

        refreshChart(scope, element, true)
      })
      scope.$watch('to', function (newValue, oldValue) {
        if (newValue == oldValue) {
          return
        }

        refreshChart(scope, element, true)
      })
    }

    function controller ($scope, $element) {
      ctrlScope = $scope
      $scope.chartHeight = $scope.chartHeight || 300
      $scope.refreshChart = function () {
        refreshChart($scope, $element)
      }
    }

    function reFlowChart () {
      if (!ctrlScope) {
        return
      }
      ctrlScope.target.highcharts().reflow()
    }

    function refreshChart ($scope, $element, reCreate) {
      let target = $element.find('.chart')
      let options = {
        from: $scope.from,
        to: $scope.to,
        height: $scope.chartHeight
      }
      $scope.dataLoaded = false
      createChart(target, $scope.sensor, options, function (err, data) {
        $scope.data = data
        $scope.dataLoaded = true
        $(window).resize(reFlowChart)
      }, reCreate)
    }

    function createChart (target, sensor, options, callback, reCreate) {
      SensorService
        .getData(sensor.id, {from: options.from, to: options.to})
        .then(data => {
          data = prepareData(data)
          let chart = target.highcharts ? target.highcharts() : null

          if (chart && !reCreate) {
            let instance = target.highcharts()
            instance.series[0].setData(data.backward.values, true, false)
            instance.series[1].setData(data.forward.values, true, false)
          } else {
            if (chart) {
              chart.destroy()
            }
            target.highcharts('StockChart', {
              chart: {
                height: options.height,
                spacing: [1, 5, 1, 5],
                plotBorderColor: '#dddddd',
                plotBorderWidth: 1
              },
              legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                margin: 0
              },
              scrollbar: {
                enabled: false
              },
              /*navigator: {
                margin: 5,
                height: 30,
                handles: {
                  backgroundColor: '#2E363F',
                  height: 28,
                  width: 15
                }
              },*/
              rangeSelector: {enabled: false},
              credits: {enabled: false},
              line: {connectNulls: false},
              xAxis: {
                ordinal: false
              },
              series: [{
                name: 'Backward',
                step: true,
                type: 'area',
                dataGrouping: {
                  approximation: 'sum',
                  groupPixelWidth: 15
                },
                credits: {
                  enabled: false
                },
                data: data.backward.values,
                tooltip: {
                  xDateFormat: '%A, %b %e, %H:%M:%S',
                  valueDecimals: 0
                }
              }, {
                name: 'Forward',
                type: 'area',
                step: true,
                dataGrouping: {
                  approximation: 'sum',
                  groupPixelWidth: 15
                },
                credits: {
                  enabled: false
                },
                data: data.forward.values,
                tooltip: {
                  xDateFormat: '%A, %b %e, %H:%M:%S',
                  valueDecimals: 0
                }
              }]
            })
          }
          callback(null, {
            min: data.min,
            max: data.max,
            last: data.last,
            average: data.average
          })
        })
        .catch(function (err) {
          callback(err, {})
        })
    }

    function prepareData (data) {
      let series = {
        forward: {values: []},
        backward: {values: []}
      }
      data.forEach((current, index) => {
        let from = index === 0 ? current.from : data[index - 1].to
        let content = current.content

        series.backward.values.push([current.from.getTime(), content.backward])
        //series.backward.values.push([current.to, content.backward])

        series.forward.values.push([current.from.getTime(), content.forward])
        //series.forward.values.push([current.to, content.forward])
      })

      return series
    }
  }
})()
