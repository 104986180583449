;(function () {
  'use strict';

  let angular = window.angular;

  ProfileShowController.$inject = ['LoopBackAuth', 'UserService'];

  angular
    .module('app')
    .component('accountProfileShow', {
      template:'<div class="well well-light"><div class="profile-content"><div class="profile-left"><div class="profile-left-fix"><div class="text-center thumbnail marginB10"><img ng-if="$ctrl.user.image" ng-src="{{$ctrl.user.image}}"> <img ng-if="!$ctrl.user.image" ng-src="{{$root.assetsPath}}/images/no-image.png"></div></div></div><div class="profile-right"><div class="table-responsive"><table class="table table-borderless no-margin"><thead><tr><th colspan="2"><div class="pull-left"><h2 class="no-margin text-info">{{$ctrl.user.name}} {{$ctrl.user.surname}}</h2><h3 class="no-margin" style="display: block">@{{$ctrl.user.username}}</h3></div></th></tr><tr></tr></thead><tbody><tr class="separator"><td colspan="2"></td></tr><tr class="bordered"><td class="field" translate>entities._all.email</td><td>{{$ctrl.user.email}}</td></tr><tr class="bordered"><td class="field" translate>entities._all.phone</td><td>{{$ctrl.user.phone}}</td></tr><tr class="bordered"><td class="field" translate>entities._all.address</td><td>{{$ctrl.user.address}}</td></tr><tr class="bordered"><td class="field" translate>entities._all.birthday</td><td>{{$ctrl.user.birthday | date}}</td></tr><tr class="separator"><td colspan="2"></td></tr><tr><td class="field" translate>entities._all.country</td><td>{{$ctrl.user.country.name}}</td></tr><tr><td class="field" translate>entities._all.timezone</td><td>{{$ctrl.user.timezone.text}}</td></tr></tbody></table></div></div></div></div>',
      controller: ProfileShowController,
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function ProfileShowController (LoopBackAuth, UserService) {
    let vm = this;

    vm.$onInit = function onInit () {
      vm.user = angular.copy(vm.resolve.user);
      vm.countries = vm.resolve.countries;
      vm.timeZones = vm.resolve.timeZones;
      vm.userType = vm.resolve.userType;

      if (vm.timeZones && vm.user.timeZoneId) {
        vm.user.timezone = vm.timeZones.find(timezone => vm.user.timeZoneId === timezone.id);
      }

      if (vm.countries && vm.user.countryId) {
        vm.user.country = vm.countries.find(country => vm.user.countryId === country.id);
      }

      vm.user.image = UserService.getUserImageUrl(vm.user, '_profile', vm.userType);
    };
  }
})();
