(() => {
  Controller.$inject = ['$rootScope', 'Customer', 'Device', 'AppUtils'];
  angular
    .module('app')
    .component('connectionDeviceModuleCommands', {
      template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><button type="button" class="close" ng-click="ctrl.modalInstance.dismiss()"><i class="fas fa-times"></i></button> <span>{{ctrl.device.name}} |</span> <span translate>entities.device._commands.restartModule</span></div><div class="modal-body"><div class="form-group select2-sm marginLR20"><label translate>entities.device.module.modelName</label><ui-select ng-model="ctrl.module" theme="select2"><ui-select-match placeholder="{{\'general._select.a\' | translate}} {{\'entities.device.module.modelName\' | translate}}">{{$select.selected.name}}</ui-select-match><ui-select-choices repeat="module.name as module in ctrl.device.modules | filter: {name: $select.search}"><div ng-bind-html="module.name | highlight: $select.search"></div></ui-select-choices></ui-select></div><div ng-if="ctrl.module"><hr><div class="text-center" translate data-translate-values="{device: ctrl.device.name, module: ctrl.module}">entities.device.messages.restartModule</div></div></div><div class="alert alert-warning no-margin text-center" ng-if="ctrl.error">{{ctrl.error}}</div><div class="modal-footer"><button class="btn btn-sm btn-default js-btn-step pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-sm btn-danger" ng-disabled="!ctrl.module" ng-click="ctrl.execCmd()" translate>buttons.exec</button></div></div><spinner css-class="\'small\'" show-logo="true" show="ctrl.loading"></spinner>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    });

  function Controller($root, Customer, Device, utils) {
    const vm = this;

    vm.$onInit = function () {
      vm.device = vm.resolve.device;
      vm.command = vm.resolve.command;

      vm.execCmd = execCmd;

      loadDevice()
        .then(() => {
          const tags = vm.resolve.tags || [];
          vm.module = null;
          for (let current of tags) {
            const module = vm.device.modules.find(module => module.name.toLowerCase() === current.toLowerCase());

            if (module) {
              vm.module = module.name;
              break;
            }
          }
        });
    };

    function execCmd() {
      vm.loading = true;
      const command = utils.capitalize(vm.command);

      Device[`prototype$cmdc${command}Module`]({
        id: vm.device.id,
        name: vm.module,
      }).$promise
        .then((result) => {
          vm.loading = false;
          vm.modalInstance.close();
        })
        .catch(err => {
          console.log(err);
          vm.error = utils.getHTTPError(err);
          vm.error = vm.error.message.replace('Docker error: ', '');
          vm.loading = false;
        });
    }

    function loadDevice() {
      vm.loading = true;
      return Customer
        .devices({
          id: $root.customerId,
          filter: {
            where: {id: vm.device.id},
            include: {
              relation: 'edgeAgents',
              scope: {where: {enabled: true}},
            },
          },
        })
        .$promise
        .then(function (result) {
          vm.device = result[0];
          vm.device.modules = vm.device.edgeAgents.reduce((modules, agent) => {
            Object.keys(agent.modules).forEach(moduleName => {
              modules.push({name: moduleName, properties: agent.modules[moduleName]});
            });
            return modules;
          }, []);
          vm.loading = false;
        })
        .catch(function (err) {
          vm.error = utils.getHTTPError(err);
          vm.loading = false;
        });
    }
  }
})();