;(() => {
  const angular = window.angular;

  angular
    .module('app')
    .config(ReportRouter);

  ReportRouter.$inject = ['$stateProvider'];

  function ReportRouter ($stateProvider) {
    $stateProvider
      .state('member.var.show.customer.show.project.reports', {
        url: '/reports',
        redirectTo: 'member.var.show.customer.show.project.reports.embedded',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Reportes'
        }
      })
      .state('member.var.show.customer.show.project.reports.embedded', {
        url: '/embedded/:reportId',
        component: 'embeddedReports',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Embedded'
        },
        resolve: {
          hasTool: ['tools', 'AppUtils', function (tools, utils) {
            const hasTool = tools.find(tool => tool.module === 'xompassEmbeddedReports');
            if (!hasTool) {
              throw utils.getHTTPError(new Error('Not allowed'), {
                redirectTo: 'member.var.show.customer.invalidTool',
                params: { tool: 'xompassEmbeddedReport' }
              });
            }
          }],
          reports: ['Customer', '$transition$', function (Customer, $transition$) {
            let params = $transition$.params();
            return Customer
              .prototype$__get__projects__embeddedReports({
                id: params.customerId,
                nk: params.projectId,
                filter: { fields: ['id', 'name', 'source'], order: 'name ASC' }
              })
              .$promise
              .then(reports => {
                let tree = [];
                reports.forEach(report => {
                  tree.push({
                    id: report.id,
                    text: report.name,
                    parent: '#'
                  });
                });
                return { tree: tree, reports: reports };
              });
          }]
        }
      });
  }

})();
