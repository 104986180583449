(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  ToolKitTemplateLink.$inject = [
    '$element',
    'Var',
    '$uibModal',
    '$q',
    'Customer',
    'LoopBackAuth',
    'AppUtils',
  ];

  angular.module('app').component('toolKitTemplateLink', {
    template:'<div ng-class="{transparent: $ctrl.loading}"><div class="modal-header"><span translate>buttons.link</span> <span translate>entities.toolkit.modelNamePl</span> <button class="close" ng-click="$ctrl.modalInstance.dismiss()"><i class="fas fa-times"></i></button></div><div class="modal-body"><div ng-repeat="toolKit in $ctrl.toolKits"><label class="checkbox"><input type="checkbox" ng-model="toolKit.checked"> <i></i> {{toolKit.name}}</label></div></div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="$ctrl.linkToolKits()" translate>buttons.link</button></div></div><spinner show="$ctrl.loading" css-class="\'small\'"></spinner>',
    controller: ToolKitTemplateLink,
    controllerAs: '$ctrl',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function ToolKitTemplateLink($element, Var, $uibModal, $q, Customer, LoopBackAuth, utils) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.customerId = vm.resolve.customerId;
      vm.customerToolKits = vm.resolve.customerToolKits || [];

      vm.loading = true;
      Var.prototype$__get__toolkits({ id: LoopBackAuth.currentUserData.varId })
        .$promise.then((result) => {
          let map = utils.arrayToObject(vm.customerToolKits, 'id');

          result.forEach((toolKit) => {
            toolKit.checked = map[toolKit.id] !== undefined;
          });
          vm.toolKits = result;

          vm.loading = false;
        })
        .catch((err) => {
          vm.loading = false;
          console.log(err);
        });

      vm.linkToolKits = linkToolKits;
    };

    function linkToolKits() {
      if (!vm.toolKits) {
        return;
      }

      let toLink = [];
      let toUnlink = [];

      let map = utils.arrayToObject(vm.customerToolKits, 'id');

      vm.toolKits.forEach((toolKit) => {
        if (toolKit.checked) {
          if (!map[toolKit.id]) {
            toLink.push(toolKit.id);
          }
        } else if (map[toolKit.id]) {
          toUnlink.push(toolKit.id);
        }
      });

      executeRequest(toLink, 'link')
        .then(() => {
          return executeRequest(toUnlink, 'unlink');
        })
        .then(() => {
          return Customer.toolkits({
            id: vm.customerId,
            filter: {
              include: 'tools',
            },
          }).$promise;
        })
        .then((toolKits) => {
          vm.modalInstance.close(toolKits);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function executeRequest(array, operation) {
      let defer = $q.defer();
      let count = 0;
      if (array.length) {
        array.forEach((id) => {
          Customer.toolkits[operation]({ id: vm.customerId, fk: id })
            .$promise.then((result) => {
              count++;
              if (count === array.length) {
                defer.resolve();
              }
            })
            .catch((err) => {
              console.log(err);
              count++;
              if (count === array.length) {
                defer.resolve();
              }
              console.log(err);
            });
        });
      } else {
        defer.resolve();
      }

      return defer.promise;
    }
  }
})();
