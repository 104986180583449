;(function () {
  angular
    .module('app')
    .directive('fileModel', ['$parse', function ($parse) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          let model = $parse(attrs.fileModel);
          let modelSetter = model.assign;

          element.bind('change', function () {
            scope.$apply(function () {
              let action = element.attr('file-change');
              let multi = element.attr('multiple');

              if (multi) {
                modelSetter(scope, element[0].files);
              } else {
                modelSetter(scope, element[0].files[0]);
              }

              if (action) {
                eval('scope.' + action);
              }
            });
          });
        }
      };
    }]);
})();
