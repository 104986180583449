(function () {
  'use strict';

  let angular = window.angular;

  ProfileEditController.$inject = ['AppUtils'];

  angular.module('app').component('twoFactorFormSuccess', {
    template:'<div ng-class="{transparent: $ctrl.loading}"><p class="modal-header" translate>buttons.enable2faSuccess</p><div class="modal-body text-center"><p class="text-success"><strong translate>form.2fa.enableMessage1</strong></p><p class="marginTB10" translate>form.2fa.enableMessage2</p></div><div class="modal-footer" style="text-align: right"><button class="btn btn-primary" type="button" ng-click="$ctrl.closeModal()" translate>buttons.accept</button></div></div>',
    controller: ProfileEditController,
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function ProfileEditController(utils) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.closeModal = closeModal;
    };

    function closeModal() {
      vm.modalInstance.close({});
    }
  }
})();
