;(function() {
  const angular = window.angular;

  angular
    .module('app')
    .directive('draggable', ['$document', function ($document) {
      return {
        restrict: 'A',
        link: function (scope, elm, attrs) {
          var startX, startY, initialMouseX, initialMouseY;
          elm.on('mousedown', function ($event) {
            startX = elm.prop('offsetLeft');
            startY = elm.prop('offsetTop');
            initialMouseX = $event.clientX;
            initialMouseY = $event.clientY;
            $document.on('mousemove', mousemove);
            $document.on('mouseup', mouseup);
            // return false
          });

          function mousemove($event) {
            var dx = $event.clientX - initialMouseX;
            var dy = $event.clientY - initialMouseY;
            elm.css({
              top: startY + dy + 'px',
              left: startX + dx + 'px'
            });
            return false;
          }

          function mouseup() {
            $document.off('mousemove', mousemove);
            $document.off('mouseup', mouseup);
          }
        }
      }
    }])
})();