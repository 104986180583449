; (function () {
  const angular = window.angular;

  Controller.$inject = ['$element', '$rootScope', 'AppUtils', 'Customer', '$translate', 'LocalStorageService', '$timeout'];

  angular
    .module('app')
    .component('restrictionYoloClassLinkModal', {
      template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><span translate>buttons.add_remove</span> <span translate>entities.yoloclass.modelNamePl</span></div><div ng-style="{opacity: ctrl.loading? 0: 1}" style="min-height: 200px"><div class="paddingT5"><table class="table table-bordered table-hover no-margin" id="tag-table"></table></div><div class="alert alert-warning no-margin text-center" ng-if="ctrl.loadError">{{ctrl.loadError}}</div><div class="alert alert-warning no-margin text-center" ng-if="ctrl.linkError">{{ctrl.linkError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="ctrl.setYoloClasses()" translate>buttons.save</button></div></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function Controller($element, $root, utils, Customer, $translate, LocalStorageService, $timeout) {
    let vm = this;

    vm.$onInit = function () {
      vm.project = vm.resolve.project;

      const yoloClasses = angular.copy($root.yoloClasses);
      let currentYoloClasses = utils.arrayToObject(vm.project.yoloClasses || [], 'id');
      vm.yoloClasses = yoloClasses;
      yoloClasses.forEach(current => {
        current.checked = !!currentYoloClasses[current.id];
      });

      const selected = [];
      vm.project.yoloClasses.forEach(current => {
        const index = vm.yoloClasses.findIndex(tag => tag.id === current.id);
        if (index !== -1) {
          selected.push(index);
        }
      });

      vm.loading = true;
      $timeout(() => {
        initTable(yoloClasses, selected);
        vm.loading = false;
      }, 100);

      vm.setYoloClasses = setYoloClasses;
    };

    function initTable(data, selected) {
      let dtOptions = {
        dom: '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
          '<div tr>' +
          '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        order: [1, 'asc'],
        paging: false,
        info: false,
        data: data,
        scrollY: '400px',
        select: {
          selector: 'td:not(.control)',
          style: 'multi'
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-checkbox',
            defaultContent: '',
            width: '10px'
          },
          {
            data: 'label',
            title: $translate.instant('entities._all.name')
          }
        ]
      };

      vm.dtInstance = $element.find('#tag-table').DataTable(dtOptions);

      if (Array.isArray(selected) && selected.length) {
        vm.dtInstance.rows(selected).select();
      }
    }

    function setYoloClasses() {
      let selected = vm.dtInstance.rows({ selected: true }).data().toArray();
      let toLink = [];
      let toUnlink = [];

      let map = utils.arrayToObject(selected, 'id');

      vm.yoloClasses.forEach(yoloClass => {
        if (yoloClass.checked) {
          if (!map[yoloClass.id]) {
            toUnlink.push(yoloClass.id);
          }
        } else if (map[yoloClass.id]) {
          toLink.push(yoloClass.id);
        }
      });

      vm.loading = true;
      executeRequest(toLink, 'link')
        .then(() => {
          return executeRequest(toUnlink, 'unlink');
        })
        .then(errors => {
          LocalStorageService.removeItem('Customer$CurrentProject');
          vm.loading = false;
          if (errors && errors.length) {
            vm.errors = errors;
            return;
          }
          vm.modalInstance.close();
        })
        .catch(err => {
          console.log(err);
          vm.loading = false;
        });
    }

    function executeRequest(array, operation) {
      return utils.Promise(resolve => {
        let count = 0;
        const method = `prototype$__${operation}__projects__yoloClasses`;

        const errors = [];
        if (array.length) {
          array.forEach(yoloClassId => {
            let data = undefined;
            Customer[method]({
              id: vm.project.customerId,
              nk: vm.project.id,
              fk: yoloClassId
            }, data)
              .$promise
              .then(result => {
                count++;
                if (count === array.length) {
                  resolve(errors);
                }
              })
              .catch(err => {
                count++;
                errors.push(utils.getHTTPError(err));
                if (count === array.length) {
                  resolve(errors);
                }
              });
          });
        } else {
          resolve();
        }
      });
    }
  }

})();
