;(() => {
  window.angular
    .module('app')
    .component('svgPluginFlowAnalysis', {
      template:'<div class="x-plugin-icon"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve"><path ng-style="{fill: ctrl.fillColor}" d="M385.4,282.26l-41.66,23.87c-6.46,3.7-14.52-0.97-14.52-8.42V285.5c-23.6,0.55-41.01,6.56-51.85,17.95 c-8.59,9.02-13.73,22.06-15.39,38.99c-0.18,1.85-0.32,3.74-0.41,5.69v39.05c0,6.92-5.6,12.53-12.53,12.53 c-6.92,0-12.53-5.61-12.53-12.53v-92.82c-1.52-18.69-5.58-33.38-15.22-42.6c-9.96-9.53-26.96-14.24-51.69-14.26v11.04 c0,7.47-8.08,12.13-14.55,8.39l-40.49-23.38c-6.46-3.73-6.46-13.06,0-16.8l40.49-23.38c6.46-3.73,14.55,0.93,14.55,8.39v10.66 c30.4,0.02,51.98,6.29,66.9,19.32v-71.55h-10.85c-7.47,0-12.13-8.08-8.39-14.55l23.38-40.49c3.73-6.46,13.06-6.46,16.79,0 l23.38,40.49c3.73,6.46-0.93,14.55-8.39,14.55h-10.86v123.65c15.63-14.95,38.35-22.78,67.66-23.38v-10.46 c0-7.45,8.06-12.12,14.52-8.42l41.66,23.87C391.9,269.16,391.9,278.54,385.4,282.26z"></path></svg><div class="caption" ng-if="ctrl.showLabel" translate translate-attr="{title: \'plugins.flowAnalysis\'}">plugins.flowAnalysis</div></div>',
      controllerAs: 'ctrl',
      bindings: {
        fillColor: '<',
        showLabel: '<'
      }
    });
})();
