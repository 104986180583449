(function () {
  'use strict';

  let angular = window.angular;

  Controller.$inject = ['$scope', '$rootScope', 'Customer', 'Var', 'AppUtils'];

  angular.module('app').component('deletePermissionModal', {
    template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><span translate>buttons.delete</span> <span translate>entities.telegram.titles.permission</span></div><div class="modal-body text-center" style="display: flex; flex-direction: column; align-items: center; justify-content: center"><div><span translate>entities.telegram.alerts.deletePermission</span></div><div style="background-color: white; padding: 5px; width: 35%; margin-top: 15px; margin-bottom: 15px; box-sizing: border-box; display: flex; align-items: center; justify-content: center;"><span style="color: black">{{ctrl.fullName}}</span></div></div><div ng-if="ctrl.removeError" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.removeError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" ng-click="ctrl.deletePermission()" translate>buttons.delete</button></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function Controller($scope, $root, Customer, Var, utils) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.loading = true;
      vm.data = vm.resolve.permission;
      vm.deletePermission = deletePermission;
      getUserData().then((user) => {
        setTimeout(function () {
          $scope.$apply(function () {
            console.log(user);
            vm.fullName = user.name;
            if (user.surname) {
              vm.fullName = vm.fullName + ' ' + user.surname;
            }

            if ($root.isAdmin) {
              vm.fullName = vm.fullName + ' ( ' + vm.resolve.permission.userType + ' )';
            }
            vm.loading = false;
          });
        });
      });
    };

    function deletePermission() {
      vm.loading = true;
      Customer.prototype$__destroyById__projects__telegramPermissions({
        id: vm.resolve.customerId,
        nk: vm.resolve.projectId,
        permissionId: vm.resolve.permission._id,
      })
        .$promise.then((res) => {
          vm.modalInstance.close({ deleted: true });
        })
        .catch((err) => {
          vm.removeError = utils.getHTTPError(err);
          vm.loading = false;
        });
    }

    async function getUserData() {
      let user;
      try {
        if (vm.resolve.permission.userType === 'Admin') {
          const data = await Var.prototype$__get__admins({ id: $root.user.varId }).$promise;
          user = data.find((user) => user.id === vm.resolve.permission.vsaasUserId);
        } else {
          const data = await Customer.prototype$__get__managers({ id: vm.resolve.customerId })
            .$promise;
          user = data.find((user) => user.id === vm.resolve.permission.vsaasUserId);
        }

        return user;
      } catch (error) {
        console.error('Error al obtener el usuario:', error);
        throw error; // Puedes manejar el error según tus necesidades
      }
    }
  }
})();
