(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  TagSelection.$inject = ['$element', '$timeout', '$translate', 'SensorTypeService'];

  angular.module('app').component('assetTagSelection', {
    template:'<div><div class="modal-header"><button type="button" class="close" ng-click="ctrl.modalInstance.dismiss()" aria-hidden="true"><i class="fas fa-times"></i></button> <span translate data-translate-values="{item: \'entities.sensor.modelNamePl\'}">general._select.selection</span></div><div class="modal-body"><div style="margin-left: -5px; margin-right: -5px"><table id="tags-table" class="table table-hover no-wrap table-condensed" style="width: 100%"><thead><tr><th></th><th translate>entities._all.name</th><th translate>entities._all.type</th><th translate>entities._all.enabled</th></tr></thead></table></div></div><div class="modal-footer"><button class="btn btn-default js-btn-step pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="ctrl.returnSelected()" translate>buttons.done</button></div></div>',
    controller: TagSelection,
    controllerAs: 'ctrl',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function TagSelection($element, $timeout, $translate) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.tags = vm.resolve.asset.sensors;
      vm.supportedTypes = vm.resolve.supportedTypes;
      let selectedTags = vm.resolve.asset.selectedTags || [];

      const supportedTypes = vm.supportedTypes || [
        'FaceDetection',
        'FaceMaskDetection',
        'NaiveSocialDistancing',
        'Number',
        'ObjectCounting',
        'ObjectRecognition',
        'ObjectRecognitionNumeric',
        'GranularityDetection',
        'PeriodicObjectCounting',
        'PoseDetection',
        'SpeedChange',
        'StoppedObjectsDetector',
      ];

      let selected = [];
      vm.tags = vm.tags.filter((current) => supportedTypes.indexOf(current.type) !== -1);

      vm.tags.forEach(function (tag, index) {
        let exist = selectedTags.find((selectedTag) => selectedTag.id === tag.id);

        if (exist) {
          selected.push(index);
        }
      });

      vm.returnSelected = returnSelected;

      $timeout(function () {
        let height = calculateHeight($element.find('table'));
        initTagTable(vm.tags, selected, height);
      });
    };

    function initTagTable(data, selected, height) {
      let dtOptions = {
        dom:
          "<'row'<'col-xs-12 col-sm-6 col-md-4'f><'col-sm-6'l>>" +
          '<div tr>' +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        processing: true,
        order: [1, 'asc'],
        paging: false,
        info: false,
        data: data,
        select: {
          selector: 'td:not(.control)',
          style: 'multi',
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-checkbox',
            render: checkboxRendered,
            width: '10px',
          },
          { data: 'name' },
          {
            data: 'type',
            render: (value) => $translate.instant('entities.sensortype.__types.' + value),
          },
          {
            data: 'enabled',
            orderable: false,
            render: enabledLabelRendered,
            width: '70px',
          },
        ],
      };

      if (height >= 400) {
        dtOptions.scrollY = height;
      }

      vm.dtInstance = $element.find('#tags-table').DataTable(dtOptions);

      if (Array.isArray(selected) && selected.length) {
        vm.dtInstance.rows(selected).select();
      }
    }

    function calculateHeight(instance) {
      let winHeight = $(window).height();
      let top = instance.offset().top;
      let height = winHeight - top - 15 - 69;
      return height < 400 ? height : 400;
    }

    function checkboxRendered() {
      return '';
    }

    function enabledLabelRendered(data, type, tag) {
      let text = $translate.instant('entities._all.' + (tag.enabled ? 'enabled' : 'disabled'));
      let labelType = tag.enabled ? 'success' : 'default';

      return type === 'display' ? `<span class="label label-${labelType}">${text}</span>` : data;
    }

    function returnSelected() {
      vm.modalInstance.close(vm.dtInstance.rows({ selected: true }).data().toArray());
    }
  }
})();
