;(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  ManagersHandlerSelectorController.$inject = [
    'Customer',
    'Var',
    'Manager',
    'Admin',
    'AppUtils',
    '$element',
    '$q',
    '$timeout'
  ];

  angular
    .module('app')
    .component('managersHandlerSelector', {
      template:'<div style="position: relative" ng-style="{opacity: ctrl.loading? 0: 1}"><div class="modal-header"><button type="button" class="close" ng-click="ctrl.modalInstance.dismiss()" aria-hidden="true"><i class="fas fa-times"></i></button> <span translate data-translate-values="{item: \'entities.user.modelNamePl\'}">general._select.selection</span></div><div class="modal-body"><div style="margin-left: -5px; margin-right: -5px"><table id="users-table" class="table table-hover no-wrap table-condensed" style="width: 100%"><thead><tr><th></th><th translate>entities._all.name</th><th translate>entities._all.username</th><th translate>entities._all.email</th></tr></thead></table></div></div><div class="alert alert-warning no-margin text-center" ng-if="ctrl.errors.length"><div ng-repeat="error in ctrl.errors">{{error}}</div></div><div class="modal-footer"><button class="btn btn-default js-btn-step pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="ctrl.setResponsibleUsers()" translate>buttons.done</button></div></div><spinner show="ctrl.loading" show-logo="true"></spinner>',
      controller: ManagersHandlerSelectorController,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function ManagersHandlerSelectorController (Customer, Var, Manager, Admin, utils, $element, $q, $timeout) {
    let vm = this;

    vm.$onInit = function onInit () {
      vm.userType = vm.resolve.userType || 'manager';
      vm.instance = vm.resolve.instance;
      vm.customer = vm.resolve.customer;
      vm.resolve.selected = vm.resolve.selected || [];
      vm.methodName = vm.resolve.methodName;

      const fields = ['id', 'name', 'surname', 'username', 'email'];

      vm.setResponsibleUsers = setResponsibleUsers;

      let promise;
      if (vm.userType === 'manager') {
        promise = Customer
          .managers({ id: vm.customer.id, filter: { fields: fields } })
          .$promise;
      } else if (vm.userType === 'admin') {
        const admin = Admin.getCachedCurrent();
        promise = Var
          .admins({ id: admin.varId, filter: { fields: fields } })
          .$promise;
      }

      vm.loading = true;
      if (!promise) {
        return;
      }

      promise
        .then(users => {
          vm.users = users;
          vm.selected = [];

          vm.users.forEach((current, index) => {
            if (vm.resolve.selected.indexOf(current.id) !== -1) {
              vm.selected.push(index);
              current.checked = true;
            } else {
              current.checked = false;
            }
          });

          vm.loading = false;
          initUserTable(users, vm.selected);
        });
    };

    function initUserTable (data, selected, height) {
      let dtOptions = {
        dom: '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
          '<div tr>' +
          '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        order: [1, 'asc'],
        paging: false,
        info: false,
        data: data,
        scrollY: data.length > 12 ? '400px' : undefined,
        select: {
          selector: 'td:not(.control)',
          style: 'multi'
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-checkbox',
            defaultContent: '',
            width: '10px'
          },
          {
            data: 'name',
            render: function (data, type, current) {
              return current.name + ' ' + (current.surname || '');
            }
          },
          {
            data: 'username',
            defaultContent: ''
          },
          {
            data: 'email',
            defaultContent: ''
          }
        ]
      };

      if (height >= 400) {
        dtOptions.scrollY = height;
      }

      vm.dtInstance = $element.find('#users-table').DataTable(dtOptions);

      if (Array.isArray(selected) && selected.length) {
        vm.dtInstance.rows(selected).select();
      }

      const headerCheckbox = $element.find('th.select-checkbox');
      const row = headerCheckbox.parent();

      checkSelection(row, data);

      vm.dtInstance
        .on('select', function () {
          checkSelection(row, data);
        })
        .on('deselect', function () {
          checkSelection(row, data);
        });

      if (vm.dtInstance.rows('.selected').data().length === data.length) {
        row.addClass('selected');
      }

      headerCheckbox.on('click', function () {
        toggleSelection(row, data, true);
      });
    }

    function toggleSelection (row, data, changeSelection) {
      if (data.length === 0) {
        return;
      }
      if (vm.dtInstance.rows('.selected').data().length === data.length) {
        row.removeClass('selected');
        if (changeSelection) {
          vm.dtInstance.rows().deselect();
        }
      } else {
        row.addClass('selected');
        if (changeSelection) {
          vm.dtInstance.rows().select();
        }
      }
    }

    function checkSelection (row, data) {
      if (vm.dtInstance.rows('.selected').data().length === data.length) {
        row.addClass('selected');
      } else {
        row.removeClass('selected');
      }
    }

    function setResponsibleUsers () {
      let selected = vm.dtInstance.rows({ selected: true }).data().toArray();
      let toLink = [];
      let toUnlink = [];

      let map = utils.arrayToObject(selected, 'id');

      vm.users.forEach(user => {
        if (user.checked) {
          if (!map[user.id]) {
            toUnlink.push(user.id);
          }
        } else if (map[user.id]) {
          toLink.push(user.id);
        }
      });

      const userType = vm.userType === 'manager' ? Manager : Admin;
      vm.loading = true;
      executeRequest(userType, toLink, 'link')
        .then(() => {
          return executeRequest(userType, toUnlink, 'unlink');
        })
        .then(errors => {
          vm.loading = false;
          if (errors && errors.length) {
            vm.errors = errors;
            return;
          }
          vm.modalInstance.close();
        })
        .catch(err => {
          vm.loading = false;
        });
    }

    function executeRequest (User, array, operation) {
      let defer = $q.defer();
      let count = 0;

      const errors = [];
      if (array.length) {
        array.forEach(userId => {
          let data = undefined;
          User[vm.methodName][operation]({
            id: userId,
            fk: vm.instance.id
          }, data)
            .$promise
            .then(result => {
              count++;
              if (count === array.length) {
                defer.resolve(errors);
              }
            })
            .catch(err => {
              count++;
              errors.push(utils.getHTTPError(err));
              if (count === array.length) {
                defer.resolve(errors);
              }
            });
        });
      } else {
        defer.resolve();
      }
      return defer.promise;
    }
  }
})();
