;(function () {
  'use strict'

  RTConcentrationAlertController.$inject = ['$scope', '$compile', '$element', '$timeout']

  const angular = window.angular
  angular
    .module('app')
    .component('rtConcentrationAlert', {
      template:'<div class="panel panel-default rt-panel rt-panel-sm"><header class="panel-heading"><h2 class="nowrap"><strong>{{ctrl.sensor.name}}</strong> <small>{{ctrl.assetName}}</small></h2></header><div class="panel-body" ng-class="{\'with-location\': ctrl.data.location}"><div class="chart-header" ng-if="ctrl.data.content.image"><div class="btn-group" data-toggle="buttons"><label ng-click="ctrl.activeType = \'image\'" ng-class="{active: ctrl.activeType === \'image\'}" class="btn btn-default btn-xs" title="Image"><input type="radio"> <i class="fas fa-fw fa-image"></i></label> <label ng-click="ctrl.activeType = \'raw\'" ng-class="{active: ctrl.activeType === \'raw\'}" class="btn btn-default btn-xs" title="Raw"><input type="radio"> <i class="fas fa-fw fa-file-alt"></i></label></div></div><div class="rt-content"><div ng-if="ctrl.data.content !== undefined"><pre ng-if="ctrl.activeType === \'raw\'">{{ctrl.data.content | json}}</pre><rt-image ng-if="ctrl.activeType === \'image\'" sensor="ctrl.sensor" asset-name="ctrl.assetName" state="ctrl.state" data="ctrl.data" only-content="true"></rt-image></div><div ng-if="ctrl.data.content === undefined" class="center-middle text-muted"><i class="fas fa-times-rectangle fa-3x"></i></div></div><div class="rt-footer"><div class="date" ng-if="ctrl.data.content">{{ctrl.data.from | date: "yyyy-MM-dd HH:mm:ss"}}<div ng-if="ctrl.data.location"><a target="_blank" ng-href="https://www.google.com/maps/search/?api=1&query={{ctrl.data.location.coordinates[1]}},{{ctrl.data.location.coordinates[0]}}">[ {{ctrl.data.location.coordinates[1]}}, {{ctrl.data.location.coordinates[0]}} ]</a></div></div><div class="rt-footer chart-footer" ng-if="ctrl.data && !ctrl.data.content"><div class="small" translate>errors.NOT_DATA_FOUND</div></div><div class="rt-footer chart-footer" ng-if="!ctrl.data"><div class="small"><i translate>general.loading</i><i>...</i></div></div></div></div></div>',
      controller: RTConcentrationAlertController,
      controllerAs: 'ctrl',
      bindings: {
        sensor: '<',
        assetName: '<',
        state: '<',
        data: '<'
      }
    })

  function RTConcentrationAlertController ($scope, $compile, $element, $timeout) {
    const vm = this

    vm.$onInit = function () {
      vm.activeType = 'image'

      vm.activeType = vm.data && vm.data.content && vm.data.content.image ? 'image' : 'raw'
    }
  }
})()
