(function () {
  'use strict';

  let angular = window.angular;

  PublicRouter.$inject = ['$stateProvider'];

  angular.module('app').config(PublicRouter);

  function PublicRouter($stateProvider) {
    $stateProvider
      .state('public', {
        url: '?style&access_token&user_id',
        params: {
          style: { type: 'string', inherit: true },
          access_token: { type: 'string', inherit: true },
          user_id: { type: 'string', inherit: true },
        },
        abstract: true,
        template:'<ui-view></ui-view><spinner show="$root.viewLoading" css-class="\'black\'"></spinner>',
      })
      .state('public.login', {
        url: '/login',
        component: 'publicLogin',
        login: true,
      })
      .state('public.resetPassword', {
        url: '/reset-password?error',
        component: 'publicResetPassword',
        login: true,
      })
      .state('public.changePassword', {
        url: '/change-password?uid&token',
        component: 'publicChangePassword',
        login: true,
      })
      .state('public.confirmAccount', {
        url: '/confirm?uid&token',
        component: 'publicAccountConfirmation',
        login: true,
      })
      .state('public.validateEmail', {
        url: '/validate-email',
        component: 'publicVerifyEmail',
        login: true,
      });
  }
})();
