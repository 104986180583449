(() => {
  const angular = window.angular;

  GridPaper.$inject = ['BasePaper', 'RectanglePaper', '$timeout'];

  angular.module('app').service('GridPaper', GridPaper);

  function GridPaper(BasePaper, RectanglePaper, $timeout) {
    class GridPaperCanvas extends RectanglePaper.Canvas {
      constructor(container, targetElement, size, options = {}) {
        options.style = options.style || {};
        options.style.fillOpacity = 0.2;

        super(container, targetElement, size, options);
        this.PortClass = GridPaperPort;
      }

      updateTempFigure(x, y) {
        if (isNaN(x) || isNaN(y)) {
          return;
        }
        const tempPath = this.tempPath;
        const _this = this;
        $timeout(() => {
          _this.mode = 'drawing';
          tempPath[1][2] = tempPath[2][2] = y;
          tempPath[2][1] = tempPath[3][1] = x;
          _this.tempFigure.attr({ path: tempPath });
          this.generateGrid(_this.tempFigure);
        });
      }

      createFigure(sensorConfig) {
        const figure = super.createFigure(sensorConfig);
        figure._currentPath = angular.copy(figure.getPath());
        this.generateGrid(figure);
        return figure;
      }

      updateFigure(figure, config) {
        config.rows = config.rows || 5;
        config.columns = config.columns || 5;
        super.updateFigure(figure, config);
        figure._sensor_config = Object.assign(figure._sensor_config, config);
        this.generateGrid(figure);
      }

      endFigure(figure) {
        super.endFigure(figure);
        $timeout(() => {
          figure._sensor_config.rows = 5;
          figure._sensor_config.columns = 5;
        });
      }

      fixPath(path) {
        let lesser = {
          point: { x: path[0][1], y: path[0][2] },
          index: 0,
        };

        for (let i = 1; i < path.length - 1; i++) {
          const x = path[i][1];
          const y = path[i][2];

          if (x <= lesser.point.x) {
            if (x === lesser.point.x) {
              if (y < lesser.point.y) {
                lesser.point.x = x;
                lesser.point.y = y;
                lesser.index = i;
              }
            } else {
              lesser.point.x = x;
              lesser.point.y = y;
              lesser.index = i;
            }
          }
        }

        let p1 = path[lesser.index];
        let p2 = path[(lesser.index + 1) % 4];
        let p3 = path[(lesser.index + 2) % 4];
        let p4 = path[(lesser.index + 3) % 4];

        const newPath = [p1, p2[1] >= p4[1] ? p2 : p4, p3, p2[1] >= p4[1] ? p4 : p2].map(
          (current, index) => {
            return [index === 0 ? 'M' : 'L', current[1], current[2]];
          }
        );

        newPath.push(['Z']);
        return newPath;
      }

      generateGrid(figure) {
        const config = (figure._sensor_config = Object.assign(
          { rows: 5, columns: 5 },
          figure._sensor_config
        ));
        const rows = config.rows;
        const columns = config.columns;

        let path = figure.getPath();
        let sides = [
          [path[0], path[1]],
          [path[1], path[2]],
          [path[3], path[2]],
          [path[0], path[3]],
        ];

        const grid = [];

        function createLines(side1, side2, stroke) {
          if (side1.length === side2.length) {
            for (let i = 0; i < side1.length; i++) {
              grid.push({
                stroke: stroke,
                path: [
                  ['M', side1[i][0], side1[i][1]],
                  ['L', side2[i][0], side2[i][1]],
                ],
              });
            }
          }
        }

        // rows
        const top = generatePoints(sides[0], rows);
        const bottom = generatePoints(sides[2], rows);
        createLines(top, bottom, 'yellow');

        // columns
        const right = generatePoints(sides[1], columns);
        const left = generatePoints(sides[3], columns);
        createLines(right, left, 'orange');

        figure.children = figure.children || [];

        for (let i = 0; i < grid.length; i++) {
          const current = grid[i];
          let line = figure.children[i];
          if (!line) {
            line = this.paper.path(current.path);
            line._color = current.stroke;
            line.attr({ stroke: current.stroke, 'stroke-width': 2 });
            line.toBack();

            figure.children.push(line);
          } else {
            line._color = current.stroke;
            line.attr({
              path: current.path,
              stroke: current.stroke,
            });
          }
        }

        for (let i = figure.children.length - 1; i >= grid.length; i--) {
          figure.children[i].remove();
          figure.children.pop();
        }

        return figure.children;
      }
    }

    function generatePoints(side, n) {
      const vector = [side[1][1] - side[0][1], side[1][2] - side[0][2]];
      const length = Math.sqrt(vector[0] * vector[0] + vector[1] * vector[1]);

      if (length === 0) {
        return [];
      }

      const unitVector = [vector[0] / length, vector[1] / length];
      const segment = length / n;

      const points = [];
      for (let i = 1; i < n; i++) {
        points.push([
          side[0][1] + unitVector[0] * segment * i,
          side[0][2] + unitVector[1] * segment * i,
        ]);
      }
      return points;
    }

    class GridPaperPort extends BasePaper.Port {
      onMove() {
        const _this = this;
        return function (event) {
          if (_this.canvas.readOnly) {
            return;
          }
          const pointIndex = _this.index;
          const target = _this.target;
          const drawArea = _this.canvas.drawArea;
          const rect = _this.svg.getBoundingClientRect();

          let x = _this.checkLimits(drawArea.ox, rect.width);
          let y = _this.checkLimits(drawArea.oy, rect.height);

          let path = target.getPath();
          path[pointIndex][1] = x;
          path[pointIndex][2] = y;

          target.attr({ path: path });
          _this.attr({ cx: x, cy: y });
          _this.canvas.generateGrid(target);

          target.valid = _this.isValid(path);

          if (target.valid) {
            const color =
              target._sensor_config && target._sensor_config.color
                ? target._sensor_config.color
                : _this.canvas.style.stroke;
            target.attr({ stroke: color });

            for (let child of target.children) {
              child.attr({ stroke: child._color || _this.canvas.style.stroke });
            }
            $timeout(() => {
              if (target._sensor_config) {
                target._sensor_config.points[pointIndex] = {
                  x: _this.canvas.unApplyRatio(x),
                  y: _this.canvas.unApplyRatio(y),
                };
              }
            });
          } else {
            target.attr({ stroke: 'red' });
            for (let child of target.children) {
              child.attr({ stroke: 'red' });
            }
          }
        };
      }

      onEnd() {
        const _this = this;
        return function () {
          if (_this.canvas.readOnly) {
            return;
          }
          const target = _this.target;

          const fill =
            target._sensor_config && target._sensor_config.color
              ? target._sensor_config.color
              : _this.canvas.style.stroke;
          _this.target.attr({ fill: fill });

          _this.canvas.figures.forEach((current) => {
            const fill =
              current._sensor_config && current._sensor_config.color
                ? current._sensor_config.color
                : _this.canvas.style.stroke;
            current.attr({ fill: fill });
          });

          $timeout(() => {
            _this.canvas.state = null;
          });

          if (!target.valid) {
            const path = angular.copy(target._currentPath);
            target.attr({ path: path });
            target.attr({ stroke: target.canvas.style.stroke });

            _this.attr({ cx: path[_this.index][1], cy: path[_this.index][2] });
            _this.canvas.generateGrid(target);

            for (let child of target.children) {
              child.attr({ stroke: child._color || target.canvas.style.stroke });
            }

            $timeout(() => {
              if (target._sensor_config) {
                for (let i = 0; i < path.length - 1; i++) {
                  const current = path[i];
                  target._sensor_config.points[i] = {
                    x: _this.canvas.unApplyRatio(current[1]),
                    y: _this.canvas.unApplyRatio(current[2]),
                  };
                }
              }
            });
          } else {
            target._currentPath = angular.copy(target.getPath());
          }
        };
      }

      isValid(polygonPath) {
        const end =
          polygonPath[polygonPath.length - 1].length === 1
            ? polygonPath.length - 1
            : polygonPath.length;

        let sign = false;
        for (let i = 0; i < end; i++) {
          const dx1 = polygonPath[(i + 2) % end][1] - polygonPath[(i + 1) % end][1];
          const dy1 = polygonPath[(i + 2) % end][2] - polygonPath[(i + 1) % end][2];
          const dx2 = polygonPath[i][1] - polygonPath[(i + 1) % end][1];
          const dy2 = polygonPath[i][2] - polygonPath[(i + 1) % end][2];
          const zCrossProduct = dx1 * dy2 - dy1 * dx2;

          if (zCrossProduct === 0) {
            return false;
          }
          if (i === 0) {
            sign = zCrossProduct > 0;
          } else if (sign !== zCrossProduct > 0) {
            return false;
          }
        }

        return true;
      }
    }

    return {
      Canvas: GridPaperCanvas,
      Port: GridPaperPort,
    };
  }
})();
