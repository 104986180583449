(() => {
  Controller.$inject = [];

  angular.module('app').component('videoCarousel', {
    template:'<div class="detection-carousel"><div class="slideshow-container"><div class="detection-slide slide-fade"><div class="carousel-caption"><span>{{ctrl.currentElement.ts | date: "HH:mm:ss"}}</span></div><div class="carousel-controls"><div class="paddingL5">{{ctrl.slideIndex + 1 }} / <span>{{ctrl.videos.length}}</span></div></div><video-renderer video-src="ctrl.currentElement.src"></video-renderer></div><div ng-show="ctrl.slides > 1"><a class="prev" ng-class="{disabled: ctrl.slideIndex === 0}" ng-click="ctrl.plusSlides(-1)">&#10094;</a> <a class="next" ng-class="{disabled: ctrl.slideIndex === ctrl.videos.length - 1}" ng-click="ctrl.plusSlides(1)">&#10095;</a></div></div></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      videos: '<',
      startSlide: '<',
    },
  });

  function Controller() {
    const vm = this;

    vm.$onInit = function () {
      vm.plusSlides = plusSlides;
      vm.currentSlide = currentSlide;
    };

    vm.$onChanges = function (changes) {
      vm.currentElement = null;
      if (changes.videos) {
        vm.slideIndex = getStartSlide();
        showSlides(vm.slideIndex);
      }

      if (changes.startSlide) {
        vm.slideIndex = getStartSlide();
        showSlides(vm.slideIndex);
      }
    };

    vm.$onDestroy = () => { };

    function getStartSlide() {
      const startSlide = vm.startSlide || 0;
      return vm.slides > startSlide ? startSlide : 0;
    }

    function plusSlides(n) {
      if (vm.loadingImage) {
        return;
      }
      showSlides(vm.slideIndex + n);
    }

    function currentSlide(n) {
      showSlides((vm.slideIndex = n));
    }

    function showSlides(selected) {
      let videos = vm.videos || [];
      let slides = (vm.slides = videos.length);
      if (selected >= slides) {
        selected = 0;
      }
      if (selected < 0) {
        vm.slideIndex = 0;
        return;
      }

      vm.slideIndex = selected;
      vm.currentElement = videos[selected];
    }
  }
})();
