;(function () {
  'use strict';
  const angular = window.angular;

  ToolkitOverviewController.$inject = [];
  angular
    .module('app')
    .component('toolkitOverview', {
      template:'<div class="tool"><div class="container"><div class="tool-header"><div class="tool-icon"><div class="thumbnail no-margin"><img ng-src="{{$root.assetsPath}}/images/logos/xompass-cut.png"></div></div><div class="tool-name"><h2 class="no-margin">{{ctrl.toolKit.name}}</h2></div></div></div><div class="tool-body"><div class="container"><div class="row"><div class="col-lg-7"><div style="max-width: 500px; margin: 0 auto 20px"><div class="thumbnail"><div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="//www.youtube.com/embed/J3FceN2lYdA"></iframe></div></div></div><div class="markdown text-justify" markdown-to-html="ctrl.toolKit.description || \'\'"></div></div><div class="col-lg-5"><div style="padding-left: 30px"><div class="well well-light"><h4 class="marginT0">Herramientas</h4><hr class="marginT0"><ul class="list-unstyled tools-list"><li ng-repeat="tool in ctrl.toolKit.tools"><a target="_blank" ui-sref="member.tools.show({toolId: tool.id})">{{tool.name}}</a></li></ul></div></div></div></div></div></div></div>',
      controller: ToolkitOverviewController,
      controllerAs: 'ctrl',
      bindings: {
        toolKit: '<'
      }
    });

  function ToolkitOverviewController () {
    const vm = this;

    vm.$onInit = function () {
    };
  }
})();
