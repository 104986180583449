(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  DatePickerController.$inject = ['DateUtils', '$timeout'];

  angular.module('app').component('datePicker', {
    template:'<div class="form-group"><label translate ng-if="ctrl.label">{{ctrl.label}}</label> <input ng-if="!ctrl.hideTime" class="form-control input-sm" datetime-picker="yyyy-MM-dd HH:mm" is-open="ctrl.value.open" ng-change="ctrl.onDateChange({ type: ctrl.type })" datepicker-options="ctrl.value.options" ng-click="ctrl.value.open = !ctrl.value.open" timepicker-options="ctrl.value.options" enable-time="!ctrl.hideTime" ng-model="ctrl.value.value"> <input ng-if="ctrl.hideTime" class="form-control input-sm" datetime-picker="yyyy-MM-dd" is-open="ctrl.value.open" ng-change="ctrl.onDateChange({ type: ctrl.type })" datepicker-options="ctrl.value.options" ng-click="ctrl.value.open = !ctrl.value.open" timepicker-options="ctrl.value.options" enable-time="!ctrl.hideTime" ng-model="ctrl.value.value"></div>',
    controller: DatePickerController,
    controllerAs: 'ctrl',
    bindings: {
      label: '<',
      type: '<',
      value: '=',
      onDateChange: '&',
      hideTime: '<',
    },
  });

  function DatePickerController(DateUtils) {
    let vm = this;
    vm.$onInit = function () {
      const { initTo, initFrom } = DateUtils.initDatePicker(1);
      if (!Boolean(vm.value)) {
        if (vm.type === 'from') {
          vm.value = initFrom;
        } else {
          vm.value = initTo;
        }
      }
    };
  }
})();
