(function () {
  'use strict';

  let angular = window.angular;

  CCTVRouter.$inject = ['$stateProvider'];

  angular.module('app').config(CCTVRouter);

  function CCTVRouter($stateProvider) {
    $stateProvider
      .state('member.var.show.customer.show.project.cctv', {
        url: '/cctv',
        redirectTo: 'member.var.show.customer.show.project.cctv.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'CCTV',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassCCTV');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.var.show.customer.invalidTool',
                  params: { tool: 'xompassCCTV' },
                });
              }
            },
          ],
          dependencies: [
            '$rootScope',
            '$ocLazyLoad',
            ($root, $ocLazyLoad) => {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/app/dashboard-common.module.min.js',
                window.assetsPath + '/js/app/cctv.module.min.js',
                window.assetsPath + `/css/cctv.style-${style}.css`,
              ]);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.cctv.home', {
        url: '/',
        component: 'cctvDashboardHome',
        resolve: {
          dashboards: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              return EntityService.find('CctvDashboard', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.cctv.show', {
        url: '/:dashboardId',
        component: 'cctvDashboardShow',
        redirectTo: 'member.var.show.customer.show.project.cctv.show.cameras',
        authenticated: true,
        resolve: {
          dashboard: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadCCTVDashboard(EntityService, $transition$.params(), utils);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.dashboard.name}}',
        },
      })
      .state('member.var.show.customer.show.project.cctv.show.cameras', {
        url: '/?monitorId',
        component: 'cctvDashboardCameras',
        authenticated: true,
        params: {
          monitorId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
        },
        ncyBreadcrumb: {
          label: "{{'general.cameraPl' | translate}}",
        },
      })
      .state('member.var.show.customer.show.project.cctv.show.editor', {
        url: '/editor',
        component: 'cctvDashboardEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.CctvDashboard.breadcrumb.editor" | translate}}',
        },
      });

    function loadCCTVDashboard(EntityService, params, utils) {
      const options = angular.copy(params);
      options.entityId = options.dashboardId;
      options.filter = {
        include: [
          'managers',
          {
            relation: 'assets',
            scope: { fields: ['id', 'name', 'type'], include: ['credentials'] },
          },
        ],
      };

      return EntityService.findById('CctvDashboard', options).catch((err) => {
        console.log(err);
        throw utils.getHTTPError(err, {
          redirectTo: 'member.var.show.customer.show.project.cctv',
          params: { customerId: params.customerId, projectId: params.projectId },
        });
      });
    }
  }
})();
