;(function () {
  'use strict';

  const angular = window.angular;

  EmbeddedFormController.$inject = ['$scope', 'Customer', 'AppUtils'];

  angular
    .module('app')
    .component('embeddedForm', {
      template:'<form class="form-validation" novalidate name="ctrl.reportForm" ng-class="{transparent: ctrl.saving}"><div class="modal-header"><button type="button" class="close" ng-click="ctrl.modalInstance.dismiss()"><i class="fas fa-times"></i></button> <span>Embedded Report</span></div><div class="modal-body"><div class="form-group"><label for="reportName" class="hidden">Report Name</label> <input class="form-control" type="text" placeholder="Report Name" name="reportName" id="reportName" ng-model="ctrl.report.name" required></div><div class="form-group"><label for="reportSrc" class="hidden">Report URL</label> <input class="form-control" type="url" placeholder="Report URL" name="reportSrc" id="reportSrc" ng-model="ctrl.report.source" required></div></div><div class="modal-footer"><button type="button" class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-if="ctrl.report.id" ng-click="ctrl.saveReport()" translate>buttons.update</button> <button class="btn btn-primary" ng-if="!ctrl.report.id" ng-click="ctrl.saveReport()" translate>buttons.create</button></div></form><spinner css-class="\'small\'" show-logo="true" show="ctrl.saving"></spinner>',
      controller: EmbeddedFormController,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function EmbeddedFormController ($scope, Customer, utils) {
    let vm = this;

    vm.$onInit = function () {
      vm.report = angular.copy(vm.resolve.report || {});

      vm.customer = vm.resolve.customer;
      vm.project = vm.resolve.project;
      vm.saveReport = saveReport;

      vm.mdeOptions = {
        spellChecker: false,
        toolbar: [
          'bold', 'italic', 'heading', '|',
          'unordered-list', 'ordered-list', '|',
          'quote', 'link', 'table', 'code', '|',
          'preview'
        ]
      };
    };

    function saveReport () {
      if (!vm.reportForm.$valid) {
        $scope.$broadcast('show-errors-check-validity');
        return;
      }

      vm.saving = true;
      let promise;
      if (vm.report.id) {
        promise = Customer.prototype$__updateById__projects__embeddedReports({
          id: vm.customer.id,
          nk: vm.project.id,
          fk: vm.report.id
        }, {
          name: vm.report.name,
          source: vm.report.source
        }).$promise;
      } else {
        promise = Customer.prototype$__create__projects__embeddedReports({
          id: vm.customer.id,
          nk: vm.project.id
        }, {
          name: vm.report.name,
          source: vm.report.source
        }).$promise;
      }

      promise
        .then(response => {
          vm.saving = false;
          vm.modalInstance.close(response);
        })
        .catch(err => {
          vm.saveError = utils.getHTTPError(err);
          vm.saving = false;
        });
    }
  }

})();
