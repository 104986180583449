(function () {
  'use strict';

  let angular = window.angular;

  Controller.$inject = ['$element', 'AppUtils'];

  angular.module('app').component('detectionIsoContainerCanvas', {
    template:'<div tabindex="0" style="position: relative; margin: auto" class="detections-canvas-container"><canvas style="display: block; margin: auto"></canvas></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      sensor: '<',
      data: '<',
      image: '<',
      drawZone: '<?',
      filterByZone: '<?',
      ratio: '<',
      width: '<',
      height: '<',
      copyObjects: '<?',
    },
  });

  function Controller($element, utils) {
    let vm = this;
    let firstChange = true;

    vm.$onInit = function () {
      vm.canvasTarget = $element.find('.detections-canvas-container');
      initDrawing();
    };

    vm.$onDestroy = function () {};

    vm.$onChanges = function (changes) {
      if (firstChange) {
        firstChange = false;
        return;
      }
      initDrawing();
    };

    function initDrawing() {
      if (!vm.data || !vm.image) {
        return;
      }
      const info = vm.data.content?.info;
      if (!info) {
        return;
      }

      const canvas = vm.canvasTarget.find('canvas')[0];
      canvas.width = vm.width;
      canvas.height = vm.height;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const fontSize = 14;
      ctx.font = `${fontSize}px Arial`;

      let row = 0;
      const XStart = 10;
      const YStart = 10 + fontSize / 2;
      const padding = 3;
      const shadow = 1;

      const keyWidth = Object.keys(info).map((key) => ctx.measureText(utils.capitalize(key)).width);
      const valueWidth = Object.values(info).map((value) => ctx.measureText(value).width);

      const longestWidth = Math.max(...keyWidth);
      const longestValueWidth = Math.max(...valueWidth);

      ctx.strokeStyle = '#ffffff';

      if (Object.keys(info).length > 0) {
        // background
        const rows = Object.keys(info).length;
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fillRect(
          XStart,
          YStart - fontSize / 2 - padding,
          longestWidth + longestValueWidth + padding * 5,
          rows * 20
        );
      }

      for (const key in info) {
        const y = YStart + row * 20;
        const keyX = XStart;
        const valueX = XStart + longestWidth + padding * 3;

        ctx.beginPath();
        ctx.moveTo(XStart, y - fontSize / 2 - padding);
        ctx.lineTo(
          XStart + longestWidth + longestValueWidth + padding * 5,
          y - fontSize / 2 - padding
        );
        ctx.stroke();

        const keyLabel = utils.capitalize(key);
        const value = info[key];

        ctx.fillStyle = '#ffffff'; // Color del texto
        ctx.shadowColor = 'transparent';
        ctx.fillText(keyLabel, keyX + padding, y + padding + 1);
        ctx.fillText(value, valueX + padding, y + padding + 1);
        row++;
      }

      if (row > 0) {
        ctx.beginPath();
        ctx.moveTo(XStart, YStart + row * 20 - fontSize / 2 - padding);
        ctx.lineTo(
          XStart + longestWidth + longestValueWidth + padding * 5,
          YStart + row * 20 - fontSize / 2 - padding
        );
        ctx.stroke();
      }
    }
  }
})();
