;(() => {
  const angular = window.angular;

  ManagerPasswordCtrl.$inject = ['$scope', 'Manager'];

  angular
    .module('app')
    .component('managerPasswordForm', {
      template:'<form name="ctrl.changePasswordForm" class="validate" novalidate autocomplete="off" ng-class="{transparent: ctrl.saving}"><div class="modal-header"><span translate>buttons.change</span> <span translate>entities._all.password</span></div><div class="modal-body"><fieldset><legend><span class="text-info">{{ctrl.user.name}} {{ctrl.user.surname}}</span></legend><div class="form-group" show-errors><label class="control-label" for="password-input"><span translate>entities._all.password</span></label> <input ng-model="ctrl.changeForm.password" id="password-input" ng-change="ctrl.onChange()" ng-blur="ctrl.onBlur()" type="password" class="form-control" name="password" required ng-minlength="ctrlminPassLength" translate-attr="::{placeholder: \'entities._all.password\'}"><p class="help-block" ng-messages="ctrl.changePasswordForm.password.$error"></p></div><div class="form-group" show-errors><label class="control-label" for="password-confirm" translate>entities.user.password.confirm</label> <input ng-model="ctrl.changeForm.repeatPassword" id="password-confirm" ng-change="ctrl.onChange()" ng-blur="ctrl.onBlur()" type="password" class="form-control" name="repeatPassword" required ng-minlength="ctrlminPassLength" translate-attr="::{placeholder: \'entities._all.password\'}"><p class="help-block" ng-messages="ctrl.changePasswordForm.repeatPassword.$error"></p></div></fieldset></div><div class="alert alert-warning no-margin text-center" ng-show="ctrl.saveError">{{ctrl.saveError}}</div><div class="modal-footer"><button type="button" class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="ctrl.changePassword()" translate>buttons.change</button></div></form><spinner css-class="\'small\'" show-logo="true" show="ctrl.loading"></spinner>',
      controller: ManagerPasswordCtrl,
      controllerAs: 'ctrl',
      bindings: {
        resolve: '<',
        modalInstance: '<'
      }
    });

  function ManagerPasswordCtrl ($scope, Manager) {
    const vm = this;

    vm.$onInit = function () {
      vm.user = vm.resolve.user;

      vm.changeForm = {};
      vm.minPassLength = 4;

      vm.onChange = onChange;
      vm.onBlur = onBlur;
      vm.changePassword = changePassword;
    };

    function changePassword () {
      let password = vm.changeForm.password;
      let repeatPassword = vm.changeForm.repeatPassword;

      if (password && repeatPassword && password !== repeatPassword) {
        vm.changePasswordForm.password.$setValidity('password_mismatch', false);
        vm.changePasswordForm.repeatPassword.$setValidity('password_mismatch', false);
      }

      $scope.$broadcast('show-errors-check-validity');

      if (!vm.changePasswordForm.$valid) {
        return;
      }

      vm.loading = true;
      Manager
        .prototype$patchAttributes({ id: vm.user.id }, { password: password })
        .$promise
        .then(() => {
          vm.loading = false;
          vm.modalInstance.close();
        })
        .catch(response => {
          vm.loading = false;
          vm.saveError = utils.getHTTPError(response);
        });
    }

    function onChange () {
      let password = vm.changeForm.password || '';
      let repeatPassword = vm.changeForm.repeatPassword || '';
      if (password.length < vm.minPassLength && repeatPassword.length < vm.minPassLength) {
        vm.changePasswordForm.password.$setValidity('password_mismatch', true);
        vm.changePasswordForm.repeatPassword.$setValidity('password_mismatch', true);
      }
    }

    function onBlur () {
      let password = vm.changeForm.password;
      let repeatPassword = vm.changeForm.repeatPassword;
      if (password && repeatPassword && password !== repeatPassword) {
        vm.changePasswordForm.password.$setValidity('password_mismatch', false);
        vm.changePasswordForm.repeatPassword.$setValidity('password_mismatch', false);
      } else {
        vm.changePasswordForm.password.$setValidity('password_mismatch', true);
        vm.changePasswordForm.repeatPassword.$setValidity('password_mismatch', true);
      }
    }
  }
})();
