; (function () {
  let angular = window.angular;

  Controller.$inject = [
    '$element',
    '$state',
    '$uibModal',
    '$translate'
  ];

  angular
    .module('app')
    .component('restrictionSensorTypeTable', {
      template:'<div style="position: relative; min-height: 200px"><div class="panel panel-default"><header class="panel-heading"><h2 class="nowrap"><strong translate>entities.sensortype.modelNamePl</strong></h2><div class="panel-toolbars pull-right"><div class="panel-toolbar"><button class="btn btn-primary" ng-click="ctrl.showLinkModal()" translate>buttons.add_remove</button></div></div></header><div class="panel-body no-padding"><table id="sensor-type-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th></tr></thead></table></div></div><spinner show="ctrl.loading" show-logo="true"></spinner></div>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        project: '<'
      }
    });

  function Controller($element, $state, $uibModal, $translate) {
    let vm = this;

    vm.$onInit = function onInit() {
      initTable(vm.project.allowedSensorTypes);

      vm.showLinkModal = showLinkModal;
    };

    function initTable(sensorTypes) {
      sensorTypes = sensorTypes || [];
      let dtOptions = {
        dom: '<tr> <\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        searching: false,
        info: false,
        data: sensorTypes,
        columns: [{
          data: 'type',
          title: $translate.instant('entities._all.name'),
          render: function (data) {
            return $translate.instant('entities.sensortype.__types.' + data);
          }
        }/*, {
          orderable: false,
          data: null,
          defaultContent: '<div class="table-buttons"><button class="btn btn-danger btn-xs"><i class="fas fa-times fa-fw"></i></button></div>'
        }*/]
      };

      const table = $element.find('#sensor-type-table');
      vm.dtInstance = table.DataTable(dtOptions);

    }

    function showLinkModal() {
      $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          component: 'restrictionSensorTypeLinkModal',
          resolve: {
            project: () => vm.project
          }
        })
        .result
        .then(function (result) {
          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
})();
