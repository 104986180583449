(function () {
  'use strict';

  const angular = window.angular;

  angular.module('app').directive('toolIcon', () => {
    return {
      restrict: 'E',
      scope: {
        icon: '=',
      },
      template: `
        <i ng-if="icons.length===1" class="{{icons[0]}}"></i>
        <span class="fa-stack" ng-if="icons.length === 2"  style="vertical-align: top;">
          <i class="{{icons[0]}}"></i>
          <i class="{{icons[1]}}"></i>
        </span>`,
      controller: [
        '$scope',
        function (scope) {
          let icon = scope.icon;
          if (typeof icon === 'string') {
            scope.icons = [icon];
          } else if (Array.isArray(icon) && icon.length >= 2) {
            scope.icons = icon.slice(0, 2);
          }
        },
      ],
    };
  });
})();
