;(function () {
  'use strict';

  let angular = window.angular;

  DataSourceRouter.$inject = ['$stateProvider'];

  angular
    .module('app')
    .config(DataSourceRouter);

  function DataSourceRouter ($stateProvider) {
    $stateProvider
      .state('member.var.show.customer.show.support', {
        url: '/support',
        redirectTo: 'member.var.show.customer.show.support.ticket.create',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Support'
        },
        resolve: {
          dependencies: ['$ocLazyLoad', function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/vendors/support-vendors.min.js'
            ]);
          }],
          module: ['$ocLazyLoad', 'dependencies', function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/support.module.min.js',
            ]);
          }]
        }
      })
      .state('member.var.show.customer.show.support.ticket', {
        url: '/ticket',
        template: '<ui-view></ui-view>',
        ncyBreadcrumb: {
          label: '{{"support.ticket.modelNamePl" | translate}}'
        }
      })
      .state('member.var.show.customer.show.support.ticket.create', {
        url: '/form',
        component: 'ticketForm',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"form.actions.create" | translate}}'
        },

      });
  }
})();
