(function () {
  'use strict';

  Controller.$inject = ['$scope', '$compile', '$element', '$timeout'];

  const angular = window.angular;
  angular.module('app').component('rawContent', {
    template:'<div class="panel panel-default rt-panel rt-panel-sm" ng-if="!ctrl.onlyContent"><header class="panel-heading"><h2 class="nowrap"><strong>{{ctrl.sensor.name}}</strong> <small>{{ctrl.assetName}}</small></h2></header><div class="panel-body" ng-class="{\'with-location\': ctrl.data.location}"><div class="rt-content"><pre ng-if="ctrl.data.content !== undefined">{{ctrl.data.content | json}}</pre><div ng-if="ctrl.data.content === undefined" class="center-middle text-muted"><i class="fas fa-times-rectangle fa-3x"></i></div></div><div class="rt-footer"><div class="date" ng-if="ctrl.data.content">{{ctrl.data.from | date: "yyyy-MM-dd HH:mm:ss"}}<div ng-if="ctrl.data.location"><a target="_blank" ng-href="https://www.google.com/maps/search/?api=1&query={{ctrl.data.location.coordinates[1]}},{{ctrl.data.location.coordinates[0]}}">[ {{ctrl.data.location.coordinates[1]}}, {{ctrl.data.location.coordinates[0]}} ]</a></div></div><div class="rt-footer chart-footer" ng-if="ctrl.data && !ctrl.data.content"><div class="small" translate>errors.NOT_DATA_FOUND</div></div><div class="rt-footer chart-footer" ng-if="!ctrl.data"><div class="small"><i translate>general.loading</i><i>...</i></div></div></div></div></div><div ng-if="ctrl.onlyContent" class="rt-content" style="top: 0; bottom: 0; right: 0; left: 0"><pre ng-if="ctrl.data.content !== undefined">{{ctrl.data.content | json}}</pre></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      sensor: '<',
      assetName: '<',
      state: '<',
      data: '<',
      onlyContent: '<',
      onLoadContent: '<',
    },
  });

  function Controller($scope, $compile, $element, $timeout) {
    const vm = this;
    vm.$onInit = () => {
      if (vm.onLoadContent) {
        vm.onLoadContent();
      }
    };
  }
})();
