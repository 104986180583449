(function () {
  window.angular
    .module('app')
    .directive('normalUppercased', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
          element.css('text-transform', 'uppercase');
          modelCtrl.$parsers.push(function (input) {
            return input ? removeAccents(input).toUpperCase() : '';
          });
        }
      };

      function removeAccents(str) {
        let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüŠšŸÿýŽž';
        let accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuSsYyyZz';
        str = str.split('');
        str.forEach((letter, index) => {
          let i = accents.indexOf(letter);
          if (i !== -1) {
            str[index] = accentsOut[i];
          }
        });
        return str.join('');
      }
    });
})();
