(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  EdgeAgentTable.$inject = [
    '$element',
    '$scope',
    '$timeout',
    '$uibModal',
    'Customer',
    'AppUtils',
    '$translate',
    'uiModalService',
    '$filter',
  ];

  angular.module('app').component('deviceEdgeAgentTable', {
    template:'<div class="dataTable-toolbar paddingT0"><div class="btn-group marginR20"><label class="marginR10" translate>entities.edgeagent.wizard.mode</label><toggle-switch ng-model="ctrl.wizardEnabled" ng-change="ctrl.toggleWizardEnabled()"></toggle-switch></div><div class="btn-group btn-group-sm"><button class="btn btn-primary" ng-if="ctrl.addButton" ng-click="ctrl.addEdgeAgent(ctrl.wizardEnabled)" style="min-width: 90px"><i class="fas fa-plus fa-fw"></i> <span translate>buttons.add</span></button> <button class="btn btn-primary edit-edgeAgent" style="min-width: 90px" ng-if="ctrl.editButton" ng-disabled="!ctrl.selectedEdgeAgent" ng-click="ctrl.editEdgeAgent(ctrl.wizardEnabled)"><i class="fas fa-edit fa-fw"></i> <span translate>buttons.update</span></button> <button class="btn btn-primary remove-edgeAgent" style="min-width: 90px" ng-if="ctrl.removeButton" ng-disabled="!ctrl.selectedEdgeAgent" ng-click="ctrl.removeEdgeAgent()"><i class="fas fa-trash fa-fw"></i> <span translate>buttons.delete</span></button></div><div class="btn-group btn-group-sm marginL20"><button class="btn btn-primary" ng-if="ctrl.editButton" ng-disabled="!ctrl.selectedEdgeAgent" ng-click="ctrl.showEdgeAgentAssetLink()" style="min-width: 90px"><i class="fas fa-link fa-fw"></i> <span translate>entities.asset.modelNamePl</span></button></div><div class="pull-right" style="display: inline-block"><label class="checkbox marginT5 marginB0" for="hideDisabled"><input id="hideDisabled" type="checkbox" ng-model="ctrl.hideDisabled" ng-change="ctrl.filterTable()"> <i></i> <span style="font-weight: normal" translate data-translate-values="{items: \'entities.edgeagent.modelNamePl\'}">general._messages.hideDisabled.items</span></label></div></div><table id="edgeAgents-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th><th>Name</th><th></th><th></th><th></th><th class="none"></th><th class="none"></th><th class="control"></th></tr></thead></table>',
    controller: EdgeAgentTable,
    controllerAs: 'ctrl',
    bindings: {
      device: '<',
      assets: '<',
      projects: '<',
      addButton: '<',
      editButton: '<',
      removeButton: '<',
    },
  });

  function EdgeAgentTable(
    $element,
    $scope,
    $timeout,
    $uibModal,
    Customer,
    utils,
    $translate,
    uiModalService,
    $filter
  ) {
    let vm = this;

    const wizardEnabled = window.localStorage.getItem('dt-device:wizardEnabled');
    vm.wizardEnabled = wizardEnabled === 'true' || !wizardEnabled;
    vm.hideDisabled = window.localStorage.getItem('dt-device:hideDisabled') === 'true';

    vm.$onInit = function onInit() {
      if (!vm.device) {
        return;
      }

      vm.addEdgeAgent = addEdgeAgent;
      vm.editEdgeAgent = editEdgeAgent;
      vm.removeEdgeAgent = removeEdgeAgent;
      vm.filterTable = filterTable;
      vm.toggleWizardEnabled = toggleWizardEnabled;
      vm.showEdgeAgentAssetLink = showEdgeAgentAssetLink;

      vm.deviceEdgeAgents = vm.device.edgeAgents;
      vm.edgeAgentMap = utils.arrayToObject(vm.deviceEdgeAgents, 'id');

      vm.addButton = vm.addButton === undefined ? true : vm.addButton;
      vm.editButton = vm.editButton === undefined ? true : vm.editButton;
      vm.removeButton = vm.removeButton === undefined ? true : vm.removeButton;
    };

    vm.$onChanges = function (changes) {
      if (changes && changes.device) {
        vm.deviceEdgeAgents = vm.device.edgeAgents;
        vm.edgeAgentMap = utils.arrayToObject(vm.deviceEdgeAgents, 'id');
        vm.selectedEdgeAgent = null;
        initEdgeAgentTable(vm.device.edgeAgents);
      }
    };

    function getNamesTaken(id) {
      return vm.deviceEdgeAgents.map(function (edgeAgent) {
        return edgeAgent.id !== undefined && edgeAgent.id === id ? null : edgeAgent.name;
      });
    }

    function initEdgeAgentTable(data) {
      if (vm.dtInstance) {
        vm.dtInstance.clear();
        vm.dtInstance.rows.add(data);
        vm.dtInstance.draw();

        filterTable();
        return;
      }

      let dtOptions = {
        dom:
          "<'row'<'col-xs-12 col-sm-6 col-md-4'f><'col-sm-6'l>>" +
          '<div tr>' +
          "<'row'<'col-sm-5'i><'col-sm-7'p>>",
        processing: true,
        order: [
          [2, 'desc'],
          [1, 'asc'],
        ],
        paging: false,
        info: false,
        data: data,
        select: {
          selector: 'td:not(.control)',
          style: 'single',
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-radio-btn',
            render: () => '',
            width: '10px',
          },
          { data: 'name', title: $translate.instant('entities._all.name') },
          {
            data: 'created',
            title: $translate.instant('entities._all.createdAt'),
            render: (data) => {
              return $filter('date')(data, 'yyyy-MM-dd HH:mm');
            },
          },
          {
            data: 'modified',
            title: $translate.instant('entities._all.updatedAt'),
            render: (data) => {
              return $filter('date')(data, 'yyyy-MM-dd HH:mm');
            },
          },
          {
            data: 'enabled',
            orderable: true,
            title: $translate.instant('entities._all.enabled'),
            width: '150px',
            render: (data, type, agent) => {
              if (type !== 'display') {
                return data;
              }

              let text = $translate.instant(
                'entities._all.' + (agent.enabled ? 'enabled' : 'disabled')
              );
              return `<span style="display: inline-block; width: 90px">${text}</span>
                  <label class="marginL5 toggle-switch ${data ? 'active' : ''}">
                    <input type="checkbox" ${agent.enabled ? 'checked' : ''}/>
                  </label>`;
            },
          },
          {
            data: 'id',
            title: 'Id',
          },
          {
            data: 'assets',
            title: $translate.instant('entities.asset.modelNamePl'),
            render: (data) => {
              data = data || [];
              return data
                .filter((current) => !!current)
                .map((current) => current.name)
                .join(', ');
            },
          },
          {
            data: null,
            render: () => '',
            orderable: false,
            width: '10px',
          },
        ],
        columnDefs: [
          {
            className: 'control',
            orderable: false,
            targets: [-1, -2],
          },
        ],
        responsive: {
          details: {
            type: 'column',
            target: -1,
          },
        },
      };

      let table = $element.find('#edgeAgents-table');
      let dtInstance = (vm.dtInstance = table.DataTable(dtOptions));
      filterTable();

      table.find('thead th').removeClass('select-radio-btn');

      table.find('tbody').on('change', 'input:checkbox', function () {
        const input = angular.element(this);
        const row = vm.dtInstance.row(input.parents('tr'));
        const toggle = input.parent();
        const agent = row.data();

        toggle.addClass('disabled');
        agent.enabled = !agent.enabled;

        let promise;
        if (!agent.enabled) {
          promise = showConfirm(agent);
        } else {
          promise = Promise.resolve(true);
        }

        promise.then((confirmed) => {
          if (!confirmed) {
            toggle.removeClass('disabled');
            return;
          }
          toggleEnabled(agent)
            .then(() => {
              row.invalidate().draw();
            })
            .catch((err) => {
              toggle.removeClass('disabled');
            });
        });
      });

      dtInstance
        .on('select', function () {
          $timeout(function () {
            vm.selectedEdgeAgent = dtInstance.rows({ selected: true }).data().toArray()[0];
          });
        })
        .on('deselect', function () {
          $timeout(function () {
            vm.selectedEdgeAgent = null;
          });
        });
    }

    function showEdgeAgentAssetLink() {
      $uibModal
        .open({
          animation: false,
          bindToController: true,
          size: 'lg',
          component: 'edgeAgentAssetModal',
          backdrop: 'static',
          resolve: {
            device: () => vm.device,
            edgeAgent: () => vm.selectedEdgeAgent,
            projects: () => vm.projects,
            assets: () => vm.assets,
          },
        })
        .result.then(function (result) {
          vm.dtInstance.clear();
          vm.dtInstance.rows.add(result);
          vm.dtInstance.draw();

          vm.deviceEdgeAgents = vm.device.edgeAgents = result;
          vm.edgeAgentMap = utils.arrayToObject(vm.deviceEdgeAgents, 'id');
          vm.selectedEdgeAgent = null;
        })
        .catch(() => {});
    }

    function showEdgeAgentForm(edgeAgent, wizard) {
      let names = getNamesTaken(edgeAgent ? edgeAgent.id : undefined);
      let instance = $uibModal.open({
        animation: false,
        bindToController: true,
        size: 'lg',
        component: wizard ? 'deviceEdgeAgentWizardModal' : 'deviceEdgeAgentFormModal',
        backdrop: 'static',
        resolve: {
          device: function () {
            return vm.device;
          },
          edgeAgent: function () {
            return edgeAgent;
          },
          names: function () {
            return names;
          },
          projects: () => vm.projects,
          assets: () => vm.assets,
        },
      });

      instance.result
        .then(function (result) {
          vm.dtInstance.clear();
          vm.dtInstance.rows.add(result);
          vm.dtInstance.draw();

          vm.deviceEdgeAgents = vm.device.edgeAgents = result;
          vm.edgeAgentMap = utils.arrayToObject(vm.deviceEdgeAgents, 'id');
          vm.selectedEdgeAgent = null;
          $scope.$emit('contextualization:new-agent', result);
        })
        .catch(() => {});
    }

    function addEdgeAgent(wizard) {
      showEdgeAgentForm(null, wizard);
    }

    function editEdgeAgent(wizard) {
      if (!vm.selectedEdgeAgent) {
        return;
      }
      showEdgeAgentForm(vm.selectedEdgeAgent, wizard);
    }

    function removeEdgeAgent() {
      if (!vm.selectedEdgeAgent) {
        return;
      }
      let removeId = vm.selectedEdgeAgent.id;
      let instance = $uibModal.open({
        animation: false,
        backdrop: 'static',
        windowClass: 'modal-xs modal-warning',
        bindToController: true,
        component: 'deviceEdgeAgentRemove',
        resolve: {
          customerId: () => vm.customerId,
          device: () => vm.device,
          edgeAgent: () => vm.selectedEdgeAgent,
        },
      });

      instance.result
        .then(function (result) {
          let data = vm.dtInstance.rows().data().toArray();
          for (let i = 0; i < data.length; i++) {
            if (data[i].id === vm.selectedEdgeAgent.id) {
              data.splice(i, 1);
            }
          }

          vm.dtInstance.clear();
          vm.dtInstance.rows.add(data);
          vm.dtInstance.draw();

          vm.deviceEdgeAgents = vm.device.edgeAgents = data;
          vm.edgeAgentMap = utils.arrayToObject(vm.deviceEdgeAgents);
          vm.selectedEdgeAgent = null;

          $('th.select-radio-btn').parent().removeClass('selected');
          $scope.$emit('contextualization:remove-agent', removeId);
        })
        .catch(() => {});
    }

    function filterTable() {
      window.localStorage.setItem('dt-device:hideDisabled', JSON.stringify(vm.hideDisabled));
      if (!vm.hideDisabled) {
        vm.dtInstance.columns().search('');
        vm.dtInstance.search('');
        vm.dtInstance.draw();
        return;
      }

      vm.dtInstance.column(4).search(true).draw();
    }

    function showConfirm(agent) {
      return uiModalService.confirm({
        message: $translate.instant('general._messages.disable', {
          model: 'entities.edgeagent.modelName',
          name: agent.name,
        }),
      });
    }

    function toggleEnabled(agent) {
      return Customer.prototype$__updateById__devices__edgeAgents(
        {
          id: vm.device.customerId,
          nk: vm.device.id,
          fk: agent.id,
        },
        { enabled: agent.enabled }
      ).$promise.catch((err) => {
        agent.enabled = !agent.enabled;
      });
    }

    function toggleWizardEnabled() {
      window.localStorage.setItem('dt-device:wizardEnabled', JSON.stringify(vm.wizardEnabled));
    }
  }
})();
