;(function () {
  'use strict';

  const angular = window.angular;

  angular
    .module('app')
    .directive('latexDisplay', () => {
      return {
        restrict: 'A',
        scope: {
          tex: '='
        },
        link: function (scope, element) {
          if (!window.MathJax) {
            console.log('MathJax not loaded');
            return;
          }
          scope.$watch('tex', (newValue) => {
            const texScript = angular.element('<script type=\'math/tex\'>').html(newValue ? newValue : ' ');
            element.empty().append(texScript);

            if (newValue) {
              MathJax.Hub.Queue(['Reprocess', MathJax.Hub, element[0]]);
            }
          });
        }
      };
    });
})();

