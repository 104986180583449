;(function () {
  'use strict';

  TimeSeriesController.$inject = [
    'AssetService',
    'AppUtils',
    '$uibModal',
    '$timeout'
  ];

  const angular = window.angular;
  angular
    .module('app')
    .component('monitoringTimeSeries', {
      template:'<view-sidebar><view-sidebar-title><span translate>entities.asset.assetHierarchy</span></view-sidebar-title><view-sidebar-content><js-tree data="ctrl.assetsTree" multiple="true" on-select="ctrl.onSelectAsset(selected)"></js-tree><div class="absolute" ng-if="ctrl.loading"></div></view-sidebar-content></view-sidebar><div><view-sidebar-toggle></view-sidebar-toggle><div ng-hide="ctrl.keysLength(ctrl.assetsSelected) || ctrl.loading"><h1 class="no-margin centered-message"><span translate>general._select.an</span> <span translate>entities.asset.modelName</span></h1></div><div ng-class="{transparent: !ctrl.keysLength(ctrl.assetsSelected) || ctrl.loading}"><div class="panel panel-inverse" ng-repeat="asset in ctrl.assetsSelected" ng-if="asset"><header class="panel-heading"><h2>{{asset.name}}</h2><div class="panel-toolbars pull-right" ng-show="asset.selectedTags.length"><div class="panel-toolbar"><button class="btn btn-default btn-xs" ng-click="ctrl.showTagSelection(asset)" translate>buttons.add_remove</button></div></div></header><div class="panel-body no-padding"><div class="charts row no-space"><div class="text-center" ng-show="!asset.selectedTags.length" style="height: 240px; line-height: 210px"><button class="btn btn-default btn-lg" ng-click="ctrl.showTagSelection(asset)"><span translate>general._add._</span> <span translate>entities.sensor.modelNamePl</span></button></div><time-series-chart ng-repeat="tag in asset.selectedTags" sensor="tag" asset="asset.id"></time-series-chart></div></div></div></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
      controller: TimeSeriesController,
      controllerAs: 'ctrl',
      bindings: {
        $transition$: '<',
        assets: '<'
      }
    });

  function TimeSeriesController (assetService, utils, $uibModal, $timeout) {
    let vm = this;
    vm.$onInit = function () {
      vm.assetsTree = vm.assets.tree;
      vm.assets = vm.assets.assets;
      vm.assetsSelected = {};

      vm.onSelectAsset = onSelectAsset;
      vm.showTagSelection = showTagSelection;

      vm.keysLength = function (obj) {
        return Object.keys(obj).length;
      };
    };

    function onSelectAsset (selected) {
      $timeout(function () {
        if (!Array.isArray(selected) || selected.length === 0) {

          vm.assetsSelected = {};
          return;
        }

        let removeUnselected = function () {
          for (let assetId in vm.assetsSelected) {
            let exist = selected.find(current => current === assetId);
            if (!exist) {
              delete vm.assetsSelected[assetId];
            }
          }
        };

        let count = 0;
        let notLoaded = selected.filter(function (assetId) {
          return vm.assetsSelected[assetId] === undefined;
        });

        if (notLoaded.length) {
          vm.loading = true;
          notLoaded.forEach(function (assetId) {
            vm.assetsSelected[assetId] = null;
            assetService
              .findById(assetId, {
                include: {
                  relation: 'sensors',
                  scope: {
                    fields: ['id', 'name', 'type', 'enabled', 'unit']
                  }
                },
                fields: ['id', 'name', 'customerId', 'currentStateId']
              })
              .then(function (asset) {
                vm.assetsSelected[assetId] = asset;
                count++;
                if (count === notLoaded.length) {
                  removeUnselected();
                  vm.loading = false;
                }
              })
              .catch(function (err) {
                console.log(err);
                count++;
                delete vm.assetsSelected[assetId];
                if (count === notLoaded.length) {
                  removeUnselected();
                  vm.loading = false;
                }
              });
          });
        } else {
          removeUnselected();
        }
      });
    }

    function showTagSelection (asset) {
      let instance = $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          component: 'assetTagSelection',
          size: 'lg',
          resolve: {
            asset: function () {
              return asset;
            }
          }
        });

      instance
        .result
        .then(function (result) {
          asset.selectedTags = result;
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
})();
