;(function () {
  'use strict';

  let angular = window.angular;

  AssetTemplateService.$inject = ['Customer', 'AssetTemplate', '$q', '$state', 'iconService', 'AppUtils', 'assetUtilsService'];

  angular
    .module('appServices')
    .service('AssetTemplateService', AssetTemplateService);

  function AssetTemplateService (Customer, AssetTemplate, $q, $state, iconService, appUtils, assetUtilsService) {
    return {
      findById: findById,
      find: find,
      getAssetTemplateTree: getAssetTemplateTree,
      getAssetTemplateTreeNode: assetUtilsService.getAssetTreeNode,
      getTemplatePictureUrl: getTemplatePictureUrl
    };

    function findById (assetTemplateId, filter) {
      filter = filter || {};
      filter.where = { id: assetTemplateId };

      let params = $state.params;
      let defer = $q.defer();
      Customer
        .assetTemplates({
          id: params.customerId,
          filter: filter
        })
        .$promise
        .then(assetTemplates => {
          if (!assetTemplates || !assetTemplates.length) {
            const err = new Error('Not Found');
            err.code = 'NOT_FOUND';

            throw err;
          }
          defer.resolve(assetTemplates[0]);
        })
        .catch(err => {
          defer.reject(err);
        });

      return defer.promise;
    }

    function find (filter) {
      filter = filter || {};

      let params = $state.params;
      let defer = $q.defer();
      Customer
        .prototype$__get__assetTemplates({
          id: params.customerId,
          nk: params.projectId,
          filter: filter
        })
        .$promise
        .then(assetTemplates => {
          defer.resolve(assetTemplates);
        })
        .catch(err => {
          defer.reject(err);
        });

      return defer.promise;
    }

    function getAssetTemplateTree (assetTemplates) {
      return assetUtilsService.getAssetTree(assetTemplates, 'AssetTemplate');
    }

    function getTemplatePictureUrl (templateId) {
      return assetUtilsService.getImageUrl(templateId, 'picture', 'AssetTemplate');
    }
  }
})();
