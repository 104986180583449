;(function () {
  'use strict';

  let $ = window.$;
  let angular = window.angular;

  RetailTraceController.$inject = ['$element', '$timeout', '$rootScope', 'SensorService'];

  angular
    .module('app')
    .component('retailTraceDisplay', {
      controller: RetailTraceController,
      bindings: {
        traces: '<',
        floorMasking: '<',
        mode: '=?', // display or config
        backgroundSrc: '=',
        // asset: '=',
      },
      template:'<div class="row retail-tracking"><div class="col-xs-12 col-ms-12 col-sm-12 col-md-8"><div class="well well-sm well-light"><div class="well-title"><i class="fas fa-video fa-fw"></i> Escena</div><div class="tracking-bg-container"><div class="background"><img ng-src="{{$ctrl.backgroundSrc}}"></div><div style="position: absolute; top:0; bottom:0; left: 0; right: 0" id="lines-container"></div></div></div></div><div class="col-xs-12 col-ms-12 col-sm-12 col-md-4"><div ng-if="$ctrl.mode === \'display\'"><div class="well well-sm well-light"><div class="well-title"><i class="fas fa-bars fa-fw"></i> Conteo</div><div ng-repeat="trace in $ctrl.traces" class="count-item" ng-class="{hovered: trace.hovered}" ng-mouseover="$ctrl.lineIn(trace)" ng-mouseleave="$ctrl.lineOut(trace)"><div><i class="fas fa-minus fa-fw"></i> <strong>{{trace.sensor.name}}</strong></div><hr><div class="row"><div class="col-xs-6"><i class="fas fa-long-arrow-down fa-fw"></i> <span class="pull-right">{{$ctrl.lastValues[trace.sensor.id].content.forward}}</span></div><div class="col-xs-6"><i class="fas fa-long-arrow-up fa-fw"></i> <span class="pull-right">{{$ctrl.lastValues[trace.sensor.id].content.backward}}</span></div></div></div></div></div><div ng-if="$ctrl.mode === \'config\'"><div class="well well-sm well-light"><div class="well-title"><i class="fas fa-bars fa-fw"></i> Elementos</div><div ng-repeat="trace in $ctrl.traces" class="count-item" ng-class="{hovered: trace.hovered}" ng-mouseover="$ctrl.lineIn(trace)" ng-mouseleave="$ctrl.lineOut(trace)"><i class="fas fa-minus fa-fw"></i> <strong>{{trace.sensor.name}}</strong></div><div class="count-item" ng-if="$ctrl.floorMasking" ng-class="{hovered: $ctrl.floorMasking.hovered}" ng-mouseover="$ctrl.maskIn($ctrl.floorMasking)" ng-mouseleave="$ctrl.maskOut($ctrl.floorMasking)"><i class="fas fa-square fa-fw"></i> <strong>{{$ctrl.floorMasking.name}}</strong></div></div></div></div></div>'
    });

  function RetailTraceController (element, $timeout, $rootScope, SensorService) {
    let vm = this;

    vm.$onInit = function () {
      vm.loading = true;
      vm.mode = vm.mode || 'display';

      vm.lineIn = lineIn;
      vm.lineOut = lineOut;
      vm.maskIn = maskIn;
      vm.maskOut = maskOut;

      let container = element.find('.tracking-bg-container');
      let background = container.find('.background');
      let img = container.find('img');

      if (vm.mode === 'display') {
        vm.lastValues = {};
        vm.offIOData = $rootScope.$on('io:data', onNewData);
      }

      if (img.attr('src')) {
        img.on('load', () => {

          initCanvas(container, background, img.naturalHeight / img.naturalWidth);
        });
      } else {
        initCanvas(container, background, 385 / 640, true);
      }
    };

    vm.$onDestroy = function () {
      if (vm.offIOData) {
        vm.offIOData();
      }
    };

    function onNewData (event, data) {
      data.content.from = new Date(data.content.from);
      let current = vm.lastValues[data.tagId];
      if (current && current.from > data.content.from) {
        return;
      }
      vm.lastValues[data.tagId] = data.content;
    }

    function loadData (tagId) {
      SensorService
        .getLastData(tagId)
        .then(last => {
          onNewData(null, {
            tagId: tagId,
            content: last
          });
        })
        .catch(err => {
        });
    }

    function initCanvas (container, background, aspectRatio, setHeight) {
      let width = Math.round(container.width() * 100) / 100;
      width = width < 340 ? 340 : width;
      width = width > 640 ? 640 : width;

      let height = Math.round(width * aspectRatio * 100) / 100;

      background.width(width);

      if (setHeight) {
        background.height(height);
      }

      let paper = new Raphael(element.find('#lines-container')[0], width, height);

      if (vm.floorMasking) {
        renderMasking(vm.floorMasking, paper, width / 640);
      }

      vm.traces.forEach(trace => {
        renderTrace(trace, paper, width / 640);
        if (trace.sensor) {
          loadData(trace.sensor.id);
        }
      });
    }

    function renderMasking (mask, paper, ratio) {
      let points = mask.point;

      let path = '';
      points.forEach((point, index) => {
        let x = applyRatio(point.x, ratio);
        let y = applyRatio(point.y, ratio);
        if (index === 0) {
          path = `M${x},${y}`;
        } else {
          path += `L${x},${y}`;
        }
      });
      path += 'Z';

      let strokeWidth = applyRatio(2, ratio);
      strokeWidth = strokeWidth < 1 ? 1 : strokeWidth;

      path = paper
        .path(path)
        .attr({ stroke: '#c17f3e', 'stroke-width': strokeWidth, fill: '#e9b47f', 'fill-opacity': 0.1 });

      mask.figure = path;

      path.hover(function hIn () {
        maskIn(mask);
      }, function hOut () {
        maskOut(mask);
      });
    }

    function renderTrace (trace, paper, ratio) {
      let path = trace.path;
      let x1 = applyRatio(path.x1, ratio);
      let x2 = applyRatio(path.x2, ratio);
      let y1 = applyRatio(path.y1, ratio);
      let y2 = applyRatio(path.y2, ratio);

      let mid = { x: (x2 + x1) / 2, y: (y2 + y1) / 2 };

      let dx = x2 - x1;
      let dy = y2 - y1;
      let len = Math.sqrt(dx * dx + dy * dy);
      dx = dx / len;
      dy = dy / len;

      let angle = Math.atan2(dy, dx);
      let arrowWidth = applyRatio(13, ratio);
      let start = { x: mid.x - arrowWidth * dy, y: mid.y + arrowWidth * dx };

      let p1 = { x: start.x + Math.cos(angle), y: start.y + Math.sin(angle) };
      let p2 = { x: start.x - 2 * Math.sin(Math.PI - angle), y: start.y - 2 * Math.cos(Math.PI - angle) };
      let p3 = { x: start.x - Math.cos(angle), y: start.y - Math.sin(angle) };

      path = `M${x1},${y1}L${x2},${y2}`; // Line
      path += `M${start.x},${start.y}L${p1.x},${p1.y}L${p2.x},${p2.y}L${p3.x},${p3.y}L${start.x},${start.y}`; // Arrow

      let strokeWidth = applyRatio(6, ratio);
      strokeWidth = strokeWidth < 2 ? 2 : strokeWidth;
      path = paper
        .path(path)
        .attr({ stroke: '#497e01', 'stroke-width': strokeWidth });

      trace.line = path;
      path
        .hover(function hIn () {
          lineIn(trace);
        }, function hOut () {
          lineOut(trace);
        });
    }

    function applyRatio (number, ratio) {
      const value = Math.round(number * ratio * 10000) / 10000;
      return value >= 0 ? value : 0;
    }

    function lineOut (trace) {
      if (trace.line) {
        trace.line.attr({
          stroke: '#497e01',
          fill: '#497e01'
        });
      }

      $timeout(function () {
        trace.hovered = false;
      });
    }

    function lineIn (trace) {
      if (trace.line) {
        trace.line.attr({
          stroke: '#ff4a4a',
          fill: '#ff4a4a'
        });
      }

      $timeout(function () {
        trace.hovered = true;
      });
    }

    function maskIn (mask) {
      if (mask.figure) {
        mask.figure.attr({
          stroke: '#ff4a4a',
          fill: '#ff4a4a'
        });
      }

      $timeout(function () {
        mask.hovered = true;
      });
    }

    function maskOut (mask) {
      if (mask.figure) {
        mask.figure.attr({
          stroke: '#c17f3e',
          fill: '#e9b47f'
        });
      }

      $timeout(function () {
        mask.hovered = false;
      });
    }
  }
})();
