(() => {
  Controller.$inject = [
    '$scope',
    '$element',
    '$timeout',
    'EdgeAgentService',
    '$translate',
    'AppUtils',
    '$rootScope',
  ];

  angular.module('app').component('edgeAgentJson', {
    template:'<div ng-show="!ctrl.generateError"><div class="relative"><div class="form-group marginB0" ng-class="{\'has-error\': ctrl.schemaError, transparent: ctrl.showDiff}"><label class="clearfix" for="json-container" style="display: block"><span ng-if="!ctrl.hideLabel"><span translate>general.configuration</span> <span class="text-danger">*</span></span><div class="pull-right"><span class="small marginR10" style="font-weight: normal"><span translate>form.validation.disable</span>:</span><toggle-switch ng-model="ctrl.disableValidation" style="display: inline-block"></toggle-switch></div></label><div id="json-container" style="height: 550px" ng-style="{height: (ctrl.height || 550) + \'px\'}"></div><div class="help-block"><span>{{ctrl.schemaError.message}}</span></div></div><div class="absolute" style="overflow-y: auto" ng-if="ctrl.showDiff"><json-diff json1="ctrl.leftJson" json2="ctrl.rightJson"></json-diff></div><spinner show="ctrl.loading"></spinner></div><hr class="marginTB5"><div class="marginT5 text-right" ng-if="!ctrl.onlyShowChanges"><a class="cursor-pointer" ng-click="ctrl.toggleChanges()" ng-class="{disabled: ctrl.loading}"><span translate>{{ctrl.showDiff ? \'buttons.hide\' : \'buttons.show\'}}</span> <span translate>general.changes</span></a></div></div><div class="alert alert-warning text-center no-margin" ng-if="ctrl.generateError"><strong translate>entities.edgeagent._errors.couldNotGenerate</strong><div>{{ctrl.generateError}}</div></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      device: '<',
      edgeAgent: '=',
      templateTags: '<',
      height: '<?',
      hideLabel: '<?',
      customValidation: '&',
      onlyShowChanges: '<?', // true/false para solo mostrar os cambios
      checked: '=?', // se utiliza para habilitar un boton de guardar al ver los cambios en el json
      saveAgent: '=?', // se utiliza para habilitar un boton de guardar al tener un json diferente al original y sin errores
      originalEdgeAgent: '<?', // agente original, se utiliza para el modo "onlyShowChanges"
      jsonModified: '=',
      blockFirstRecreate: '=?', // booleano para bloquear la re-creacion del agente despues de guardar cambios ya que este se recarga con un ng-if
    },
  });

  function Controller($scope, $element, $timeout, EdgeAgentService, $translate, utils, $rootScope) {
    const vm = this;

    const defaultAgent = {
      broker: {
        host: 'localhost',
        port: 1883,
        namespace: 'default',
      },
      modules: {},
      routes: {},
      moduleParameters: {},
    };

    vm.$onInit = function () {
      vm.saveAgentLock = false;
      vm.tempAgent = angular.copy(vm.edgeAgent);
      vm.originalAgent = angular.copy(vm.edgeAgent);
      vm.firstTime = true;
      vm.toggleChanges = toggleChanges;
      vm.updateEdgeAgent = updateEdgeAgent;
      vm.disableValidation = false;
      vm.blockFirstRecreate = false || vm.blockFirstRecreate;
      //funcion que actualiza el agente edge por defecto con los datos del agente entregado
      if (vm.saveAgent != undefined) {
        updateDefaultAgent();
      }

      const editorOptions = {
        mode: 'code',
        // mainMenuBar: false,
        schema: EdgeAgentService.getEdgeAgentSchema(),
        onValidate: validate,

        onChange: (e) => {
          //el onChange se utiliza solo si se está enlazando una variable para permitir/megar e guardado
          if (vm.saveAgent !== undefined) {
            try {
              // verifica que sean diferentes y que se respete el schema del json
              if (
                !compareEdgeAgents() &&
                (vm.jsonEditor.validateSchema(vm.jsonEditor.get()) || vm.disableValidation)
              ) {
                $timeout(() => {
                  vm.saveAgent = true;
                  vm.saveAgentLock = false;
                }, 100);
              } else {
                $timeout(() => {
                  vm.saveAgent = false;
                }, 100);
              }
            } catch (error) {
              $timeout(() => {
                vm.saveAgentLock = true;
                vm.saveAgent = false;
              });
            }
          }
        },
      };

      vm.loading = true;
      //si solo se están mostrando cambios, se utiliza toggleChanges
      if (vm.onlyShowChanges !== undefined) {
        if (vm.onlyShowChanges) {
          toggleChanges();
        }
        vm.loading = false;
      } else {
        // si no, significa que es el funcionamiento normal de componente
        recreateAgent()
          .then((interpolated) => {
            vm.generateError = null;
            if (interpolated) {
              for (let prop in interpolated) {
                vm.tempAgent[prop] = interpolated[prop];
              }
            }

            const jsonContainer = $element.find('#json-container')[0];
            if (!jsonContainer) {
              return;
            }

            vm.jsonEditor = new window.JSONEditor(jsonContainer, editorOptions);
            if (vm.editorInstance) {
              vm.editorInstance.instance = vm.jsonEditor;
              vm.editorInstance.target = jsonContainer;
            }
            let json;
            if (!vm.blockFirstRecreate) {
              vm.blockFirstRecreate = true;
              json = angular.copy(defaultAgent);
              if (vm.edgeAgent) {
                json = Object.assign({}, json, EdgeAgentService.getAgentBody(vm.tempAgent));
              }
            } else {
              json = angular.copy(EdgeAgentService.getAgentBody(vm.edgeAgent));
            }

            vm.jsonEditor.set(json);
            vm.firstJSon = angular.copy(json);
            vm.loading = false;
            // se actualiza el agente con las propiedades que se cargan en el json
            if (vm.saveAgent != undefined) {
              updateEdgeAgent();
            }
            if (vm.saveAgent !== undefined) {
              let dif = window.jsondiffpatch.diff(
                json,
                EdgeAgentService.getAgentBody(vm.originalAgent)
              );
              if (dif) {
                vm.saveAgent = true;
              }
            }

            $rootScope.$emit('ds:agent-generated-error', null);
          })
          .catch((err) => {
            vm.loading = false;
            vm.generateError = err;

            $rootScope.$emit('ds:agent-generated-error', err);

            console.log(err);
          });

        $scope.$on('ds:agent-validate-content', function () {
          $rootScope.$emit('ds:agent-validate-content-after', validateAgentContent());
        });
      }

      // estos watchs funcionan solo cuando se requiere habilitar/deshabilitar un boton para guardar los cambios
      vm.regenerateTimeout = null;
      if (vm.saveAgent !== undefined) {
        // este watch vigila la variable que desabilita la validacion
        $scope.$watch('ctrl.disableValidation', function (newVal, oldVal) {
          try {
            if (newVal && vm.jsonEditor.get()) {
              $timeout(() => {
                vm.saveAgent = true;
              }).$promise;
            } else if (!newVal) {
              if (!compareEdgeAgents() && vm.jsonEditor.validateSchema(vm.jsonEditor.get())) {
                $timeout(() => {
                  vm.saveAgent = true;
                  vm.saveAgentLock = false;
                }, 100);
              } else {
                $timeout(() => {
                  vm.saveAgent = false;
                }, 100);
              }
            }
          } catch (err) {}
        });

        //este watch regenera el agente cuando hay cambio en los assets relacionados
        $scope.$watchCollection('ctrl.edgeAgent.assets', function (newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            if (!vm.saveAgentLock) {
              if (!vm.firstTime) {
                if (vm.regenerateTimeout) {
                  $timeout.cancel(vm.regenerateTimeout);
                }
                vm.tempAgent = angular.copy(vm.edgeAgent);
                vm.regenerateTimeout = $timeout(() => {
                  recreateAgent()
                    .then((interpolated) => {
                      if (interpolated) {
                        for (let prop in interpolated) {
                          vm.tempAgent[prop] = interpolated[prop];
                        }
                      }
                      if (!vm.jsonEditor) {
                        return;
                      }
                      let json = angular.copy(defaultAgent);

                      if (vm.edgeAgent) {
                        json = Object.assign({}, json, EdgeAgentService.getAgentBody(vm.tempAgent));
                      }

                      vm.jsonEditor.set(json);
                      vm.loading = false;
                      if (vm.saveAgent !== undefined) {
                        if (!vm.saveAgentLock) {
                          $timeout(() => {
                            vm.saveAgent = true;
                          }).$promise;
                        }
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }, 2000);
              }
            }
          } else {
            //console.log('Los arreglos son iguales');
          }
          vm.firstTime = false;
        });
      }
    };

    vm.$onChanges = function (changes) {
      if (!vm.jsonEditor) {
        return;
      }
      if (changes.edgeAgent) {
        vm.tempAgent = angular.copy(vm.edgeAgent);
        let json = angular.copy(defaultAgent);

        if (vm.edgeAgent) {
          json = Object.assign({}, json, EdgeAgentService.getAgentBody(vm.tempAgent));
        }

        vm.jsonEditor.update(json);

        if (vm.saveAgent !== undefined) {
          $timeout(() => {
            vm.saveAgent = true;
          }).$promise;
        }
      }
    };

    vm.$onDestroy = function () {
      if (vm.jsonEditor) {
        vm.jsonEditor.destroy();
      }
    };

    function validateAgentContent() {
      vm.schemaError = null;
      let error;
      let edgeAgent;
      try {
        edgeAgent = angular.copy(vm.jsonEditor.get());
        const isValid = vm.disableValidation || vm.jsonEditor.validateSchema(edgeAgent);
        if (!isValid) {
          error = new Error($translate.instant('entities.edgeagent._errors.invalid'));
        } else {
          let errors = validate(edgeAgent);
          if (errors) {
            error = new Error($translate.instant('entities.edgeagent._errors.invalid'));
          }
        }
      } catch (e) {
        console.log(e);
        error = new Error($translate.instant('general._errors.invalidJSON'));
      }

      if (error) {
        vm.schemaError = error;
        console.log(error);
        return { valid: false, edgeAgent };
      }
      return { valid: true, edgeAgent };
    }

    function validate(json) {
      // para habilitar el guardado se verifica si se cumple el schema o si esta desactivada la validacion
      if (vm.saveAgent !== undefined) {
        if (!vm.jsonEditor.validateSchema(vm.jsonEditor.get()) && !vm.disableValidation) {
          $timeout(() => {
            vm.saveAgent = false;
          }).$promise;
        }
      }
      if (vm.jsonModified != null) {
        vm.jsonModified = JSON.stringify(json);
        $rootScope.$emit('contextualization:json-modified', vm.jsonModified);
      }
      const errors = [];
      if (
        (!json.routes || !Object.keys(json.routes).length) &&
        (!json.moduleParameters || !Object.keys(json.moduleParameters).length)
      ) {
        return null;
      }

      if (json.routes && !vm.disableValidation) {
        for (let module in json.routes) {
          try {
            if (!json.modules[module]) {
              errors.push({
                path: ['routes', module],
                message: $translate.instant('entities.edgeagent._errors.moduleNotFound', {
                  module: module,
                }),
              });
            }
          } catch (err) {
            $timeout(() => {
              vm.saveAgent = false;
            }).$promise;
          }
        }
      }

      if (json.moduleParameters && !vm.disableValidation) {
        for (let module in json.moduleParameters) {
          if (!json.modules[module]) {
            errors.push({
              path: ['moduleParameters', module],
              message: $translate.instant('entities.edgeagent._errors.moduleNotFound', {
                module: module,
              }),
            });
          }
        }
      }

      if (vm.customValidation) {
        const customErrors = vm.customValidation({ agent: json });
        if (Array.isArray(customErrors)) {
          Array.prototype.push.apply(errors, customErrors);
        }
      }
      // si no hay errores se habilita e guardado
      if (vm.saveAgent !== undefined) {
        if (errors.length === 0) {
          vm.saveAgentLock = false;
          //update del agente
          vm.updateEdgeAgent();
        } else {
          $timeout(() => {
            vm.saveAgentLock = true;
            vm.saveAgent = false;
          }).$promise;
        }
      }

      return errors.length ? errors : null;
    }

    // funcion que actualiza el agente default con los datos que trae el agente
    function updateDefaultAgent() {
      Object.entries(defaultAgent).forEach((prop) => {
        defaultAgent[prop[0]] = angular.copy(vm.edgeAgent[prop[0]]);
      });
    }

    // funcion que actuliza el agente con los datos del json
    function updateEdgeAgent() {
      let jsonText = JSON.parse(vm.jsonEditor.getText());
      for (var key in jsonText) {
        vm.edgeAgent[key] = angular.copy(jsonText[key]);
      }
      if (vm.firstTime) vm.firstTime = false;
    }

    //funcion que conpara el agente original con lo que está en el json actualmente
    function compareEdgeAgents() {
      let jsonText = JSON.parse(vm.jsonEditor.getText());
      let a = JSON.stringify(vm.firstJSon);
      let b = JSON.stringify(jsonText);
      return a == b;
    }

    function toggleChanges() {
      if (vm.showDiff) {
        vm.showDiff = false;
        return;
      }
      if (vm.checked != undefined) vm.checked = true;
      //si está en el modo "onlyShowChanges", muestra los cambios del agente original(antes de cualquier cambio) vs el agente que se le entrega al componente
      if (vm.onlyShowChanges !== undefined) {
        $timeout(() => {
          vm.leftJson = EdgeAgentService.getAgentBody(vm.originalEdgeAgent);
          if (vm.jsonModified != undefined) {
            vm.rightJson = JSON.parse(vm.jsonModified);
          } else {
            vm.rightJson = EdgeAgentService.getAgentBody(vm.edgeAgent);
          }
          vm.showDiff = true;
        });
      } else {
        let { valid, edgeAgent } = validateAgentContent();
        $timeout(() => {
          vm.leftJson = EdgeAgentService.getAgentBody(vm.originalAgent);
          vm.rightJson = edgeAgent;
          vm.showDiff = true;
          if (vm.checked != undefined) vm.checked = true;
        });
      }
    }

    function recreateAgent() {
      vm.err = null;
      if (!vm.tempAgent || !vm.tempAgent.templateTagId) {
        return utils.Promise.resolve();
      }
      const [templateId, tagId] = vm.tempAgent.templateTagId.split('/');
      let template;
      if (vm.templateTags) {
        template = vm.templateTags.find((current) => current._id === vm.tempAgent.templateTagId);
      }
      if (!template) {
        return utils.Promise.resolve();
      }

      template = EdgeAgentService.getAgentBody(template);
      const image =
        typeof vm.tempAgent.image === 'object'
          ? vm.tempAgent.image.name + ':' + vm.tempAgent.image.version
          : vm.tempAgent.image;

      return EdgeAgentService.recreateAgentFromTemplate(
        templateId,
        tagId,
        {
          name: vm.tempAgent.name,
          sharedPath: vm.tempAgent.sharedPath,
          image: image,
        },
        vm.device,
        vm.tempAgent.assets || []
      );
    }
  }
})();
