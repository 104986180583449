(function () {
  'use strict';

  let angular = window.angular;

  RoundsReportRouter.$inject = ['$stateProvider'];

  angular.module('app').config(RoundsReportRouter);

  function RoundsReportRouter($stateProvider) {
    $stateProvider
      .state('member.var.show.customer.show.project.roundsreport', {
        url: '/rounds-report',
        redirectTo: 'member.var.show.customer.show.project.roundsreport.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"modules.rounds-report.breadcrumb.home" | translate}}',
        },
        resolve: {
          module: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/app/dashboard-common.module.min.js',
                window.assetsPath + '/js/app/report-common.module.min.js',
                window.assetsPath + '/js/app/rounds-report.module.min.js',
                window.assetsPath + `/css/rounds-report.style-${style}.css`,
                window.assetsPath + '/js/vendors/pdf-vendors.min.js',
              ]);
            },
          ],
          adminUsers: [
            'Var',
            '$transition$',
            'AppUtils',
            function (Var, $transition$, utils) {
              let params = $transition$.params();
              return Var.admins({ id: params.varId }).$promise.catch((err) => {
                throw utils.getHTTPError(err, {
                  redirectTo: 'member.var.show.customer.show.home',
                  params: { customerId: params.varId },
                });
              });
            },
          ],
          users: [
            'Customer',
            '$transition$',
            'AppUtils',
            function (Customer, $transition$, utils) {
              let params = $transition$.params();
              return Customer.managers({ id: params.customerId }).$promise.catch((err) => {
                throw utils.getHTTPError(err, {
                  redirectTo: 'member.var.show.customer.show.home',
                  params: { customerId: params.customerId },
                });
              });
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.roundsreport.home', {
        url: '/',
        component: 'roundsReportHome',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"modules.rounds-report.breadcrumb.tab" | translate}}',
        },
        resolve: {
          reports: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { where: { type: 'ROUND_OF_GUARD' } };
              return EntityService.find('Report', options);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.roundsreport.show', {
        url: '/:reportId',
        authenticated: true,
        redirectTo: 'member.var.show.customer.show.project.roundsreport.show.editor',
        template:'<div class="page-header page-header-sm"><div class="container-lg"><ul class="nav nav-tabs nav-tabs-sm nav-justified"><li ui-sref-active="active" style="width: 99%"><a ui-sref=".view"><div class="nowrap" translate>modules.rounds-report.tabs.view</div></a></li><li ui-sref-active="active" class="bg-warning"><a ui-sref=".editor"><i class="fas fa-cog"></i></a></li></ul></div></div><div class="absolute main"><ui-view></ui-view></div>',
        ncyBreadcrumb: {
          label: '{{$resolve.report.name}}',
        },
        resolve: {
          report: [
            'EntityService',
            'AppUtils',
            '$transition$',
            function (EntityService, utils, $transition$) {
              return loadRoundsReport(EntityService, utils, $transition$.params());
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.roundsreport.show.view', {
        url: '/?from&to&limit',
        component: 'roundsReportView',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"modules.rounds-report.breadcrumb.report" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.roundsreport.show.editor', {
        url: '/editor',
        component: 'roundsReportEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"modules.rounds-report.breadcrumb.editor" | translate}}',
        },
      });

    function loadRoundsReport(EntityService, utils, params) {
      const defaultInclude = [
        'managers',
        {
          relation: 'assets',
          scope: {
            fields: ['id', 'name', 'type', 'assetId'],
            include: [
              {
                relation: 'sensors',
                scope: {
                  fields: ['id', 'name', 'parameters', 'assetId', 'type'],
                  where: {
                    type: 'ObjectRecognition',
                  },
                },
              },
            ],
          },
        },
      ];

      const options = angular.copy(params);
      options.entityId = options.reportId;
      options.filter = {
        where: { type: 'ROUND_OF_GUARD' },
        include: defaultInclude,
      };

      return EntityService.findById('Report', options)
        .then((report) => {
          return report;
        })
        .catch((err) => {
          console.log(err);
          throw utils.getHTTPError(err, {
            redirectTo: redirect[type],
            params: { customerId: params.customerId, projectId: params.projectId },
          });
        });
    }
  }
})();
