angular.module('app').directive('filterMenu', function () {
  return {
    restrict: 'EA',
    scope: {
      onClick: '&',
      disabled: '=',
      hideButton: '<',
      hideDesktop: '<',
      modalTitle: '@',
      icon: '@',
    },
    transclude: true,
    controllerAs: 'ctrl',
    controller: function () {
      var vm = this;
      vm.modalCtrl = {};
      vm.openComponentModal = function () {
        vm.modalCtrl.openModal();
      };
    },
    template: `
			<!-- Mobile -->
			<div class="filter-menu mobile-filter-menu-bar">
				<div class="filter-menu-bar-btn-container">
					<button
						type="button"
						class="btn btn-default filter-menu-bar-btn"
						ng-click="ctrl.openComponentModal()"
					>
						<span id="filter-btn-text" translate ng-if="!modalTitle"> general.filter </span>
						<span id="filter-btn-text" ng-if="modalTitle" ng-repeat = "title in modalTitle.split(' ')">
							<span translate>{{ title }}</span>
						</span>
						<i class="fas" ng-class="icon? icon : 'fa-filter'"></i>
					</button>
				</div>
			</div>
			<filter-modal 
				control="ctrl.modalCtrl" 
				on-click="onClick({value: 'value'})" 
				disabled="disabled" 
				hide-button="hideButton" 
				modal-title="modalTitle"
			>
				<ng-transclude></ng-transclude>
			</filter-modal>
			<!-- Desktop -->
			<div
				ng-class="{'hide-desktop': hideDesktop, 'show-desktop': !hideDesktop }"
				id="desktop-filter-menu-bar"
				class="filter-menu view-sidebar view-sidebar-sm view-sidebar-static view-sidebar-right"
			>
				<ul class="navbar-nav navbar-nav-vertical">
					<ng-transclude></ng-transclude>
				</ul>
				<div class="marginT20 marginLR10" ng-if="!hideButton">
					<button
						class="btn btn-primary btn-block"
						ng-click="onClick({value: ''})"
						ng-disabled="disabled"
						translate
					>
							buttons.load
					</button>
				</div>
			</div>
      `,
  };
});
