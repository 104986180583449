;(() => {
  const angular = window.angular;

  angular
    .module('app')
    .directive('ngIndeterminate', [function () {
      return {
        compile: function (tElm, tAttrs) {
          if (!tAttrs.type || tAttrs.type.toLowerCase() !== 'checkbox') {
            return angular.noop;
          }

          return function ($scope, elm, attrs) {
            $scope.$watch(attrs.ngIndeterminate, function (newVal) {
              elm[0].indeterminate = !!newVal;
            });
          };
        }
      };
    }]);
})();
