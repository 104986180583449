;(function () {
  'use strict';

  const angular = window.angular;

  AppRouter.$inject = ['$urlRouterProvider'];

  angular
    .module('app')
    .config(AppRouter);

  function AppRouter ($urlRouterProvider) {
    $urlRouterProvider
      .otherwise('/member/home');
  }
})();
