(function () {
  'use strict';
  const angular = window.angular;

  angular.module('app').config(CustomerRouter);

  CustomerRouter.$inject = ['$stateProvider'];

  function CustomerRouter($stateProvider) {
    $stateProvider
      .state('member.var.show.customer', {
        url: '/customer',
        authenticated: true,
        abstract: true,
        template: '<ui-view></ui-view>',
        ncyBreadcrumb: {
          skip: true,
        },
        resolve: {
          yoloClasses: [
            'StaticService',
            '$rootScope',
            (StaticService, $rootScope) => {
              return StaticService.YoloClasses.find().then((classes) => {
                $rootScope.yoloClasses = classes;
                return classes;
              });
            },
          ],
        },
      })
      .state('member.var.show.customer.show', {
        url: '/:customerId',
        authenticated: true,
        template: '<ui-view></ui-view>',
        redirectTo: 'member.var.show.customer.show.home',
        resolve: {
          projects: [
            'ProjectService',
            '$transition$',
            'navbarService',
            function (ProjectService, $transition$, navbarService) {
              let params = $transition$.params();
              return ProjectService.findProjects(params.customerId, {
                fields: { name: true, id: true },
              }).then((projects) => {
                navbarService.setProjects(projects);
                return projects;
              });
            },
          ],
          customer: [
            'Customer',
            '$transition$',
            'AppUtils',
            'navbarService',
            '$rootScope',
            function (Customer, $transition$, utils, navbarService, $rootScope) {
              let params = $transition$.params();
              return Customer.findById({
                id: params.customerId,
                filter: { include: ['container'] },
              })
                .$promise.then((customer) => {
                  navbarService.setCustomer(customer);
                  return customer;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, { redirectTo: 'member.home' });
                });
            },
          ],
          managerCount: [
            'Customer',
            '$transition$',
            (Customer, $transition$) => {
              let params = $transition$.params();
              return Customer.prototype$__count__managers({ id: params.customerId }).$promise.then(
                (response) => response.count
              );
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.customer.name}}',
        },
      })
      .state('member.var.show.customer.show.home', {
        url: '/',
        redirectTo: 'member.var.show.customer.show.home.projects',
        controller: [
          'customer',
          function (customer) {
            this.customer = customer;
          },
        ],
        controllerAs: 'ctrl',
        template:'<div class="page-header"><h1 class="no-margin"><i class="fas fa-id-card fa-fw"></i> <span class="header-text">{{ctrl.customer.name}}</span></h1><div class="container"><ul class="nav nav-tabs nav-justified"><li ui-sref-active="active"><a ui-sref="member.var.show.customer.show.home.projects" translate>entities.project.modelNamePl</a></li><li ui-sref-active="active"><a ui-sref="member.var.show.customer.show.home.profile" translate>entities.organization.customer.modelName</a></li></ul></div></div><div class="container"><ui-view></ui-view></div>',
        ncyBreadcrumb: { skip: true },
      })
      .state('member.var.show.customer.show.home.projects', {
        url: 'project',
        authenticated: true,
        component: 'customerProjects',
        ncyBreadcrumb: { label: '<span>{{"entities.project.modelNamePl"|translate}}</span>' },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.home.profile', {
        url: 'profile',
        authenticated: true,
        component: 'customerProfile',
        ncyBreadcrumb: { label: '<span>{{"entities._all.profile" | translate}}</span>' },
        resolve: {
          users: [
            'Customer',
            '$transition$',
            'AppUtils',
            function (Customer, $transition$, utils) {
              let params = $transition$.params();
              return Customer.managers({
                id: params.customerId,
                filter: { include: ['container'] },
              }).$promise.catch((err) => {
                throw utils.getHTTPError(err, {
                  redirectTo: 'member.var.show.customer.show.home',
                  params: { customerId: params.customerId },
                });
              });
            },
          ],
          countries: [
            'StaticService',
            function (StaticService) {
              return StaticService.Country.find();
            },
          ],
          timeZones: [
            'StaticService',
            function (StaticService) {
              return StaticService.TimeZone.find();
            },
          ],
        },
      })
      .state('member.var.show.customer.show.dataDisplay', {
        url: '/data-display/:projectId/:assetId/:sensorId?:dataId&:zoneFilter',
        component: 'monitoringDataDisplay',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Data Display',
        },
        resolve: {
          project: [
            'ProjectService',
            '$transition$',
            'navbarService',
            'AppUtils',
            function (ProjectService, $transition$, navbarService, utils) {
              let params = $transition$.params();
              return ProjectService.findById(params.customerId, params.projectId, {
                include: ['yoloClasses'],
                order: 'name ASC',
              })
                .then((project) => {
                  navbarService.setProject(project);
                  return project;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.var.show.customer.show.home',
                    params: { customerId: params.customerId },
                  });
                });
            },
          ],
        },
      });
  }
})();
