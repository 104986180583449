(function () {
  'use strict';

  let angular = window.angular;

  PublicRouter.$inject = ['$stateProvider'];

  angular.module('app').config(PublicRouter);

  function PublicRouter($stateProvider, utils) {
    $stateProvider
      .state('member', {
        url: '/member?style&access_token&user_id',
        redirectTo: 'member.home',
        authenticated: true,
        template:'<div ng-if="$root.systemMessage.show || $root.appErrorMessage" class="system-message"><div class="text-center no-margin alert alert-{{$root.systemMessage.level || \'info\'}}" ng-if="$root.systemMessage.show"><button class="close" ng-click="$root.systemMessage.show=false" ng-if="$root.systemMessage.allowClose"><i class="fas fa-times fa-fw" style="font-size: 15px"></i></button><div ng-bind-html="$root.systemMessage.text"></div></div><div class="text-center no-margin alert alert-{{$root.appErrorMessage.level || \'info\'}}" ng-if="$root.appErrorMessage.show"><button class="close" ng-if="$root.appErrorMessage.allowClose" ng-click="$root.appErrorMessage.show=false"><i class="fas fa-times fa-fw" style="font-size: 15px"></i></button><div ng-bind-html="$root.appErrorMessage.text"></div></div></div><div class="absolute" ng-style="{top: $root.systemMessage.show || $root.appErrorMessage.show? \'36px\': 0}"><nav-bar></nav-bar><div id="page-wrapper"><div class="absolute" style="overflow-y: auto"><ui-view></ui-view></div><spinner show="$root.viewLoading" css-class="\'dark\'"></spinner></div></div>',
        params: {
          style: { type: 'string', inherit: true },
          access_token: { type: 'string', inherit: true },
          user_id: { type: 'string', inherit: true },
        },
        resolve: {
          user: [
            'UserService',
            'AppUtils',
            '$rootScope',
            function (UserService, utils, $root) {
              return UserService.getCurrent()
                .then((current) => {
                  return UserService.findById(current.id);
                })
                .then((user) => {
                  user.isAdmin = true;
                  user.isSuperAdmin = true;
                  user.image = UserService.getUserImageUrl(user);
                  $root.user = user;
                  return user;
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, { redirectTo: 'public.login' });
                });
            },
          ],
          isAdmin: () => true,
          isSuperAdmin: () => true,
          vars: [
            'Var',
            function (Var) {
              return Var.find({ filter: { order: 'name ASC' } }).$promise.then((vars) => {
                return vars;
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '<i class="fas fa-home"></i>',
        },
      })
      .state('member.home', {
        url: '/home',
        authenticated: true,
        component: 'memberHome',
        ncyBreadcrumb: {
          label: 'Home',
        },
      });
  }
})();
