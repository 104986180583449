(function () {
  'use strict';

  let angular = window.angular;

  Router.$inject = ['$stateProvider'];

  angular.module('app').config(Router);

  function Router($stateProvider) {
    $stateProvider
      .state('member.var.show.customer.show.project.biPanel', {
        url: '/bi-panel',
        redirectTo: 'member.var.show.customer.show.project.biPanel.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Bi Panels',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassBIPanels');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.var.show.customer.invalidTool',
                  params: { tool: 'xompassBIPanels' },
                });
              }
            },
          ],
          module: [
            '$ocLazyLoad',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([window.assetsPath + '/js/app/bi-panel.module.min.js']);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.biPanel.home', {
        url: '/',
        component: 'biPanelHome',
        authenticated: true,
        resolve: {
          reports: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = Object.assign({ order: 'name ASC' }, $transition$.params());
              return EntityService.find('BiPanel', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.biPanel.dashboard', {
        url: '/:reportId',
        redirectTo: 'member.var.show.customer.show.project.biPanel.dashboard.viewer',
        component: 'biPanelDashboard',
        authenticated: true,
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const theme = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${theme}.min.js`,
              ]);
            },
          ],
          virtualGroups: [
            '$rootScope',
            'Customer',
            ($root, Customer) => {
              return Customer.prototype$__get__projects__virtualGroups({
                id: $root.customerId,
                nk: $root.projectId,
              }).$promise;
            },
          ],
          virtualExpressions: [
            '$rootScope',
            'VirtualExpressionService',
            function ($root, VirtualExpressionService) {
              return VirtualExpressionService.find($root.customerId, $root.projectId, {
                fields: ['id', 'name', 'description', 'expression', 'virtualGroupId'],
              });
            },
          ],
          report: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadBIPanel(EntityService, $transition$.params(), utils);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.report.name}}',
        },
      })

      .state('member.var.show.customer.show.project.biPanel.dashboard.viewer', {
        url: '/viewer',
        component: 'biPanelViewer',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Viewer',
        },
      })
      .state('member.var.show.customer.show.project.biPanel.dashboard.editor', {
        url: '/editor',
        component: 'biPanelEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Editor',
        },
      });

    function loadBIPanel(EntityService, params, utils) {
      const options = angular.copy(params);
      options.entityId = params.reportId;
      options.filter = {
        include: ['series'],
      };
      return EntityService.findById('BiPanel', options).catch((err) => {
        console.log(err);
        throw utils.getHTTPError(err, {
          redirectTo: 'member.var.show.customer.show.project.biPanel.home',
        });
      });
    }
  }
})();
