;(function () {
  'use strict';

  const angular = window.angular;

  VerifyEmailPassword.$inject = ['SuperAdmin', '$state', 'AppUtils', '$rootScope'];

  angular
    .module('app')
    .component('publicVerifyEmail', {
      template:'<section class="login-container"><div class="login-header"><div class="login-brand"></div><h3 class="no-margin" translate>views.public.verifyEmail.title</h3></div><div class="login-content"><form class="login-form" ng-submit="$ctrl.sendVerifyEmail()" name="$ctrl.verifyForm" ng-if="!$ctrl.success"><div class="form-group marginB20"><div class="input-group"><span class="input-group-addon"><i class="fas fa-envelope fa-fw"></i></span> <input ng-model="$ctrl.email" class="form-control" required name="email" type="email" translate-attr="::{placeholder: \'entities._all.email\'}"></div></div><button class="btn btn-login btn-lg btn-block" translate>buttons.send</button></form><div ng-if="$ctrl.success" class="text-center"><h3 class="text-success"><i class="far fa-check-circle fa-fw fa-2x"></i> <strong style="display: block" class="marginTB10" translate>views.public.verifyEmail.checkEmail</strong></h3><p class="marginB20 paddingB20" translate>views.public.verifyEmail.message</p></div><div class="text-center marginT20"><a ui-sref="public.login" translate>buttons.signin</a></div><div ng-if="$ctrl.requestError" class="alert alert-danger marginT20 marginB0 padding5 text-center">{{\'errors.\' + $ctrl.requestError | translate}}</div></div></section>',
      controller: VerifyEmailPassword
    });

  function VerifyEmailPassword (SuperAdmin, $state, utils, $root) {
    let vm = this;

    this.$onInit = function () {
      vm.sendVerifyEmail = sendVerifyEmail;
    };

    function sendVerifyEmail () {
      if (!vm.verifyForm.$valid) {
        return;
      }

      vm.requestError = null;
      vm.success = false;
      $root.appReady = false;
      SuperAdmin
        .verifyAccount({ email: vm.email })
        .$promise
        .then(function (result) {
          vm.success = true;
          $root.appReady = true;
        })
        .catch(function (err) {
          const error = utils.getHTTPError(err);
          vm.requestError = error.code || err.message;
          $root.appReady = true;
        });
    }
  }
})();
