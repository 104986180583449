(() => {
  const angular = window.angular;

  DetectionCropperController.$inject = [
    'AppUtils',
    'ObjectRecognitionService',
    'SensorService',
    '$translate',
    '$timeout',
  ];

  angular.module('app').component('detectionsCropper', {
    template:'<detection-crop-element detection="detection" ng-if="!detection.hidden && ctrl.image" image="ctrl.image" date="ctrl.data.from" canvas-height="ctrl.canvasHeight" show-caption="ctrl.showCaption" adjust-width="ctrl.adjustWidth" padding="ctrl.padding" box-mode="ctrl.sensor.type === \'FaceDetection\' || ctrl.sensor.type === \'FaceMaskDetection\' ? \'left-top\': \'center-middle\'" ng-repeat="detection in ctrl.detections"></detection-crop-element>',
    controllerAs: 'ctrl',
    controller: DetectionCropperController,
    bindings: {
      sensor: '<',
      data: '<',
      image: '<',
      showCaption: '<',
      adjustWidth: '<',
      canvasHeight: '<',
      padding: '<?',
    },
  });

  function DetectionCropperController(
    utils,
    ObjectRecognitionService,
    SensorService,
    $translate,
    $timeout
  ) {
    const vm = this;
    let firstChange = true;

    vm.$onInit = function () {
      if (!vm.image) {
        let src = getImageUrl(vm.data);
        loadImage(src);
      } else if (typeof vm.image === 'string') {
        loadImage(vm.image);
      } else {
        init();
      }
    };

    vm.$onChanges = function (changes) {
      if (firstChange) {
        firstChange = false;
        return;
      }

      if (changes.data) {
        let src = getImageUrl(vm.data);
        loadImage(src);
      }
    };

    function loadImage(src) {
      if (!src) {
        return;
      }
      vm.image = new window.Image();
      vm.image.src = src;
      vm.image.crossOrigin = 'anonymous';
      vm.image.onload = function () {
        init();
      };
      vm.image.onerror = function (err) {};
    }

    function getImageUrl(data) {
      if (!data || !data.content) {
        return;
      }
      const content = data.content;

      let image;
      if (vm.content?.files) {
        image = content.files.gif ? content.files.gif : content.files.image;
        image = Array.isArray(image) ? image[0] : image;
      }

      if (!image || !image.container || !image.name) {
        return;
      }

      return SensorService.getImageUrl(vm.sensor.id, image, data.from);
    }

    function init() {
      $timeout(() => {
        const classes = utils.arrayToObject(ObjectRecognitionService.getClasses(), 'value');

        const content = angular.copy(vm.data.content);
        vm.detections = [];
        if (content.objects) {
          content.objects.filter((current) => {
            if (classes[current.class]) {
              current._label = $translate.instant(
                'entities.sensor.__classes.' + current.class.replace(/ /g, '')
              );
              vm.detections.push(current);
            }
          });
        }

        if (content.faces) {
          content.faces.forEach((current) => {
            if (current.mask !== undefined) {
              current._type = 'mask';
              const hasMask = current.mask && current.mask.probability;
              current._label = $translate.instant(
                `entities.sensor.__faces.${hasMask ? 'hasMask' : 'notHasMask'}`
              );
              current.color = hasMask ? 'success' : 'danger';
            } else {
              current._type = 'face';
              current._label = $translate.instant('entities.sensor.__classes.face');
            }

            vm.detections.push(current);
          });
        }

        if (content.poses) {
          content.poses.forEach((current) => {
            current._type = 'pose';
            current._label = $translate.instant(
              'entities.sensor.__poses.' + current.label.toLowerCase()
            );
            vm.detections.push(current);
          });
        }
      }, 300);
    }
  }
})();
