angular.module('app').directive('filterModal', [
  '$uibModal',
  '$timeout',
  function ($uibModal, $timeout) {
    return {
      restrict: 'EA',
      scope: {
        control: '=',
        onClick: '&',
        disabled: '=',
        hideButton: '<',
        modalTitle: '<',
      },
      transclude: true,
      replace: true,
      link: function (vm, element, attrs, ctrl, transclude) {
        var onClick = vm.onClick;
        var hideButton = vm.hideButton;
        var modalTitle = vm.modalTitle;
        vm.control = vm.control || {};
        vm.control.openModal = function () {
          vm.instance = $uibModal.open({
            animation: true,
            backdrop: 'static',
            scope: vm,
            template: `
            <form class="validate" class="modal-demo">
              <div class="modal-header filter-modal-header">
                  <p translate ng-if="!ctrl.modalTitle">general.filter</p>
                  <p ng-if="ctrl.modalTitle">
                    <span ng-repeat="_title in modalTitle.split(' ')">
                      <span translate>{{_title}}</span>
                    </span>
                  </p>  
                <button ng-click="ctrl.cancel()" class="filter-modal-close-btn">&times;</button>
              </div>
              <div class="modal-body-content modal-body"></div>
              <div class="modal-button-container marginT20 marginLR10" ng-if="!hideButton">
                <button
                  class="btn btn-primary btn-block"
                  ng-click="ctrl.ok()"
                  ng-disabled="disabled"
                  translate
                >
                  buttons.load
                </button>
              </div>
            </form>
            `,
            appendTo: element,
            controllerAs: 'ctrl',
            controller: [
              '$uibModalInstance',
              function ($uibModalInstance) {
                var vm = this;
                vm.hideButton = hideButton;
                vm.modalTitle = modalTitle;
                vm.windowWidth = $(window).width();

                function clearDatePicker() {
                  if (vm.windowWidth <= 990) {
                    var elements = $('.datetime-picker-dropdown');
                    Array.prototype.forEach.call(elements, function (node) {
                      node.parentNode.removeChild(node);
                    });
                  }
                }
                vm.ok = function () {
                  onClick({ value: '' });
                  clearDatePicker();
                  $uibModalInstance.close({ $value: 'value' });
                };

                vm.cancel = function () {
                  clearDatePicker();
                  $uibModalInstance.close({ $value: 'value' });
                };
                vm.$onInit = function () {
                  // Closes modal when resizing from mobile to desktop
                  window.addEventListener('resize', onResize);
                  clearDatePicker();
                };

                vm.$onDestroy = function () {
                  window.removeEventListener('resize', onResize);
                };
                // Closes modal when resizing from mobile to desktop
                function onResize() {
                  $timeout(() => {
                    vm.windowWidth = $(window).width();
                    if (vm.windowWidth >= 990) vm.cancel();
                  });
                }
              },
            ],
          });
          $timeout(function () {
            var content = element.find('.modal-body-content');
            transclude(vm.$parent, function (clonedContent) {
              content.append(clonedContent);
            });
          });
        };
      },
    };
  },
]);
