(() => {
  function strRemoveAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Function that return true if a search string is contained in a string
   */
  function strContains(str, search) {
    return strRemoveAccents(str.toLowerCase()).includes(strRemoveAccents(search.toLowerCase()));
  }

  angular.module('app').filter('search', () => {
    return (array, criteria) => {
      if (!criteria) {
        return array;
      }

      if (typeof criteria === 'string') {
        return array.filter((item) => {
          if (typeof item !== 'string') {
            return false;
          }
          return strContains(item, criteria);
        });
      }

      if (typeof criteria === 'object') {
        const keys = Object.keys(criteria).filter((key) => criteria[key]);

        return array.filter((item) => {
          if (typeof item !== 'object') {
            return false;
          }

          return keys.every((key) => {
            if (typeof item[key] !== 'string') {
              return false;
            }

            return strContains(item[key], criteria[key]);
          });
        });
      }

      return array;
    };
  });
})();
