;(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  UsersTable.$inject = [
    'Manager',
    '$element',
    '$timeout',
    '$uibModal',
    '$state',
    'AppUtils',
    '$translate'
  ];

  angular
    .module('app')
    .component('usersTable', {
      template:'<div class="col-xs-12 marginT5"><table id="users-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th><th></th><th></th><th></th></tr></thead></table></div>',
      controller: UsersTable,
      controllerAs: '$ctrl',
      bindings: {
        users: '<',
        timeZones: '<',
        countries: '<',
        userType: '<',
        edit: '<?',
        remove: '<?'
      }
    });

  function UsersTable (Manager, $element, $timeout, $uibModal, $state, utils, $translate) {
    let vm = this;

    vm.$onInit = function onInit () {
      vm.userType = vm.userType || 'Manager';
      vm.usersMap = utils.arrayToObject(vm.users, 'id');
      vm.edit = typeof vm.edit === 'boolean' ? vm.edit : false;
      vm.remove = typeof vm.remove === 'boolean' ? vm.remove : false;

      initUsersTable(vm.users);
    };

    function initUsersTable (data) {
      let btnWidth = 28;
      btnWidth = vm.edit ? btnWidth + 2 * 33 : btnWidth;
      btnWidth = vm.remove ? btnWidth + 33 : btnWidth;

      let dtOptions = {
        processing: true,
        searching: true,
        lengthChange: false,
        info: false,
        data: data,
        columns: [
          {
            data: 'name',
            title: $translate.instant('entities._all.name'),
            render: function (data, type, row) {
              return row.name + ' ' + (row.surname || '');
            }
          },
          {
            data: 'id',
            title: $translate.instant('entities._all.username'),
            render: function (data, type, row) {
              return row.username || '';
            }
          },
          {
            data: 'email',
            title: $translate.instant('entities._all.email'),
            render: emptyRenderer,
          },
          {
            data: 'id',
            render: buttonsRenderer,
            orderable: false,
            width: btnWidth + 'px'
          }
        ]
      };

      $element
        .find('#users-table')
        .DataTable(dtOptions);

      $element.on('click', '.table-buttons .btn', function () {
        let _this = $(this);

        let id = _this.data('entity');
        let op = _this.data('operation');

        switch (op) {
          case 'view':
            showUser(id);
            break;
          case 'edit':
            showUserForm(id);
            break;
          case 'remove':
            showRemoveModal(id);
            break;
          case 'password':
            showPasswordModal(id);
            break;
          default:
            console.log('invalid operation');
        }
      });
    }

    function showUser (id) {
      $uibModal
        .open({
          animation: false,
          bindToController: true,
          size: 'lg',
          backdrop: 'static',
          component: 'accountProfileShow',
          resolve: {
            user: function () {
              return vm.usersMap[id];
            },
            userType: function () {
              return vm.userType;
            },
            timeZones: function () {
              return vm.timeZones;
            },
            countries: function () {
              return vm.countries;
            }
          }
        })
        .result
        .catch(function (err) {
          console.log(err);
        });
    }

    function showUserForm (id) {
      let component = vm.userType === 'Manager' ? 'managerForm' : 'adminForm';
      let instance = $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          size: 'lg',
          component: component,
          resolve: {
            user: function () {
              return vm.usersMap[id];
            },
            customerId: function () {
              return vm.usersMap[id].customerId;
            },
            timezones: function () {
              return vm.timeZones;
            },
            countries: function () {
              return vm.countries;
            }
          }
        });

      instance
        .result
        .then(function (result) {
          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function showRemoveModal (id) {
      $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          windowClass: 'modal-xs modal-warning',
          component: 'managerRemoveModal',
          resolve: {
            user: function () {
              return vm.usersMap[id];
            }
          }
        })
        .result
        .then(function (result) {
          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function showPasswordModal (id) {
      $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          bindToController: true,
          component: 'managerPasswordForm',
          resolve: {
            user: function () {
              return vm.usersMap[id];
            }
          }
        })
        .result
        .then(function (result) {
          $state.reload();
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function emptyRenderer (data) {
      return data || '';
    }

    function buttonsRenderer (data) {
      const $user = $translate.instant('entities.user.modelName');

      let buttons = `<div class="table-buttons">
                <button class="btn btn-primary btn-xs" data-operation="view" data-entity="${data}"
                    title="${$translate.instant('buttons.show')} ${$user}">
                    <i class="fas fa-fw fa-eye"></i>
                </button>`;

      if (vm.edit) {
        buttons += `<button class="btn btn-warning btn-xs marginL5" data-operation="edit" data-entity="${data}"
                        title="${$translate.instant('buttons.edit')} ${$user}">
                    <i class="fas fa-fw fa-edit"></i>
                </button>`;
      }

      if (vm.remove) {
        buttons += `<button class="btn btn-danger btn-xs marginL5" data-operation="remove" data-entity="${data}"
                        title="${$translate.instant('buttons.delete')} ${$user}">
                    <i class="fas fa-fw fa-times"></i>
                </button>`;
      }

      if (vm.edit) {
        buttons += `<button class="btn btn-primary btn-xs marginL5" data-operation="password" data-entity="${data}"
                        title="${$translate.instant('buttons.change')} ${$translate.instant('entities._all.password')}">
                    <i class="fas fa-fw fa-key"></i>
                </button>`;
      }

      buttons += '</div>';
      return buttons;
    }
  }
})();
