;(function () {
  'use strict';
  const angular = window.angular;

  ToolkitListController.$inject = ['AppUtils', '$showdown'];
  angular
    .module('app')
    .component('toolkitList', {
      template:'<div class="container"><h2>Mis Aplicaciones</h2><div class="tool-kit-gallery"><div ng-repeat="sector in ctrl.toolkitSectors"><h4 class="sector">{{sector.name}}</h4><div class="tool-kit" ng-repeat="toolkit in sector.toolkits"><div class="tool-kit-thumb"><img ng-src="{{$root.assetsPath}}/images/logos/xompass-cut.png"></div><div class="tool-kit-content"><div class="tool-kit-body"><h5><a ui-sref="member.applications.show({toolkitId: toolkit.id})">{{toolkit.name}}</a></h5><p class="tool-kit-description" ng-if="toolkit.description" popover-placement="bottom" popover-trigger="\'mouseenter\'" popover-class="markdown markdown-popover" uib-popover-html="\'{{toolkit.htmlDescription}}\'">{{toolkit.textDescription}}</p><ul class="titled" aria-label="Herramientas:"><li ng-repeat="tool in toolkit.tools"><a ui-sref="member.tools.show({toolId: tool.id})">{{tool.name}}</a></li></ul><ul class="list-inline"><li ng-repeat="keyword in toolkit.keywords" class="text-info"><small>#{{keyword}}</small></li></ul></div></div></div></div></div></div>',
      controller: ToolkitListController,
      controllerAs: 'ctrl',
      bindings: {
        toolKits: '<'
      }
    });

  function ToolkitListController (utils, $showdown) {
    const vm = this;

    vm.$onInit = function () {
      const sectors = {};
      vm.toolKits.forEach(toolkit => {
        toolkit.htmlDescription = $showdown.makeHtml(toolkit.description);
        toolkit.textDescription = angular.element('<div>').append(toolkit.htmlDescription).text();
        toolkit.sector = toolkit.sector || 'Multisectorial';
        sectors[toolkit.sector] = sectors[toolkit.sector] || { name: toolkit.sector, toolkits: [] };
        sectors[toolkit.sector].toolkits.push(toolkit);
      });

      vm.toolkitSectors = utils.objectToArray(sectors);
    };
  }
})();
