(() => {
  Controller.$inject = ['$timeout', 'Device', 'AppUtils'];
  angular
    .module('app')
    .component('deviceConfigView', {
      template:'<div class="relative"><div class="padding10" ng-class="{transparent: ctrl.loading}"><div class="marginB10"><button class="btn btn-primary btn-sm" style="min-width: 90px" ng-click="ctrl.editConfig()" ng-if="ctrl.editorOptions.mode === \'view\'"><span translate>buttons.update</span></button> <button class="btn btn-primary btn-sm" style="min-width: 90px" ng-click="ctrl.saveConfig()" ng-if="ctrl.editorOptions.mode === \'code\'"><span translate>buttons.save</span></button> <button class="btn btn-default btn-sm" style="min-width: 90px" ng-click="ctrl.cancelConfig()" ng-if="ctrl.editorOptions.mode === \'code\'"><span translate>buttons.cancel</span></button></div><json-editor height="500" json="ctrl.config" editor-instance="ctrl.editorInstance" config="ctrl.editorOptions"></json-editor></div><spinner show="ctrl.loading"></spinner></div>',
      controllerAs: 'ctrl',
      controller: Controller,
      bindings: {
        device: '<',
        isAdmin: '<',
      },
    });

  function Controller($timeout, Device, utils) {
    const vm = this;
    let firstChange = true;

    vm.$onInit = function () {
      vm.config = angular.copy(vm.device._config || {});
      clearConfig();

      vm.editorInstance = {};
      vm.editorOptions = {
        mode: 'view',
        mainMenuBar: false,
        schema: getConfigSchema(),
      };

      vm.editConfig = editConfig;
      vm.cancelConfig = cancelConfig;
      vm.saveConfig = saveConfig;

      /*vm.editorOptions = {
        mode: 'code',
        // mainMenuBar: false,
        schema: EdgeAgentService.getEdgeAgentSchema(),
        onValidate: validate,
      };*/
    };

    vm.$onChanges = function () {
      if (firstChange) {
        firstChange = false;
        return;
      }

      vm.config = angular.copy(vm.device.config);
      clearConfig();
    };

    function getConfigSchema() {
      return {
        $id: '#deviceConfig',
        type: 'object',
        definitions: {
          hostPort: {
            $id: '#/properties/hostPort',
            type: 'object',
            required: ['host', 'port'],
            properties: {
              host: {
                $id: '#/properties/hostPort/properties/host',
                type: 'string',
                minLength: 1,
              },
              port: {
                $id: '#/properties/hostPort/properties/port',
                type: 'number',
                minimum: 1,
              },
            },
          },
          tunnel: {
            $id: '#/properties/tunnel',
            type: 'object',
            required: ['type', 'user', 'host', 'port', 'local', 'remote'],
            properties: {
              name: {
                $id: '#/properties/tunnel/properties/name',
                type: 'string',
              },
              type: {
                $id: '#/properties/tunnel/properties/type',
                type: 'string',
                enum: ['local', 'remote'],
              },
              user: {
                $id: '#/properties/tunnel/properties/user',
                type: 'string',
              },
              host: {
                $id: '#/properties/tunnel/properties/host',
                type: 'string',
              },
              port: {
                $id: '#/properties/tunnel/properties/port',
                type: 'number',
              },
              local: {
                $ref: '#/definitions/hostPort',
              },
              remote: {
                $ref: '#/definitions/hostPort',
              },
            },
          },
        },
        required: ['tunnels'],
        properties: {
          tunnels: {
            $id: '#/properties/tunnels',
            type: 'array',
            items: {
              $ref: '#/definitions/tunnel',
            },
          },
        },
      };
    }

    function clearConfig() {
      ['deleted', 'created', 'modified', 'id'].forEach(current => {
        delete vm.config[current];
      });
    }

    function editConfig() {
      vm.editorOptions.mode = 'code';
      vm.prevConfig = angular.copy(vm.config);
      if (!vm.config || !Object.keys(vm.config).length) {
        const defaultAdminConfig = {
          name: 'tunnel-gw',
          type: 'remote',
          user: 'cameras',
          host: '186.10.255.85',
          port: 22,
          local: {
            host: 'localhost',
            port: 22,
          },
          remote: {
            host: '0.0.0.0',
            port: 1,
          },
        };

        vm.config = {
          tunnels: [vm.isAdmin ? defaultAdminConfig : {
            name: '',
            type: null,
            user: '',
            host: '',
            port: 22,
            local: {
              host: '',
              port: null,
            },
            remote: {
              host: '',
              port: null,
            },
          }],
        };
      }
    }

    function saveConfig() {
      try {
        vm.config = vm.editorInstance.editor.get();

        if (!vm.editorInstance.editor.validateSchema(vm.config)) {
          return;
        }

        vm.loading = true;
        Device
          .prototype$__update__config({
            id: vm.device.id,
          }, vm.config)
          .$promise
          .then(() => {
            vm.editorInstance.editor.setMode('view');
            vm.editorOptions.mode = 'view';
            vm.loading = false;
            vm.device._config = vm.config;
          })
          .catch(err => {
            const error = utils.getHTTPError(err);
            console.log(error);
            vm.loading = false;
          });
      } catch (e) {
        console.log(e);
      }
    }

    function cancelConfig() {
      vm.config = angular.copy(vm.prevConfig);
      $timeout(() => {
        vm.editorInstance.editor.setMode('view');
        vm.editorOptions.mode = 'view';
      });
    }
  }
})();