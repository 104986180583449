(function () {
  'use strict';

  let angular = window.angular;

  ManagerRemoveController.$inject = ['Manager', 'AppUtils', 'LocalStorageService'];

  angular.module('app').component('managerRemoveModal', {
    template:'<div ng-style="{opacity: ctrl.loading? 0: 1}"><div class="modal-header"><span translate>buttons.delete</span> <span translate>entities.user.modelName</span></div><div class="modal-body text-center"><div translate-values="{user: ctrl.manager.name + \' \' + ctrl.manager.surname}" translate>entities.user.messages.remove</div></div><div ng-if="ctrl.removeUserError" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.removeUserError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" ng-click="ctrl.removeUser()" translate>buttons.delete</button></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
    controller: ManagerRemoveController,
    controllerAs: 'ctrl',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function ManagerRemoveController(Manager, utils, LocalStorageService) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.manager = vm.resolve.user;
      vm.removeUser = removeUser;
    };

    function removeUser() {
      if (!vm.manager) {
        return;
      }

      vm.loading = true;
      Manager.deleteById({
        id: vm.manager.id,
      })
        .$promise.then(function () {
          vm.loading = false;
          LocalStorageService.clear();
          vm.modalInstance.close();
        })
        .catch(function (err) {
          vm.loading = false;
          let error = utils.getHTTPError(err);
          vm.removeUserError = error.code || error.message;
        });
    }
  }
})();
