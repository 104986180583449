(() => {
  angular.module('app').component('measurementPaper', {
    template: `<div class="measurement-paper">
      <canvas id="grid"></canvas>
      <canvas id="ruler"></canvas>
    </div>`,
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      ratio: '<',
      width: '<',
      height: '<',
      offsetTop: '<',
      offsetLeft: '<',
      options: '<',
      onChange: '&',
      onFinished: '&',
    },
  });

  Controller.$inject = ['$element', '$timeout'];

  function Controller($element, $timeout) {
    const vm = this;

    let firstRun = true;

    vm.$onInit = function () {
      $timeout(() => {
        vm.element = $element.find('div');
        vm.rulerCanvas = $element.find('#ruler')[0];
        vm.gridCanvas = $element.find('#grid')[0];

        initEvents();

        reset();
      }, 20);
    };

    vm.$onChanges = function (changes) {
      if (firstRun) {
        firstRun = false;
        return;
      }

      reset();
    };

    function initEvents() {
      const canvas = vm.rulerCanvas;
      const ctx = canvas.getContext('2d');
      let isDrawing = false;
      canvas.addEventListener('click', function (e) {
        isDrawing = !isDrawing;

        if (isDrawing) {
          vm.startPoint = getMousePos(canvas, e);
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas on new measure
          return;
        }

        const currentPoint = getMousePos(canvas, e);
        const distance = calculateDistance(vm.startPoint, currentPoint);
        vm.startPoint = null;
        $timeout(() => {
          vm.onFinished({ distance: Math.round(distance / vm.ratio) });
        });
      });

      canvas.addEventListener('mousemove', function (e) {
        if (!vm.startPoint) {
          return;
        }

        requestAnimationFrame(function () {
          var currentPoint = getMousePos(canvas, e);
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas on each move
          var distance = calculateDistance(vm.startPoint, currentPoint);
          drawRuler(ctx, vm.startPoint, currentPoint, distance);

          $timeout(() => {
            vm.onChange({ distance: Math.round(distance / vm.ratio) });
          });
        });
      });
    }

    function reset() {
      vm.startPoint = null;

      const container = vm.element[0];
      container.style.height = vm.height + 'px';
      container.style.top = vm.offsetTop + 'px';
      //container.style.left = vm.offsetLeft + 'px';

      const canvas = vm.rulerCanvas;
      canvas.width = vm.width;
      canvas.height = vm.height;
      canvas.style.width = vm.width + 'px';
      canvas.style.height = vm.height + 'px';

      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#ffff00';

      const gridCanvas = vm.gridCanvas;
      gridCanvas.width = vm.width;
      gridCanvas.height = vm.height;
      gridCanvas.style.width = vm.width + 'px';
      gridCanvas.style.height = vm.height + 'px';

      const gridCtx = gridCanvas.getContext('2d');
      gridCtx.clearRect(0, 0, gridCanvas.width, gridCanvas.height);

      const gridSize = 50;
      const gridColor = '#ffffff';
      const gridWidth = 1;
      const lineSize = 6;

      for (let x = 0; x < vm.width + gridSize; x += gridSize) {
        for (let y = 0; y < vm.height + gridSize; y += gridSize) {
          gridCtx.strokeStyle = gridColor;
          gridCtx.lineWidth = gridWidth;

          gridCtx.beginPath();
          const x1 = Math.max(x - lineSize / 2, 0);
          const x2 = Math.min(x + lineSize / 2, vm.width);
          gridCtx.moveTo(x1, y);
          gridCtx.lineTo(x2, y);

          const y1 = Math.max(y - lineSize / 2, 0);
          const y2 = Math.min(y + lineSize / 2, vm.height);

          gridCtx.moveTo(x, y1);
          gridCtx.lineTo(x, y2);
          gridCtx.stroke();
        }
      }
    }

    function getMousePos(canvas, evt) {
      var rect = canvas.getBoundingClientRect();
      return {
        x: evt.clientX - rect.left,
        y: evt.clientY - rect.top,
      };
    }

    function calculateDistance(p1, p2) {
      var a = p2.x - p1.x;
      var b = p2.y - p1.y;
      return Math.sqrt(a * a + b * b);
    }

    function drawRuler(ctx, start, end, distance) {
      drawLine(ctx, start, end);
      drawMeasureMarks(ctx, start, end, distance);
    }

    function drawLine(ctx, start, end) {
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      ctx.lineTo(end.x, end.y);
      ctx.stroke();
    }

    function drawMeasureMarks(ctx, start, end, distance) {
      const step = 10;
      var steps = Math.floor(distance / step);
      var angle = Math.atan2(end.y - start.y, end.x - start.x);

      for (var i = 0; i <= steps; i++) {
        var x = start.x + i * step * Math.cos(angle);
        var y = start.y + i * step * Math.sin(angle);
        var markLength = i % 5 === 0 ? 10 : 5;
        drawMark(ctx, x, y, angle, markLength);
      }

      const midX = (start.x + end.x) / 2;
      const midY = (start.y + end.y) / 2;

      drawText(ctx, midX, midY, angle, distance);
    }

    function drawMark(ctx, x, y, angle, length) {
      ctx.save();
      ctx.translate(x, y);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -length);
      ctx.lineTo(0, length);
      ctx.stroke();
      ctx.restore();
    }

    function drawText(ctx, x, y, angle, distance) {
      const text = Math.round(distance / vm.ratio) + 'px';
      const fontSize = 14;
      const offset = fontSize + 5;

      ctx.save();
      ctx.translate(x, y);

      const fixedAngle = Math.abs(angle) > Math.PI / 2 ? angle - Math.PI : angle;

      ctx.rotate(fixedAngle); // Rotar en dirección opuesta para que el texto esté horizontal
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // Colocar el texto encima o debajo de la marca dependiendo del ángulo
      const textOffset = Math.abs(fixedAngle) > Math.PI / 2 ? offset : -offset;

      ctx.shadowColor = '#00000';
      ctx.shadowBlur = 2;
      ctx.font = fontSize + 'px Arial';
      ctx.fillStyle = '#ffff00';
      ctx.fillText(text, 0, textOffset);

      ctx.restore();
    }
  }
})();
