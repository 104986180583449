angular.module('app').directive('filterSection', [
  '$timeout',
  function ($timeout) {
    return {
      restrict: 'EA',
      scope: {
        animation: '=',
        index: '<',
        handleAnimation: '&',
        filterName: '@',
        filterType: '@',
        translateValues: '<',
      },
      transclude: {
        title: '?sectionTitle',
        body: 'sectionBody',
      },
      controller: function () {
        var vm = this;
        vm.isCollapsed = true;
        onResize();
        vm.$onInit = function () {
          window.addEventListener('resize', onResize);
        };

        vm.$onDestroy = function () {
          window.removeEventListener('resize', onResize);
        };
        function onResize() {
          $timeout(() => {
            vm.isMobile = $(window).width() <= 990;
          });
        }
      },

      controllerAs: 'ctrl',
      template: ` 
        <!-- Desktop -->
        <li class="navbar-item" ng-if="!ctrl.isMobile" >
          <a ng-click="handleAnimation({ index: index })">
            <div>
              <div class="navbar-item-title" translate>{{ filterName }}</div>
              <div
                ng-if="filterType === 'multipleSelect'"
                class="navbar-item-desc text-lowercase"
                translate
                data-translate-values="translateValues"
              >
                general._select.selectedPl
              </div>
              <div ng-if="filterType === 'custom'">
                <!-- Title content -->
                <div ng-transclude="title" class="filter-menu-title"></div>
              </div>
            </div>
          </a>
          <div class="nav nav-sub" id="filter-nav-sub" 
          ng-class="{'filter-animation-slide-in': animation[index], 'filter-animation-slide-out': !animation[index]}">
            <div class="view-sidebar-title" translate>{{ filterName }}</div>
            <div class="view-sidebar-content">
              <!-- Body content -->
            <div ng-transclude="body"></div>
            </div>
          </div>
        </li>
        
        <!-- Mobile -->
        <div ng-if="ctrl.isMobile">
          <button type="button" class="btn btn-default collapse-btn" ng-click="ctrl.isCollapsed = !ctrl.isCollapsed">
            <div>
              <div class="navbar-item-title" translate>{{ filterName }}</div>
              <div
                ng-if="filterType === 'multipleSelect'"
                class="navbar-item-desc text-lowercase"
                translate
                data-translate-values="translateValues"
              >
                general._select.selectedPl
              </div>
              <div ng-if="filterType === 'custom'">
                <!-- Title content -->
                <div ng-transclude="title"></div>
              </div>
            </div>
            <i class="fas" ng-class="{'fa-angle-down': ctrl.isCollapsed, 'fa-angle-up': !ctrl.isCollapsed}"></i>
          </button>
          <div uib-collapse="ctrl.isCollapsed" class="collapse-button-content">
            <hr class="collapsed-hr" />
            <!-- Body content -->
            <div ng-transclude="body"></div>
          </div>
        </div>
        `,
    };
  },
]);
