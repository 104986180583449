;(function () {
  'use strict';

  const angular = window.angular;

  angular
    .module('app')
    .directive('notiDot', function () {
      return {
        restrict: 'E',
        transclude: true,
        scope: {
          color: '='
        },
        template: `<span class="noti-dot">
            <span ng-transclude></span><i class="fas fa-circle" ng-style="{color: color}"></i>
        </span>`
      };
    });
})();
