(() => {
  const angular = window.angular;

  angular.module('app').config(Router);

  Router.$inject = ['$stateProvider'];

  function Router($stateProvider) {
    $stateProvider.state('member.var.show.customer.show.project.storylineMuckup', {
      url: '/storyline-demo',
      component: 'storylineMockup',
      authenticated: true,
      resolve: {
        module: [
          '$ocLazyLoad',
          function ($ocLazyLoad) {
            return $ocLazyLoad.load([window.assetsPath + '/js/app/storyline-mockup.module.min.js']);
          },
        ],
      },
      ncyBreadcrumb: {
        label: 'Storyline',
      },
    });
  }
})();
