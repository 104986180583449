;(() => {
  const angular = window.angular;

  Controller.$inject = ['Customer', '$rootScope', 'AppUtils'];

  angular
    .module('app')
    .component('telegramChatUnlink', {
      template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header">{{ctrl.instance.name}} | <span translate>buttons.unlink</span> <span translate>entities.telegramchat.modelName</span></div><div class="modal-body text-center"><div translate data-translate-values="{model: \'entities.telegramchat.modelName\', name: ctrl.telegramChat.name}">general._messages.unlink</div></div><div ng-if="ctrl.error" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.error}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" ng-click="ctrl.unlink()" translate>buttons.unlink</button></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        resolve: '<',
        modalInstance: '<'
      }
    });

  function Controller (Customer, $root, utils) {
    const vm = this;

    vm.$onInit = function () {
      vm.Entity = vm.resolve.Entity;
      vm.instance = vm.resolve.instance;
      vm.telegramChat = vm.resolve.telegramChat;
      vm.unlink = unlink;
    };

    function unlink () {
      vm.loading = true;
      vm.Entity
        .prototype$__unlink__telegramChats({
          id: vm.instance.id,
          fk: vm.telegramChat.id
        })
        .$promise
        .then(result => {
          vm.loading = false;
          vm.modalInstance.close(result);
        })
        .catch(err => {
          vm.loading = false;
          vm.error = utils.getHTTPError(err);
        });
    }
  }
})();
