/**
 *
 * @typedef {Object} AdminEntityServiceOpt
 * @property {string} customerId customerId Customer Id.
 * @property {string} projectId projectId Project Id.
 * @property {string} [entityId] entityId.
 * @property {object} [filter] Loopback filter.
 */

/**
 *
 * @typedef {Object} AdminEntityServiceOpt2
 * @property {string} id customerId Customer Id.
 * @property {string} nk projectId Project Id.
 * @property {string} [fk] entityId.
 * @property {object} [filter] Loopback filter.
 */

;(function () {
  'use strict';

  let angular = window.angular;

  EntityService.$inject = ['Customer', '$state', 'AppUtils'];

  angular
    .module('appServices')
    .service('EntityService', EntityService);

  function EntityService (Customer, $state, appUtils) {
    const plural = {
      'gis': 'gis',
      'customer': 'customer',
      'toolkit': 'toolkit',
      'trafficFlowAnalysis': 'trafficFlowAnalysis'
    };

    return {
      find: find,
      findById: findById,
      findOne: findOne,
      create: create,
      updateById: updateById,
      destroyById: destroyById
    };

    /**
     *
     * @param {string} entity Entity name
     * @param {AdminEntityServiceOpt | AdminEntityServiceOpt2} options
     */
    function find (entity, options) {
      if (!options) {
        throw new Error('Invalid parameters');
      }

      entity = appUtils.decapitalize(entity);
      entity = plural[entity] ? plural[entity] : entity + 's';

      return Customer[`prototype$__get__projects__${entity}`]({
        id: options.customerId || options.id,
        nk: options.projectId || options.nk,
        filter: options.filter
      })
        .$promise
        .catch(err => {
          throw appUtils.getHTTPError(err);
        });
    }

    /**
     *
     * @param {string} entity Entity name
     * @param {AdminEntityServiceOpt | AdminEntityServiceOpt2} options
     */
    function findById (entity, options) {
      if (!options || !options.entityId && !options.fk) {
        throw new Error('Invalid Entity Id');
      }

      entity = appUtils.decapitalize(entity);
      entity = plural[entity] ? plural[entity] : entity + 's';

      options.filter = options.filter || {};
      options.filter.where = options.filter.where || {};
      options.filter.where.id = options.entityId || options.fk;

      return Customer[`prototype$__get__projects__${entity}`]({
        id: options.customerId || options.id,
        nk: options.projectId || options.nk,
        filter: options.filter
      }).$promise
        .then(result => {
          if (angular.isArray(result)) {
            if (!result.length) {
              const err = new Error('Not Found');
              err.code = 'NOT_FOUND';
              err.statusCode = 404;

              throw err;
            }
            return result[0];
          }
          return result;
        })
        .catch(err => {
          throw appUtils.getHTTPError(err);
        });
    }

    /**
     *
     * @param {string} entity Entity name
     * @param {AdminEntityServiceOpt | AdminEntityServiceOpt2} options
     */
    function findOne (entity, options) {
      if (!options || !options.filter) {
        throw new Error('Invalid parameters');
      }

      entity = appUtils.decapitalize(entity);
      entity = plural[entity] ? plural[entity] : entity + 's';

      return Customer[`prototype$__get__projects__${entity}`]({
        id: options.customerId || options.id,
        nk: options.projectId || options.nk,
        filter: options.filter
      })
        .$promise
        .then(result => {
          return result[0];
        });
    }

    /**
     *
     * @param {string} entity Entity name
     * @param {AdminEntityServiceOpt | AdminEntityServiceOpt2} options
     * @param {object} data
     */
    function create (entity, options, data) {
      if (!options) {
        throw new Error('Invalid Entity Id');
      }

      if (typeof data !== 'object' || !Object.keys(data).length) {
        throw new Error('Invalid data');
      }

      entity = appUtils.decapitalize(entity);
      entity = plural[entity] ? plural[entity] : entity + 's';

      return Customer[`prototype$__create__projects__${entity}`]({
        id: options.customerId || options.id,
        nk: options.projectId || options.nk
      }, data)
        .$promise
        .catch(err => {
          throw appUtils.getHTTPError(err);
        });
    }

    /**
     *
     * @param {string} entity Entity name
     * @param {AdminEntityServiceOpt | AdminEntityServiceOpt2} options
     * @param {object} data
     */
    function updateById (entity, options, data) {
      if (!options || !options.entityId && !options.fk) {
        throw new Error('Invalid Entity Id');
      }

      if (typeof data !== 'object' || !Object.keys(data).length) {
        throw new Error('Invalid data');
      }

      entity = appUtils.decapitalize(entity);
      entity = plural[entity] ? plural[entity] : entity + 's';

      return Customer[`prototype$__updateById__projects__${entity}`]({
        id: options.customerId || options.id,
        nk: options.projectId || options.nk,
        fk: options.entityId || options.fk
      }, data)
        .$promise
        .catch(err => {
          throw appUtils.getHTTPError(err);
        });
    }

    /**
     *
     * @param {string} entity Entity name
     * @param {AdminEntityServiceOpt | AdminEntityServiceOpt2} options
     */
    function destroyById (entity, options) {
      if (!options || !options.entityId && !options.fk) {
        throw new Error('Invalid Entity Id');
      }

      entity = appUtils.decapitalize(entity);
      entity = plural[entity] ? plural[entity] : entity + 's';

      return Customer[`prototype$__destroyById__projects__${entity}`]({
        id: options.customerId || options.id,
        nk: options.projectId || options.nk,
        fk: options.entityId || options.fk
      })
        .$promise
        .catch(err => {
          throw appUtils.getHTTPError(err);
        });
    }
  }
})();
