;(function () {
  'use strict'

  let angular = window.angular

  angular
    .module('app')
    .component('apiKeyRuleForm', {
      template:'<form name="ctrl.ruleForm" class="form-full form-validation" novalidate ng-hide="ctrl.saving"><div class="modal-header"><button type="button" class="close" ng-click="ctrl.modalInstance.dismiss()"><i class="fas fa-times"></i></button> Api Key Form</div><div class="modal-body"><div class="form-group" show-errors><label for="ruleName">Name <span class="text-danger">*</span> <span class="help hidden-xs">The display name of the rule</span></label> <input id="ruleName" name="ruleName" placeholder="Name" class="form-control input-sm" required ng-model="ctrl.rule.name" ng-model-options="{ debounce: 200 }" ng-change="ctrl.isTaken(ctrl.rule.name, ctrl.names, ctrl.ruleForm.ruleName)" ng-pattern="ctrl.nameRegex"><div class="help-block" ng-messages="ctrl.ruleForm.ruleName.$error"><div ng-messages-include="messages.html"></div></div></div><div class="form-group" show-errors><label for="ruleUnit">IP <span class="help hidden-xs">IP</span></label><div class="input-group input-group-sm"><input id="ruleUnit" name="ruleUnit" placeholder="Unit" required ng-pattern="ctrl.ipRegex" class="form-control input-sm" ng-model="ctrl.rule.ip"><div class="input-group-btn"><button class="btn btn-primary" ng-click="ctrl.addClientIP()">My IP</button></div></div></div><div class="alert alert-warning text-center no-margin" ng-show="ctrl.saveError">{{ctrl.saveError}}</div></div><div class="modal-footer"><button class="btn btn-default js-btn-step pull-left" ng-click="ctrl.modalInstance.dismiss()">Cancel</button> <button class="btn btn-primary" ng-click="ctrl.applyChanges()">Save</button></div></form><div style="position: relative; height: 400px" ng-show="ctrl.saving"><xompass-spinner css-class="\'small\'" show="ctrl.saving"></xompass-spinner></div>',
      controller: ['$scope', 'AppUtils', 'ApiKey', RuleFormController],
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    })

  function RuleFormController ($scope, utils, ApiKey) {
    let vm = this
    vm.$onInit = function onInit () {
      vm.apiKey = vm.resolve.apiKey
      vm.rule = vm.resolve.rule
      vm.names = vm.resolve.names

      vm.isTaken = isTaken

      vm.nameRegex = /^[A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff0-9]+[A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff0-9_\s\-]*$/
      vm.ipRegex = new RegExp('\\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.|$)){4}\\b')

      vm.applyChanges = applyChanges

      vm.addClientIP = addClientIP
    }

    function isTaken (property, taken, formProperty) {
      let isTaken = Array.isArray(taken) && taken.indexOf(property) !== -1
      formProperty.$setValidity('taken', !isTaken)
    }

    function applyChanges () {
      $scope.$broadcast('show-errors-check-validity')

      if (vm.ruleForm.ruleName) {
        isTaken(vm.rule.name, vm.names, vm.ruleForm.ruleName)
      }

      if (!vm.ruleForm.$valid) {
        return
      }

      if (!vm.rule._id) {
        vm.saving = true
        ApiKeyService
          .createRule(vm.apiKey._id, vm.rule)
          .then(function (result) {
            vm.saving = false
            vm.modalInstance.close(result)
          })
          .catch(function (err) {
            vm.saving = false
            vm.saveError = err
          })
      } else {
        ApiKeyService
          .saveRule(vm.apiKey._id, vm.rule)
          .then(function (result) {
            vm.saving = false
            vm.modalInstance.close(result)
          })
          .catch(function (err) {
            vm.saving = false
            vm.saveError = err
          })
      }
    }
  }
})()
