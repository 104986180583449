(() => {
  const angular = window.angular;

  DetectionCropElementCtrl.$inject = [
    '$element',
    'ObjectRecognitionService',
    '$timeout',
    'AppUtils',
  ];

  angular.module('app').component('detectionCropElement', {
    template:'<div class="thumbnail" style="margin: 0 auto 6px"><div style="line-height: 1; position: relative"><canvas class="detection-crop" height="100" style="margin: auto; vertical-align: middle"></canvas><spinner ng-if="ctrl.loading" show="true" css-class="\'xs\'"></spinner></div><div class="marginT5" ng-hide="ctrl.hideColors"><detection-colors-display detection="ctrl.detection"></detection-colors-display></div><div class="caption paddingB0 paddingT5" ng-show="ctrl.showCaption"><table class="table-condensed table no-margin text-center"><tr><td><strong translate>{{ctrl.detection._label}}</strong><div style="height: 15px"><small ng-if="ctrl.detection.text">{{ctrl.detection.text}}</small></div><div style="height: 15px" ng-if="ctrl.detection.blacklistedText"><small>{{ctrl.detection.blacklistedText}}</small></div></td></tr><tr><td>{{ctrl.date | date: \'dd-MM-yyyy HH:mm\'}}</td></tr></table></div></div>',
    controller: DetectionCropElementCtrl,
    controllerAs: 'ctrl',
    bindings: {
      detection: '<',
      image: '<',
      date: '<',
      boxMode: '<',
      showCaption: '<',
      adjustWidth: '<',
      canvasHeight: '<',
      padding: '<?',
      hideColors: '<',
    },
  });

  function DetectionCropElementCtrl(element, ObjectRecognitionService, $timeout) {
    const vm = this;

    let firstChange = true;

    vm.$onInit = function () {
      vm.showCaption = typeof vm.showCaption === 'boolean' ? vm.showCaption : true;
      //vm.showColors = vm.showColors || true;
    };

    vm.$onChanges = function (changes) {
      if (firstChange) {
        firstChange = false;
        return;
      }
      if (changes.image) {
        if (vm.image instanceof Error) {
          vm.loading = false;
          return;
        }
        cropImage(vm.cropOptions);
      }
    };

    vm.$postLink = function () {
      if (!vm.detection) {
        return;
      }
      prepareCanvas(50).then((cropOptions) => {
        vm.cropOptions = cropOptions;
        if (vm.image) {
          cropImage(cropOptions);
        } else {
          vm.loading = true;
        }
      });
    };

    function prepareCanvas(timeout) {
      return new Promise((resolve) => {
        $timeout(() => {
          const canvasEl = element.find('.detection-crop');
          const thumb = canvasEl.parent().parent();
          let parentWidth = canvasEl.parent().width();
          const canvas = (vm.canvas = canvasEl[0]);
          vm.canvasContext = canvas.getContext('2d');
          let canvasHeight = canvasEl.height();

          if (vm.canvasHeight && vm.canvasHeight !== canvasHeight) {
            canvasHeight = vm.canvasHeight;
            canvasEl[0].height = vm.canvasHeight;
          }

          let frame;
          if (vm.detection._type === 'pose') {
            frame = ObjectRecognitionService.getPoseFrame(vm.detection);
          } else {
            frame = vm.detection.frame;
          }

          const box = ObjectRecognitionService.getBoxPoints(frame, vm.boxMode);
          const start = box[0];

          let width = Math.abs(box[1].x - start.x);
          let height = Math.abs(box[3].y - start.y);

          let maxWidth;
          let maxHeight;

          if (vm.adjustWidth) {
            const pWidth = (width * canvasHeight) / height;
            const withPadding = parentWidth + 8;
            if (withPadding < pWidth) {
              const temp = (height * withPadding) / width;
              thumb.width(parentWidth);
              canvasHeight = temp;
            } else {
              parentWidth = pWidth;
              thumb.width(pWidth);
            }
          } else {
            parentWidth = parentWidth - 15;
            thumb.width(parentWidth);
          }

          canvasEl.prop('width', parentWidth);
          let canvasWidth = parentWidth;
          const ratioW = width / canvasWidth;
          const ratioH = height / canvasHeight;

          if (ratioW > ratioH) {
            maxHeight = (height * canvasWidth) / width;
            maxWidth = canvasWidth;
          } else {
            maxWidth = (width * canvasHeight) / height;
            maxHeight = canvasHeight;
          }

          const dx = canvasWidth / 2 - maxWidth / 2;

          resolve({
            x: start.x,
            y: start.y,
            width,
            height,
            dx,
            maxWidth,
            maxHeight,
          });
        }, timeout);
      });
    }

    function cropImage(options) {
      if (!options) {
        return;
      }
      if (vm.canvasContext) {
        vm.canvasContext.clearRect(0, 0, options.width, options.height);
        vm.canvasContext.drawImage(
          vm.image,
          options.x,
          options.y,
          options.width,
          options.height,
          options.dx,
          0,
          options.maxWidth,
          options.maxHeight
        );
        vm.loading = false;
      }
    }
  }
})();
