;(() => {
  window.angular
    .module('app')
    .component('svgPluginTemperature', {
      template:'<div class="x-plugin-icon"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve"><g><g><g><path ng-style="{fill: ctrl.fillColor}" d="M292.3,286.31V120.97c0-11.31-4.4-21.92-12.39-29.91c-7.99-7.98-18.61-12.38-29.9-12.38 c-23.32,0-42.3,18.97-42.3,42.3v165.34c-19.96,13.88-31.84,36.53-31.84,60.88c0,40.88,33.25,74.13,74.13,74.13 c40.87,0,74.13-33.25,74.13-74.13C324.13,322.84,312.26,300.19,292.3,286.31z M284.99,383.86c-9.52,9.09-21.86,14.02-34.96,14.02 c-0.81,0-1.62-0.02-2.43-0.06c-26.12-1.21-47.32-22.64-48.24-48.78c-0.78-21.71,12.62-41.69,32.8-49.29V121.97 c0-9.2,6.71-16.97,15.61-18.05c5.52-0.68,10.94,1.17,14.86,5.09c3.38,3.38,5.22,7.86,5.22,12.63v12.14h-15.6 c-2.05,0-3.71,1.25-3.71,2.77c0,1.53,1.66,2.77,3.71,2.77h15.6v33.93h-15.6c-2.05,0-3.71,1.24-3.71,2.77 c0,1.53,1.66,2.77,3.71,2.77h15.6v33.93h-15.6c-2.05,0-3.71,1.24-3.71,2.77c0,1.53,1.66,2.77,3.71,2.77h15.6v33.93h-15.6 c-2.05,0-3.71,1.24-3.71,2.77c0,1.53,1.66,2.77,3.71,2.77h15.6v42c19.72,7.4,32.83,26.22,32.83,47.44 C300.67,361.17,295.11,374.19,284.99,383.86z"></path></g></g><path ng-style="{fill: ctrl.fillColor}" d="M285.02,347.95c0,19.34-15.68,35.03-35.03,35.03c-19.34,0-35.02-15.68-35.02-35.03 c0-15.99,10.72-29.47,25.36-33.67v-29.05c0-5.34,4.33-9.67,9.66-9.67c2.67,0,5.09,1.08,6.84,2.83c1.75,1.76,2.83,4.17,2.83,6.84 v29.06C274.31,318.47,285.02,331.97,285.02,347.95z"></path></g></svg><div class="caption" ng-if="ctrl.showLabel" translate translate-attr="{title: \'plugins.temperature\'}">plugins.temperature</div></div>',
      controllerAs: 'ctrl',
      bindings: {
        fillColor: '<',
        showLabel: '<'
      }
    });
})();
