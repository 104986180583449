;(function () {
  'use strict'

  let angular = window.angular
  let $ = window.jQuery

  RuleTable.$inject = [
    '$element',
    '$timeout',
    '$uibModal',
    'ApiKey',
    'AppUtils'
  ]

  angular
    .module('app')
    .component('apiKeyRuleTable', {
      template:'<div class="dataTable-toolbar paddingT0"><div class="btn-group btn-group-sm"><button class="btn btn-default btn-label disabled" translate>general.actions</button> <button class="btn btn-primary" ng-if="ctrl.addButton" ng-click="ctrl.addRule()" style="min-width: 90px"><i class="fas fa-plus"></i> <span translate>buttons.add</span></button> <button class="btn btn-primary edit-rule" style="min-width: 90px" ng-if="ctrl.editButton" ng-disabled="!ctrl.selectedRules.length" ng-click="ctrl.editRules()"><i class="fas fa-edit"></i> <span translate>buttons.edit</span></button> <button class="btn btn-primary remove-rule" style="min-width: 90px" ng-if="ctrl.removeButton" ng-disabled="!ctrl.selectedRules.length" ng-click="ctrl.removeRules()"><i class="fas fa-trash"></i> <span translate>buttons.delete</span></button></div></div><table id="rules-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th class="select-checkbox"></th><th>Name</th><th>Ip Block</th></tr></thead></table>',
      controller: RuleTable,
      controllerAs: 'ctrl',
      bindings: {
        apiKey: '<',
        addButton: '<',
        editButton: '<',
        removeButton: '<'
      }
    })

  function RuleTable ($element, $timeout, $uibModal, ApiKeyService, utils) {
    let vm = this

    vm.$onInit = function onInit () {
      if (!vm.apiKey) {
        return
      }

      vm.apiKeyRules = []
      vm.toggleSelectedRules = toggleSelectedRules

      vm.addRule = addRule
      vm.editRules = editRules
      vm.removeRules = removeRules

      vm.keyRules = vm.apiKey.rules
      vm.ruleMap = utils.arrayToObject(vm.keyRules, '_id')

      vm.addButton = vm.addButton === undefined ? true : vm.addButton
      vm.editButton = vm.editButton === undefined ? true : vm.editButton
      vm.removeButton = vm.removeButton === undefined ? true : vm.removeButton

      initRuleTable(vm.apiKey.rules)
    }

    function getNamesTaken (_id) {
      return vm.keyRules.map(function (rule) {
        return rule._id !== undefined && rule._id === _id ? null : rule.name
      })
    }

    function getRelativeIdTaken (_id) {
      return vm.keyRules.map(function (rule) {
        return rule._id !== undefined && rule._id === _id ? null : rule.relativeId
      })
    }

    function initRuleTable (data) {
      let dtOptions = {
        dom: '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
             '<div tr>' +
             '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        order: [1, 'asc'],
        paging: false,
        info: false,
        data: data,
        select: {
          selector: 'td:not(.control)',
          style: 'multi'
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-checkbox',
            render: checkboxRendered,
            width: '10px'
          },
          {data: 'name'},
          {data: 'ip', width: '200px'},
        ]
      }

      let dtInstance = vm.dtInstance = $element.find('#rules-table').DataTable(dtOptions)

      dtInstance
        .on('select', function () {
          $timeout(function () {
            vm.apiKeyRules = dtInstance.rows({selected: true}).data().toArray()
          })
        })
        .on('deselect', function () {
          $timeout(function () {
            vm.apiKeyRules = dtInstance.rows({selected: true}).data().toArray()
          })
        })

      $element.on('click', 'th.select-checkbox', function () {
        if (vm.keyRules.length === 0) {
          return
        }

        let checkbox = $(this)
        let row = checkbox.parent()

        $timeout(function () {
          row.toggleClass('selected')
          toggleSelectedRules()
        })
      })
    }

    function toggleSelectedRules () {
      if (vm.apiKeyRules.length === vm.keyRules.length) {
        vm.dtInstance.rows().deselect()
      } else {
        vm.dtInstance.rows().select()
      }
    }

    function showRuleForm (rule) {
      let names = getNamesTaken(rule._id)
      let instance = $uibModal
        .open({
          animation: true,
          bindToController: true,
          component: 'apiKeyRuleForm',
          backdrop: 'static',
          resolve: {
            apiKey: function () {
              return vm.apiKey
            },
            rule: function () {
              return rule
            },
            names: function () {
              return names
            }
          }
        })

      instance
        .result
        .then(function (result) {
          vm.dtInstance.clear()
          vm.dtInstance.rows.add(result)
          vm.dtInstance.draw()

          vm.keyRules = vm.apiKey.rules = result
          vm.ruleMap = utils.arrayToObject(vm.keyRules, '_id')
          vm.apiKeyRules = []

          $('th.select-checkbox')
            .parent()
            .removeClass('selected')
        })
        .catch(function (err) {
          console.log(err)
        })
    }

    function addRule () {
      showRuleForm([{}])
    }

    function editRules () {
      if (!vm.apiKeyRules || !vm.apiKeyRules.length) {
        return
      }

      showRuleForm(vm.apiKeyRules)
    }

    function removeRules () {
      let ruleIds = vm.apiKeyRules.map(function (rule) {
        return rule._id
      })
      if (vm.apiKey._id) {
        ApiKeyService
          .removeSensors(vm.apiKey._id, ruleIds)
          .then(function (result) {
            vm.dtInstance.clear()
            vm.dtInstance.rows.add(result)
            vm.dtInstance.draw()

            vm.keyRules = vm.apiKey.rules = result
            vm.ruleMap = utils.arrayToObject(vm.keyRules, '_id')
            vm.apiKeyRules = []

            $('th.select-checkbox')
              .parent()
              .removeClass('selected')
          })
          .catch(function (err) {
            console.log(err)
          })
      } else {
        for (let i = vm.apiKey.rules.length - 1; i >= 0; i--) {
          let rule = vm.apiKey.rules[i]
          let remove = ruleIds.find(_id => _id === rule._id)

          if (remove) {
            vm.apiKey.rules.splice(i, 1)
          }

          vm.keyRules = vm.apiKey.rules
          vm.ruleMap = utils.arrayToObject(vm.keyRules, '_id')
          vm.apiKeyRules = []

          $('th.select-checkbox')
            .parent()
            .removeClass('selected')

          vm.dtInstance.clear()
          vm.dtInstance.rows.add(vm.apiKey.rules)
          vm.dtInstance.draw()
        }
      }
    }

    function checkboxRendered () {
      return ''
    }
  }
})()
