(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  ToolKitsTable.$inject = [
    '$element',
    '$timeout',
    '$uibModal',
    'Customer',
    'AppUtils',
    '$translate',
  ];

  angular.module('app').component('toolKitsTable', {
    template:'<div class="panel panel-default"><header class="panel-heading"><h2 class="nowrap"><strong translate>entities.toolkit.modelNamePl</strong></h2><div class="panel-toolbars pull-right" ng-if="$ctrl.addNew"><div class="panel-toolbar"><button class="btn btn-primary" ng-click="$ctrl.showToolKitLink()"><i class="fas fa-link"></i></button></div></div></header><div class="panel-body no-padding"><table id="toolKits-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th><th></th></tr></thead></table></div></div>',
    controller: ToolKitsTable,
    controllerAs: '$ctrl',
    bindings: {
      customerId: '<',
      entityType: '<',
      addNew: '<',
    },
  });

  function ToolKitsTable($element, $timeout, $uibModal, Customer, utils, $translate) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.addNew = typeof vm.addNew === 'boolean' ? vm.addNew : false;
      Customer.toolkits({ id: vm.customerId, filter: { include: 'tools' } })
        .$promise.then((toolKits) => {
          vm.toolKits = toolKits;
          vm.toolKitsMap = utils.arrayToObject(vm.toolKits, 'id');
          initToolKitsTable(vm.toolKits);
        })
        .catch((err) => {
          err = utils.getHTTPError(err);
          console.log(err);
        });

      vm.showToolKitLink = showToolKitLink;
    };

    function initToolKitsTable(data) {
      let dtOptions = {
        dom: "<tr> <'row'<'col-sm-5'i><'col-sm-7'p>>",
        processing: true,
        searching: false,
        info: false,
        data: data,
        columns: [
          {
            data: 'name',
            width: 150,
            title: $translate.instant('entities._all.name'),
          },
          {
            data: 'tools',
            title: $translate.instant('entities.tool.modelNamePl'),
            render: function (data) {
              if (!data) {
                return;
              }
              return data
                .map((current) =>
                  $translate.instant(
                    'entities.tool.__tools.' + current.module.replace(/xompass|vsaas/, '')
                  )
                )
                .join(', ');
            },
          },
        ],
      };

      vm.dtInstance = $element.find('#toolKits-table').DataTable(dtOptions);
    }

    function showToolKitLink() {
      let instance = $uibModal.open({
        animation: false,
        bindToController: true,
        backdrop: 'static',
        component: 'toolKitTemplateLink',
        resolve: {
          customerId: function () {
            return vm.customerId;
          },
          customerToolKits: function () {
            return vm.toolKits;
          },
        },
      });

      instance.result
        .then(function (result) {
          vm.dtInstance.clear();
          vm.dtInstance.rows.add(result);
          vm.dtInstance.draw();

          vm.toolKits = result;
          vm.toolKitsMap = utils.arrayToObject(vm.toolKits, 'id');

          $('th.select-checkbox').parent().removeClass('selected');
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
})();
