(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  DrawPaper.$inject = [
    '$scope',
    '$element',
    '$timeout',
    'AppUtils',
    'ZonePaper',
    'LinePaper',
    'RectanglePaper',
    'GridPaper',
  ];

  angular.module('app').component('detectorPaper', {
    template:'<div class="draw-area raphael-draw" tabindex="0" style="position: relative; margin: auto; user-select: none;" ng-style="{width: ctrl.width}"><div class="raphael-paper {{ctrl.state}}"></div></div>',
    controller: DrawPaper,
    controllerAs: 'ctrl',
    bindings: {
      figures: '<',
      ratio: '<',
      width: '<',
      height: '<',
      options: '<',
      mode: '<',
      context: '<',
    },
  });

  function DrawPaper(
    $scope,
    $element,
    $timeout,
    utils,
    ZonePaper,
    LinePaper,
    RectanglePaper,
    GridPaper
  ) {
    let vm = this;
    let firstChange = true;

    let modes = {
      polygon: {
        canvas: ZonePaper,
      },
      line: {
        canvas: LinePaper,
      },
      rectangle: {
        canvas: RectanglePaper,
      },
      grid: {
        canvas: GridPaper,
      },
    };

    vm.$onInit = function () {
      vm.context = vm.context || '';
      // on selecting a figure out of this component
      vm.offUpdateFigure = $scope.$on('det_draw:update' + vm.context, $onUpdateFigure);

      // on selecting a figure out of this component
      vm.offSelectFigure = $scope.$on('det_draw:item-select' + vm.context, $onSelectFigure);

      // on removing a figure out of this component
      vm.offRemoveFigure = $scope.$on('det_draw:item-remove' + vm.context, $onRemoveFigure);

      // on toggle visibility of a figure
      vm.offToggleFigure = $scope.$on('det_draw:item-toggle' + vm.context, $onToggleFigure);

      // on toggle visibility of all figures
      vm.offToggleAll = $scope.$on('det_draw:item-toggle-all' + vm.context, $onToggleAll);

      // on change color
      vm.offChangeColor = $scope.$on('det_draw:color' + vm.context, $onChangeColor);

      // on copy
      vm.offCopy = $scope.$on('det_draw:copy' + vm.context, $onCopy);

      vm.toggleReadOnly = $scope.$on('det_draw:toggleReadOnly' + vm.context, $onToggleReadOnly);

      init();
    };

    vm.$onChanges = (changes) => {
      if (firstChange) {
        firstChange = false;
        return;
      }

      if (vm.canvas) {
        vm.canvas.destroy();
      }

      init();
    };

    function init() {
      vm.mode = vm.mode || 'polygon';
      const PaperService = modes[vm.mode].canvas;
      const style = modes[vm.mode].style || {};

      vm.options = vm.options || {};
      Object.assign(style, vm.options.style || {});

      vm.readOnly = typeof vm.options.readOnly === 'boolean' ? vm.options.readOnly : false;
      vm.single = typeof vm.options.single === 'boolean' ? vm.options.single : false;

      vm.ratio = utils.isValidNumber(vm.ratio) ? vm.ratio : 1;
      vm.figures = vm.figures || [];

      const container = $element.find('.draw-area');
      const target = $element.find('.raphael-paper');
      const size = {
        width: vm.width,
        height: vm.height,
      };

      vm.canvas = new PaperService.Canvas(container, target, size, {
        readOnly: vm.readOnly,
        ratio: vm.ratio,
        style,
        context: vm.context,
        single: vm.single === true,
      });

      if (vm.timeout) {
        $timeout.cancel(vm.timeout);
      }

      vm.timeout = $timeout(() => {
        initDrawing();
      }, 50);
    }

    vm.$onDestroy = function () {
      if (vm.canvas) {
        vm.canvas.destroy();
      }

      if (vm.offRemoveFigure) {
        vm.offRemoveFigure();
      }

      if (vm.offSelectFigure) {
        vm.offSelectFigure();
      }

      if (vm.offToggleAll) {
        vm.offToggleAll();
      }

      if (vm.offToggleFigure) {
        vm.offToggleFigure();
      }

      if (vm.offUpdateFigure) {
        vm.offUpdateFigure();
      }

      if (vm.offChangeColor) {
        vm.offChangeColor();
      }

      if (vm.offCopy) {
        vm.offCopy();
      }
    };

    function initDrawing() {
      vm.figures.forEach((current) => {
        const figure = vm.canvas.createFigure(current);
        if (figure) {
          vm.canvas.figures.push(figure);
        }
      });

      return vm.canvas;
    }

    function $onUpdateFigure(event, figure) {
      let paperFigure = vm.canvas.figures.find(
        (current) => current._sensor_config.id === figure.id
      );
      if (!paperFigure) {
        return;
      }

      vm.canvas.updateFigure(paperFigure, figure);
    }

    function $onSelectFigure(e, figure) {
      let paperFigure = vm.canvas.figures.find(
        (current) => current._sensor_config.id === figure.id
      );
      if (!paperFigure) {
        return;
      }

      vm.canvas.select(paperFigure);
    }

    function $onRemoveFigure(e, figure) {
      let paperFigure = vm.canvas.figures.find(
        (current) => current._sensor_config.id === figure.id
      );
      if (!paperFigure) {
        return;
      }
      vm.canvas.remove(paperFigure);
    }

    function $onToggleAll(e, hide) {
      vm.figures.forEach((current) => {
        if (typeof hide === 'boolean') {
          if (current.hidden !== hide) {
            $onToggleFigure(null, current);
          }
        } else {
          $onToggleFigure(null, current);
        }
      });
    }

    function $onToggleFigure(e, figure) {
      let paperFigure = vm.canvas.figures.find(
        (current) => current._sensor_config.id === figure.id
      );
      if (!paperFigure) {
        return;
      }

      if (paperFigure.hidden) {
        vm.canvas.showFigure(paperFigure);
      } else {
        vm.canvas.hideFigure(paperFigure);
      }
      figure.hidden = paperFigure.hidden;
    }

    function $onChangeColor(e, figure) {
      let paperFigure = vm.canvas.figures.find(
        (current) => current._sensor_config.id === figure.id
      );
      if (!paperFigure) {
        return;
      }

      paperFigure.attr({ stroke: figure.color, fill: figure.color });
    }

    function $onCopy(e, figureId) {
      const figure = vm.figures.find((current) => current.id === figureId);

      if (!figure) {
        return;
      }

      const newFigure = {
        points: figure.points,
        id: utils.generateUUID(),
      };

      vm.figures.push(newFigure);

      const paperFigure = vm.canvas.createFigure(newFigure);
      if (paperFigure) {
        paperFigure.attr({ 'stroke-dasharray': '-' });
        vm.canvas.figures.push(paperFigure);
      }
    }

    function $onToggleReadOnly(e) {
      vm.$onChanges();
    }
  }
})();
