(() => {
  const angular = window.angular;

  Controller.$inject = ['SensorTableData', '$scope', '$filter', '$element'];

  angular.module('app').component('keyValueTable', {
    template:'<table class="table"></table>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      sensors: '<',
      asset: '<',
      loading: '<',
    },
  });

  function Controller(SensorTableData, $scope, $filter, $element) {
    const vm = this;
    let firstChange = true;

    vm.$onInit = function () {
      vm.offLoadData = $scope.$on('key-value:load-data');
      createTable();
    };

    vm.$onChanges = function (changes) {
      if (firstChange) {
        firstChange = false;
        return;
      }

      if (changes.loading && !vm.loading && vm.dtInstance) {
        const data = [];

        if (vm.sensors) {
          for (const sensor of vm.sensors) {
            const tableData = SensorTableData.getData(sensor, sensor.data || []);
            Array.prototype.push.apply(data, tableData);
          }
        }

        vm.dtInstance.clear();
        vm.dtInstance.rows.add(data);
        vm.dtInstance.draw();
      }
    };

    vm.$onDestroy = function () {
      vm.offLoadData();
    };

    function createTable(data) {
      const table = (vm.table = $element.find('.table'));
      let dtOptions = {
        columns: [
          {
            title: 'Fecha',
            data: 'from',
            width: 150,
            render: (value) => $filter('date')(value, 'dd-MM-yyyy HH:mm:ss'),
          },
          {
            title: 'Sensor',
            data: 'sensor',
            render: (sensor) => sensor.name,
          },
          {
            title: 'Datos',
            data: 'value',
          },
        ],
        order: [[0, 'desc']],
        data: data,
        pageLength: 25,
        dom: 'tp',
        scrollY: 520,
      };
      vm.dtInstance = table.DataTable(dtOptions);
    }
  }
})();
