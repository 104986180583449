(function () {
  'use strict';

  const angular = window.angular;

  ToolkitLinkController.$inject = [
    'AppUtils',
    'Var',
    'Customer',
    '$translate',
    'LocalStorageService',
  ];

  angular.module('app').component('linkTool', {
    template:'<div style="position: relative"><div class="modal-header"><span translate>buttons.add</span> <span translate>entities.tool.modelName</span></div><div ng-style="{opacity: $ctrl.loading? 0: 1}"><div class="modal-body text-center" ng-if="!$ctrl.tools.length && !$ctrl.loadError"><div class="marginTB20"><strong translate translate-values="{element: \'entities.tool.modelNamePl\'}">general._list.notAvailable</strong></div></div><table class="table table-bordered table-hover no-margin" ng-if="$ctrl.tools.length && !$ctrl.loadError"><thead><tr><th colspan="2" class="text-center text-capitalize" translate translate-values="{element: \'entities.tool.modelNamePl\'}">general._list.available</th></tr><tr><th translate>entities._all.name</th><th translate>entities.assettype.modelNamePl</th></tr></thead><tbody><tr ng-repeat="tool in $ctrl.tools | orderBy: \'label\'" ng-click="$ctrl.toolSelected = tool" class="cursor-pointer" ng-class="{active: $ctrl.toolSelected === tool}"><td translate>{{tool.label}}</td><td>{{tool.assetTypes}}</td></tr></tbody></table><div class="alert alert-warning no-margin text-center" ng-if="$ctrl.loadError">{{$ctrl.loadError}}</div><div class="alert alert-warning no-margin text-center" ng-if="$ctrl.linkError">{{$ctrl.linkError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-disabled="!$ctrl.toolSelected" ng-if="$ctrl.tools.length" ng-click="$ctrl.linkTool()" translate>buttons.add</button></div></div><spinner show="$ctrl.loading" show-logo="true" css-class="\'small\'"></spinner></div>',
    controller: ToolkitLinkController,
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function ToolkitLinkController(utils, Var, Customer, $translate, LocalStorageService) {
    let vm = this;

    vm.$onInit = function () {
      vm.entityType = vm.resolve.entityType;
      vm.entityId = vm.resolve.entityId;
      const target = vm.resolve.target || 'tools';
      vm.organizationId = vm.resolve.organizationId;

      switch (vm.entityType) {
        case 'toolkit':
          vm.linkMethod = Var[`prototype$__link__toolkits__${target}`];
          vm.loadEntityTools = Var[`prototype$__get__toolkits__${target}`];
          vm.loadTools = Var[`prototype$__get__${target}`];
          break;
        case 'project':
          vm.linkMethod = Customer[`prototype$__link__projects__${target}`];
          vm.loadEntityTools = Customer[`prototype$__get__projects__${target}`];
          vm.loadTools = Customer[`prototype$__get__${target}`];
          break;
      }

      if (!vm.linkMethod || !vm.entityId) {
        return;
      }

      vm.loading = true;
      loadData()
        .then(() => {
          let currentTools = utils.arrayToObject(vm.entityTools || [], 'id');
          vm.tools = vm.tools.filter((current) => {
            current.label = $translate.instant(
              'entities.tool.__tools.' + current.module.replace(/xompass|vsaas/, '')
            );
            if (currentTools[current.id] === undefined) {
              const assetTypes = (current.assetTypes || []).map((assetType) => assetType.name);
              current.assetTypes = assetTypes
                .map((current) => $translate.instant('entities.assettype.__types.' + current))
                .join(', ');
              return true;
            }
          });
          vm.loading = false;
        })
        .catch((err) => {
          vm.loadError = utils.getHTTPError(err).message;
          console.log(vm.loadError);
          vm.loading = false;
        });

      vm.linkTool = linkTool;
    };

    function loadData() {
      return vm
        .loadEntityTools({ id: vm.organizationId, nk: vm.entityId })
        .$promise.then((result) => {
          vm.entityTools = result;
          return vm.entity;
        })
        .then((entity) => {
          return vm.loadTools({ id: vm.organizationId, filter: { include: 'assetTypes' } })
            .$promise;
        })
        .then((tools) => {
          console.log(tools);
          vm.tools = tools;
        });
    }

    function linkTool() {
      if (!vm.toolSelected) {
        return;
      }

      vm.loading = true;
      vm.linkMethod({
        id: vm.organizationId,
        nk: vm.entityId,
        fk: vm.toolSelected.id,
      })
        .$promise.then((result) => {
          vm.loading = false;
          LocalStorageService.clear();
          vm.modalInstance.close(result);
        })
        .catch((err) => {
          vm.linkError = utils.getHTTPError(err).message;
          vm.loading = false;
          console.log(vm.linkError);
        });
    }
  }
})();
