(() => {
  const angular = window.angular;

  LinePaper.$inject = ['BasePaper', '$rootScope', '$timeout', 'AppUtils'];

  angular.module('app').service('LinePaper', LinePaper);

  function LinePaper(BasePaper, $root, $timeout, utils) {
    class LinePaperCanvas extends BasePaper.Canvas {
      constructor(container, targetElement, size, options = {}) {
        options.style = Object.assign({ strokeWidth: 3 }, options.style);
        super(container, targetElement, size, options);
        this.PortClass = LinePaperPort;
      }

      createFigure(sensorConfig) {
        sensorConfig.hidden =
          typeof sensorConfig.hidden === 'boolean' ? sensorConfig.hidden : false;
        let points = sensorConfig.points;
        if (!points) {
          return;
        }

        let path = [];
        const rect = this.svg.getBoundingClientRect();
        points.forEach((current, index) => {
          const x = this.checkLimits(this.applyRatio(current.x), rect.width);
          const y = this.checkLimits(this.applyRatio(current.y), rect.height);
          path.push([index === 0 ? 'M' : 'L', x, y]);
        });
        path = this.getArrowPath(path);

        let figure = this.paper.path(path).attr({
          stroke: sensorConfig.color || this.style.stroke,
          fill: sensorConfig.color || this.style.stroke,
          'stroke-width': this.style.strokeWidth,
          'stroke-dasharray': '---',
          'fill-opacity': 0.3,
        });

        figure.canvas = this;
        figure.node.style.cursor = 'pointer';
        figure._sensor_config = sensorConfig;

        if (sensorConfig.hidden) {
          this.hideFigure(figure, true);
        }

        const _this = this;
        figure.click(function (e) {
          e.stopPropagation();
          _this.select(this);
        });

        return figure;
      }

      getArrowPath(linePath) {
        const x1 = linePath[0][1];
        const y1 = linePath[0][2];
        const x2 = linePath[1][1];
        const y2 = linePath[1][2];
        const arrowWidth = 13;

        let dx = x2 - x1;
        let dy = y2 - y1;
        let len = Math.sqrt(dx * dx + dy * dy);
        dx = dx / len;
        dy = dy / len;

        let mid = { x: (x2 + x1) / 2, y: (y2 + y1) / 2 };
        let start = { x: mid.x - arrowWidth * dy, y: mid.y + arrowWidth * dx };

        let angle = Math.atan2(dy, dx);

        let p1 = { x: start.x + Math.cos(angle), y: start.y + Math.sin(angle) };
        let p2 = {
          x: start.x - 2 * Math.sin(Math.PI - angle),
          y: start.y - 2 * Math.cos(Math.PI - angle),
        };
        let p3 = { x: start.x - Math.cos(angle), y: start.y - Math.sin(angle) };

        if (linePath.length < 6) {
          for (let i = linePath.length - 1; i < 6; i++) {
            linePath.push([]);
          }
        }
        linePath[2] = ['M', start.x, start.y];
        linePath[3] = ['L', p1.x, p1.y];
        linePath[4] = ['L', p2.x, p2.y];
        linePath[5] = ['L', p3.x, p3.y];
        linePath[6] = ['L', start.x, start.y];

        return linePath;
      }

      afterClick() {
        this.endFigure(this.tempFigure);
      }

      endFigure(figure) {
        const _this = this;
        figure.click(function (e) {
          e.stopPropagation();
          _this.select(this);
        });
        figure.attr({ stroke: '#fff', 'stroke-dasharray': '-' });

        const path = figure.getPath();
        const p1 = { x: this.unApplyRatio(path[0][1]), y: this.unApplyRatio(path[0][2]) };
        const p2 = { x: this.unApplyRatio(path[1][1]), y: this.unApplyRatio(path[1][2]) };

        const linePoints = [p1, p2];

        figure.canvas = this;
        this.figures.push(figure);
        $timeout(() => {
          figure._sensor_config = {
            points: linePoints,
            id: utils.generateUUID(),
            color: '#ffffff',
          };
          $root.$emit('det_draw:add' + _this.context, figure._sensor_config);
          _this.state = null;
        });
        _this.select(figure);

        this.tempPath = null;
        this.tempFigure = null;
      }

      updateTempFigure(x, y) {
        if (isNaN(x) || isNaN(y)) {
          return;
        }
        if (this.tempFigure) {
          this.tempPath[1] = ['L', x, y];
          this.tempFigure.attr({ path: this.getArrowPath(this.tempPath) });
        }
      }

      showPorts(figure) {
        for (let i = 0; i < 2; i++) {
          let port = new this.PortClass(figure, i, this.style.port);
          this.ports.push(port);
        }
      }
    }

    // Port

    class LinePaperPort extends BasePaper.Port {
      onMove() {
        const _this = this;
        return function () {
          if (_this.canvas.readOnly) {
            return;
          }

          const pointIndex = _this.index;
          const target = _this.target;
          const drawArea = _this.canvas.drawArea;
          const rect = _this.svg.getBoundingClientRect();

          let x = _this.checkLimits(drawArea.ox, rect.width);
          let y = _this.checkLimits(drawArea.oy, rect.height);

          let path = target.getPath();
          path[pointIndex][1] = x;
          path[pointIndex][2] = y;

          path = _this.canvas.getArrowPath(path);

          target.attr({ path: path });
          _this.attr({ cx: x, cy: y });
          $timeout(() => {
            if (target._sensor_config) {
              target._sensor_config.points[pointIndex] = {
                x: _this.canvas.unApplyRatio(x),
                y: _this.canvas.unApplyRatio(y),
              };
            }
          });
        };
      }
    }

    return {
      Canvas: LinePaperCanvas,
      Port: LinePaperPort,
    };
  }
})();
