;(function () {
  const angular = window.angular;

  Controller.$inject = ['$element', 'AppUtils', 'ProjectTag', 'Customer', '$translate'];

  angular
    .module('app')
    .component('linkTag', {
      template:'<div ng-class="{transparent: $ctrl.loading}"><div class="modal-header"><span translate>buttons.add</span> <span translate>entities.project._tag.modelName</span></div><div ng-style="{opacity: $ctrl.loading? 0: 1}" style="min-height: 200px"><div class="paddingT5"><table class="table table-bordered table-hover no-margin" id="tag-table"></table></div><div class="alert alert-warning no-margin text-center" ng-if="$ctrl.loadError">{{$ctrl.loadError}}</div><div class="alert alert-warning no-margin text-center" ng-if="$ctrl.linkError">{{$ctrl.linkError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="$ctrl.setTags()" translate>buttons.add</button></div></div></div><spinner show="$ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
      controller: Controller,
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function Controller ($element, utils, ProjectTag, Customer, $translate) {
    let vm = this;

    vm.$onInit = function () {
      vm.project = vm.resolve.project;

      vm.loading = true;

      ProjectTag.find()
        .$promise
        .then(tags => {
          vm.loading = false;
          let currentTags = utils.arrayToObject(vm.project.tags || [], 'id');
          vm.tags = tags;
          tags.forEach(current => {
            current.checked = !!currentTags[current.id];
          });

          const selected = [];
          vm.project.tags.forEach(current => {
            const index = vm.tags.findIndex(tag => tag.id === current.id);
            if (index !== -1) {
              selected.push(index);
            }
          });

          initTable(tags, selected);
        })
        .catch(err => {
          vm.loadError = utils.getHTTPError(err).message;
          console.log(vm.loadError);
          vm.loading = false;
        });

      vm.setTags = setTags;
    };

    function initTable (data, selected) {
      let dtOptions = {
        dom: '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
          '<div tr>' +
          '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        order: [1, 'asc'],
        paging: false,
        info: false,
        data: data,
        scrollY: '400px',
        select: {
          selector: 'td:not(.control)',
          style: 'multi'
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-checkbox',
            defaultContent: '',
            width: '10px'
          },
          {
            data: 'name',
            title: $translate.instant('entities._all.name'),
            render: function (data, type, current) {
              return $translate.instant('plugins.' + data);
            }
          }
        ]
      };

      vm.dtInstance = $element.find('#tag-table').DataTable(dtOptions);

      if (Array.isArray(selected) && selected.length) {
        vm.dtInstance.rows(selected).select();
      }
    }

    function setTags () {
      let selected = vm.dtInstance.rows({ selected: true }).data().toArray();
      let toLink = [];
      let toUnlink = [];

      let map = utils.arrayToObject(selected, 'id');

      vm.tags.forEach(tag => {
        if (tag.checked) {
          if (!map[tag.id]) {
            toUnlink.push(tag.id);
          }
        } else if (map[tag.id]) {
          toLink.push(tag.id);
        }
      });

      vm.loading = true;
      executeRequest(toLink, 'link')
        .then(() => {
          return executeRequest(toUnlink, 'unlink');
        })
        .then(errors => {
          vm.loading = false;
          if (errors && errors.length) {
            vm.errors = errors;
            return;
          }
          vm.modalInstance.close();
        })
        .catch(err => {
          vm.loading = false;
        });
    }

    function executeRequest (array, operation) {
      return utils.Promise(resolve => {
        let count = 0;
        const method = `prototype$__${operation}__projects__tags`;

        const errors = [];
        if (array.length) {
          array.forEach(tagId => {
            let data = undefined;
            Customer[method]({
              id: vm.project.customerId,
              nk: vm.project.id,
              fk: tagId
            }, data)
              .$promise
              .then(result => {
                count++;
                if (count === array.length) {
                  resolve(errors);
                }
              })
              .catch(err => {
                count++;
                errors.push(utils.getHTTPError(err));
                if (count === array.length) {
                  resolve(errors);
                }
              });
          });
        } else {
          resolve();
        }
      });
    }
  }

})();
