;(function () {
  'use strict';

  const angular = window.angular;

  angular
    .module('app')
    .directive('toggleSwitch', function () {
      return {
        restrict: 'E',
        require: '^ngModel',
        scope: {
          activeColor: '=',
          disableColor: '=',
          size: '=',
          indeterminate: '='
        },
        template: `<label class="toggle-switch toggle-switch-{{size}}" ng-class="{active: toggleValue === true, indeterminate: indeterminate}">
            <input type="checkbox" ng-model="toggleValue" ng-change="toggle()" ng-indeterminate="indeterminate">
        </label>`,
        link: (scope, el, attrs, ngModel) => {
          ngModel.$viewChangeListeners.push(function () {
            scope.$eval(attrs.ngChange);
          });

          ngModel.$render = function () {
            scope.toggleValue = ngModel.$modelValue;
          };

          scope.toggle = function () {
            ngModel.$setViewValue(scope.toggleValue);
          };
        }
      };
    });
})();
