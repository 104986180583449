;(function () {
  'use strict';

  let angular = window.angular;

  ProfileImageController.$inject = ['AppUtils'];

  angular
    .module('app')
    .component('accountProfileImage', {
      template:'<form name="ctrl.profileImageForm" ng-hide="ctrl.saving"><div class="modal-header"><button type="button" class="close" data-dismiss="modal"><i class="fas fa-times" ng-click="ctrl.modalInstance.dismiss()"></i></button> <span translate>form.actions.selectImage</span></div><div class="modal-body"><image-upload label="\'form.actions.selectImage\' | translate" image="ctrl.image" crop="true"></image-upload><div ng-show="ctrl.uploadError" class="alert alert-warning text-center no-margin"><strong>{{ctrl.uploadError}}</strong></div></div><div class="modal-footer no-margin"><button type="button" class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="ctrl.saveImage()" translate>buttons.update</button></div></form><div style="position: relative; height: 400px" ng-show="ctrl.saving"><spinner css-class="\'small\'" show-logo="true" show="ctrl.saving"></spinner></div>',
      controller: ProfileImageController,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  function ProfileImageController (utils) {
    let vm = this;
    let imagePreview;
    vm.$onInit = function onInit () {
      vm.entity = vm.resolve.entity;
      vm.entityId = vm.resolve.entityId;
      vm.property = vm.resolve.property;

      imagePreview = $('#img-preview');
      vm.saveImage = saveImage;
      vm.image = {};
    };

    function saveImage () {
      let file = vm.image.file;
      let cropInstance = vm.image.cropInstance;
      if (file) {
        let params = {
          entityType: vm.entity,
          entityId: vm.entityId
        };

        let url = `${window.apiPath}/${vm.entity}s/${vm.entityId}/container/upload?property=${vm.property}`;
        if (cropInstance) {
          let cropped = typeof cropInstance.getCroppedCanvas === 'function'
            ? cropInstance.getCroppedCanvas({ maxWidth: 500 })
            : null;
          if (cropped && typeof cropped.toBlob === 'function') {
            cropped.toBlob(blob => {
              let croppedFile = utils.blobToFile(blob, file ? file.name : '');
              uploadImage(url, croppedFile, params);
            });
          }
        } else {
          uploadImage(url, file, params);
        }
      }
    }

    function uploadImage (url, file, params) {
      utils.uploadFileToUrl(url, file, params, function (err, data) {
        if (err) {
          vm.uploadError = err;
          vm.saving = false;
        } else {
          vm.saving = false;
          vm.modalInstance.close(data);
        }
      });
    }
  }
})();
