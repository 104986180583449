(() => {
  angular.module('app').component('detectionHistogram', {
    template:'<div class="chart"></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      sensor: '<',
      data: '<',
      filterByZone: '<',
      zone: '<',
      maxHeight: '<',
    },
  });

  Controller.$inject = ['$element', '$timeout', '$translate', '$rootScope'];

  function Controller($element, $timeout, $translate, $root) {
    const vm = this;
    let firstRun = true;
    vm.$onInit = function () {
      $timeout(() => {
        vm.target = $element.find('.chart')[0];
        firstRun = false;
        renderHistogram();
      });
    };

    vm.$onChanges = function (changes) {
      if (firstRun) {
        return;
      }
      if (changes.data) {
        renderHistogram();
      }
    };

    function renderHistogram() {
      const sensor = vm.sensor;
      const data = vm.data;

      const histogramData = getHistogramData(data);

      const chart = echarts.init(vm.target, $root.darkMode ? 'dark' : 'default');
      const option = {
        tooltip: {
          formatter: (params) => {
            const data = params.data;
            const value = data[1];
            return `${data[0]}- ${data[2]} <br/> ${$translate.instant('general.amount')}: ${value}`;
          },
        },
        grid: {
          left: 65,
          right: 20,
          top: 10,
          bottom: 55,
        },
        xAxis: {
          name: `${$translate.instant('general.size')}${sensor.unit ? ` (${sensor.unit})` : ''}`,
          type: 'category',
          nameLocation: 'center',
          nameGap: 30,
        },
        yAxis: {
          type: 'value',
          name: 'Cantidad',
          nameLocation: 'center',
          nameGap: 40,
        },
        series: [
          {
            data: histogramData,
            type: 'bar',
          },
        ],
      };

      chart.setOption(option);
    }

    function getHistogramData(data) {
      const histogramData = [];

      const values = (data?.content?.objects || [])
        .filter((c) => {
          return c.class === 'material' && c.value;
        })
        .map((c) => c.value);

      const bins = 10;
      const min = Math.min(...values);
      const max = Math.max(...values);
      const binSize = (max - min) / bins;

      for (let i = 0; i < bins; i++) {
        let binMin = min + i * binSize;
        let binMax = min + (i + 1) * binSize;

        if (binSize > 1) {
          binMin = Math.round(binMin);
          binMax = Math.round(binMax);
        }

        const count = values.filter((v) => {
          if (i === bins - 1) {
            return v >= binMin && v <= binMax;
          }

          return v >= binMin && v < binMax;
        }).length;
        histogramData.push([binMin, count, binMax]);
      }

      return histogramData;
    }
  }
})();
