;(function () {
  let angular = window.angular;

  Controller.$inject = [
    '$element',
    '$timeout',
    '$filter',
    'Customer',
    'AppUtils',
    'LogUtils',
  ];

  angular
    .module('app')
    .component('connectionDeviceLogs', {
      template:'<div><div class="modal-header">Logs: {{ctrl.device.name}} <span ng-if="ctrl.module">| {{ctrl.module}}</span></div><div class="modal-body"><div class="form-inline" style="position: absolute;right: 10px;top: 15px; z-index: 1"><div class="form-group"><label class="no-margin">Elementos:</label><select class="form-control input-sm marginL10" ng-model="ctrl.logsLength" ng-change="ctrl.loadLogs()"><option ng-value="50">50</option><option ng-value="100">100</option><option ng-value="500">500</option><option ng-value="1000">1000</option></select></div><div class="form-group"><div class="input-group input-group-sm marginL10"><span class="input-group-btn"><button class="btn btn-default" ng-click="ctrl.toggleAutoPlay()"><i class="fas fa-fw" ng-class="{\'fa-play\': !ctrl.autoPlay, \'fa-stop-circle\': ctrl.autoPlay}"></i></button></span><select class="form-control" ng-model="ctrl.autoPlayInterval" ng-change="ctrl.changeAutoPlayInterval()"><option ng-value="5000">5s</option><option ng-value="10000">10s</option><option ng-value="30000">30s</option></select></div></div></div><div style="min-height: 400px; margin: 0 -15px" class="relative"><table class="table table-condensed" id="logs-table"></table><spinner css-class="\'small\'" show-logo="true" show="ctrl.loading"></spinner></div></div><div ng-if="ctrl.loadError" class="alert alert-warning text-center marginB0">{{ctrl.loadError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.close</button></div></div>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    });

  function Controller ($element, $timeout, $filter, Customer, utils, LogUtils) {
    let vm = this;

    vm.$onInit = function onInit () {
      vm.device = vm.resolve.device ? angular.copy(vm.resolve.device) : {};
      vm.module = vm.resolve.module;
      vm.logsLength = 100;
      vm.autoPlayInterval = 5000;

      vm.loadLogs = loadLogs;
      vm.toggleAutoPlay = toggleAutoPlay;
      vm.changeAutoPlayInterval = changeAutoPlayInterval;

      loadLogs();
    };

    vm.$onDestroy = () => {
      cancelAutoPlay();
    };

    function cancelAutoPlay () {
      if (vm.autoPlayTimeout) {
        $timeout.cancel(vm.autoPlayTimeout);
        vm.autoPlayTimeout = null;
      }
    }

    function changeAutoPlayInterval () {
      if (vm.autoPlay) {
        cancelAutoPlay();
        vm.autoPlayTimeout = $timeout(() => {
          initAutoPlay();
        }, vm.autoPlayInterval);
      }
    }

    function toggleAutoPlay () {
      vm.autoPlay = !vm.autoPlay;
      cancelAutoPlay();
      if (vm.autoPlay) {
        vm.autoPlayTimeout = $timeout(() => {
          initAutoPlay();
        }, vm.autoPlayInterval);
      }
    }

    function initAutoPlay () {
      loadLogs(true)
        .then(() => {
          vm.autoPlayTimeout = $timeout(() => {
            initAutoPlay();
          }, vm.autoPlayInterval);
        });
    }

    function loadLogs () {
      const query = {
        agent: {},
        modules: {},
      };

      let since;
      if (vm.autoPlay && vm.lastLoaded) {
        const lastLoaded = new Date(vm.lastLoaded);
        since = lastLoaded.getTime() / 1000;
      }

      if (vm.module) {
        query.modules[vm.module] = {tail: vm.logsLength, since: since};
        query.agent = {tail: 1};
      } else {
        query.agent = {tail: vm.logsLength, since: since};
      }

      vm.loadError = null;
      vm.loading = true;
      return Customer
        .prototype$getDeviceLogs({
          id: vm.device.customerId,
          nk: vm.device.id,
          query: query,
        })
        .$promise
        .then((result) => {
          result = result.content;
          const logs = result.agent || [];
          if (result.modules) {
            for (let module in result.modules) {
              Array.prototype.push.apply(logs, result.modules[module]);
            }
          }
          vm.logs = !vm.autoPlay || !vm.logs ? [] : vm.logs;
          logs.forEach(current => {
            const log = LogUtils.parse(current);
            const key = `${log.date}_${log.service}_${log.state}_${log.content.substring(0, 50)}`;
            log._id = key;

            if (vm.autoPlay) {
              const exist = vm.logs.findIndex(current => current._id === key) !== -1;
              if (!exist) {
                vm.logs.push(log);
              }
            } else {
              vm.logs.push(log);
            }
          });

          vm.logs.sort((a, b) => a._id > b._id ? -1 : 1);

          vm.logs = vm.logs.slice(0, vm.logsLength);
          const lastLog = vm.logs[0];
          vm.lastLoaded = lastLog ? lastLog.date || new Date() : null;
          vm.loading = false;
          initLogTable();
        })
        .catch(err => {
          vm.loadError = utils.getHTTPError(err);
          vm.loading = false;
          cancelAutoPlay();
          vm.autoPlay = false;
        });
    }

    function initLogTable () {
      if (vm.dtInstance) {
        vm.dtInstance.clear();
        vm.dtInstance.rows.add(vm.logs);
        vm.dtInstance.draw();
        return;
      }

      let dtOptions = {
        dom:
          '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
          '<div tr>' +
          '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        order: [1, 'DESC'],
        paging: false,
        info: false,
        scrollY: 550,
        data: vm.logs,
        columns: [{
          title: '',
          data: 'status',
          orderable: false,
          className: 'paddingLR5',
          width: 10,
          render: (value, type) => {
            return value && type === 'display' ? `<span title="${value}" class="log-status log-${value.toLowerCase()}"></span>` : value;
          },
        }, {
          title: 'Date',
          width: 120,
          data: 'date',
          render: (value, type) => {
            return type === 'display' || type === 'filter' ? $filter('date')(value, 'MMM dd HH:mm:ss') : value;
          },
        }, {
          title: 'Service',
          width: 300,
          data: 'service',
        }, {
          title: 'Content',
          data: 'content',
        }],
      };

      let table = $element.find('#logs-table');
      vm.dtInstance = table.DataTable(dtOptions);
    }
  }
})();
