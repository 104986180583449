;(function () {
  'use strict';

  let angular = window.angular;

  EdgeAgentFormController.$inject = ['Customer', 'AppUtils'];

  angular
    .module('app')
    .component('deviceEdgeAgentRemove', {
      template:'<div ng-class="{transparent: ctrl.loading}"><div class="modal-header"><span translate>buttons.delete</span> <span translate>entities.edgeagent.modelName</span></div><div class="modal-body text-center"><div translate data-translate-values="{model: \'entities.edgeagent.modelName\', name: ctrl.edgeAgent.name}">general._messages.remove</div></div><div ng-if="ctrl.removeEdgeAgentError" class="alert alert-warning text-center marginB0 marginT10">{{ctrl.removeEdgeAgentError}}</div><div class="modal-footer"><button class="btn btn-default pull-left" ng-click="ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-danger" ng-click="ctrl.removeEdgeAgent()" translate>buttons.delete</button></div></div><spinner show="ctrl.loading" show-logo="true" css-class="\'small\'"></spinner>',
      controller: EdgeAgentFormController,
      controllerAs: 'ctrl',
      bindings: {
        modalInstance: '<',
        resolve: '<',
      },
    });

  function EdgeAgentFormController(Customer, utils) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.customerId = vm.resolve.customerId;
      vm.device = vm.resolve.device;
      vm.edgeAgent = vm.resolve.edgeAgent;

      vm.removeEdgeAgent = removeEdgeAgent;
    };

    function removeEdgeAgent() {
      vm.loading = true;
      Customer
        .prototype$__destroyById__devices__edgeAgents({
          id: vm.customerId || vm.device.customerId,
          nk: vm.device.id,
          fk: vm.edgeAgent.id,
        })
        .$promise
        .then(function (result) {
          vm.loading = false;
          vm.modalInstance.close();
        })
        .catch(function (err) {
          vm.loading = false;
          let error = utils.getHTTPError(err);
          vm.removeEdgeAgentError = error.code || error.message;
        });
    }
  }
})();
