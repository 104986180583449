(function () {
  'use strict';

  let angular = window.angular;

  ProfileEditController.$inject = ['AppUtils'];

  angular.module('app').component('twoFactorForm', {
    template:'<form ng-show="!$ctrl.loading" name="$ctrl.updateForm" class="validate" novalidate autocomplete="off"><div class="modal-header"><button type="button" class="close" ng-click="$ctrl.modalInstance.dismiss()"><i class="fas fa-times"></i></button> <span translate>form.2fa.title</span></div><div class="modal-body paddingB0"><div><h4 translate style="font-weight: bold">form.2fa.subtitle1</h4></div><div><ol><li translate>form.2fa.instruct1</li><li translate>form.2fa.instruct2</li><li translate>form.2fa.instruct3</li></ol></div><div><h4 translate style="font-weight: bold">form.2fa.subtitle2</h4></div><div style="text-align: center"><div id="qrcode" class="qrcontainer" style="margin-top: 20px; margin-bottom: 20px"></div></div><div><h4 translate style="font-weight: bold">form.2fa.subtitle3</h4></div><div><span>SecretKey:&nbsp&nbsp</span><span>{{$ctrl.secretCode}}</span></div><div><h4 translate style="font-weight: bold">form.2fa.subtitle4</h4></div><div style="padding-bottom: 10px"><input id="2fa-input" name="twoFactorCode" ng-model="$ctrl.twoFactorCode" translate-attr="::{placeholder: \'form.2fa.inputPlaceHolder\'}" class="form-control input-sm" required></div></div><div class="alert alert-warning no-margin text-center" ng-show="$ctrl.verifyError">{{$ctrl.verifyError}}</div><div class="modal-footer"><button type="button" class="btn btn-default pull-left" ng-click="$ctrl.modalInstance.dismiss()" translate>buttons.cancel</button> <button class="btn btn-primary" ng-click="$ctrl.validate2fa()" translate>buttons.enable2fa</button></div></form><div style="position: relative; height: 600px" ng-show="$ctrl.loading"><spinner css-class="\'small\'" show-logo="true" show="$ctrl.loading"></spinner></div>',
    controller: ProfileEditController,
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  function ProfileEditController(utils) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.user = angular.copy(vm.resolve.user);
      vm.Model = vm.resolve.model;
      vm.validate2fa = validate2fa;
      vm.loading = true;
      vm.Model.prototype$otpGenerate({
        id: vm.user.id,
      })
        .$promise.then((result) => {
          vm.secretCode = result.base32;
          new QRCode(document.getElementById('qrcode'), {
            text: result.otpauthUrl,
            width: 192,
            height: 192,
            colorDark: '#333',
            colorLight: '#fff',
            correctLevel: QRCode.CorrectLevel.L,
          });
        })
        .then(() => {
          vm.loading = false;
        })
        .catch((err) => {
          vm.loading = false;
          vm.verifyError = utils.getHTTPError(err);
        });
    };

    function validate2fa() {
      vm.loading = true;
      vm.Model.prototype$otpVerify(
        {
          id: vm.user.id,
        },
        {
          token: vm.twoFactorCode,
        }
      )
        .$promise.then((result) => {
          vm.loading = false;

          vm.modalInstance.close({
            success: true,
          });
        })
        .catch((err) => {
          vm.loading = false;
          vm.verifyError = utils.getHTTPError(err);
        });
    }
  }
})();
