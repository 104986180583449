(function () {
  'use strict';

  let angular = window.angular;

  EventRouter.$inject = ['$stateProvider'];

  angular.module('app').config(EventRouter);

  function EventRouter($stateProvider) {
    $stateProvider
      .state('member.var.show.customer.show.project.events', {
        url: '/events',
        redirectTo: 'member.var.show.customer.show.project.events.dashboard.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.event.modelNamePl" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/app/dashboard-common.module.min.js',
                window.assetsPath + '/js/app/event-management.module.min.js',
                window.assetsPath + `/css/event-management-${style}.css`,
              ]);
            },
          ],
          adminUsers: [
            'Var',
            '$transition$',
            'AppUtils',
            function (Var, $transition$, utils) {
              let params = $transition$.params();
              return Var.admins({ id: params.varId }).$promise.catch((err) => {
                throw utils.getHTTPError(err, {
                  redirectTo: 'member.var.show.customer.show.home',
                  params: { customerId: params.varId },
                });
              });
            },
          ],
          users: [
            'Customer',
            '$transition$',
            'AppUtils',
            function (Customer, $transition$, utils) {
              let params = $transition$.params();
              return Customer.managers({ id: params.customerId }).$promise.catch((err) => {
                throw utils.getHTTPError(err, {
                  redirectTo: 'member.var.show.customer.show.home',
                  params: { customerId: params.customerId },
                });
              });
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.events.report', {
        url: '/report?from&to',
        component: 'xompassEventReport',
        authenticated: true,
        params: {
          from: { type: 'string', dynamic: true, squash: true },
          to: { type: 'string', dynamic: true, squash: true },
        },
        ncyBreadcrumb: { label: '{{"entities.tool.__tools.EventReport" | translate}}' },
        resolve: {
          assets: [
            'Customer',
            '$rootScope',
            'AppUtils',
            (Customer, $root, utils) => {
              return Customer.prototype$__get__assets({
                id: $root.customerId,
                filter: {
                  where: { projectId: $root.projectId },
                  fields: ['id', 'name', 'type', 'customerId', 'assetId'],
                  include: [
                    {
                      relation: 'eventGroups',
                      scope: { fields: ['name', 'color', 'id', 'priority'] },
                    },
                    {
                      relation: 'eventTriggers',
                      scope: {
                        fields: ['id', 'name', 'assetId', 'eventGroupId', 'subject', 'enabled'],
                      },
                    },
                  ],
                },
              }).$promise.then((result) => {
                prepareAssets(result, utils);
                return result;
              });
            },
          ],
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/pdf-vendors.min.js',
                window.assetsPath + '/js/vendors/excel-vendors.min.js',
              ]);
            },
          ],
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassEventReport');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.var.show.customer.invalidTool',
                  params: { tool: 'xompassEventReport' },
                });
              }
            },
          ],
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })

      .state('member.var.show.customer.show.project.events.history', {
        url: '/history?tab',
        component: 'xompassEventHistory',
        authenticated: true,
        params: {
          tab: { type: 'string', dynamic: true },
        },
        ncyBreadcrumb: { label: '{{"entities.tool.__tools.EventHistory" | translate}}' },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassEventHistory');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.var.show.customer.invalidTool',
                  params: { tool: 'xompassEventHistory' },
                });
              }
            },
          ],
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.events.dashboard', {
        url: '/dashboard?date',
        redirectTo: 'member.var.show.customer.show.project.events.dashboard.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.dashboard" | translate}}',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find(
                (tool) =>
                  tool.module === 'xompassEventDashboard' ||
                  tool.module === 'xompassEventDashboardDemo'
              );
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.var.show.customer.invalidTool',
                  params: { tool: 'xompassEventDashboard' },
                });
              }
            },
          ],
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.events.dashboard.home', {
        url: '/',
        component: 'eventDashboardHome',
        authenticated: true,
        resolve: {
          dashboards: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { where: { type: 'AUTO' } };
              return EntityService.find('EventDashboard', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.events.dashboard.new', {
        url: '/new',
        component: 'eventDashboardEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"buttons.new" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.events.dashboard.show', {
        url: '/:dashboardId?false_positive',
        component: 'eventDashboardShow',
        redirectTo: 'member.var.show.customer.show.project.events.dashboard.show.management',
        authenticated: true,
        params: {
          false_positive: { type: 'string' },
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          dashboard: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadEventDashboard(EntityService, $transition$.params(), utils, 'AUTO');
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.dashboard.name}}',
        },
      })
      .state('member.var.show.customer.show.project.events.dashboard.show.management', {
        url: '/management',
        component: 'eventDashboardManagement',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.management" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.events.dashboard.show.situational', {
        url: '/situational',
        component: 'eventDashboardSituational',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.map" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.events.dashboard.show.editor', {
        url: '/editor',
        component: 'eventDashboardEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.editor" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.events.dashboard.show.history', {
        url: '/history?tab&false_positive',
        component: 'xompassEventHistory',
        authenticated: true,
        params: {
          tab: { type: 'string', dynamic: true },
          false_positive: { type: 'string' },
        },
        ncyBreadcrumb: { label: '{{"views.tools.EventDashboard.breadcrumb.history" | translate}}' },
      })

      .state('member.var.show.customer.show.project.events.store-anomalies', {
        url: '/store-anomalies?date',
        redirectTo: 'member.var.show.customer.show.project.events.store-anomalies.home',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.dashboard" | translate}}',
        },
        resolve: {
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassEventDashboard');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassEventDashboard' },
                });
              }
            },
          ],
          hours: () => 24,
          date: () => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            date.setHours(0, 0, 0, 0);
            return date;
          },
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.events.store-anomalies.report', {
        url: '/report?from&to',
        component: 'storeAnomaliesReport',
        authenticated: true,
        params: {
          from: { type: 'string', dynamic: true, squash: true },
          to: { type: 'string', dynamic: true, squash: true },
        },
        ncyBreadcrumb: { label: '{{"entities.tool.__tools.EventReport" | translate}}' },
        resolve: {
          assets: [
            'Customer',
            '$rootScope',
            'AppUtils',
            (Customer, $root, utils) => {
              return Customer.prototype$__get__assets({
                id: $root.customerId,
                filter: {
                  where: { projectId: $root.projectId, type: 'Store' },
                  fields: ['id', 'name', 'type', 'customerId', 'assetId'],
                  include: [
                    {
                      relation: 'eventGroups',
                      scope: { fields: ['name', 'color', 'id', 'priority'] },
                    },
                    {
                      relation: 'eventTriggers',
                      scope: {
                        fields: ['id', 'name', 'assetId', 'eventGroupId', 'subject', 'enabled'],
                      },
                    },
                  ],
                },
              }).$promise.then((result) => {
                prepareAssets(result, utils);
                return result;
              });
            },
          ],
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/pdf-vendors.min.js',
                window.assetsPath + '/js/vendors/excel-vendors.min.js',
              ]);
            },
          ],
          hasTool: [
            'tools',
            'AppUtils',
            function (tools, utils) {
              const hasTool = tools.find((tool) => tool.module === 'xompassStoreAnomaliesReport');
              if (!hasTool) {
                throw utils.getHTTPError(new Error('Not allowed'), {
                  redirectTo: 'member.customer.invalidTool',
                  params: { tool: 'xompassStoreAnomaliesReport' },
                });
              }
            },
          ],
          defaultComments: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadDefaultComments(true);
            },
          ],
          eventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadEventStates(true);
            },
          ],
          globalEventStates: [
            'EventStateService',
            (EventStateService) => {
              return EventStateService.loadGlobalEventStates(true);
            },
          ],
        },
      })
      .state('member.var.show.customer.show.project.events.store-anomalies.home', {
        url: '/',
        component: 'eventDashboardHome',
        authenticated: true,
        resolve: {
          dashboardType: () => 'STORE_ANOMALIES',
          dashboards: [
            'EntityService',
            '$transition$',
            function (EntityService, $transition$) {
              const options = angular.copy($transition$.params());
              options.filter = { where: { type: 'STORE_ANOMALIES' } };
              return EntityService.find('EventDashboard', options);
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"general.gallery" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.events.store-anomalies.show', {
        url: '/:dashboardId?false_positive',
        component: 'storeAnomaliesShow',
        redirectTo: 'member.var.show.customer.show.project.events.store-anomalies.show.management',
        authenticated: true,
        params: {
          false_positive: { type: 'string' },
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + `/js/vendors/chart-vendors-${style}.min.js`,
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          dashboard: [
            'EntityService',
            '$transition$',
            'AppUtils',
            function (EntityService, $transition$, utils) {
              return loadEventDashboard(
                EntityService,
                $transition$.params(),
                utils,
                'STORE_ANOMALIES'
              );
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{$resolve.dashboard.name}}',
        },
      })
      .state('member.var.show.customer.show.project.events.store-anomalies.show.management', {
        url: '/management',
        component: 'storeAnomaliesDashboardManagement',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.management" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.events.store-anomalies.show.editor', {
        url: '/editor',
        component: 'storeAnomaliesEditor',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"views.tools.EventDashboard.breadcrumb.editor" | translate}}',
        },
      })
      .state('member.var.show.customer.show.project.events.store-anomalies.show.history', {
        url: '/history?tab&false_positive',
        component: 'xompassEventHistory',
        authenticated: true,
        params: {
          tab: { type: 'string', dynamic: true },
          false_positive: { type: 'string' },
        },
        ncyBreadcrumb: { label: '{{"views.tools.EventDashboard.breadcrumb.history" | translate}}' },
      });

    function loadEventDashboard(EntityService, params, utils, type, include) {
      const redirect = {
        AUTO: 'member.var.show.customer.show.project.events.dashboard.home',
        COVID_19: 'member.var.show.customer.show.project.events.quarantine19.home',
        //'GENERIC': 'member.var.show.customer.show.project.gis.home',
        //'SAFE_CITY': 'member.var.show.customer.show.project.fiber.home'
      };

      const assetInclude = [
        {
          relation: 'eventGroups',
          scope: { fields: ['name', 'color', 'id', 'priority'] },
        },
        {
          relation: 'eventTriggers',
          scope: {
            fields: ['id', 'name', 'assetId', 'eventGroupId', 'subject', 'enabled'],
          },
        },
      ];
      if (type === 'STORE_ANOMALIES') {
        assetInclude.push({
          relation: 'staffs',
          scope: { fields: ['type', 'name', 'referenceId'] },
        });
      }

      const defaultInclude = [
        'managers',
        {
          relation: 'assets',
          scope: {
            fields: [
              'id',
              'name',
              'type',
              'icon',
              'path',
              'customerId',
              '_config',
              'assetId',
              'currentStateId',
              'uri',
            ],
            include: assetInclude,
          },
        },
      ];

      const options = angular.copy(params);
      options.entityId = options.dashboardId;
      options.filter = {
        where: { type: type },
        include: include || defaultInclude,
      };

      return EntityService.findById('EventDashboard', options)
        .then((dashboard) => {
          prepareAssets(dashboard.assets, utils);
          return dashboard;
        })
        .catch((err) => {
          console.log(err);
          throw utils.getHTTPError(err, {
            redirectTo: redirect[type],
            params: { customerId: params.customerId, projectId: params.projectId },
          });
        });
    }

    function prepareAssets(assets, utils) {
      assets.forEach((asset) => {
        asset.eventTriggers = utils.arrayToObject(asset.eventTriggers || [], 'id');
        asset.sensors = asset.sensors || [];
        asset.sensors.forEach((sensor) => {
          sensor.states = sensor.states || [];
          sensor.currentState = sensor.states.find((state) => {
            return state.assetStateId === asset.currentStateId;
          });
        });
      });
    }
  }
})();
