(function () {
  let angular = window.angular;
  let $ = window.jQuery;

  Controller.$inject = [
    '$element',
    '$timeout',
    '$uibModal',
    'Customer',
    'AppUtils',
    '$translate',
    'uiModalService'
  ];

  angular
    .module('app')
    .component('telegramChatTable', {
      template:'<div class="dataTable-toolbar paddingT0" ng-if="ctrl.linkButton || ctrl.unlinkButton"><div class="btn-group btn-group-sm"><button class="btn btn-default btn-label disabled" translate>general.actions</button> <button class="btn btn-primary" ng-if="ctrl.linkButton" ng-click="ctrl.linkTelegramChat()" style="min-width: 90px"><i class="fas fa-link"></i> <span translate>buttons.link</span></button> <button class="btn btn-primary" style="min-width: 90px" ng-if="ctrl.unlinkButton" ng-disabled="!ctrl.selectedTelegramChat" ng-click="ctrl.unlinkTelegramChat()"><i class="fas fa-unlink"></i> <span translate>buttons.unlink</span></button></div></div><table id="edgeAgents-table" class="table table-hover no-wrap" style="width: 100%"><thead><tr><th></th><th></th><th></th><th class="none">Id</th><th class="control"></th></tr></thead></table>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        entity: '<',
        instance: '<',
        linkButton: '<',
        unlinkButton: '<'
      }
    });

  function Controller($element, $timeout, $uibModal, Customer, utils, $translate, uiModalService) {
    let vm = this;

    vm.$onInit = function onInit() {
      vm.linkTelegramChat = linkTelegramChat;
      vm.unlinkTelegramChat = unlinkTelegramChat;

      vm.linkButton = typeof vm.linkButton !== 'boolean' ? true : vm.linkButton;
      vm.unlinkButton = typeof vm.unlinkButton !== 'boolean' ? true : vm.unlinkButton;
    };

    vm.$onChanges = function (changes) {
      if (changes && changes.instance) {
        vm.telegramChats = vm.instance.telegramChats;
        vm.telegramChatsMap = utils.arrayToObject(vm.telegramChats, 'id');

        vm.selectedTelegramChat = null;
        initTable(vm.telegramChats);
      }
    };

    function initTable(data) {
      if (vm.dtInstance) {
        vm.dtInstance.clear();
        vm.dtInstance.rows.add(data);
        vm.dtInstance.draw();
        return;
      }

      let dtOptions = {
        dom:
          '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
          '<div tr>' +
          '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        order: [1, 'asc'],
        paging: false,
        info: false,
        data: data,
        select: {
          selector: 'td:not(.control)',
          style: 'single'
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-radio-btn',
            render: checkboxRendered,
            width: '10px'
          },
          { data: 'name', title: $translate.instant('entities._all.name') },
          { data: 'chatId', title: $translate.instant('entities.telegramchat.chatId') },
          {
            data: 'id'
          },
          {
            data: null,
            render: () => '',
            orderable: false,
            width: '10px'
          }
        ],
        columnDefs: [
          {
            className: 'control',
            orderable: false,
            targets: [-1, -2]
          }
        ],
        responsive: {
          details: {
            type: 'column',
            target: -1
          }
        }
      };

      let table = $element.find('#edgeAgents-table');
      let dtInstance = (vm.dtInstance = table.DataTable(dtOptions));

      table.find('thead th').removeClass('select-radio-btn');

      table
        .find('tbody')
        .on('change', 'input:checkbox', function () {
          const input = angular.element(this);
          const row = vm.dtInstance.row(input.parents('tr'));
          const toggle = input.parent();
          const agent = row.data();

          toggle.addClass('disabled');
          agent.enabled = !agent.enabled;

          let promise;
          if (!agent.enabled) {
            promise = showConfirm(agent);
          } else {
            promise = Promise.resolve(true);
          }

          promise
            .then(confirmed => {
              if (!confirmed) {
                toggle.removeClass('disabled');
                return;
              }
              toggleEnabled(agent)
                .then(() => {
                  row.invalidate().draw();
                })
                .catch(err => {
                  toggle.removeClass('disabled');
                });
            });
        });

      dtInstance
        .on('select', function () {
          $timeout(function () {
            vm.selectedTelegramChat = dtInstance
              .rows({ selected: true })
              .data()
              .toArray()[0];
          });
        })
        .on('deselect', function () {
          $timeout(function () {
            vm.selectedTelegramChat = null;
          });
        });
    }

    function linkTelegramChat() {
      $uibModal
        .open({
          animation: false,
          bindToController: true,
          component: 'telegramChatLink',
          backdrop: 'static',
          resolve: {
            Entity: () => vm.entity,
            instance: () => vm.instance
          }
        })
        .result
        .then(result => {
          vm.telegramChats.push(result);
          vm.instance.telegramChats = vm.telegramChats;
          vm.telegramChatsMap = utils.arrayToObject(vm.telegramChats, 'id');

          vm.dtInstance.clear();
          vm.dtInstance.rows.add(vm.telegramChats);
          vm.dtInstance.draw();
          vm.selectedTelegramChat = null;
        })
        .catch(() => { });
    }

    function unlinkTelegramChat() {
      if (!vm.selectedTelegramChat) {
        return;
      }

      let instance = $uibModal.open({
        animation: false,
        backdrop: 'static',
        windowClass: 'modal-xs modal-warning',
        bindToController: true,
        component: 'telegramChatUnlink',
        resolve: {
          Entity: () => vm.entity,
          instance: () => vm.instance,
          telegramChat: () => vm.selectedTelegramChat
        }
      });

      instance.result
        .then(function (result) {
          let data = vm.dtInstance.rows().data().toArray();

          for (let i = 0; i < data.length; i++) {
            if (data[i].id === vm.selectedTelegramChat.id) {
              data.splice(i, 1);
            }
          }

          vm.dtInstance.clear();
          vm.dtInstance.rows.add(data);
          vm.dtInstance.draw();

          vm.instance.telegramChats = vm.telegramChats = data;
          vm.telegramChatsMap = utils.arrayToObject(vm.telegramChats, 'id');
          vm.selectedTelegramChat = null;

          $('th.select-radio-btn')
            .parent()
            .removeClass('selected');
        })
        .catch(() => { });
    }

    function checkboxRendered() {
      return '';
    }
  }
})();
