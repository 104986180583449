(function () {
  let angular = window.angular;
  let $ = window.jQuery;

  Controller.$inject = [
    '$element',
    '$timeout',
    '$uibModal',
    'Customer',
    'AppUtils',
    '$translate'
  ];

  angular.module('app')
    .component('deviceModulesTable', {
      template:'<div class="dataTable-toolbar paddingT0" ng-if="ctrl.logs"><div class="btn-group btn-group-sm"><button class="btn btn-default btn-label disabled" translate>general.actions</button> <button class="btn btn-info btn-sm" ng-click="ctrl.showModuleLogs()" ng-disabled="!ctrl.selectedModule" title="Logs" style="width: 80px">Logs</button></div></div><table id="modules-table" class="table table-hover no-wrap dt-details-title-hidden" style="width: 100%"><thead><tr><th></th><th>Name</th><th class="none"></th><th class="control"></th></tr></thead></table>',
      controller: Controller,
      controllerAs: 'ctrl',
      bindings: {
        device: '<',
        logs: '<'
      }
    });

  function Controller ($element, $timeout, $uibModal, Customer, utils, $translate) {
    let vm = this;

    vm.$onInit = function onInit () {
      if (!vm.device) {
        return;
      }
      vm.modules = getModules();
      vm.showModuleLogs = showModuleLogs;
    };

    vm.$onChanges = function (changes) {
      if (changes && changes.device) {
        vm.modules = getModules();
        vm.selectedModule = null;
        initTable(vm.modules);
      }
    };

    function initTable (data) {
      if (vm.dtInstance) {
        vm.dtInstance.clear();
        vm.dtInstance.rows.add(data);
        vm.dtInstance.draw();
        return;
      }

      let dtOptions = {
        dom:
          '<\'row\'<\'col-xs-12 col-sm-6 col-md-4\'f><\'col-sm-6\'l>>' +
          '<div tr>' +
          '<\'row\'<\'col-sm-5\'i><\'col-sm-7\'p>>',
        processing: true,
        order: [1, 'asc'],
        paging: false,
        info: false,
        data: data,
        select: {
          selector: 'td:not(.control)',
          style: 'single'
        },
        columns: [
          {
            data: null,
            orderable: false,
            className: 'select-radio-btn',
            render: () => '',
            width: '10px'
          },
          { data: 'name', title: $translate.instant('entities._all.name') },
          {
            data: 'properties',
            title: '',
            render: (value) => {
              return `<pre class="no-margin">${JSON.stringify(value, null, 2)}</pre>`;
            }
          },
          {
            data: null,
            render: () => '',
            orderable: false,
            width: '10px'
          }
        ],
        columnDefs: [
          {
            className: 'control',
            orderable: false,
            targets: [-1, -2]
          }
        ],
        responsive: {
          details: {
            type: 'column',
            target: -1
          }
        }
      };

      let table = $element.find('#modules-table');
      let dtInstance = (vm.dtInstance = table.DataTable(dtOptions));

      table.find('thead th').removeClass('select-radio-btn');

      dtInstance
        .on('select', function () {
          $timeout(function () {
            const row = dtInstance
              .rows({ selected: true })
              .data()
              .toArray()[0];
            vm.selectedModule = row ? row.name : null;
          });
        })
        .on('deselect', function () {
          $timeout(function () {
            vm.selectedModule = null;
          });
        });
    }

    function showModuleLogs () {
      $uibModal
        .open({
          animation: true,
          backdrop: 'static',
          windowClass: 'modal-full',
          bindToController: true,
          component: 'connectionDeviceLogs',
          resolve: {
            device: () => vm.device,
            module: () => vm.selectedModule
          }
        })
        .result
        .catch(err => {});
    }

    function getModules () {
      return vm.device.edgeAgents.reduce((modules, agent) => {
        if (agent.enabled) {
          Object.keys(agent.modules).forEach(moduleName => {
            modules.push({ name: moduleName, properties: agent.modules[moduleName] });
          });
        }
        return modules;
      }, []);
    }
  }
})();
