;(function () {
  'use strict';

  let angular = window.angular;
  EmbeddedController.$inject = ['Customer', '$timeout', '$state', '$sce', '$uibModal'];

  angular
    .module('app')
    .component('embeddedReports', {
      template:'<div class="reports"><spinner show="ctrl.loading" css-class="\'small\'"></spinner><div ng-if="!ctrl.loading"><div class="absolute"><view-sidebar><view-sidebar-title>Reports<div class="btn-group pull-right"><a class="btn btn-primary btn-sm" ng-click="ctrl.showReportForm()" title="Create a report"><i class="fas fa-plus"></i></a></div></view-sidebar-title><view-sidebar-content><js-tree data="ctrl.reportsTree" selected="ctrl.reportId" hide-icons="true" on-select="ctrl.onSelectReport(selected)"></js-tree></view-sidebar-content></view-sidebar><div><view-sidebar-toggle></view-sidebar-toggle><div class="absolute margin10" style="top: 50px !important;"><div ng-if="!ctrl.selectedReport"><h1 class="text-center" style="margin-top: 200px"><i class="fas fa-arrow-circle-left"></i> Select a Report</h1></div><div ng-if="ctrl.selectedReport"><embedded-container class="absolute" options="ctrl.selectedReport" show-report-form="ctrl.showReportForm(ctrl.selectedReport)" remove-report="ctrl.removeReport(ctrl.selectedReport)" ng-if="ctrl.selectedReport"></embedded-container></div></div></div></div></div></div>',
      controller: EmbeddedController,
      controllerAs: 'ctrl',
      bindings: {
        $transition$: '<',
        reports: '<',
        customer: '<',
        project: '<'
      }
    });

  function EmbeddedController (Customer, $timeout, $state, $sce, $uibModal) {
    let vm = this;

    vm.$onInit = function () {
      vm.showReportForm = showReportForm;
      vm.showReport = showReport;
      vm.removeReport = removeReport;
      vm.onSelectReport = onSelectReport;

      vm.selectedReport = null;
      vm.reportsTree = vm.reports.tree;
      vm.reports = vm.reports.reports;

      if (vm.$transition$.params().reportId) {
        vm.reportId = vm.$transition$.params().reportId;
      }
    };

    function showReportForm (report) {
      let instance = $uibModal
        .open({
          animation: false,
          bindToController: true,
          backdrop: 'static',
          component: 'embeddedForm',
          resolve: {
            report: function () {
              return report || {};
            },
            project: function () {
              return vm.project;
            },
            customer: function () {
              return vm.customer;
            }
          }
        });

      instance
        .result
        .then(function (result) {
          $state.go('.', { reportId: result.id }, { reload: true });
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function onSelectReport (reportId, noRedirect) {
      let report = vm.reports.find(rep => rep.id === reportId);
      if (!report) {
        $state.go('.', { reportId: null });
        return;
      }

      if (!noRedirect) {
        $state.go('.', { reportId: reportId });
      }

      vm.reportId = reportId;
      showReport(report);
    }

    function showReport (report) {
      $timeout(function () {
        report.trustSrc = trustSrc(report.source);
        vm.selectedReport = report;
      });
    }

    function removeReport (report) {
      let instance = $uibModal
        .open({
          animation: false,
          bindToController: true,
          backdrop: 'static',
          windowClass: 'modal-xs modal-warning',
          component: 'embeddedRemove',
          resolve: {
            report: function () {
              return report;
            },
            project: function () {
              return vm.project;
            },
            customer: function () {
              return vm.customer;
            }
          }
        });

      instance
        .result
        .then(function (result) {
          $state.go('.', { reportId: null });
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    function trustSrc (url) {
      return $sce.trustAsResourceUrl(url);
    }

  }
})();
