; (function () {
  'use strict';

  const angular = window.angular;

  angular
    .module('app')
    .directive('spinner', () => {
      return {
        restrict: 'E',
        scope: {
          show: '=',
          text: '=',
          cssClass: '=',
          showLogo: '=?'
        },
        template: `<div class="spinner-container {{cssClass}}" ng-hide="!show">
          <div class="text-spinner">
            <div class="spinner" ng-class="{'spinner-ready': !show}">
              <div class="spinner-bg"></div>
              <div class="spinner-logo" ng-if="showLogo"></div>
            </div>
            <div class="spinner-message">
               {{text}}
            </div>
          </div>
        </div>`,
        controller: ['$scope', ($scope) => {
          $scope.showLogo = typeof $scope.showLogo === 'boolean' ? $scope.showLogo : true;
        }]
      };
    });
})();

