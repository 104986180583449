;(function () {
  'use strict';

  const angular = window.angular;

  angular
    .module('app')
    .directive('textComplete', ['$timeout', function ($timeout) {
      return {
        restrict: 'E',
        require: '^ngModel',
        scope: {
          ngModel: '=',
          ngChange: '&',
          ngModelOptions: '=?',
          state: '=',
          ngBlur: '&',
          ngRequired: '=?',
          placeholder: '@?',
          options: '=',
          instance: '=?'
        },
        template: `<textarea class="form-control" rows="6"
            placeholder="{{placeholder}}" ng-required="ngRequired"
            ng-change="onChange()" ng-blur="ngBlur()"
            ng-model-options="ngModelOptions"
            ng-model="model.value"/>`,
        link: link
      };


      function link (scope, el, attrs, ctrl) {
        scope.model = {};
        scope.instance = scope.instance || {};

        const textArea = el.find('textarea');

        const editor = new window.Textcomplete.editors.Textarea(textArea[0]);
        const textComplete = new window.Textcomplete(editor);

        scope.instance.element = textArea;
        scope.instance.editor = editor;
        scope.instance.textComplete = textComplete;

        scope.onChange = function () {
          ctrl.$setViewValue(scope.model.value);
          if (scope.state) {
            scope.state.bussy = false;
          }

        };

        el.find('textarea').on('input', () => {
          $timeout(() => {
            if (scope.state) {
              scope.state.bussy = true;
            }
          });

        });

        scope.$watch('ngModel', function (newValue) {
          if (newValue !== scope.model.value) {
            scope.model.value = newValue;
          }
        });

        scope.$watch('options', function (newValue, oldValue) {
          if (scope.options && !scope.registered) {
            const options = Array.isArray(scope.options) ? scope.options : [scope.options];
            textComplete.register(options);
            scope.registered = true;
          }
        });

        scope.$on('$destroy', function () {
          try {
            if (textComplete) {
              textComplete.destroy();
            }
            editor.destroy();
          } catch (e) {

          }

        });
      }
    }]);
})();
