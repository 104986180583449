;(function () {
  'use strict'

  RTImageModalController.$inject = []

  window.angular
    .module('app')
    .component('rtImageModal', {
      controller: RTImageModalController,
      controllerAs: 'ctrl',
      bindings: {
        resolve: '<'
      },
      template:'<rt-image sensor="ctrl.resolve.sensor" asset-name="ctrl.resolve.assetName" state="ctrl.resolve.state" data="ctrl.resolve.data" hide-expand="true"></rt-image>'
    })

  function RTImageModalController () {
  }
})()
