(function () {
  'use strict';

  const angular = window.angular;
  angular.module('app').directive('jsonEditor', function () {
    return {
      restrict: 'E',
      scope: {
        config: '=',
        json: '=',
        watchArrays: '=',
        height: '=',
        editorInstance: '=?',
      },
      link: renderEditor,
      template: `<div class="json-container" ng-style="{height: (height || 400) + 'px'}"></div>`,
    };
  });

  function renderEditor(scope, element) {
    const jsonContainer = element.find('.json-container')[0];
    if (!jsonContainer || !scope.config) {
      return;
    }
    const jsonEditor = new window.JSONEditor(jsonContainer, scope.config);

    if (scope.json) {
      jsonEditor.set(scope.json);
    }

    if (scope.editorInstance) {
      scope.editorInstance = {
        target: jsonContainer,
        editor: jsonEditor,
      };
    }

    scope.$watch('json', function (v) {
      jsonEditor.set(scope.json ? scope.json : {});
    });

    scope.$watch('config.mode', function () {
      const modes = ['tree', 'view', 'form', 'code', 'text', 'preview'];
      const currentMode = jsonEditor ? jsonEditor.getMode() : null;

      if (modes.indexOf(scope.config.mode) !== -1 && scope.config.mode !== currentMode) {
        jsonEditor.setMode(scope.config.mode);
      }
    });

    if (scope.watchArrays) {
      scope.watchArrays =
        typeof scope.watchArrays === 'string' ? [scope.watchArrays] : scope.watchArrays;
      for (const array of scope.watchArrays) {
        scope.$watch(`json.${array}.length`, () => {
          jsonEditor.update(scope.json);
        });
      }
    }
  }
})();
