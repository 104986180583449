(() => {
  angular.module('app').directive('dashboardRowSection', () => ({
    restrict: 'E',
    transclude: true,
    template:'<section class="dashboard-row-section"><fieldset><legend><span>{{label}}</span></legend></fieldset><div><ng-transclude></ng-transclude></div></section>',
    controller: () => { },
    scope: {
      label: '<',
    },
  }));
})();
