(() => {
  /**
   * Component for displaying video activity chart
   */
  angular.module('app').component('videoActivityChart', {
    template:'<div class="variation-chart" style="height: 50px"></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      variation: '<',
      stddev: '<',
      min: '<',
      max: '<',
      avg: '<',
      date: '<',
      videoElement: '<',
    },
  });

  Controller.$inject = ['$element', '$interval', 'AppUtils', '$rootScope'];

  function Controller($element, $interval, utils, $root) {
    const vm = this;
    let firstRun = true;
    let inProgress = false;

    vm.$onInit = () => {
      vm.container = $element.find('.variation-chart')[0];
      renderVariation();
      firstRun = false;
    };

    vm.$onChanges = (changes) => {
      if (firstRun) {
        return;
      }

      if (changes.variation && vm.variation?.length > 0 && !inProgress) {
        renderVariation();
      }
    };

    function renderVariation() {
      if (!vm.variation || vm.variation.length === 0) {
        if (vm.chart) {
          echarts.dispose(vm.chart);
        }

        return;
      }
      //Prevent multiple chart rendering
      inProgress = true;
      loadChartVendors().then(() => {
        const date = new Date(vm.date);

        // Convert variation to chart data
        const data = vm.variation.map((item) => {
          const t = new Date(date.setSeconds(date.getSeconds() + 1));
          return [t.getTime(), item];
        });

        // Add 10% to min and max to avoid chart being cut
        const min = Math.min(...vm.variation) * 0.9;
        const max = Math.max(...vm.variation) * 1.1;

        const options = {
          tooltip: {
            trigger: 'axis',
            appendToBody: true,
            formatter: (series) => {
              const data = series[0].data;
              const value = data[1];
              return `${value.toFixed(2)}`;
            },
          },
          xAxis: {
            type: 'time',
            axisLabel: {
              show: false,
            },
          },
          grid: {
            left: 10,
            top: 10,
            right: 10,
            bottom: 10,
          },
          yAxis: {
            type: 'value',
            show: false,
            min: min,
            max: max,
          },
          series: [
            {
              data: data,
              type: 'bar',
              name: 'Variation',
              showSymbol: false,
            },
          ],
        };

        if (vm.chart) {
          echarts.dispose(vm.chart);
        }

        if (!vm.chart || vm.chart.isDisposed()) {
          vm.chart = echarts.init(vm.container, $root.darkMode ? 'dark' : 'default');
        }

        vm.chart.clear();
        vm.chart.setOption(options);
        vm.chart.resize();

        // Set the selected second to the video
        vm.chart.on('select', function (params) {
          if (!vm.videoElement) {
            return;
          }
          const index = params.dataIndexInside;
          vm.videoElement.currentTime = index;
        });

        inProgress = false;
      });
    }

    /**
     * Load chart vendors if not loaded
     */
    function loadChartVendors() {
      return utils.Promise((resolve, reject) => {
        if (window.echarts) {
          return resolve();
        }

        const theme = $root.darkMode ? 'dark' : 'light';

        $.cachedScript(
          'chart-vendors',
          window.assetsPath + `/js/vendors/chart-vendors-${theme}.min.js`,
          (err, script, loaded) => {
            if (err) {
              return reject(err);
            }

            if (loaded) {
              return resolve();
            }

            if (!loaded) {
              return resolve(waitForChartVendors());
            }
          }
        );
      });
    }

    function waitForChartVendors() {
      let count = 0;
      return utils.Promise((resolve, reject) => {
        const interval = $interval(() => {
          count++;
          if (window.echarts) {
            $interval.cancel(interval);
            return resolve();
          }

          if (count > 20) {
            $interval.cancel(interval);
            return reject('Timeout waiting for chart vendors');
          }
        }, 100);
      });
    }
  }
})();
